import { EditableCell } from "../../../components/utils/EditableCell";

export const businessActivitiesColumns = [
  {
    Header: "S.No",
    accessor: "sNo",
    type: "number",
    Cell: EditableCell,
  },
  {
    Header: "Description of Main Activity ",
    accessor: "mainActivity",
    Cell: EditableCell,
  },
  {
    Header: "Description of Business Activity ",
    accessor: "businessActivity",
    Cell: EditableCell,
  },
  {
    Header: "% of Turnover of the entity",
    accessor: "turnover",
    type: "number",
    maxLength: 3,
    Cell: EditableCell,
  },
];

export const businessActivitiesCellData = [
  {
    id: "1",
    sNo: "",
    mainActivity: "",
    businessActivity: "",
    turnover: "",
  },
  {
    id: "2",
    sNo: "",
    mainActivity: "",
    businessActivity: "",
    turnover: "",
  },
];
