import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Checkbox,
} from "@mui/material";
import { Tooltip, Zoom, Typography, styled } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { accordionData } from "../../constant/index";
import { accordionStyles } from "../../styles/index";
import { getFormDataByUser } from "../../api/api";
import { useLocation } from "react-router-dom";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import ElectronicFormContext from "../../Context";
import Context from "../../context/Context";
import CustomImageUploadAdmin from "./CustomImageUploadAdmin";
import axios from "axios";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export function CustomizedAccordions({
  keyProps,
  chileKeyPros,
  handleFilterVal,
}) {
  const [expanded, setExpanded] = useState("panel");
  const [childexpanded, setnewExpanded] = useState("panel");
  const location = useLocation();
  const pathName = location.pathname;
  const [sidebarData, setSidebarData] = useState(accordionData);
  const [filterById, setFilterById] = useState("");
  const [filterByName, setfilterByName] = useState("");
  const forBRSRReport = useContext(Context);
  const { setBRSRReportRef } = forBRSRReport;
  const existingTableData = useContext(ElectronicFormContext);
  const [updateCompletion, setUpdateCompletion] = useState("");
  const [updateFailedCompletion, setFailedCompletion] = useState("");
  const [updateSuccessCompletion, setUpdateSuccessCompletion] = useState(true);


  const updateCompletionApi = () => {
    const url = `${cookies.get("baseURL")}/updateCompletionStatus`;
    const data = {};
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(url, data, { headers })
      .then((response) => {
        if(response.data.success === "true"){
          setUpdateCompletion("Successfully updated");
          setUpdateSuccessCompletion(false);
          removeAlertSms();
          return;
        }
        setFailedCompletion("something went wrong");
        removeFailedAlertSms();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeAlertSms = () => {
    setTimeout(() => {
      setUpdateCompletion("");
    }, 2000);
  };

  const removeFailedAlertSms = () => {
    setTimeout(() => {
      setFailedCompletion("");
    }, 2000);
  };
  

  const childhandleChange = (panel) => (event, newExpanded) => {
    if (childexpanded === panel) {
      return setnewExpanded(false);
    }
    setnewExpanded(panel);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    // setnewExpanded(false);
  };

  const {
    toggleBtn,
    accordionContainer,
    acitveToggleBtn,
    buttonCheck,
    buttonCheckWrap,
  } = accordionStyles();
  const navigate = useNavigate();

  // toggle button
  const [view, setView] = useState("individualUsers");
  const handleChangeToggler = (event, nextView) => {
    setExpanded("panel3");
    setView(nextView);
  };

  //userAccess details
  const cookies = new Cookies();
  const customerId = cookies.get("custId");
  const userId = cookies.get("userId");
  const userTypeName = cookies.get("userTypeName");
  const [userAccessDetails, setUserAccessDetails] = useState(null);
  const [leadershipIndicatorApi, setLeadershipIndicatorApi] = useState(false);

  const token = cookies.get("token");
  const completionStatus = cookies.get("completionStatus");

  useEffect(() => {
    if (customerId) {
      getFormDataByUser(customerId)
        .then((response) => {
          setLeadershipIndicatorApi(response.data.leadershipIndicator);
          const accessData = response.data.formAccess.filter((item) => {
            return item.userId === userId;
          });
          setUserAccessDetails(accessData[0]?.formAccess);
        })
        .catch((error) => console.log(error));
    }
  }, [customerId, userId]);

  let filteringCheckbox =
    existingTableData &&
    existingTableData.map((data) =>
      data.section.split("/").join("").slice(0, 17)
    );
  const filteringCheckboxlower =
    filteringCheckbox &&
    filteringCheckbox.map((element) => {
      return element.toLowerCase();
    });

  const eachButtons = (item, index) => {
    let filteringSubItem = item.path
      .split("/")
      .join("")
      .toLowerCase()
      .slice(0, 17);

    return (
      <div style={{ display: "flex" }}>
        <Tooltip
          title={<p>{item.tooltipContent}</p>}
          placement="right"
          arrow
          TransitionComponent={Zoom}
          key={index}
        >
          <ToggleButton
            value={item.value}
            key={item.id}
            className={pathName === item.path ? acitveToggleBtn : toggleBtn}
            onChange={() => navigate(item.path)}
          >
            {item.name}
          </ToggleButton>
        </Tooltip>
        {userTypeName === "Company Admin" && (
          <Checkbox
            checked={
              filteringCheckboxlower &&
              filteringCheckboxlower.includes(filteringSubItem)
            }
          />
        )}
      </div>
    );
  };

  // let apiData = false;

  useEffect(() => {
    if (keyProps) {
      setExpanded(keyProps);
      if (chileKeyPros) setnewExpanded(chileKeyPros);
    }
  }, [keyProps, chileKeyPros]);

  let filteringCheckboxPrinciple =
    existingTableData &&
    existingTableData
      .filter((data) => data.section.slice(0, 8) === "sectionC")
      .map((data) => data.section.split("/").join("").toLowerCase());

  const eachButtonsPrinciple = (item, index) => {
    let filteringSubItem = item.path.split("/").join("").toLowerCase();
    // console.log(filteringSubItem);
    return item?.leadershipIndicators ? (
      leadershipIndicatorApi && (
        <div style={{ display: "flex" }}>
          <Tooltip
            title={<p>{item.tooltipContent}</p>}
            placement="right"
            arrow
            TransitionComponent={Zoom}
            key={index}
          >
            <ToggleButton
              value={item.value}
              key={item.id}
              className={pathName === item.path ? acitveToggleBtn : toggleBtn}
              // onClick={toggleDrawer("left", false)}
              onChange={() => navigate(item.path)}
            >
              {item.name}
            </ToggleButton>
          </Tooltip>
          {userTypeName === "Company Admin" && (
            <Checkbox
              checked={
                filteringCheckboxPrinciple &&
                filteringCheckboxPrinciple.includes(filteringSubItem)
              }
            />
          )}
        </div>
      )
    ) : (
      <div style={{ display: "flex" }}>
        <Tooltip
          title={<p>{item.tooltipContent}</p>}
          placement="right"
          arrow
          TransitionComponent={Zoom}
          key={index}
        >
          <ToggleButton
            value={item.value}
            key={item.id}
            className={pathName === item.path ? acitveToggleBtn : toggleBtn}
            // onClick={toggleDrawer("left", false)}
            onChange={() => navigate(item.path)}
          >
            {item.name}
          </ToggleButton>
        </Tooltip>
        {userTypeName === "Company Admin" && (
          <Checkbox
            checked={
              filteringCheckboxPrinciple &&
              filteringCheckboxPrinciple.includes(filteringSubItem)
            }
          />
        )}
      </div>
    );
  };

  const toggleCheckbox = (item) => {
    setFilterById(item.id);
    //setfilterByName(item.name)
    //window.sessionStorage.setItem("itemName",item.name)
    //console.log("item item1",item.name)
    const toggleValue = !item.isCheck;
    item.isCheck = toggleValue;
    if (item.buttons) {
      item.buttons.forEach((itm) => {
        itm.isCheck = toggleValue;
        if (itm.buttons) {
          itm.buttons.forEach((it) => {
            it.isCheck = toggleValue;
          });
        }
      });
    }
    setSidebarData([...sidebarData]);
  };
  let sectionfilter = sidebarData
    .filter((data) => data.isCheck)
    .map((filterData) => filterData.value);

  let sectionPrincipleDataFilter = sidebarData
    .filter((data) => data.name === "SECTION C")
    .pop()
    .buttons.map((data) => data.buttons)
    .flat()
    .filter((data) => data.isCheck)
    .map((filterData) => filterData.value);

  let filteringDataForBRSR = sidebarData.map((data) => data.buttons).flat();
  let newArryFilter = filteringDataForBRSR.filter((data) => data.isCheck);

  cookies.set("filteredSectionData", sectionfilter, {
    path: "/",
  });
  cookies.set("filteredSectionCData", sectionPrincipleDataFilter, {
    path: "/",
  });
  cookies.set("filteredData", newArryFilter, {
    path: "/",
  });

  const renderButtons = (accordData) => {
    return accordData?.map((data, i) => {
      let isCheck = data.isCheck;
      if (data.buttons) {
        isCheck = data.buttons.every((item) => item.isCheck);
      }
      return (
        <Accordion
          expanded={expanded === data.key}
          onChange={handleChange(data.key)}
          key={i}
        >
          <Typography variant="subtitle1">
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              {data.name}
            </AccordionSummary>
            {/* <Checkbox
              checked={isCheck}
              type="checkbox"
              onClick={() => {
                toggleCheckbox(data);
              }}
              value={data.value}
            />
            <label>All</label> */}
          </Typography>

          <AccordionDetails>
            <ToggleButtonGroup orientation="vertical" value={view} exclusive>
              {data.buttons.map((item, index) => {
                if (item.buttons) {
                  return (
                    <div
                      className={buttonCheckWrap}
                      onChange={handleChangeToggler}
                    >
                      <div className="buttonCheck">
                        {userTypeName === "Company User"
                          ? [item]?.map(
                              (subItem) =>
                                userAccessDetails?.tableChecked?.hasOwnProperty(
                                  subItem.value
                                ) && renderSubButtons(subItem, index)
                            )
                          : renderSubButtons(item, index)}
                      </div>
                    </div>
                  );
                }
                // let filteringCheckbox =
                //   existingTableData &&
                //   existingTableData.map((data) =>
                //     data.section.split("/").join("").slice(0, 17)
                //   );
                // const filteringCheckboxlower =
                //   filteringCheckbox &&
                //   filteringCheckbox.map((element) => {
                //     return element.toLowerCase();
                //   });
                return userTypeName === "Company User"
                  ? [item].map((subItem, index) => {
                      //  console.log("all Item", item, "all SUb item", subItem)
                      // let filteringSubItem = subItem.path
                      //   .split("/")
                      //   .join("")
                      //   .toLowerCase()
                      //   .slice(0, 17);
                      let isCheck = subItem.isCheck;
                      if (subItem.buttons) {
                        isCheck = subItem.buttons.every((item) => item.isCheck);
                      }
                      return (
                        <div
                          className={buttonCheckWrap}
                          onChange={handleChangeToggler}
                        >
                          <div className="buttonCheck">
                            {userAccessDetails?.tableChecked[data.value][
                              subItem.value
                            ] && eachButtons(item, index)}
                          </div>
                          {/* <Checkbox
                            checked={
                              isCheck
                              // &&
                              // userTypeName === "Company User"
                              //   ?
                              //   isCheck
                              //   :
                              //   filteringCheckboxlower.includes(filteringSubItem)
                            }
                            type="checkbox"
                            onClick={() => {
                              toggleCheckbox(subItem);
                              handleFilterVal(filterById, filterByName);
                            }}
                            value={subItem.value}
                          /> */}
                        </div>
                      );
                    })
                  : eachButtons(item, index);
              })}
            </ToggleButtonGroup>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const renderSubButtons = (subAccordion, i) => {
    let isCheck = subAccordion.isCheck;
    if (subAccordion.buttons) {
      subAccordion.isCheck = subAccordion.buttons.every((item) => item.isCheck);
      isCheck = subAccordion.isCheck;
    }
    return (
      <Accordion expanded={childexpanded === subAccordion.key} key={i}>
        <Typography variant="subtitle1">
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            onClick={childhandleChange(subAccordion.key)}
          >
            {subAccordion.name}
          </AccordionSummary>
          {/* <Checkbox
            checked={isCheck}
            type="checkbox"
            onClick={() => {
              toggleCheckbox(subAccordion);
            }}
            value={subAccordion.value}
          />
          <label>All</label> */}
        </Typography>
        <AccordionDetails>
          <ToggleButtonGroup orientation="vertical" value={view} exclusive>
            {subAccordion.buttons.map((item, index) => {
              return userTypeName === "Company User"
                ? [item].map((subItem) => {
                    let isCheck = subItem.isCheck;

                    if (subItem.buttons) {
                      isCheck = subItem.buttons.every((item) => item.isCheck);
                    }
                    return (
                      <div className={buttonCheckWrap}>
                        <div
                          className="buttonCheck"
                          onChange={handleChangeToggler}
                        >
                          {userAccessDetails?.tableChecked[subAccordion.value][
                            subItem.value
                          ] && eachButtonsPrinciple(item, index)}
                        </div>
                        {subItem.leadershipIndicators === true ? (
                          ""
                        ) : (
                          <></>
                          // <Checkbox
                          //   checked={isCheck}
                          //   type="checkbox"
                          //   onClick={() => {
                          //     toggleCheckbox(subItem);
                          //   }}
                          //   value={subItem.value}
                          // />
                        )}
                      </div>
                    );
                  })
                : eachButtonsPrinciple(item, index);
            })}
          </ToggleButtonGroup>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div className={accordionContainer}>
      {userTypeName === "Company User"
        ? sidebarData.map((item) =>
            userAccessDetails?.tableChecked?.hasOwnProperty(item.value)
              ? renderButtons([item])
              : item.buttons.some((subItem) =>
                  userAccessDetails?.tableChecked?.hasOwnProperty(subItem.value)
                ) && renderButtons([item])
          )
        : renderButtons(sidebarData)}
      {userTypeName === "Company Admin" && (
        <Button
          variant="outlined"
          style={{ width: "100%", margin: "15px 0" }}
          onClick={() => {
            navigate("/printPdf");
            setBRSRReportRef(true);
          }}
        >
          BRSR Report
        </Button>
      )}
      { updateCompletion && updateCompletion ? <div className="successalert">{updateCompletion}</div> : '' }
      { updateFailedCompletion && updateFailedCompletion ? <div className="successalertfailed">{updateFailedCompletion}</div> : '' }
      
      {userTypeName !== "Company Admin" ? (
        <>
        {completionStatus === "In Progress" || completionStatus === "Rejected" ? <Button
          variant="outlined"
          style={{ width: "100%", margin: "15px 0" }}
          onClick={updateCompletionApi}
        >Send to Admin</Button> : ""}
        </>
      ) : "" }
      
    </div>
  );
}
