export const publicPolicyContent = [
  {
    id: "1",
    title:
      "PRINCIPLE 7 Businesses, when engaging in influencing public and regulatory policy, should do so in a manner that is responsible and transparent",
    aboutTable: "Leadership Indicators",
    subAboutTable:
      "1. Details of public policy positions advocated by the entity:",
  },
];
