export const cyberSecurityOptions = ["Yes", "No"];

export const cyberSecurityInitialvalues = {
  security: "",
  policy: "",
};

export const cyberSecurityConstants = [
  {
    id: 1,
    label:
      "5. Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) ",
    name: "security",
    field: "radioButton",
  },
  {
    id: 2,
    label: "If available, provide a web-link of the policy.",
    name: "policy",
    field: "textField",
  },
];
