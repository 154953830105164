import React, { useContext, useEffect, useState, useMemo } from "react";
import ElectronicFormContext from "../../../../Context";
import { createTableValues, updateTableValues } from "../../../../api/method";
import { TableWrapper } from "../../../reusable/index";
import {
  SECTIONA_BUSINESSACTIVITIES,
  ROW_ADDINGTABLE,
  businessActivitiesColumns,
  businessActivitiesCellData,
  businessActivitiesContents,
} from "../../../../constant/index";
import Cookies from "universal-cookie";

function BusinessActivities() {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(businessActivitiesCellData);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const buisnessFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_BUSINESSACTIVITIES
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);
    buisnessFormConfig
      ? updateTableValues(
          SECTIONA_BUSINESSACTIVITIES,
          { businessActivities: `${jsonStr}` },
          buisnessFormConfig.id
        )
      : createTableValues(SECTIONA_BUSINESSACTIVITIES, {
          businessActivities: `${jsonStr}`,
        });
  };
  useEffect(() => {
    if (buisnessFormConfig) {
      // console.log(buisnessFormConfig, '=====================');
      setParentData(JSON.parse(buisnessFormConfig.data.businessActivities));
    }
  }, [buisnessFormConfig]);

  return (
    <TableWrapper
      columns={businessActivitiesColumns}
      cellData={parentData}
      storeTableData={storeTableData}
      tableType={ROW_ADDINGTABLE}
      modalContent={businessActivitiesContents}
      keyProps={"panel1"}
    />
  );
}

export default BusinessActivities;
