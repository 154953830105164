import { EditableCell } from "../../../../components/utils/EditableCell";

export const projectsColumns = [
  {
    Header: "S. No.",
    accessor: "sNo",
    type: "number",
    Cell: EditableCell,
  },
  {
    Header: "State",
    accessor: "state",
    Cell: EditableCell,
  },
  {
    Header: "Aspirational District ",
    accessor: "district",
    Cell: EditableCell,
  },
  {
    Header: "Amount spent (In INR)",
    accessor: "amount",
    type: "number",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const projectsCellData = columnName.map((item) => ({
  id: item.id,
  sNo: "",
  state: "",
  district: "",
  amount: "",
}));
