import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  membershipColumns,
  membershipInitialvalues,
  membershipContent,
  STATIC_TABLE,
  PRINCIPLE3_MEMBERSHIP,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const MembershipOfEmployees = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(membershipInitialvalues);
  const formik = useFormik({
    initialValues: parentData || membershipInitialvalues,
    enableReinitialize: true,
  });

  const { values, setFieldValue } = formik;
  //to get data from api
  const membershipFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_MEMBERSHIP
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE3_MEMBERSHIP,
        { membershipOfEmployees: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      membershipFormConfig
        ? updateTableValues(
            PRINCIPLE3_MEMBERSHIP,
            { membershipOfEmployees: `${jsonStr}` },
            membershipFormConfig.id
          )
        : createTableValues(PRINCIPLE3_MEMBERSHIP, {
            membershipOfEmployees: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      membershipFormConfig &&
      membershipFormConfig.data.membershipOfEmployees
    ) {
      setParentData(
        JSON.parse(membershipFormConfig.data.membershipOfEmployees)
      );
    }
  }, [membershipFormConfig]);

  return (
    <>
      <TableWrapper
        columns={membershipColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          membershipFormConfig &&
          !membershipFormConfig.data.membershipOfEmployees
            ? membershipFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={membershipContent}
        keyProps={"panel3"}
        chileKeyPros={"principle3"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={membershipColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={membershipContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default MembershipOfEmployees;
