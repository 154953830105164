export const CSRdetailsOptions = ["Yes", "No"];

export const CSRdetailsContent = {
  id: 1,
  title: " 22. CSR Details",
};

export const CSRdetailsInitialvalues = {
  applicable: "",
  turnover: "",
  netWorth: "",
};

export const CSRdetailsConstants = [
  {
    id: 1,
    name: "applicable",
    label:
      "  (i) Whether CSR is applicable as per section 135 of Companies Act, 2013:",
    field: "radioButton",
  },
  {
    id: 2,
    name: "turnover",
    label: "(ii) Turnover (in Rs.)",
    field: "textField",
    type: "number",
  },
  {
    id: 3,
    name: "netWorth",
    label: "(iii) Net worth (in Rs.)",
    field: "textField",
    type: "number",
  },
];
