import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Modal, Typography } from "@mui/material";
import { CustomTextarea } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  assessmentsP5Columns,
  assessmentsP5Initialvalues,
  assessmentsP5ConstantData,
  assessmentsP5Content,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE5_ASSESSMENT,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import CustomImageUpload from "../../../../reusable/CustomImageUpload";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
  contentStyle: {
    marginTop: "20px",
  },
}));

const AssessmentsP5 = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(assessmentsP5Initialvalues);
  const { list, contentStyle } = useStyles();

  //to get data from api
  const assessmentsP5FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE5_ASSESSMENT
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE5_ASSESSMENT,
        { assessmentsP5: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      assessmentsP5FormConfig
        ? updateTableValues(
            PRINCIPLE5_ASSESSMENT,
            { assessmentsP5: `${jsonStr}` },
            assessmentsP5FormConfig.id
          )
        : createTableValues(PRINCIPLE5_ASSESSMENT, {
            assessmentsP5: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (assessmentsP5FormConfig && assessmentsP5FormConfig.data.assessmentsP5) {
      setParentData(JSON.parse(assessmentsP5FormConfig.data.assessmentsP5));
    }
  }, [assessmentsP5FormConfig]);

  const formik = useFormik({
    initialValues: parentData || assessmentsP5Initialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, handleBlur } = formik;

  const renderFormFields = () => {
    return (
      <>
        {assessmentsP5ConstantData.map(({ id, label, name, upload }) => (
          <div key={id} className={contentStyle}>
            <Typography className={list}>{label}</Typography>

            <CustomTextarea
              name={name}
              onChange={handleChange}
              value={values[name]}
              onBlur={handleBlur}
              variant={"outlined"}
              style={{ fontSize: "16px" }}
            />
            <div className="" style={{ marginTop: "10px" }}>
              {upload === "uploadImage" && <CustomImageUpload />}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={assessmentsP5Columns}
        storeTableData={storeTableData}
        cellData={
          assessmentsP5FormConfig && !assessmentsP5FormConfig.data.assessmentsP5
            ? assessmentsP5FormConfig.data
            : parentData.tableData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        modalContent={assessmentsP5Content}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle5"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={assessmentsP5Columns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={assessmentsP5Content}
          />
        </div>
      </Modal>
    </>
  );
};

export default AssessmentsP5;
