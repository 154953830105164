import { EditableCell } from "../../../components/utils/EditableCell";
import { CustomTextField } from "../../../components/reusable/index";

export const transparencyAndDisclosureColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Stake holder group from whom complaint is received ",
      // accessor: "stakeholder",
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "stakeholder",
        },
      ],
    },
    {
      Header:
        "Grievance Redressal Mechanism in Place (Yes/No) (If Yes, then provide web-link for grievance redress policy) ",
      // accessor: "redressalMechanism",
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "redressalMechanism",
          Cell: EditableCell,
        },
      ],
    },

    {
      id: 1,
      Header: () => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          Current Financial Year
        </span>
      ),
      columns: [
        {
          Header: "Number of complaints filed during the year",
          accessor: "complaintsFiled",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header:
            "Number of complaints pending resolution at close of the year",
          accessor: "complaintsPending",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Remarks",
          accessor: "remarks",
          Cell: EditableCell,
        },
      ],
    },
    {
      id: 2,
      Header: (props) => (
        <span>
          FY{" "}
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          Previous Financial Year
        </span>
      ),
      columns: [
        {
          Header: "Number of complaints filed during the year",
          accessor: "complaintsFiledPrevious",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header:
            "Number of complaints pending resolution at close of the year",
          accessor: "complaintsPendingPrevious",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Remarks",
          accessor: "remarksPrevious",
          Cell: EditableCell,
        },
      ],
    },
  ];
};

export const transparencyAndDisclosureCellData = [
  {
    id: "1",
    stakeholder: "Communities",
    redressalMechanism: "",
    complaintsFiled: "",
    complaintsPending: "",
    remarks: "",
    complaintsFiledPrevious: "",
    complaintsPendingPrevious: "",
    remarksPrevious: "",
  },
  {
    id: "2",
    stakeholder: "Investors (other than shareholders)",
    redressalMechanism: "",
    complaintsFiled: "",
    complaintsPending: "",
    remarks: "",
    complaintsFiledPrevious: "",
    complaintsPendingPrevious: "",
    remarksPrevious: "",
  },
  {
    id: "3",
    stakeholder: "Shareholders",
    redressalMechanism: "",
    complaintsFiled: "",
    complaintsPending: "",
    remarks: "",
    complaintsFiledPrevious: "",
    complaintsPendingPrevious: "",
    remarksPrevious: "",
  },
  {
    id: "4",
    stakeholder: "Employees and workers",
    redressalMechanism: "",
    complaintsFiled: "",
    complaintsPending: "",
    remarks: "",
    complaintsFiledPrevious: "",
    complaintsPendingPrevious: "",
    remarksPrevious: "",
  },
  {
    id: "5",
    stakeholder: "Customers",
    redressalMechanism: "",
    complaintsFiled: "",
    complaintsPending: "",
    remarks: "",
    complaintsFiledPrevious: "",
    complaintsPendingPrevious: "",
    remarksPrevious: "",
  },
  {
    id: "6",
    stakeholder: "Value Chain Partners",
    redressalMechanism: "",
    complaintsFiled: "",
    complaintsPending: "",
    remarks: "",
    complaintsFiledPrevious: "",
    complaintsPendingPrevious: "",
    remarksPrevious: "",
  },
  {
    id: "7",
    stakeholder: "Other (please specify)",
    redressalMechanism: "",
    complaintsFiled: "",
    complaintsPending: "",
    remarks: "",
    complaintsFiledPrevious: "",
    complaintsPendingPrevious: "",
    remarksPrevious: "",
  },
];

export const transparencyAndDisclosureInitialvalues = {
  tableData: transparencyAndDisclosureCellData,
  current: "",
  previous: "",
};
