import { EditableCell } from "../../../../components/utils/EditableCell";

export const EssentialIndicatorsP2Columns = [
  {
    Header: "",
    accessor: "values",
  },
  {
    Header: "Current Financial Year",
    accessor: "currentYear ",
    Cell: EditableCell,
  },
  {
    Header: "Previous Financial Year",
    accessor: "previousYear",
    Cell: EditableCell,
  },
  {
    Header: "Details of improvements in environmental and social impacts",
    accessor: "socialImpacts",
    Cell: EditableCell,
  },
];

export const EssentialIndicatorsP2CellData = [
  {
    id: "1",
    values: "R & D",
    currentYear: "",
    previousYear: "",
    socialImpacts: "",
  },
  {
    id: "2",
    values: "Capex",
    currentYear: "",
    previousYear: "",
    socialImpacts: "",
  },
];

export const EssentialIndicatorsP2Initialvalues = {
  tableData: EssentialIndicatorsP2CellData,
  source: "",
  percentage: "",
};

export const essentialIndicatorsP2ConstantData = [
  {
    id: 1,
    label:
      "2. a. Does the entity have procedures in place for sustainable sourcing? (Yes/No)",
    name: "source",
    field: "radioButton",
    upload: "uploadImage",
  },
  {
    id: 2,
    label: "b. If yes, what percentage of inputs were sourced sustainably?",
    field: "textField",
    type: "number",
    name: "percentage",
  },
];
