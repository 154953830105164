import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import { Modal, Typography } from "@mui/material";
import { CustomRadioButton, CustomTextarea } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { useFormik } from "formik";
import {
  numberOfComplaintsColumns,
  numberOfComplaintsInitialvalues,
  numberOfComplaintsContent,
  STATIC_TABLE,
  numberOfComplaintsConstantData,
  PRINCIPLE5_NUMBER_OF_COMPLAINTS,
  numberOfComplaintsOptions,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import CustomImageUpload from "../../../../reusable/CustomImageUpload";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
}));

const NumberOfComplaints = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(numberOfComplaintsInitialvalues);
  const { list } = useStyles();

  //to get data from api
  const numberOfComplaintsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE5_NUMBER_OF_COMPLAINTS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE5_NUMBER_OF_COMPLAINTS,
        { numberOfComplaints: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      numberOfComplaintsFormConfig
        ? updateTableValues(
            PRINCIPLE5_NUMBER_OF_COMPLAINTS,
            { numberOfComplaints: `${jsonStr}` },
            numberOfComplaintsFormConfig.id
          )
        : createTableValues(PRINCIPLE5_NUMBER_OF_COMPLAINTS, {
            numberOfComplaints: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      numberOfComplaintsFormConfig &&
      numberOfComplaintsFormConfig.data.numberOfComplaints
    ) {
      // setParentData(
      //   JSON.parse(numberOfComplaintsFormConfig.data.numberOfComplaints)
      // );
    }
  }, [numberOfComplaintsFormConfig]);

  const formik = useFormik({
    initialValues: parentData || numberOfComplaintsInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, errors, touched, handleBlur, setFieldValue } =
    formik;

  const renderFormFields = (tableData) => {
    return (
      <>
        {numberOfComplaintsConstantData.map(
          ({ id, label, name, field, type, rowId, accessor, upload }) => {
            let filterData = tableData?.filter((item) => {
              return parseInt(item.id) === rowId;
            });
            // return (
            //   <div key={id}>
            //     {field !== "textArea" && (
            //       <Typography variant="subtitle" className={list}>
            //         {label}
            //       </Typography>
            //     )}
            //     {field === "textField" && (
            //       <CustomTextarea
            //         name={name}
            //         onChange={handleChange}
            //         value={values[name]}
            //         type={type}
            //         onBlur={handleBlur}
            //         variant="outlined"
            //         style={{ fontSize: "16px" }}
            //       />
            //     )}
            //     {field === "radioButton" && (
            //       <CustomRadioButton
            //         name={name}
            //         CSRdetailsOptions={numberOfComplaintsOptions}
            //         onChange={handleChange}
            //         value={values[name]}
            //       />
            //     )}
            //     {field === "textArea" &&
            //       filterData &&
            //       filterData[0][accessor] === "SB" && (
            //         <div key={id} style={{ margin: 8 }}>
            //           <Typography
            //             variant="subtitle"
            //             style={{ display: "flex" }}
            //           >
            //             {label}

            //             <CustomTextarea
            //               name={name}
            //               onChange={handleChange}
            //               onBlur={handleBlur}
            //               value={values[name]}
            //               error={touched[name] && errors[name]}
            //               helperText={touched[name] && errors[name]}
            //               variant="outlined"
            //               style={{
            //                 width: "75%",
            //                 background: "white",
            //                 paddingBottom: "0px ",
            //               }}
            //             />
            //           </Typography>
            //         </div>
            //       )}
            //     <div className="" style={{ marginTop: "10px" }}>
            //       {upload === "uploadImage" && <CustomImageUpload />}
            //     </div>
            //   </div>
            // );
          }
        )}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={numberOfComplaintsColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          numberOfComplaintsFormConfig &&
          !numberOfComplaintsFormConfig.data.numberOfComplaints
            ? numberOfComplaintsFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={numberOfComplaintsContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle5"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={numberOfComplaintsColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={numberOfComplaintsContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default NumberOfComplaints;
