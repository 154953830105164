import { CustomTextField } from "../../../../components/reusable/index";
import { EditableCell } from "../../../../components/utils/EditableCell";

export const recycledReusedColumns = (rate, setFieldValue) => {
  return [
    {
      Header: "Indicate input material ",
      // accessor: "inputMaterial",
      // Cell: EditableCell,
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "inputMaterial",
          Cell: EditableCell,
        },
      ],
    },

    {
      Header: "Recycled or re-used input material to total material",
      columns: [
        {
          Header: () => (
            <span>
              FY
              <CustomTextField
                name="current"
                type="number"
                headerField
                value={rate?.current || ""}
                setFieldValue={setFieldValue}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
              />
              (Current Financial Year)
            </span>
          ),
          accessor: "currentYear",
          type: "number",
          maxLength: 4,
          Cell: EditableCell,
        },
        {
          Header: (props) => (
            <span>
              FY{" "}
              <CustomTextField
                name="previous"
                type="number"
                headerField
                value={rate?.previous || ""}
                setFieldValue={setFieldValue}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
              />
              (Previous Financial Year)
            </span>
          ),

          accessor: "previousYear",
          type: "number",
          maxLength: 4,
          Cell: EditableCell,
        },
      ],
    },
  ];
};

export const recycledReusedCellData = [
  {
    inputMaterial: "",
    currentYear: "",
    previousYear: "",
  },
];

export const recycledReusedInitialvalues = {
  tableData: recycledReusedCellData,
  current: "",
  previous: "",
};
