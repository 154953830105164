import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
cookies.set("baseURL", "https://api.esgms.in", {
  path: "/",
});
// http://13.233.74.63:5000
export const appApi = axios.create({
  baseURL: cookies.get("baseURL"),
  // baseURL: "http://13.233.74.63:4000",
  // baseURL: "http://192.168.1.19:3000",
  // baseURL: "http://brsr-compliance-be-468207569.ap-south-1.elb.amazonaws.com",
  responseType: "json",
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});
appApi.interceptors.request.use(
  (config) => {
    config.headers["authorization"] = `Bearer ${cookies.get("token")}`;
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);
