import { appApi } from "./config";
import Cookies from "universal-cookie";

const cookies = new Cookies();
// const userId = cookies.get("userId");

const dataFormat = (sectionName, tableData) => {
  const formId = cookies.get("formId");

  return {
    formId: formId,
    section: `${sectionName}`,
    data: tableData,
  };
};

export const getTableValues = (custId) => {
  return appApi.get(`getFormDataByCustomerId/${custId}`);
};

export const getTableValuesByUserId = (custId) => {
  return appApi.get(`getFormDataByUserId/${custId}`);
};

export const createTableValues = (sectionName, tableData) => {
  cookies.set("sectionName", sectionName, {
    path: "/",
  });
  appApi
    .post("createFormData", dataFormat(sectionName, tableData))
    .then((response) => {
      cookies.set("id", response.data.id, {
        path: "/",
      });
      cookies.set("status", "true", {
        path: "/",
      });
    });
};

export const updateTableValues = (sectionName, tableData, id) => {
  console.log(tableData, "methodteble date")
  cookies.set("sectionName", sectionName, {
    path: "/",
  });
  return appApi.put(`updateFormData/${id}`, dataFormat(sectionName, tableData));
};
export const updateAdminTableValues = (sectionName, tableData, id) => {
  console.log(id);
  return appApi.put(
    `updateFormDataAdmin/${id}`,
    dataFormat(sectionName, tableData)
  );
};
