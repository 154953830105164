import { FormControl, FormHelperText } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import React from "react";

function CustomImageDownload({ bufferValue }) {
  return (
    <div style={{ marginLeft: "15px" }}>
      <a
        href={bufferValue && bufferValue.length !== 0 && bufferValue}
        download
        style={{ color: "black", textDecoration: "none", opacity: (bufferValue && bufferValue.length === 0 && "0.6") }}
      >
        <FormControl
          fullWidth
          style={{
            marginLeft: 3,
            width: 330,
            cursor: "pointer",
            marginBottom: 20,
          }}
        >
          <div
            // onClick={(e) => handleClick(e)}
            className="fileInputHolder"
            //   sytle={sytle}
          >
            <SaveAltIcon
              style={{
                color: "#356acd",
                maxWidth: "100%",
                width: 45,
                height: 45,
              }}
            />
            <p className="fileUplodeStyle">Download File</p>
            {/* <span style={{ max: "grey" }}>{ fileName.name || label || fileName }</span> */}
          </div>
          <FormHelperText style={{ color: "#d32f2f" }}>
            {/* {error} */}
          </FormHelperText>
        </FormControl>
      </a>
    </div>
  );
}

export default CustomImageDownload;
