import { EditableCell } from "../../../../components/utils/EditableCell";

export const operationsOfficesColumns = [
  {
    Header: "S. No.",
    accessor: "sNo",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "Location of operations/offices",
    accessor: "offices",
    Cell: EditableCell,
  },
  {
    Header: "Type of operations",
    accessor: "operations",
    Cell: EditableCell,
  },
  {
    Header:
      "Whether the conditions of environmental approval / clearance are being complied with? (Y/N) If no, the reasons thereof and corrective action taken, if any.",
    accessor: "approval",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const operationsOfficesCellData = columnName.map((item) => ({
  id: item.id,
  sNo: "",
  offices: "",
  operations: "",
  approval: "",
}));
