import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";
import DropdownField from "../../../../components/reusable/DropDownField";

export const numberOfComplaintsOptions = ["Yes", "No"];

export const numberOfComplaintsDropdown = [
  { id: 1, name: "NA", acccessor: "name" },
  { id: 2, name: "SB", acccessor: "name" },
];

export const numberOfComplaintsColumns = (setFieldValue, rate, isView) => {
  return [
    {
      Header: "",
      // accessor: "values",
      id: 1,
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "values",
        },
      ],
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),

      id: 2,
      columns: [
        {
          Header: "Filed during the year",
          accessor: "currentFiledYear",
          Cell: EditableCell,

          maxLength: 4,
        },
        {
          Header: "Pending resolution at the end of year",
          accessor: "currentPendingYear",
          Cell: EditableCell,
        },
        {
          Header: "Remarks",
          accessor: "currentRemarks",
          Cell: (props) => {
            const { updateData, row, cell } = props;
            return isView ? (
              <div>{cell.value}</div>
            ) : (
              <DropdownField
                inputValues={numberOfComplaintsDropdown || []}
                name={cell.column.id}
                fieldStyle={{ margin: 0, padding: 4 }}
                onChange={(e) =>
                  updateData(row.index, cell.column.id, e.target.value)
                }
                value={cell.value}
              />
            );
          },
        },
      ],
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),

      id: 3,
      columns: [
        {
          Header: "Filed during the year",
          accessor: "previousFiledYear",
          Cell: EditableCell,

          maxLength: 4,
        },
        {
          Header: "Pending resolution at the end of year",
          accessor: "previousPendingYear",
          Cell: EditableCell,
        },
        {
          Header: "Remarks",
          accessor: "previousRemarks",
          Cell: (props) => {
            const { updateData, row, cell } = props;
            return isView ? (
              <div>{cell.value}</div>
            ) : (
              <DropdownField
                inputValues={numberOfComplaintsDropdown || []}
                name={cell.column.id}
                fieldStyle={{ margin: 0, padding: 4 }}
                onChange={(e) =>
                  updateData(row.index, cell.column.id, e.target.value)
                }
                value={cell.value}
              />
            );
          },
        },
      ],
    },
  ];
};

const columnName = [
  { id: 1, values: "Sexual Harassment" },
  { id: 2, values: "Discrimination at workplace" },
  { id: 3, values: "Child Labour" },
  { id: 4, values: "Forced Labour/Involuntary Labour" },
  { id: 5, values: "Wages" },
  { id: 6, values: "Other human rights related issues" },
];
export const numberOfComplaintsCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  currentFiledYear: "",
  currentPendingYear: "",
  currentRemarks: "",
  previousFiledYear: "",
  previousPendingYear: "",
  previousRemarks: "",
}));

export const numberOfComplaintsInitialvalues = {
  tableData: numberOfComplaintsCellData,
  harassment: "",
  contracts: "",
  current: "",
  previous: "",
};

export const numberOfComplaintsConstantData = [
  {
    id: 1,
    rowId: 1,
    label: "Remarks - 1a:",
    name: "remark1a",
    accessor: "currentRemarks",
    field: "textArea",
  },
  {
    id: 2,
    rowId: 1,
    label: "Remarks - 1b:",
    name: "remark1b",
    accessor: "previousRemarks",
    field: "textArea",
  },
  {
    id: 3,
    rowId: 2,
    label: "Remarks - 2a:",
    name: "remark2a",
    accessor: "currentRemarks",
    field: "textArea",
  },
  {
    id: 4,
    rowId: 2,
    label: "Remarks - 2b:",
    name: "remark2b",
    accessor: "previousRemarks",
    field: "textArea",
  },
  {
    id: 5,
    rowId: 3,
    label: "Remarks - 3a:",
    name: "remark3a",
    accessor: "currentRemarks",
    field: "textArea",
  },
  {
    id: 6,
    rowId: 3,
    label: "Remarks - 3b:",
    name: "remark3b",
    accessor: "previousRemarks",
    field: "textArea",
  },
  {
    id: 7,
    rowId: 4,
    label: "Remarks - 4a:",
    name: "remark4a",
    accessor: "currentRemarks",
    field: "textArea",
  },
  {
    id: 8,
    rowId: 4,
    label: "Remarks - 4b:",
    name: "remark4b",
    accessor: "previousRemarks",
    field: "textArea",
  },
  {
    id: 9,
    rowId: 5,
    label: "Remarks - 5a:",
    name: "remark5a",
    accessor: "currentRemarks",
    field: "textArea",
  },
  {
    id: 10,
    rowId: 5,
    label: "Remarks - 5b:",
    name: "remark5b",
    accessor: "previousRemarks",
    field: "textArea",
  },
  {
    id: 11,
    rowId: 6,
    label: "Remarks - 6a:",
    name: "remark6a",
    accessor: "currentRemarks",
    field: "textArea",
  },
  {
    id: 12,
    rowId: 6,
    label: "Remarks - 6b:",
    name: "remark6b",
    accessor: "previousRemarks",
    field: "textArea",
  },
  {
    id: 13,
    label:
      "7. Mechanisms to prevent adverse consequences to the complainant in discrimination and harassment cases",
    name: "harassment",
    field: "textField",
    isViewPdf: true,
    upload: "uploadImage",
  },
  {
    id: 14,
    label:
      "8. Do human rights requirements form part of your business agreements and contracts? (Yes/No)",
    field: "radioButton",
    name: "contracts",
    isViewPdf: true,
    upload: "uploadImage",
  },
];
