import React, { useState }  from "react";
import { useTable, usePagination } from "react-table";
import { Box, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { staticTableStyles } from "../../../styles";

export function RowGroupingTable(props) {
  const { columns, data, setData, cloneActive } = props;
  const { boxContainer, tableCellFocus, stickyHeader } = staticTableStyles();
 // const [noOfTImes, setNoOfTimes] = useState([])
  const updateData = (rowIndex, columnId, value) => {
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const headerCell = (headerGroup) => {
    return headerGroup.headers.map((column) =>
      column.hideHeader ? null : (
        <TableCell {...column.getHeaderProps()} rowSpan={column.rowspan || 1}>
          {column.render("Header")}
        </TableCell>
      )
    );
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        updateData,
      },
      usePagination
    );
    // const cloneHandle = () => {
    //   setNoOfTimes(preValue => [...preValue, {}])
    // }
  return (
    <>
      <Box className={boxContainer}>
        <Paper sx={{ width: "100%" }} elevation={8}>
          <Table {...getTableProps()} style={{ borderCollapse: "separate" }}>
            <TableHead className={stickyHeader}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerCell(headerGroup)}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                for (let j = 0; j < row.allCells.length; j++) {
                  if (row.allCells[j].column?.enableRowSpan) {
                    row.allCells[j].isRowSpanned = true;
                  }
                }

                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.isRowSpanned && cell.row.id % 2 === 0)
                        cell.rowSpan = 2;

                      if (cell.isRowSpanned && cell.row.id % 2 !== 0)
                        return null;
                      else
                        return (
                          <TableCell
                            rowSpan={cell.rowSpan}
                            {...cell.getCellProps()}
                            className={
                              cell.column.type === "number"
                                ? tableCellFocus
                                : ""
                            }
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>


          {/* {
              noOfTImes.map((_, i) => {
                return (
                <>
                <Table {...getTableProps()} style={{ borderCollapse: "separate", marginTop: "40px" }}>
            <TableHead className={stickyHeader}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerCell(headerGroup)}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                for (let j = 0; j < row.allCells.length; j++) {
                  if (row.allCells[j].column?.enableRowSpan) {
                    row.allCells[j].isRowSpanned = true;
                  }
                }

                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.isRowSpanned && cell.row.id % 2 === 0)
                        cell.rowSpan = 2;

                      if (cell.isRowSpanned && cell.row.id % 2 !== 0)
                        return null;
                      else
                        return (
                          <TableCell
                            rowSpan={cell.rowSpan}
                            {...cell.getCellProps()}
                            className={
                              cell.column.type === "number"
                                ? tableCellFocus
                                : ""
                            }
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
              </>
              )
              })
            } */}

        </Paper>
      </Box>
      {/* {cloneActive ? <div className="cloneTbl"><span className="cloneIcon" onClick={cloneHandle}>+</span></div> : null } */}
    </>
  );
}
