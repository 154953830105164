/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import { ROUTE_PATHS } from "./routes/routePath";

const useAuth = () => {
  const cookies = new Cookies();
  const authToken = cookies.get("token");
  if (authToken) {
    return true;
  } else {
    return false;
  }
};
const PrivateRouteClient = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
  const auth = useAuth();

  return <>{auth === true ? <Component /> : navigate(ROUTE_PATHS.LOGIN)}</>;
};

export default PrivateRouteClient;
