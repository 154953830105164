import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../api/method";
import ElectronicFormContext from "../../../../Context";
import { TableWrapper } from "../../../reusable/index";
import {
  differentlyAbledColumns,
  differentlyAbledCellData,
  SECTIONA_DIFFERENTABLED,
  ROW_FULLWIDTH_TABLE,
  differentAbledContents,
} from "../../../../constant/index";
import Modal from "@mui/material/Modal";
import Cookies from "universal-cookie";
import Context from "../../../../context/Context";

function DifferentAbled() {
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(differentlyAbledCellData);
  const differentAbledFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_DIFFERENTABLED
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);
    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        SECTIONA_DIFFERENTABLED,
        { differentAbled: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      differentAbledFormConfig
        ? updateTableValues(
            SECTIONA_DIFFERENTABLED,
            { differentAbled: `${jsonStr}` },
            differentAbledFormConfig.id
          )
        : createTableValues(SECTIONA_DIFFERENTABLED, {
            differentAbled: `${jsonStr}`,
          });
    }
  };

  let differentAbledDataWithUserId =
    existingTableData &&
    existingTableData.filter(
      (data) => data.section.split("/").join("").slice(8) === "differentAbled"
    );
  differentAbledDataWithUserId =
    differentAbledDataWithUserId &&
    differentAbledDataWithUserId.map((data) => data.data).flat();

  let forDownLoadFile =
    existingTableData &&
    existingTableData.filter(
      (data) => data.section.split("/").join("").slice(8) === "differentAbled"
    );
  forDownLoadFile =
    forDownLoadFile && forDownLoadFile.map((data) => data.document_link);

  useEffect(() => {
    console.log(differentAbledFormConfig, "differentAbledFormConfig");
    if (differentAbledFormConfig && differentAbledDataWithUserId.length <= 1) {
      setParentData(JSON.parse(differentAbledFormConfig.data.differentAbled));
    }
  }, [differentAbledFormConfig]);

  return (
    <>
      {differentAbledDataWithUserId &&
      differentAbledDataWithUserId.length > 1 ? (
        <TableWrapper
          columns={differentlyAbledColumns}
          cellData={differentAbledDataWithUserId}
          storeTableData={storeTableData}
          tableType={ROW_FULLWIDTH_TABLE}
          modalContent={differentAbledContents}
          keyProps={"panel1"}
          fileUplode={true}
          cloneActive={true}
        />
      ) : (
        <TableWrapper
          columns={differentlyAbledColumns}
          cellData={parentData}
          storeTableData={storeTableData}
          tableType={ROW_FULLWIDTH_TABLE}
          modalContent={differentAbledContents}
          keyProps={"panel1"}
          fileUplode={true}
          cloneActive={true}
          fileDownload={forDownLoadFile}
        />
      )}
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={differentlyAbledColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={differentAbledContents}
          />
        </div>
      </Modal>
    </>
  );
}

export default DifferentAbled;