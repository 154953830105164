import { EditableCell } from "../../../../components/utils/EditableCell";

export const correctiveColumns = [
  {
    Header: "Name of authority",
    accessor: "authority",
    Cell: EditableCell,
  },
  {
    Header: "Brief of the case",
    accessor: "briefCase",
    Cell: EditableCell,
  },
  {
    Header: "Corrective action taken",
    accessor: "action",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }, { id: 3 }];
export const correctiveCellData = columnName.map((item) => ({
  id: item.id,
  authority: "",
  briefCase: "",
  action: "",
}));
