import { EditableCell } from "../../../components/utils/EditableCell";

export const overviewEntityColumns = [
  {
    Header: "S. No",
    accessor: "sNo",
    type: "number",
    Cell: EditableCell,
  },
  {
    Header: "Material issue identified",
    accessor: "issue",
    Cell: EditableCell,
  },
  {
    Header: "Indicate whether risk  or  opportunity (R/O)",
    accessor: "indicate",
    Cell: EditableCell,
  },
  {
    Header: "Rationale for identifying the risk / opportunity",
    accessor: "Rationale",
    Cell: EditableCell,
  },
  {
    Header: "In case of risk, approach to adapt or mitigate",
    accessor: "mitigate",
    Cell: EditableCell,
  },
  {
    Header:
      "Financial implications of the risk or opportunity (Indicate positive or negative implications)",
    accessor: "implications",
    Cell: EditableCell,
  },
];

export const overviewEntityCellData = [
  {
    id: "1",
    sNo: "",
    issue: "",
    indicate: "",
    Rationale: "",
    mitigate: "",
    implications: "",
  },
  {
    id: "2",
    sNo: "",
    issue: "",
    indicate: "",
    Rationale: "",
    mitigate: "",
    implications: "",
  },
];
