import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Typography } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  dialogBox: {
    "& .MuiDialog-container > .MuiDialog-paperScrollPaper": {
      maxWidth: "800px",
      minWidth: "500px",
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ ml: 2, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function CustomizedDialogs(props) {
  const { openDialog, handleCloseDialog, modalContent } = props;
  const { dialogBox, title } = useStyles();

  return (
    <div>
      {modalContent?.map((dialog) => (
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          className={dialogBox}
          key={dialog.id}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            className={title}
          >
            {dialog.title}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom variant="h6">
              {dialog.subTitle}
            </Typography>
            <Typography gutterBottom>{dialog.aboutTable}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialog}>
              ok
            </Button>
          </DialogActions>
        </BootstrapDialog>
      ))}
    </div>
  );
}
