import { EditableCell } from "../../../../components/utils/EditableCell";

export const turnoverColumns = [
  {
    Header: "",
    accessor: "values",
  },
  {
    Header: "As a percentage to total turnover",
    accessor: "percentage",
    Cell: EditableCell,
    type: "number",
    maxLength: 3,
  },
];

const columnName = [
  {
    id: 1,
    value: "Environmental and social parameters relevant to the product",
  },
  { id: 2, value: "Safe and responsible usage" },
  { id: 3, value: "Recycling and/or safe disposal" },
];
export const turnoverCellData = columnName.map((item) => ({
  id: item.id,
  values: item.value,
  percentage: "",
}));

export const turnoverInitialvalues = {
  consumer: "",
  tableData: turnoverCellData,
};

export const turnoverConstantData = [
  {
    id: 1,
    label:
      "1. Describe the mechanisms in place to receive and respond to consumer complaints and feedback.",
    name: "consumer",
  },
];
