import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  errorField: {
    paddingBottom: "0 !important",
  },
}));

export function CustomTextField(props) {
  const {
    type,
    name,
    setFieldValue,
    onChange,
    error,
    helperText,
    value,
    headerField,
    variant,
    onBlur,
    label,
    disabled,
    style,
    isViewMode,
    onInput,
    logInField,
    showEyeIcon,
    hidePassword,
  } = props;
  const { errorField } = useStyles();
  const [newValue, setNewValue] = useState(value);

  const [passValues, setPassValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setPassValues({
      ...passValues,
      showPassword: !passValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <TextField
        id="standard-basic"
        label={label}
        variant={variant ? variant : "standard"}
        type={passValues?.showPassword ? "text" : type}
        name={name}
        onChange={
          onChange === undefined ? (e) => setNewValue(e.target.value) : onChange
        }
        onBlur={
          onBlur === undefined ? () => setFieldValue([name], newValue) : onBlur
        }
        error={Boolean(error)}
        value={onBlur === undefined ? newValue : value}
        helperText={helperText || error}
        className={clsx(
          error && errorField,
          !passValues.showPassword && value && hidePassword
        )}
        style={headerField ? { width: "70px" } : logInField && style}
        InputProps={{
          readOnly: isViewMode,

          disabled: disabled,
          endAdornment: showEyeIcon && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {passValues.showPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onInput={onInput}
      />
    </>
  );
}
