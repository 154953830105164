export const marginalizedOptions = ["Yes", "No"];
export const marginalizedContent = {
  id: 1,
  title: " 3. Marginalized /vulnerable",
};

export const marginalizedInitialvalues = {
  policy: "",
  procure: "",
  percentage: "",
  benefits: "",
};

export const marginalizedConstants = [
  {
    id: 1,
    name: "policy",
    label:
      "3. (a) Do you have a preferential procurement policy where you give preference to purchase from suppliers comprising marginalized /vulnerable groups? (Yes/No) ",
    field: "radioButton",
  },
  {
    id: 2,
    name: "procure",
    label: "(b) From which marginalized /vulnerable groups do you procure? ",
    field: "textField",
  },
  {
    id: 3,
    name: "percentage",
    label:
      "(c) What percentage of total procurement (by value) does it constitute? ",
    field: "textField",
    type: "number",
    maxLength: 3,
  },
  {
    id: 4,
    name: "benefits",
    label:
      "4. Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity (in the current financial year), based on traditional knowledge:",
    field: "textField",
  },
];
