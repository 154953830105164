export const leadershipIndicatorsP4Options = ["Yes", "No"];

export const leadershipIndicatorsP4Content = {
  id: 1,
  title: "Leadership Indicators",
};

export const leadershipIndicatorsP4Initialvalues = {
  economic: "",
  management: "",
  vulnerable: "",
};

export const leadershipIndicatorsP4Constants = [
  {
    id: 1,
    name: "economic",
    label:
      "1. Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social topics or if consultation is delegated, how is feedback from such consultations provided to the Board.",
    field: "textField",
  },
  {
    id: 2,
    name: "management",
    label:
      "2. Whether stakeholder consultation is used to support the identification and management of environmental, and social topics (Yes / No). If so, provide details of instances as to how the inputs received from stakeholders on these topics were incorporated into policies and activities of the entity. ",
    field: "radioButton",
  },
  {
    id: 3,
    name: "vulnerable",
    label:
      "3. Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/ marginalized stakeholder groups.",
    field: "textField",
  },
];
