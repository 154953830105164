export const reviewOfNGRBCSContents = [
  {
    id: "1",
    title: " SECTION B: Review Of NGRBCS",
    subTitle: "Review Of NGRBCS",
    aboutTable: "10. Details of Review of NGRBCs by the Company:",
    subAboutTable:
      "Response: Yes(Y), No (N), Annually (A), Bi-Annually (B) and Quarterly (Q), Other (O)",
  },
];
