import { EditableCell } from "../../../components/utils/EditableCell";

export const numberOfLocationsColumns = [
  {
    Header: "Location",
    accessor: "location",
  },
  {
    Header: "Number of plants",
    accessor: "plants",
    type: "number",
    Cell: EditableCell,
  },
  {
    Header: "Number of offices",
    accessor: "offices",
    type: "number",

    Cell: EditableCell,
  },
  {
    Header: "Total",
    accessor: "total",
    type: "number",
    Cell: EditableCell,
  },
];

export const numberOfLocationsCellData = [
  {
    id: "1",
    location: "National",
    plants: "",
    offices: "",
    total: "",
  },
  {
    id: "2",
    location: "International",
    plants: "",
    offices: "",
    total: "",
  },
];
