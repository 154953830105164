import { EditableCell } from "../../../../components/utils/EditableCell";

export const productsSoldColumns = [
  {
    Header: "Indicate product category",
    accessor: "productCategory",
    Cell: EditableCell,
  },
  {
    Header:
      "Reclaimed products and their packaging materials as % of total products sold in respective category",
    accessor: "packagingMaterials",
    Cell: EditableCell,
    maxLength: 3,
  },
];

export const productsSoldCellData = [
  {
    id: "1",
    productCategory: "",
    packagingMaterials: "",
  },
];
