import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Modal, Typography } from "@mui/material";
import { CustomTextField, CustomRadioButton } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  energyConsumedColumns,
  energyConsumedInitialvalues,
  energyConsumedConstantData,
  energyConsumedContent,
  energyConsumedOptions,
  STATIC_TABLE,
  PRINCIPLE6_ENERGY_CONSUMED,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
  contentStyle: {
    marginTop: "20px",
  },
}));

const EnergyConsumed = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(energyConsumedInitialvalues);
  const { list } = useStyles();

  //to get data from api
  const energyConsumedFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE6_ENERGY_CONSUMED
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE6_ENERGY_CONSUMED,
        { energyConsumed: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      energyConsumedFormConfig
        ? updateTableValues(
            PRINCIPLE6_ENERGY_CONSUMED,
            { energyConsumed: `${jsonStr}` },
            energyConsumedFormConfig.id
          )
        : createTableValues(PRINCIPLE6_ENERGY_CONSUMED, {
            energyConsumed: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      energyConsumedFormConfig &&
      energyConsumedFormConfig.data.energyConsumed
    ) {
      setParentData(JSON.parse(energyConsumedFormConfig.data.energyConsumed));
    }
  }, [energyConsumedFormConfig]);

  const formik = useFormik({
    initialValues: parentData || energyConsumedInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, setFieldValue, handleBlur } = formik;

  const renderFormFields = () => {
    return (
      <>
        {energyConsumedConstantData.map(({ id, label, name, field, type }) => (
          <div key={id}>
            <Typography variant="subtitle" className={list}>
              {label}
            </Typography>
            {field === "textField" && (
              <CustomTextField
                name={name}
                onChange={handleChange}
                value={values[name]}
                type={type}
                onBlur={handleBlur}
              />
            )}
            {field === "radioButton" && (
              <CustomRadioButton
                name={name}
                CSRdetailsOptions={energyConsumedOptions}
                onChange={handleChange}
                value={values[name]}
              />
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={energyConsumedColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          energyConsumedFormConfig &&
          !energyConsumedFormConfig.data.energyConsumed
            ? energyConsumedFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={energyConsumedContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle6"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={energyConsumedColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={energyConsumedContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default EnergyConsumed;
