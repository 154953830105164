import { objectFieldTemplate } from "../../../template/objectTemplate";

export const listedEntityInitialSchema = {
  type: "object",
  // required: [
  //   "corportateNumber",
  //   "name",
  //   "year",
  //   "officeAddress",
  //   "corportateAddress",
  //   "emailId",
  //   "phoneNumber",
  //   "website",
  //   "financialYear",
  //   "stockChange",
  //   "paidUpCaptial",
  //   "report",
  //   "contactName",
  //   "contactEmail",
  //   "contactNumber",
  // ],
  properties: {
    corportateNumber: {
      type: "string",
      label: "Corportate Identity Number",
    },
    name: {
      type: "string",
      label: "Name",
    },
    year: {
      type: "number",
      label: "Year of incorporation",
      maxLength: 4,
    },
    officeAddress: {
      type: "string",
      label: "Registered office address",
    },
    corportateAddress: {
      type: "string",
      label: "Corportate Address",
    },
    emailId: {
      type: "string",
      label: "Email Address",
      format: "email",
    },
    phoneNumber: {
      type: "number",
      label: "Phone Number",
    },
    website: {
      type: "string",
      label: "Website",
    },
    financialYear: {
      type: "string",
      label: "Financial Year",
    },
    stockChange: {
      type: "string",
      label: "Name of the Stock Exchange(s)",
    },
    paidUpCaptial: {
      type: "string",
      label: "Paid Up Captial",
    },
    report: {
      type: "string",
      label: "Reporting Boundary - Standalone or Consoildated",
    },
    "": {
      title: "BRSR Contact",
      type: "object",
    },
    contactName: {
      type: "string",
      label: "Name",
    },
    contactEmail: {
      type: "string",
      label: "Email Address",
      format: "email",
    },
    contactNumber: {
      type: "number",
      label: "Phone Number",
    },
  },
};

export const listedEntityUischema = {
  "ui:ObjectFieldTemplate": objectFieldTemplate,
  corportateNumber: {
    xs: 4,
  },
  name: {
    xs: 4,
  },
  year: {
    xs: 4,
  },
  officeAddress: {
    xs: 12,
  },
  corportateAddress: {
    xs: 12,
  },
  emailId: {
    xs: 8,
  },
  phoneNumber: {
    xs: 4,
  },
  website: {
    xs: 12,
  },
  financialYear: {
    xs: 4,
  },
  stockChange: {
    xs: 8,
  },

  paidUpCaptial: {
    xs: 4,
  },
  report: {
    xs: 8,
  },
  contactName: {
    xs: 4,
  },
  contactEmail: {
    xs: 4,
  },
  contactNumber: {
    xs: 4,
  },
};
// import * as yup from "yup";

// export const listedEntityInitialValues = {
//   CIN: "",
//   nameOfEntity: "",
//   year: "",
//   officeAddress: "",
//   corporateAddress: "",
//   email: "",
//   telephone: "",
//   website: "",
//   financialYear: "",
//   stocks: "",
//   queries: "",
// };

// export const listedEntityValidationSchema = yup.object({
//   email: yup.string().email("Invalid Email"),
//   website: yup.string().url("Invalid URL"),
//   year: yup.string().min(4, "Minimum Four Digits"),
// });

export const listedEntityContent = {
  id: 1,
  header: " SECTION A: GENERAL DISCLOSURES",
  title: " Details of Listed Entity",
};

// export const listedEntityData = [
//   {
//     id: 1,
//     label: " 1. Corporate Identity Number (CIN) of the Listed Entity",
//     field: "textField",
//     type: "number",
//     name: "CIN",
//   },
//   {
//     id: 2,
//     label: " 2. Name of the Listed Entity",
//     field: "textField",
//     name: "nameOfEntity",
//   },
//   {
//     id: 3,
//     label: "  3. Year of incorporation",
//     field: "textField",
//     type: "number",
//     name: "year",
//   },
//   {
//     id: 4,
//     label: " 4. Registered office address",
//     field: "textArea",
//     name: "officeAddress",
//   },
//   {
//     id: 5,
//     label: "5. Corporate address",
//     field: "textArea",
//     name: "corporateAddress",
//   },
//   {
//     id: 6,
//     label: "  6. Email",
//     field: "textField",
//     type: "email",
//     name: "email",
//   },
//   {
//     id: 7,
//     label: " 7. Telephone",
//     field: "textField",
//     type: "number",
//     name: "telephone",
//   },
//   {
//     id: 8,
//     label: "  8. Website",
//     field: "textField",
//     name: "website",
//   },
//   {
//     id: 9,
//     label: "  9. Financial year for which reporting is being done",
//     field: "textField",
//     type: "number",
//     name: "financialYear",
//   },
//   {
//     id: 10,
//     label: "  10. Name of the Stock Exchange(s) where shares are listed",
//     field: "textField",
//     name: "stock",
//   },
//   {
//     id: 11,
//     label: " 11. Paid-up Capital",
//   },
//   {
//     id: 12,
//     label:
//       " 12. Name and contact details (telephone, email address) of the  person who may be contacted in case of any queries on the BRSR  report",
//     field: "textArea",
//     name: "queries",
//   },
//   {
//     id: 13,
//     label:
//       "  13. Reporting boundary - Are the disclosures under this report made  on a standalone basis (i.e. only for the entity) or on a consolidated basis (i.e. for the entity and all the entities which  form a part of its consolidated financial statements, taken together).",
//   },
// ];
