//section - A

export const SECTIONA_BUSINESSACTIVITIES = "sectionA/businessActivities";
export const SECTIONA_DIFFERENTABLED = "sectionA/differentAbled";
export const SECTIONA_EMPLOYEESANDWORKERS = "sectionA/employeesAndWorkers";
export const SECTIONA_JOINTVENTURES = "sectionA/jointVentures";
export const SECTIONA_NUMBEROFLOCATIONS = "sectionA/numberOfLocations";
export const SECTIONA_OVERVIEWENTITY = "sectionA/overviewEntity";
export const SECTIONA_PARTICIPATIONOFWOMEN = "sectionA/participationOfWomen";
export const SECTIONA_PRODUCTSERVICE = "sectionA/productServicesByEntity";
export const SECTIOA_TRANSPARENCYANDDISCLOSURE =
  "sectionA/transparencyAndDisclosures";
export const SECTIONA_TURNOVERRATE = "sectionA/turnOverRate";
export const SECTIONA_LISTEDENTITY = "sectionA/listedEntity";
export const SECTIONA_MARKETSSERVEDENTITY = "sectionA/marketsServedEntity";
export const SECTIONA_CSRDETAILS = "sectionA/CSRdetails";

//section - B

export const SECTIONB_ASSESSMENT_EVALUATION = "sectionB/assessmentEvaluation";
export const SECTIONB_DISCLOSURE_QUESTIONS = "sectionB/disclosureQuestions";
export const SECTIONB_GOVERNANCE_LEADERSHIP = "sectionB/governanceLeadership";
export const SECTIONB_REVIEW_OF_NGRBCS = "sectionB/reviewOfNGRBCS";
export const SECTIONB_QUESTIONS = "sectionB/questions";

//section - C

//principle - 1

export const PRINCIPLE1_BRIBERY_CORRECTION =
  "sectionC/principle1/briberyCorruption";
export const PRINCIPLE1_CONFLICT_OF_INTEREST =
  "sectionC/principle1/conflictOfInterest";
export const PRINCIPLE1_ESSENTIAL_INDICATORS =
  "sectionC/principle1/essentialIndicators";
export const PRINCIPLE1_MONETARY = "sectionC/principle1/monetary";
export const PRINCIPLE1_NONMONETARY = "sectionC/principle1/nonMonetary";
export const PRINCIPLE1_CASE_DETAILS = "sectionC/principle1/caseDetails";
export const PRINCIPLE1_LEADERSHIP_INDICATORS =
  "sectionC/principle1/leadershipIndicators";

//principle - 2

export const PRINCIPLE2_ESSENTIAL_INDICATORS =
  "sectionC/principle2/essentialIndicators";
export const PRINCIPLE2_PRODUCT_RECALMATIONBOX =
  "/sectionC/principle2/productReclamationBox";
export const PRINCIPLE2_ASSESSMENTS = "sectionC/principle2/assessments";
export const PRINCIPLE2_RISKCONCERN = "sectionC/principle2/riskConcern";
export const PRINCIPLE2_RECYCLED_REUSED = "sectionC/principle2/recycledReused";
export const PRINCIPLE2_PRODUCTS_PACKING =
  "sectionC/principle2/productsPackaging";
export const PRINCIPLE2_PRODUCTS_SOLD = "sectionC/principle2/productsSold";

//principle - 3

export const PRINCIPLE3_EMPLOYEESCOVERED =
  "sectionC/principle3/employeesCovered";
export const PRINCIPLE3_WORKERSCOVERED = "sectionC/principle3/workersCovered";
export const PRINCIPLE3_RETIREMENT_BENEFITS =
  "sectionC/principle3/retirementBenefits";
export const PRINCIPLE3_ACCESSIBILITY = "sectionC/principle3/accessibility";
export const PRINCIPLE3_PARENTAL_LEAVE = "sectionC/principle3/parentalLeave";
export const PRINCIPLE3_MECHANISM = "sectionC/principle3/mechanism";
export const PRINCIPLE3_MEMBERSHIP = "sectionC/principle3/membership";
export const PRINCIPLE3_TRAINING = "sectionC/principle3/training";
export const PRINCIPLE3_PERFORMANCE = "sectionC/principle3/performance";
export const PRINCIPLE3_RELATED_INCIDENTS =
  "sectionC/principle3/relatedIncidents";

export const PRINCIPLE3_MANAGEMENT_SYSTEM =
  "sectionC/principle3/managementSystem";
export const PRINCIPLE3_COMPLAINTS = "sectionC/principle3/complaints";
export const PRINCIPLE3_ASSESSMENT = "sectionC/principle3/assessmentP3";
export const PRINCIPLE3_LIFE_INSURANCE = "sectionC/principle3/lifeInsurance";
export const PRINCIPLE3_AFFECTED_EMPLOYEES =
  "sectionC/principle3/affectedEmployees";
export const PRINCIPLE3_DETAILS_ON_ASSESSMENT =
  "sectionC/principle3/detailsOnAssessment";

//principle - 4

export const PRINCIPLE4_ESSENTIAL_INDICATORS =
  "sectionC/principle4/essentialIndicatorsP4";
export const PRINCIPLE4_LEADERSHIP_INDICATORS =
  "sectionC/principle4/leadershipIndicatorsP4";

//principle - 5

export const PRINCIPLE5_ESSENTIAL_INDICATORS =
  "sectionC/principle5/essentialIndicatorsP5";
export const PRINCIPLE5_MINIMUM_WAGES = "sectionC/principle5/minimumWages";

export const PRINCIPLE5_REMUNERATIONS_SALARY =
  "sectionC/principle5/remunerationSalary;";
export const PRINCIPLE5_NUMBER_OF_COMPLAINTS =
  "sectionC/principle5/numberOfComplaints";
export const PRINCIPLE5_ASSESSMENT = "sectionC/principle5/assessmentsP5";
export const PRINCIPLE5_GRIEVANCES = "sectionC/principle5/grievances";
export const PRINCIPLE5_DETAILS_ON_ASSESSMENT =
  "sectionC/principle5/detailsOnAssessmentP5";

//principle - 6

export const PRINCIPLE6_ESSENTIAL_INDICATORS =
  "sectionC/principle6/essentialIndicatorsP6";
export const PRINCIPLE6_DISCLOSURES_WATER =
  "sectionC/principle6/disclosuresWater";
export const PRINCIPLE6_AIR_EMISSIONS = "sectionC/principle6/airEmissions";
export const PRINCIPLE6_GREENHOUSEGAS = "sectionC/principle6/greenhouseGas";
export const PRINCIPLE6_WASTE_MANAGEMENT =
  "sectionC/principle6/wasteManagement";
export const PRINCIPLE6_OPERATIONS_OFFICES =
  "sectionC/principle6/operationsOffices";
export const PRINCIPLE6_ENVIRONMENTAL_ASSESSMENTS =
  "sectionC/principle6/environmentalAssessments";
export const PRINCIPLE6_POLLUTION = "sectionC/principle6/pollution";
export const PRINCIPLE6_ENERGY_CONSUMED = "sectionC/principle6/energyConsumed";
export const PRINCIPLE6_WATER_DISCHARGED =
  "sectionC/principle6/waterDischarged";
export const PRINCIPLE6_WATER_WITHDRAWAL =
  "sectionC/principle6/WaterWithdrawal";
export const PRINCIPLE6_EMISSIONS = "sectionC/principle6/emissions";
export const PRINCIPLE6_EFFLUENT_DISCHARGE =
  "sectionC/principle6/effluentDischarge";
export const PRINCIPLE6_MANAGEMENT_PLAN = "sectionC/principle6/managementPlan";

//principle - 7
export const PRINCIPLE7_CHAMBERS = "sectionC/principle7/chambers";
export const PRINCIPLE7_CORRECTIVE = "sectionC/principle7/corrective";
export const PRINCIPLE7_PUBLIC_POLICY = "sectionC/principle7/publicPolicy";

//principle - 8

export const PRINCIPLE8_IMPACT_ASSESSMENTS =
  "sectionC/principle8/impactAssessments";
export const PRINCIPLE8_REHABILITATION = "sectionC/principle8/rehabilitation";
export const PRINCIPLE8_INPUT_MATERIAL = "sectionC/principle8/inputMaterial";
export const PRINCIPLE8_NEGATIVE_IMPACTS =
  "sectionC/principle8/negativeImpacts";
export const PRINCIPLE8_PROJECTS = "sectionC/principle8/projects";
export const PRINCIPLE8_MARGINALIZED =
  "sectionC/principle8/marginalizedOptions";
export const PRINCIPLE8_INTELLECTUAL_PROPERTY =
  "sectionC/principle8/intellectualProperty";
export const PRINCIPLE8_CORRECTIVE = "sectionC/principle8/correctiveP8";
export const PRINCIPLE8_BENEFICIARIES = "sectionC/principle8/beneficiaries";

//principle - 9

export const PRINCIPLE9_TURNOVER = "sectionC/principle9/turnover";
export const PRINCIPLE9_CONSUMER_COMPLAINTS =
  "sectionC/principle9/consumerComplaints";
export const PRINCIPLE9_INSTANCES = "sectionC/principle9/instances";
export const PRINCIPLE9_CYBERSECURITY = "sectionC/principle9/cyberSecurity";
export const PRINCIPLE9_CORRECTIVE_ACTION =
  "sectionC/principle9/correctiveActionP9";
export const PRINCIPLE9_CHANNELS = "sectionC/principle9/channels";
