import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import { ROUTE_PATHS } from "../../../routes/routePath";
import Avatar from "@mui/material/Avatar";
import { profileDetailSytle } from "../../../styles/dashboard";
import { getUsersDetailsById } from "../../../api/api";
import Cookies from "universal-cookie";

function MyProfile() {
  const navigate = useNavigate();
  const useStyles = makeStyles(profileDetailSytle);
  const classes = useStyles();
  const [userProfile, setUserProfile] = useState({});
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const userTypeName = cookies.get("userTypeName");

  const getTableData = useCallback(() => {
    getUsersDetailsById(userId)
      .then((response) => {
        setUserProfile({ ...response.data });
      })
      .catch((error) => console.log(error));
  }, [userId]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  return (
    <div className={classes.listContainer}>
      <div
        className={
          userTypeName === "Company Admin"
            ? classes.root
            : classes.superAdminRoot
        }
      >
        <Typography
          variant="h6"
          className={
            userTypeName === "Company Admin"
              ? classes.title
              : classes.superAdminTitle
          }
        >
          My Profile
        </Typography>
      </div>
      <div className={classes.profileContainer}>
        <div className={classes.profile}>
          <Avatar
            alt="ESGMS"
            className={classes.accountCircleIcon}
            src={userProfile?.profileUrl || ""}
          />

          <div className={classes.profileDetails}>
            <Typography variant="h5" style={{ paddingBottom: 10 }}>
              {userProfile?.name || "Admin"}
            </Typography>
            <Typography variant="h6" className={classes.emailTitle}>
              {userProfile?.emailId || ""}
            </Typography>
            <Typography variant="h6" className={classes.emailTitle}>
              {userProfile?.mobileNo || ""}
            </Typography>
            <Typography className={classes.type}>
              {userProfile.userType?.name || ""}
            </Typography>
          </div>
        </div>
        <div className={classes.editProfile}>
          <LockIcon
            className={classes.lockIcon}
            onClick={() => navigate(`${ROUTE_PATHS.CHAHGE_PASSWORD}`)}
          />
          <EditIcon
            className={classes.lockIcon}
            onClick={() => navigate(`${ROUTE_PATHS.EDIT_PROFILE}`)}
          />
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
