import React from "react";
import { useTable, usePagination } from "react-table";
// import AddIcon from "@mui/icons-material/Add";
import { Button, Tooltip, Zoom } from "@mui/material";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Typography, Box, Paper } from "@mui/material";
import { rowAddingStyles } from "../../../styles";
import Cookies from "universal-cookie";
export function RowAddingTable(props) {
  const { columns, data, setData } = props;
  const { tableCellFocus, addBtnContainer, stickyHeader } = rowAddingStyles();
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const updateData = (rowIndex, columnId, value) => {
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const rowAdding = () => {
    let newData = {};
    let lastValue = Object.keys(data[data.length - 1]);

    lastValue.map((item) => {
      return (newData = {
        ...newData,
        id: `${parseInt(data.length) + 1}`,
        [item]: "",
      });
    });
    setData((props) => [...props, newData]);
  };

  const headerCell = (headerGroup) => {
    return headerGroup.headers.map((column) =>
      column.hideHeader ? null : (
        <TableCell {...column.getHeaderProps()} rowSpan={column.rowspan || 1}>
          {column.render("Header")}
        </TableCell>
      )
    );
  };

  const bodyCell = (row) => {
    return row.cells.map((cell) => {
      return (
        <TableCell
          {...cell.getCellProps()}
          className={cell.column.type === "number" ? tableCellFocus : ""}
        >
          {cell.render("Cell")}
        </TableCell>
      );
    });
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        updateData,
      },
      usePagination
    );
  return (
    <>
      <Paper sx={{ width: "100%" }} elevation={2}>
        <Table {...getTableProps()} style={{ borderCollapse: "separate" }}>
          <TableHead className={stickyHeader}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerCell(headerGroup)}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>{bodyCell(row)}</TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>

        {userTypeName === "Company User" ? 
      <Box className={addBtnContainer}>
        <Tooltip
          title={<Typography>Add Row</Typography>}
          placement="right"
          arrow
          TransitionComponent={Zoom}
        >
          {/* <IconButton onClick={rowAdding}>
            <AddIcon className={addBtn} />
          </IconButton> */}
          <Button variant="contained" color="primary" onClick={rowAdding}>
            Add Row
          </Button>
        </Tooltip>
      </Box>:<div style={{marginTop:"20px"}}></div>}
    </>
  );
}
