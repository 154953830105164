import React, { useContext, useEffect, useState, useMemo } from "react";
import ElectronicFormContext from "../../../../Context";
import { TableWrapper } from "../../../reusable/index";
import {
  productServicesColumns,
  productServicesCellData,
  productServiceContents,
  SECTIONA_PRODUCTSERVICE,
  ROW_ADDINGTABLE,
} from "../../../../constant/index";
import { createTableValues, updateTableValues } from "../../../../api/method";

function ProductServicesByEntity() {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(productServicesCellData);

  const productServiceFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_PRODUCTSERVICE
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    productServiceFormConfig
      ? updateTableValues(
          SECTIONA_PRODUCTSERVICE,
          { productService: `${jsonStr}` },
          productServiceFormConfig.id
        )
      : createTableValues(SECTIONA_PRODUCTSERVICE, {
          productService: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (productServiceFormConfig) {
      setParentData(JSON.parse(productServiceFormConfig.data.productService));
    }
  }, [productServiceFormConfig]);
  return (
    <>
      <TableWrapper
        columns={productServicesColumns}
        cellData={parentData}
        storeTableData={storeTableData}
        tableType={ROW_ADDINGTABLE}
        modalContent={productServiceContents}
        keyProps={"panel1"}
      />
    </>
  );
}

export default ProductServicesByEntity;
