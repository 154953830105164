import { makeStyles } from "@mui/styles";

export const tableWrapperStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    top: "125px",
  },
  align: {
    padding: "20px 25px",
    width: "calc(100vw - 410px)",
    position: "relative",
    left: "350px",
    [theme.breakpoints.down("lg")]: {
      left: "300px",
      width: "calc(100vw - 360px)",
    },
  },
  accordionContainer: {
    position: "fixed",
    zIndex: 100,
    height: "calc(100vh - 128px)",
    borderRight: "2px solid #021256",
    width: "350px",
    overflowY: "scroll",
    backgroundColor: "white",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: "300px",
    },
  },
  accordionContainerAlign: {
    position: "fixed",
    zIndex: 100,
    height: "calc(100vh - 58px)",
    borderRight: "2px solid #021256",
    width: "350px",
    overflowY: "scroll",
    backgroundColor: "white",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: "300px",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 50px 0 50px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  helperBtn: {
    "&:hover": {
      color: "#3ca2f3 ",
    },
  },
  tableNavbarStyle: {
    position: "fixed",
    width: "100%",
    zIndex: 100,
  },
  fileUplodeContainer: {
    paddingTop: 20,
  }
}));
