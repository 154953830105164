import DropdownField from "../../../../components/reusable/DropDownField";
import { EditableCell } from "../../../../components/utils/EditableCell";

export const impactAssessmentsDropdown = [
  { id: 1, name: "NA", acccessor: "name" },
  { id: 2, name: "SB", acccessor: "name" },
];

export const impactAssessmentsColumns = (isView) => [
  {
    Header: "Name and brief details of project",
    accessor: "name",
    Cell: (props) => {
      const { updateData, row, cell } = props;
      return isView ? (
        <div>{cell.value}</div>
      ) : (
        <DropdownField
          inputValues={impactAssessmentsDropdown || []}
          name={cell.column.id}
          fieldStyle={{ margin: 0, padding: 4 }}
          onChange={(e) =>
            updateData(row.index, cell.column.id, e.target.value)
          }
          value={cell.value}
        />
      );
    },
  },
  {
    Header: "SIA Notification No.",
    accessor: "number",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "Date of notification",
    accessor: "date",
    Cell: EditableCell,
  },
  {
    Header: "Whether conducted by independent external agency (Yes / No)",
    accessor: "external",
    Cell: EditableCell,
  },
  {
    Header: "Results communicated in public domain (Yes / No)",
    accessor: "results",
    Cell: EditableCell,
  },
  {
    Header: "Relevant Web link",
    accessor: "webLink",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const impactAssessmentsCellData = columnName.map((item) => ({
  id: item.id,
  name: "",
  number: "",
  date: "",
  external: "",
  results: "",
  webLink: "",
}));

export const impactAssessmentsInitialvalues = {
  tableData: impactAssessmentsCellData,
};

export const impactAssessmentsConstantData = [
  {
    id: 1,
    rowId: 1,
    label: " Brief 1:",
    name: "Brief1",
    accessor: "name",
  },
  {
    id: 2,
    rowId: 2,
    label: " Brief 2:",
    name: "Brief2",
    accessor: "name",
  },
];
