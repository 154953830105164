export const energyConsumedContent = [
  {
    id: "1",
    title:
      "PRINCIPLE 6: Businesses should respect and make efforts to protect and restore the environment",
    aboutTable:
      "1. Provide break-up of the total energy consumed (in Joules or multiples) from renewable and non-renewable sources, in the following format:",
    subAboutTable: "Leadership Indicators",
  },
];
