export const retirementBenefitsContent = [
  {
    id: "1",
    title:
      "PRINCIPLE 3: Businesses should respect and promote the well-being of all employees, including those in their value chains",
    aboutTable: "Essential Indicators",
    subAboutTable:
      "2. Details of retirement benefits, for Current FY and Previous Financial Year. ",
  },
];
