import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { CustomTextField } from "../../reusable";
import { useFormik } from "formik";
import { changePasswordToSchema } from "../../../constant/validation";
import {
  formGroup,
  changePasswordInitialState,
} from "../../../constant/changePassword";
import Cookies from "universal-cookie";
import { getUsersDetailsById, changePassword } from "../../../api/api";
import { useDispatch } from "react-redux";
import { toastMessageAction } from "../../../redux/action";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    padding: "20px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    height: 85,
    background: "#E46D35 0% 0% no-repeat padding-box !important",
  },
  header: {
    fontWeight: "900 !important",
    marginLeft: "5px !important",
  },
  superAdminRoot: {
    backgroundColor: "#F0EDED!important",
  },
  superAdminTitle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "30px !important",
    height: 64,
    background: "#F0EDED!important",
  },
  adminArrowBackIcon: {
    margin: 20,
    cursor: "pointer",
  },

  headerContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "20px !important",
    height: 64,
    background: "#021256 0% 0% no-repeat padding-box",
    // borderRadius: "0px 40px 0px 0px",
    color: "white",
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelBtn: {
    // backgroundColor: "#c0c1ce !important",
    marginRight: "20px !important",
    color: "#8e8f93 !important",
    border: "1px solid #8e8f93 !important",
  },
  arrowBackIcon: {
    color: "white",
    margin: 20,
    cursor: "pointer",
  },
}));

function ChangePassword() {
  const {
    root,
    header,
    headerContainer,
    bottomContainer,
    cancelBtn,
    superAdminTitle,
    superAdminRoot,
    container,
    arrowBackIcon,
    adminArrowBackIcon,
  } = useStyles();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const userTypeName = cookies.get("userTypeName");
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    let workData = { ...data, emailId: userData.emailId };
    delete workData.confirmNewPassword;

    changePassword(workData).then(
      (response) => {
        cookies.remove("token");
        cookies.remove("userTypeName");
        cookies.remove("userTypeId");
        cookies.remove("userId");
        cookies.remove("formId");
        cookies.remove("custId");
        dispatch(
          toastMessageAction({
            severity: "success",
            message: "Change password Successfully !",
            messageStatus: true,
          })
        );
        navigate(ROUTE_PATHS.LOGIN);
      },
      (response) => {
        console.log(response, "error");
      }
    );
  };
  const formik = useFormik({
    initialValues: changePasswordInitialState,
    validationSchema: changePasswordToSchema,
    onSubmit,
  });
  const { values, handleChange, errors, touched, handleBlur } = formik;

  useEffect(() => {
    if (userId) {
      getUsersDetailsById(userId)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [userId]);

  const handleBackArrow = () => {
    userTypeName === "Company User"
      ? navigate(ROUTE_PATHS.USER_PROFILE)
      : navigate(ROUTE_PATHS.PROFILE);
  };

  return (
    <>
      <div
        className={
          userTypeName === "Company Admin" ? container : superAdminRoot
        }
      >
        <Box
          className={
            userTypeName === "Company Admin" ? headerContainer : superAdminTitle
          }
        >
          <ArrowBackIcon
            className={
              userTypeName === "Company Admin"
                ? arrowBackIcon
                : adminArrowBackIcon
            }
            onClick={handleBackArrow}
          />
          <Typography variant="h6" className={header}>
            CHANGE PASSWORD
          </Typography>
        </Box>
      </div>
      <div className={root}>
        <Box
          sx={{
            width: "25%",
            border: "2px solid #727172",
            borderRadius: "10px",
            padding: "25px",
            background: "white !important",
            "& > :not(style)": { mt: 2, mb: 2 },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <CustomTextField
              name={formGroup.oldPassword.name}
              label={formGroup.oldPassword.label}
              variant={formGroup.oldPassword.variant}
              type={formGroup.oldPassword.type}
              onChange={handleChange}
              value={values.oldPassword}
              error={touched.oldPassword && errors.oldPassword}
              onBlur={handleBlur}
              showEyeIcon={true}
              logInField
              style={{ width: "330px", marginBottom: "20px" }}
            />
            <CustomTextField
              name={formGroup.newPassword.name}
              label={formGroup.newPassword.label}
              variant={formGroup.newPassword.variant}
              type={formGroup.newPassword.type}
              onChange={handleChange}
              value={values.newPassword}
              error={touched.newPassword && errors.newPassword}
              onBlur={handleBlur}
              logInField
              showEyeIcon={true}
              style={{ width: "330px", marginBottom: "20px" }}
            />
            <CustomTextField
              label={formGroup.confirmNewPassword.label}
              variant={formGroup.confirmNewPassword.variant}
              name={formGroup.confirmNewPassword.name}
              type={formGroup.confirmNewPassword.type}
              value={values.confirmNewPassword}
              onChange={handleChange}
              style={{ width: "330px", marginBottom: "20px" }}
              error={touched.confirmNewPassword && errors.confirmNewPassword}
              onBlur={handleBlur}
              logInField
              showEyeIcon={true}
            />
            <Box className={bottomContainer}>
              <Button
                variant="outlined"
                className={cancelBtn}
                onClick={() => navigate(ROUTE_PATHS.PROFILE)}
              >
                Cancel
              </Button>
              <Button variant="contained" color="success" type="submit">
                Update
              </Button>
            </Box>
          </form>
        </Box>
      </div>
    </>
  );
}

export default ChangePassword;
