import { EditableCell } from "../../../../components/utils/EditableCell";

export const parentalLeaveColumns = [
  {
    Header: "Gender ",
    accessor: "gender",
    id: 1,
  },

  {
    id: 2,
    Header: "Permanent employees",
    columns: [
      {
        Header: "Return to work rate ",
        accessor: "employeesWorkRate",
        type: "number",
        Cell: EditableCell,
      },
      {
        Header: "Retention rate ",
        accessor: "employeesRetentionRate",
        type: "number",
        Cell: EditableCell,
      },
    ],
  },
  {
    id: 3,
    Header: "Permanent workers",

    columns: [
      {
        Header: "Return to work rate",
        accessor: "workersWorkRate",
        type: "number",
        Cell: EditableCell,
      },
      {
        Header: "Retention rate",
        accessor: "workersRetentionRate",
        type: "number",
        Cell: EditableCell,
      },
    ],
  },
];

const columnName = [
  { id: 1, values: "Male" },
  { id: 2, values: "Female" },
  { id: 3, values: "Total" },
];
export const parentalLeaveCellData = columnName.map((item) => ({
  id: item.id,
  gender: item.values,
  employeesWorkRate: "",
  employeesRetentionRate: "",
  workersWorkRate: "",
  workersRetentionRate: "",
}));
