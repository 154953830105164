import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  productsPackagingColumns,
  productsPackagingInitialvalues,
  productsPackagingContent,
  STATIC_TABLE,
  PRINCIPLE2_PRODUCTS_PACKING,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";

const ProductsPackaging = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(productsPackagingInitialvalues);
  const formik = useFormik({
    initialValues: parentData || productsPackagingInitialvalues,
    enableReinitialize: true,
  });

  const { values, setFieldValue } = formik;
  //to get data from api
  const productsPackagingFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE2_PRODUCTS_PACKING
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    productsPackagingFormConfig
      ? updateTableValues(
          PRINCIPLE2_PRODUCTS_PACKING,
          { productsPackaging: `${jsonStr}` },
          productsPackagingFormConfig.id
        )
      : createTableValues(PRINCIPLE2_PRODUCTS_PACKING, {
          productsPackaging: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (productsPackagingFormConfig) {
      setParentData(
        JSON.parse(productsPackagingFormConfig.data.productsPackaging)
      );
    }
  }, [productsPackagingFormConfig]);

  return (
    <TableWrapper
      columns={productsPackagingColumns(setFieldValue, values)}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={STATIC_TABLE}
      modalContent={productsPackagingContent}
      keyProps={"panel3"}
      chileKeyPros={"principle2"}
    />
  );
};

export default ProductsPackaging;
