import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const greenhouseGasOptions = ["Yes", "No"];

export const greenhouseGasColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Parameter",
      accessor: "values",
    },
    {
      Header: "Please specify unit",
      accessor: "unit",
      Cell: EditableCell,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      type: "number",
      Cell: EditableCell,
      maxLength: 4,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      type: "number",
      Cell: EditableCell,
      maxLength: 4,
    },
  ];
};

const columnName = [
  {
    id: 1,
    values:
      "Total Scope 1 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",

    unit: "Metric tonnes of CO2 equivalent",
  },
  {
    id: 2,
    values:
      "Total Scope 2 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
    unit: "Metric tonnes of CO2 equivalent",
  },
  {
    id: 3,
    values: "Total Scope 1 and Scope 2 emissions per rupee of turnover",
  },
  {
    id: 4,
    values:
      "Total Scope 1 and Scope 2 emission intensity (optional) – the relevant metric may be selected by the entity",
  },
];
export const greenhouseGasCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  unit: item.unit ? item.unit : "",
  currentYear: "",
  previousYear: "",
}));

export const greenhouseGasInitialvalues = {
  tableData: greenhouseGasCellData,
  independent: "",
  agency: "",
  emission: "",
  details: "",
  current: "",
  previous: "",
};

export const greenhouseGasConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N)",
    field: "radioButton",
  },
  {
    id: 2,
    name: "agency",
    label: "If yes, name of the external agency.",
    field: "textField",
  },
  {
    id: 3,
    name: "emission",
    label:
      "7. Does the entity have any project related to reducing Green House Gas emission?  ",
    field: "radioButton",
  },
  {
    id: 4,
    name: "details",
    label: " If Yes, then provide details. ",
    field: "textField",
  },
];
