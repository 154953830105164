import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const trainingColumns = (setFieldValue, rate) => {
  return [
    {
      id: 1,
      Header: "Category ",
      accessor: "values",
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "values",
        },
      ],
    },
    {
      id: 2,
      Header: () => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      columns: [
        {
          Header: "Total (A)",
          rowspan: 2,
          columns: [
            {
              Header: " ",
              hideHeader: true,
              accessor: "totalA",
              Cell: EditableCell,
              type: "number",
            },
          ],
        },
        {
          Header: "On Health and safety measures",
          columns: [
            {
              Header: "No. (B) ",
              accessor: "numberB",
              Cell: EditableCell,
              type: "number",
            },
            {
              Header: "% (B / A)",
              accessor: "percentageBA",
              Cell: EditableCell,
              type: "number",
              maxLength: 3,
            },
          ],
        },
        {
          Header: "On Skill upgradation",
          columns: [
            {
              Header: "No. (C)",
              accessor: "numberC",
              Cell: EditableCell,
              type: "number",
            },
            {
              Header: "% (C / A)",
              accessor: "percentageCA",
              Cell: EditableCell,
              type: "number",
              maxLength: 3,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      columns: [
        {
          Header: "Total (D)",

          rowspan: 2,
          columns: [
            {
              Header: " ",
              hideHeader: true,
              accessor: "totalD",
              Cell: EditableCell,
              type: "number",
            },
          ],
        },
        {
          Header: "On Health and safety measures",
          columns: [
            {
              Header: "No. (E) ",
              accessor: "numberE",
              Cell: EditableCell,
              type: "number",
            },
            {
              Header: "% (E / D)",
              accessor: "percentageED",
              Cell: EditableCell,
              type: "number",
              maxLength: 3,
            },
          ],
        },
        {
          Header: "On Skill upgradation",
          columns: [
            {
              Header: "No. (F)",
              accessor: "numberF",
              Cell: EditableCell,
              type: "number",
            },
            {
              Header: "% (F / D)",
              accessor: "percentageFD",
              Cell: EditableCell,
              type: "number",
              maxLength: 3,
            },
          ],
        },
      ],
    },
  ];
};

const columnName = [
  { id: 1, values: "Employees", fullwidth: true },
  { id: 2, values: "Male" },
  { id: 3, values: "Female" },
  { id: 4, values: "Total" },
  { id: 5, values: "Workers", fullwidth: true },
  { id: 6, values: "Male" },
  { id: 7, values: "Female" },
  { id: 8, values: "Total" },
];
export const trainingCellData = columnName.map((item) =>
  item.fullwidth
    ? {
        id: item.id,
        values: item.values,
        fullwidth: item.fullwidth,
      }
    : {
        id: item.id,
        values: item.values,
        totalA: "",
        numberB: "",
        percentageBA: "",
        numberC: "",
        percentageCA: "",
        totalD: "",
        numberE: "",
        percentageED: "",
        numberF: "",
        percentageFD: "",
      }
);

export const trainingInitialvalues = {
  tableData: trainingCellData,
  current: "",
  previous: "",
};
