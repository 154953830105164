/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import logo from "../../asset/ESGMS_Logo.png";
import { AppBar, Tab } from "@mui/material";
import { navBarStyle } from "../../styles/dashboard";
import { navBarHeading } from "../../constant/navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../../routes/routePath";
import Cookies from "universal-cookie";
import { getUsersDetailsById } from "../../api/api";
import { StyledTabs } from "./StyledTabs";
import { useDispatch } from "react-redux";
import { toastMessageAction } from "../../redux/action";

const NavBar = (props) => {
  const useStyles = navBarStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [value, setValue] = React.useState(undefined);
  const [userProfile, setUserProfile] = useState({});
  const { disableProfile } = props;
  const [auth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const pathName = location.pathname;
  const userId = cookies.get("userId");
  // const userTypeId = cookies.get("userTypeId")
  const customerId = cookies.get("custId");

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    navigate(`${ROUTE_PATHS.PROFILE}`);
    setAnchorEl(null);
  };

  const handleCompanyProfile = () => {
    navigate(`${ROUTE_PATHS.COMPANY_PROFILE}`);
    setAnchorEl(null);
  };
  const handleMyUsers = () => {
    navigate(`${ROUTE_PATHS.MY_USERS_LIST}`);
    setAnchorEl(null);
  };
  const handleLogout = () => {
    cookies.remove("token");
    cookies.remove("userTypeName");
    cookies.remove("userTypeId");
    cookies.remove("userId");
    cookies.remove("formId");
    cookies.remove("custId");
    cookies.remove("completionStatus");
    cookies.remove("baseURL");
    navigate(`${ROUTE_PATHS.LOGIN}`);
    setAnchorEl(null);
    dispatch(
      toastMessageAction({
        severity: "success",
        message: "Logout Successfully !",
        messageStatus: true,
      })
    );
    setTimeout(() => {
      window.location.reload();
    }, 900);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    navBarHeading.map(({ path }, index) => {
      return path === pathName && setValue(index);
    });
    if (userId) {
      getUsersDetailsById(userId).then((response) => {
        setUserProfile(response.data);
      });
    }
  }, [pathName, userId, customerId]);
  return (
    <AppBar position="fixed">
      <div className={useStyles.root}>
        <div className={useStyles.rootAlign}>
          <div className={useStyles.container}>
            <img src={logo} alt="ESGMS" className={useStyles.logoImg} />
            {!disableProfile && (
              <div className={useStyles.profileContainer}>
                <StyledTabs
                  value={value !== undefined && value}
                  onChange={handleChange}
                  className={useStyles.navBarContentStyle}
                >
                  {navBarHeading.map(({ id, label, path }, index) => {
                    return (
                      <Tab
                        key={index}
                        label={label}
                        {...a11yProps(id)}
                        className={useStyles.navBarContentStyle}
                        onClick={() => navigate(path)}
                      />
                    );
                  })}
                </StyledTabs>
                <div className={useStyles.profile}>
                  <div className={useStyles.userProfile}>
                    <span className={useStyles.adminName}>
                      {userProfile?.name || "Admin"}
                    </span>
                    <span className={useStyles.adminRole}>
                      {"Company Admin"}
                    </span>
                  </div>
                  {auth && (
                    <div
                      className={useStyles.userProfileIcon}
                      style={{ margin: "10px" }}
                    >
                      <IconButton onClick={handleMenu}>
                        <Avatar alt="" src={userProfile?.profileUrl || ""} />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className={useStyles.menuAppBar}
                      >
                        <MenuItem
                          onClick={handleProfile}
                          style={{ width: "240px", height: "48px" }}
                        >
                          My Profile
                        </MenuItem>
                        {customerId !== "null" && (
                          <MenuItem
                            onClick={handleCompanyProfile}
                            style={{ width: "240px", height: "48px" }}
                          >
                            Company Profile
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={handleMyUsers}
                          style={{ height: "48px" }}
                        >
                          My Users
                        </MenuItem>

                        <MenuItem
                          onClick={handleLogout}
                          style={{ height: "48px" }}
                        >
                          Logout
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppBar>
  );
};

export default NavBar;
