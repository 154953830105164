import DropdownField from "../../../../components/reusable/DropDownField";
import { CustomTextField } from "../../../../components/reusable/index";
import { EditableCell } from "../../../../components/utils/EditableCell";

export const conflictOfInterestDropdown = [
  { id: 1, name: "NA", acccessor: "name" },
  { id: 2, name: "SB", acccessor: "name" },
];

export const conflictOfInterestColumns = (setFieldValue, rate, isView) => {
  return [
    {
      Header: " ",
      // accessor: "values",
      id: 1,
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "values",
        },
      ],
    },

    {
      id: 2,
      Header: () => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
          />
          {/* (Turnover rate in current FY) */}
        </span>
      ),
      columns: [
        {
          Header: "Number",
          accessor: "currentNumber",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Remarks",
          accessor: "currentRemarks",
          Cell: (props) => {
            const { updateData, row, cell } = props;
            return isView ? (
              <div>{cell.value}</div>
            ) : (
              <DropdownField
                inputValues={conflictOfInterestDropdown || []}
                name={cell.column.id}
                fieldStyle={{ margin: 0, padding: 4 }}
                onChange={(e) =>
                  updateData(row.index, cell.column.id, e.target.value)
                }
                value={cell.value}
              />
            );
          },
        },
      ],
    },
    {
      id: 3,
      Header: (props) => (
        <span>
          FY{" "}
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
          />
          {/* (Turnover rate in previous FY) */}
        </span>
      ),
      columns: [
        {
          Header: "Number",
          accessor: "previousNumber",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Remarks",
          accessor: "previousRemarks",
          Cell: (props) => {
            const { updateData, row, cell } = props;
            return isView ? (
              <div>{cell.value}</div>
            ) : (
              <DropdownField
                inputValues={conflictOfInterestDropdown || []}
                name={cell.column.id}
                fieldStyle={{ margin: 0, padding: 4 }}
                onChange={(e) =>
                  updateData(row.index, cell.column.id, e.target.value)
                }
                value={cell.value}
              />
            );
          },
        },
      ],
    },
  ];
};

export const conflictOfInterestCellData = [
  {
    id: "1",
    values:
      "Number of complaints received in relation to issues of Conflict of Interest of the Directors",
    currentNumber: "",
    currentRemarks: "",
    previousNumber: "",
    previousRemarks: "",
    // dropdownRow: true,
  },
  {
    id: "2",
    values:
      "Number of complaints received in relation to issues of Conflict of Interest of the KMPs",
    currentNumber: "",
    currentRemarks: "",
    previousNumber: "",
    previousRemarks: "",
    // dropdownRow: true,
  },
];

export const conflictOfInterestInitialvalues = {
  tableData: conflictOfInterestCellData,
  current: "",
  previous: "",
  details: "",
};

export const conflictOfInterestConstantData = [
  {
    id: 1,
    rowId: 1,
    label: "Remarks - 1a:",
    name: "remark1a",
    accessor: "currentRemarks",
  },
  {
    id: 2,
    rowId: 1,
    label: "Remarks - 1b:",
    name: "remark1b",
    accessor: "previousRemarks",
  },
  {
    id: 3,
    rowId: 2,
    label: "Remarks - 2a:",
    name: "remark2a",
    accessor: "currentRemarks",
  },
  {
    id: 4,
    rowId: 2,
    label: "Remarks - 2b:",
    name: "remark2b",
    accessor: "previousRemarks",
  },
  {
    id: 5,
    label:
      "7.Provide details of any corrective action taken or underway on issues related to fines /  penalties / action taken by regulators/ law enforcement agencies/ judicial institutions, on cases of corruption and conflicts of interest.",
    name: "details",
    type: "textField",
    isViewPdf: true,
  },
];
