import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  EssentialIndicatorsColumns,
  EssentialIndicatorsCellData,
  essentialIndicatorsContent,
  STATIC_TABLE,
  PRINCIPLE1_ESSENTIAL_INDICATORS,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import Cookies from "universal-cookie";
import Context from "../../../../../context/Context";
import { Modal } from "@mui/material";

const EssentialIndicators = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(EssentialIndicatorsCellData);

  //to get data from api
  const essentialIndicatorsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE1_ESSENTIAL_INDICATORS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE1_ESSENTIAL_INDICATORS,
        { essentialIndicators: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      essentialIndicatorsFormConfig
        ? updateTableValues(
            PRINCIPLE1_ESSENTIAL_INDICATORS,
            { essentialIndicators: `${jsonStr}` },
            essentialIndicatorsFormConfig.id
          )
        : createTableValues(PRINCIPLE1_ESSENTIAL_INDICATORS, {
            essentialIndicators: `${jsonStr}`,
          });
    }
  };

  //  fetch db data
  useEffect(() => {
    if (
      essentialIndicatorsFormConfig &&
      essentialIndicatorsFormConfig.data.essentialIndicators
    ) {
      setParentData(
        JSON.parse(essentialIndicatorsFormConfig.data.essentialIndicators)
      );
    }
  }, [essentialIndicatorsFormConfig]);
  console.log(parentData);

  return (
    <>
      <TableWrapper
        columns={EssentialIndicatorsColumns}
        storeTableData={storeTableData}
        cellData={
          essentialIndicatorsFormConfig &&
          essentialIndicatorsFormConfig.data.length > 1
            ? essentialIndicatorsFormConfig.data
            : parentData
        }
        tableType={STATIC_TABLE}
        modalContent={essentialIndicatorsContent}
        hideAbout={true}
        // keyProps={"panel3"}
        keyProps={"panel3"}
        chileKeyPros={"principle1"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother EssentialIndicatorsColumns" style={style}>
          <TableWrapper
            columns={EssentialIndicatorsColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={essentialIndicatorsContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default EssentialIndicators;
