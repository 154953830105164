import { lazy } from "react";

import { ROUTE_PATHS } from "./routePath";
const login = lazy(() => import("../components/pages/loginPage/LogIn"));
const forgetPassword = lazy(() =>
  import("../components/pages/loginPage/ForgetPassword")
);
const resetPassword = lazy(() =>
  import("../components/pages/loginPage/ResetPassword")
);

const userProfile = lazy(() =>
  import("../components/pages/companyUser/profile/UserProfile")
);
const updatePassword = lazy(() =>
  import("../components/pages/loginPage/UpdatePassword")
);

const dashboard = lazy(() =>
  import("../components/pages/companyUsersAdmin/Dashboard")
);
const brsrEntry = lazy(() =>
  import("../components/pages/companyUsersAdmin/BRSRentry")
);
const companyUsers = lazy(() =>
  import("../components/pages/companyUsersAdmin/companyUsers/CompanyUsers")
);
const invoices = lazy(() =>
  import("../components/pages/companyUsersAdmin/Invoices")
);

const myProfile = lazy(() => import("../components/pages/profile/MyProfile"));
const printPdf = lazy(() => import("../components/pages/BRSRreport"));

const myUsersList = lazy(() =>
  import("../components/pages/myUsers/MyUserList")
);

const myUsersForm = lazy(() =>
  import("../components/pages/myUsers/MyUserForm")
);

const customersForm = lazy(() =>
  import("../components/pages/superAdmin/customers/Form")
);

const customersList = lazy(() =>
  import("../components/pages/superAdmin/customers/List")
);

const invoiceSetupList = lazy(() =>
  import("../components/pages/superAdmin/invoiceSetup/List")
);

const invoiceSetupForm = lazy(() =>
  import("../components/pages/superAdmin/invoiceSetup/Form")
);

const companyProfile = lazy(() =>
  import("../components/pages/companyProfile/Profile")
);

const companyEditProfile = lazy(() =>
  import("../components/pages/companyProfile/EditProfile")
);

const superAdminDashboard = lazy(() =>
  import("../components/pages/superAdmin/Dashboard")
);

const invoiceDashboard = lazy(() =>
  import("../components/pages/superAdmin/InvoiceDashboard")
);

const userEntry = lazy(() =>
  import("../components/pages/companyUser/UserEntry")
);

const userChangePassword = lazy(() =>
  import("../components/pages/companyUser/profile/UserChangePassword")
);

const userEditProfile = lazy(() =>
  import("../components/pages/companyUser/profile/UserEditProfile")
);

export const routes = [
  //dashboard
  {
    path: ROUTE_PATHS.DASHBOARD,
    component: dashboard,
    exact: true,
  },

  //BRSR entry
  {
    path: ROUTE_PATHS.BRSRENTRY,
    component: brsrEntry,
    exact: true,
  },
  //companyusers
  {
    path: ROUTE_PATHS.COMPANYUSERS,
    component: companyUsers,
    exact: true,
  },

  //invoices
  {
    path: ROUTE_PATHS.INVOICES,
    component: invoices,
    exact: true,
  },
  //print pdf
  {
    path: ROUTE_PATHS.PRINTPDF,
    component: printPdf,
    exact: true,
  },

  //my profile
  {
    path: ROUTE_PATHS.PROFILE,
    component: myProfile,
    exact: true,
  },

  //my users list
  {
    path: ROUTE_PATHS.MY_USERS_LIST,
    component: myUsersList,
    exact: true,
  },

  //my user form

  {
    path: ROUTE_PATHS.MY_USERS_FORM,
    component: myUsersForm,
    exact: true,
  },

  //user my profile
  {
    path: ROUTE_PATHS.USER_PROFILE,
    component: userProfile,
    exact: true,
  },

  //user change password

  {
    path: ROUTE_PATHS.USER_CHAHGE_PASSWORD,
    component: userChangePassword,
    exact: true,
  },

  //user Edit profile
  {
    path: ROUTE_PATHS.USER_EDIT_PROFILE,
    component: userEditProfile,
    exact: true,
  },

  //customer form
  {
    path: ROUTE_PATHS.CUSTOMERS_FORM,
    component: customersForm,
    exact: true,
  },

  {
    path: ROUTE_PATHS.CUSTOMERS_LIST,
    component: customersList,
    exact: true,
  },

  //invoice setup

  {
    path: ROUTE_PATHS.INVOICES_SETUP_FORM,
    component: invoiceSetupForm,
    exact: true,
  },

  {
    path: ROUTE_PATHS.INVOICES_SETUP_LIST,
    component: invoiceSetupList,
    exact: true,
  },

  //company profile

  {
    path: ROUTE_PATHS.COMPANY_EDIT_PROFILE,
    component: companyEditProfile,
    exact: true,
  },
  {
    path: ROUTE_PATHS.COMPANY_PROFILE,
    component: companyProfile,
    exact: true,
  },

  //super admin dashboard
  {
    path: ROUTE_PATHS.SUPER_ADMIN_DASHBOARD,
    component: superAdminDashboard,
    exact: true,
  },

  //invoice dashboard
  {
    path: ROUTE_PATHS.INVOICE_DASHBOARD,
    component: invoiceDashboard,
    exact: true,
  },

  //user entry

  {
    path: ROUTE_PATHS.USER_ENTRY,
    component: userEntry,
    exact: true,
  },
];

export const loginRoutes = [
  //login
  {
    path: ROUTE_PATHS.LOGIN,
    component: login,
    exact: true,
  },

  //forget Password
  {
    path: ROUTE_PATHS.FORGETPASSWORD,
    component: forgetPassword,
    exact: true,
  },

  //reset Password
  {
    path: ROUTE_PATHS.RESETPASSWORD,
    component: resetPassword,
    exact: true,
  },

  //update password
  {
    path: ROUTE_PATHS.UPDATEPASSWORD,
    component: updatePassword,
    exact: true,
  },
];
