import { EditableCell } from "../../../../components/utils/EditableCell";

export const effluentDischargeColumns = [
  {
    Header: "Sr. No ",
    accessor: "sNo",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "Initiative undertaken ",
    accessor: "initiative",
    Cell: EditableCell,
  },
  {
    Header:
      "Details of the initiative (Web-link, if any, may be provided along-withsummary)",
    accessor: "webLink",
    Cell: EditableCell,
  },
  {
    Header: "Outcome of the initiative ",
    accessor: "outcome",
    Cell: EditableCell,
  },
];

export const effluentDischargeCellData = [
  {
    sNo: "",
    initiative: "",
    webLink: "",
    outcome: "",
  },
];
