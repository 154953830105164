import { EditableCell } from "../../../components/utils/EditableCell";

export const GovernanceLeadershipColumns = [
  {
    accessor: "businessResponsible",
  },
  {
    accessor: "committeeResponsible",
    Cell: EditableCell,
  },
];

export const GovernanceLeadershipCellData = [
  {
    id: "1",
    businessResponsible:
      "8. Details of the highest authority responsible for implementation and oversight of the Business Responsibility policy (ies).",
    committeeResponsible: "",
  },
  {
    id: "2",
    businessResponsible:
      "9. Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability related issues? (Yes / No). If yes, provide details.",
    committeeResponsible: "",
  },
];

export const GovernanceLeadershipInitialvalues = {
  statement: "",
  authority: "",
  details: "",
  Committee: "",
};

export const GovernanceLeadershipOptions = ["Yes", "No"];

export const GovernanceLeadershipConstantData = [
  {
    id: 1,
    label:
      "7. Statement by director responsible for the business responsibility report, highlighting ESG related challenges, targets and achievements (listed entity has flexibility regarding the placement of this disclosure)",
    name: "statement",
    field: "textField",
  },
  {
    id: 2,
    label:
      "8. Details of the highest authority responsible for implementation and oversight of the Business Responsibility policy (ies). ",
    name: "authority",
    field: "textField",
  },
  {
    id: 3,
    label:
      "9. Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability related issues? (Yes / No). If yes, provide details.",
    name: "Committee",
    field: "radioButton",
  },
  {
    id: 4,
    label: "If yes, provide details.",
    name: "details",
    field: "textField",
  },
];
