import { CustomTextField } from "../../../components/reusable/index";
import { EditableCell } from "../../../components/utils/EditableCell";

export const turnOverRateColumns = (setFieldValue, rate) => {
  return [
    {
      Header: " ",
      // accessor: "sNo",
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "sNo",
        },
      ],
    },

    {
      id: 1,
      Header: () => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue && setFieldValue}
          />
          (Turnover rate in current FY)
        </span>
      ),
      columns: [
        {
          Header: "Male",
          accessor: "currentMale",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Female",
          accessor: "currentFemale",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Total",
          accessor: "currentTotal",
          type: "number",
          Cell: EditableCell,
        },
      ],
    },
    {
      id: 2,
      Header: (props) => (
        <span>
          FY{" "}
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue && setFieldValue}
          />
          (Turnover rate in previous FY)
        </span>
      ),
      columns: [
        {
          Header: "Male",
          accessor: "preMale",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Female",
          accessor: "preFemale",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Total",
          accessor: "preTotal",
          type: "number",
          Cell: EditableCell,
        },
      ],
    },
    {
      id: 3,
      Header: (props) => (
        <span>
          FY{" "}
          <CustomTextField
            name="prior"
            type="number"
            headerField
            value={rate?.prior || ""}
            setFieldValue={setFieldValue && setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Turnover rate in the year prior to the previous FY)
        </span>
      ),
      columns: [
        {
          Header: "Male",
          accessor: "male",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Female",
          accessor: "female",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Total",
          accessor: "total",
          type: "number",
          Cell: EditableCell,
        },
      ],
    },
  ];
};

export const turnOverRateCellData = [
  {
    id: "1",
    sNo: "Permanent Employees",
    currentMale: "",
    currentFemale: "",
    currentTotal: "",
    preMale: "",
    preFemale: "",
    preTotal: "",
    male: "",
    female: "",
    total: "",
  },
  {
    id: "2",
    sNo: "Permanent Workers",
    currentMale: "",
    currentFemale: "",
    currentTotal: "",
    preMale: "",
    preFemale: "",
    preTotal: "",
    male: "",
    female: "",
    total: "",
  },
];

export const turnOverRateInitialvalues = {
  tableData: turnOverRateCellData,
  current: "",
  previous: "",
  prior: "",
};
