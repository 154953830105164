export const chambersContent = [
  {
    id: "1",
    title:
      "PRINCIPLE 7 Businesses, when engaging in influencing public and regulatory policy, should do so in a manner that is responsible and transparent",
    aboutTable:
      "1. a. Number of affiliations with trade and industry chambers/ associations.",
    subAboutTable:
      "b. List the top 10 trade and industry chambers/ associations (determined based on the total members of such body) the entity is a member of/ affiliated to.",
  },
];
