import { makeStyles } from "@mui/styles";

export const rowFullwidthStyles = makeStyles((theme) => ({
  boxContainer: {
    width: "100%",
    // maxHeight: "360px",
    overflowX: "auto",
    border: " 1px solid rgba(224,224,224,1)",
  },

  cellFullwidth: {
    textAlign: "center !important",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
  },
  tableCellFocus: {
    "&:focus-within": {
      border: " 1.1px solid rgb(114 113 113) ",
    },
  },
}));
