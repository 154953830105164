import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  minimumWagesColumns,
  minimumWagesInitialvalues,
  minimumWagesContent,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE5_MINIMUM_WAGES,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const MinimumWages = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(minimumWagesInitialvalues);
  const formik = useFormik({
    initialValues: parentData || minimumWagesInitialvalues,

    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;
  //to get data from api
  const minimumWagesFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE5_MINIMUM_WAGES
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE5_MINIMUM_WAGES,
        { minimumWages: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      minimumWagesFormConfig
        ? updateTableValues(
            PRINCIPLE5_MINIMUM_WAGES,
            { minimumWages: `${jsonStr}` },
            minimumWagesFormConfig.id
          )
        : createTableValues(PRINCIPLE5_MINIMUM_WAGES, {
            minimumWages: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (minimumWagesFormConfig && minimumWagesFormConfig.data.minimumWages) {
      setParentData(JSON.parse(minimumWagesFormConfig.data.minimumWages));
    }
  }, [minimumWagesFormConfig]);

  return (
    <>
      <TableWrapper
        columns={minimumWagesColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          minimumWagesFormConfig && !minimumWagesFormConfig.data.minimumWages
            ? minimumWagesFormConfig.data
            : parentData.tableData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        modalContent={minimumWagesContent}
        keyProps={"panel3"}
        chileKeyPros={"principle5"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={minimumWagesColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={minimumWagesContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default MinimumWages;
