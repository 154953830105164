import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Modal, Typography } from "@mui/material";
import { CustomTextarea, CustomRadioButton } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  disclosuresWaterColumns,
  disclosuresWaterInitialvalues,
  disclosuresWaterConstantData,
  disclosuresWaterContent,
  disclosuresWaterOptions,
  STATIC_TABLE,
  PRINCIPLE6_DISCLOSURES_WATER,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
  contentStyle: {
    marginTop: "20px",
  },
}));

const DisclosuresWater = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(disclosuresWaterInitialvalues);
  const { list } = useStyles();

  //to get data from api
  const disclosuresWaterFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE6_DISCLOSURES_WATER
    );
  }, [existingTableData]);

  const formik = useFormik({
    initialValues: parentData || disclosuresWaterInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, setFieldValue, handleBlur } = formik;

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE6_DISCLOSURES_WATER,
        { disclosuresWater: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      disclosuresWaterFormConfig
        ? updateTableValues(
            PRINCIPLE6_DISCLOSURES_WATER,
            { disclosuresWater: `${jsonStr}` },
            disclosuresWaterFormConfig.id
          )
        : createTableValues(PRINCIPLE6_DISCLOSURES_WATER, {
            disclosuresWater: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      disclosuresWaterFormConfig &&
      disclosuresWaterFormConfig.data.disclosuresWater
    ) {
      setParentData(
        JSON.parse(disclosuresWaterFormConfig.data.disclosuresWater)
      );
    }
  }, [disclosuresWaterFormConfig]);

  const renderFormFields = () => {
    return (
      <>
        {disclosuresWaterConstantData.map(
          ({ id, label, name, field, type }) => (
            <div key={id}>
              <Typography variant="subtitle" className={list}>
                {label}
              </Typography>
              {field === "textField" && (
                <CustomTextarea
                  name={name}
                  onChange={handleChange}
                  value={values[name]}
                  type={type}
                  onBlur={handleBlur}
                  variant="outlined"
                />
              )}
              {field === "radioButton" && (
                <CustomRadioButton
                  name={name}
                  CSRdetailsOptions={disclosuresWaterOptions}
                  onChange={handleChange}
                  value={values[name]}
                />
              )}
            </div>
          )
        )}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={disclosuresWaterColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          disclosuresWaterFormConfig &&
          !disclosuresWaterFormConfig.data.disclosuresWater
            ? disclosuresWaterFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={disclosuresWaterContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        hideHelp={true}
        keyProps={"panel3"}
        chileKeyPros={"principle6"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={disclosuresWaterColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={disclosuresWaterContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default DisclosuresWater;
