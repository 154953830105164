export const overviewEntityContents = [
  {
    id: "1",
    title: " SECTION A: GENERAL DISCLOSURES",
    subTitle:
      "24. Overview of the entity’s material responsible business conduct issues",
    aboutTable:
      "24. Please indicate material responsible business conduct and sustainability issues pertaining to environmental and social matters that present a risk or an opportunity to your business,  rationale for identifying the same, approach to adapt or mitigate the risk along-with its financial implications, as per the following format",
  },
];
