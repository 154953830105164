import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../Context";
import { createTableValues, updateTableValues } from "../../../../api/method";
import {
  QuestionsColumns,
  questionsContents,
  QuestionsDropdown,
  QuestionsInitialvalues,
  ROW_DROPDOWN_TABLE,
  QuestionsConstantData,
  SECTIONB_QUESTIONS,
} from "../../../../constant/index";
import { TableWrapper, CustomTextField } from "../../../reusable/index";
import { useFormik } from "formik";
import { Typography } from "@mui/material";

const Questions = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(QuestionsInitialvalues);

  const formik = useFormik({
    initialValues: parentData || QuestionsInitialvalues,
    enableReinitialize: true,
  });

  const { values, handleChange, errors, touched, handleBlur } = formik;

  //to get data from api
  const disclosureFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONB_QUESTIONS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    disclosureFormConfig
      ? updateTableValues(
          SECTIONB_QUESTIONS,
          { questions: `${jsonStr}` },
          disclosureFormConfig.id
        )
      : createTableValues(SECTIONB_QUESTIONS, {
          questions: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (disclosureFormConfig) {
      setParentData(JSON.parse(disclosureFormConfig.data.questions));
    }
  }, [disclosureFormConfig]);

  const renderFormFields = (tableData) => {
    return (
      <>
        {QuestionsConstantData.map(({ id, rowId, label, name, accessor }) => {
          let filterData = tableData?.filter((item) => {
            return parseInt(item.id) === rowId;
          });
          return (
            filterData &&
            filterData[0][accessor] === "SB" && (
              <div key={id} style={{ margin: 8 }}>
                <Typography variant="subtitle">
                  {label}

                  <CustomTextField
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                    variant="outlined"
                    logInField
                    style={{
                      width: "88%",
                      background: "white",
                      paddingBottom: "0px ",
                    }}
                  />
                </Typography>
              </div>
            )
          );
        })}
      </>
    );
  };

  return (
    <TableWrapper
      columns={QuestionsColumns}
      dropdownValues={QuestionsDropdown}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={ROW_DROPDOWN_TABLE}
      modalContent={questionsContents}
      renderFormFields={renderFormFields}
      renderFormFieldBottom={true}
      keyProps={"panel2"}
      cloneActive={true}
      fileUplode={true}
    />
  );
};

export default Questions;
