import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const inputMaterialColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "",
      accessor: "values",
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      Cell: EditableCell,
      type: "number",
      maxLength: 4,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      maxLength: 4,
      type: "number",
      Cell: EditableCell,
    },
  ];
};

const columnName = [
  { id: 1, value: "Directly sourced from MSMEs/ small producers" },
  {
    id: 2,
    value:
      "Sourced directly from within the district and neighbouring districts",
  },
];
export const inputMaterialCellData = columnName.map((item) => ({
  id: item.id,
  values: item.value,
  currentYear: "",
  previousYear: "",
}));

export const inputMaterialInitialvalues = {
  tableData: inputMaterialCellData,
  current: "",
  previous: "",
};
