import React from "react";
import { makeStyles } from "@mui/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: "70%",
    marginLeft: 0,
    resize: "none",
    // maxWidth: 400,
    border: "2px solid lightgray",
    borderRadius: 5,
    fontSize: "16px",
  },
}));

export function CustomTextarea(props) {
  const { textArea } = useStyles();
  const { name, onChange, onBlur, error, value, style } = props;

  return (
    <>
      <TextareaAutosize
        aria-label="minimum height"
        minRows={3}
        className={textArea}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        value={value}
        style={style}
      />
    </>
  );
}
