import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  environmentalAssessmentsColumns,
  environmentalAssessmentsInitialvalues,
  environmentalAssessmentsContent,
  environmentalAssessmentsConstantData,
  ROW_ADDINGTABLE,
  PRINCIPLE6_ENVIRONMENTAL_ASSESSMENTS,
  STATIC_TABLE,
} from "../../../../../constant/index";
import { TableWrapper, CustomTextarea } from "../../../../reusable/index";
import { Modal, Typography } from "@mui/material";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const EnvironmentalAssessments = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(
    environmentalAssessmentsInitialvalues
  );

  const formik = useFormik({
    initialValues: parentData || environmentalAssessmentsInitialvalues,
    enableReinitialize: true,
  });

  const { values, handleChange, errors, touched, handleBlur } = formik;

  //to get data from api
  const environmentalAssessmentsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE6_ENVIRONMENTAL_ASSESSMENTS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE6_ENVIRONMENTAL_ASSESSMENTS,
        { environmentalAssessments: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      environmentalAssessmentsFormConfig
        ? updateTableValues(
            PRINCIPLE6_ENVIRONMENTAL_ASSESSMENTS,
            { environmentalAssessments: `${jsonStr}` },
            environmentalAssessmentsFormConfig.id
          )
        : createTableValues(PRINCIPLE6_ENVIRONMENTAL_ASSESSMENTS, {
            environmentalAssessments: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      environmentalAssessmentsFormConfig &&
      environmentalAssessmentsFormConfig.data.environmentalAssessments
    ) {
      // setParentData(
      //   JSON.parse(
      //     environmentalAssessmentsFormConfig.data.environmentalAssessments
      //   )
      // );
    }
  }, [environmentalAssessmentsFormConfig]);

  const renderFormFields = (tableData) => {
    return (
      <>
        {environmentalAssessmentsConstantData.map(
          ({ id, rowId, label, name, accessor, type }) => {
            let filterData = tableData?.filter((item) => {
              return parseInt(item.id) === rowId;
            });
            // return (
            //   filterData &&
            //   filterData[0][accessor] === "SB" && (
            //     <div key={id} style={{ margin: 8 }}>
            //       <Typography variant="subtitle" style={{ display: "flex" }}>
            //         {label}

            //         <CustomTextarea
            //           name={name}
            //           onChange={handleChange}
            //           onBlur={handleBlur}
            //           value={values[name]}
            //           error={touched[name] && errors[name]}
            //           helperText={touched[name] && errors[name]}
            //           variant="outlined"
            //           style={{
            //             width: "75%",
            //             background: "white",
            //             paddingBottom: "0px ",
            //           }}
            //         />
            //       </Typography>
            //     </div>
            //   )
            // );
          }
        )}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={environmentalAssessmentsColumns()}
        storeTableData={storeTableData}
        cellData={
          environmentalAssessmentsFormConfig &&
          !environmentalAssessmentsFormConfig.data.environmentalAssessments
            ? environmentalAssessmentsFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={environmentalAssessmentsContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle6"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={environmentalAssessmentsColumns()}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={environmentalAssessmentsContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default EnvironmentalAssessments;
