export const channelsOptions = ["Yes", "No"];

export const channelsContent = [
  {
    title: "Educate consumers",
  },
];
export const channelsInitialvalues = {
  channels: "",
  educate: "",
  mechanisms: "",
  survey: "",
  breaches: "",
  customers: "",
};

export const channelsConstantData = [
  {
    id: 1,
    label:
      "1. Channels / platforms where information on products and services of the entity can be accessed (provide web link, if available). ",
    name: "channels",
    field: "textField",
  },
  {
    id: 2,
    label:
      "2. Steps taken to inform and educate consumers about safe and responsible usage of products and/or services. ",
    name: "educate",
    field: "textField",
  },
  {
    id: 3,
    label:
      "3. Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential services. ",
    name: "mechanisms",
    field: "textField",
  },
  {
    id: 4,
    label:
      "4. Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable) If yes, provide details in brief. Did your entity carry out any survey with regard to consumer satisfaction relating to the major products / services of the entity, significant locations of operation of the entity or the entity as a whole? (Yes/No) ",
    field: "radioButton",
    name: "survey",
  },
  {
    id: 5,
    label: "5. a. Number of instances of data breaches along-with impact",
    name: "breaches",
    field: "textField",
  },
  {
    id: 6,
    label:
      "b. Percentage of data breaches involving personally identifiable information of customers",
    name: "customers",
    field: "textField",
  },
];
