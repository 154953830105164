import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Modal, Typography } from "@mui/material";
import { CustomTextarea, CustomRadioButton } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  airEmissionsColumns,
  airEmissionsInitialvalues,
  airEmissionsConstantData,
  airEmissionsContent,
  airEmissionsOptions,
  ROW_ADDINGTABLE,
  PRINCIPLE6_AIR_EMISSIONS,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
  contentStyle: {
    marginTop: "20px",
  },
}));

const AirEmissions = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(airEmissionsInitialvalues);
  const { list } = useStyles();

  //to get data from api
  const airEmissionsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE6_AIR_EMISSIONS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE6_AIR_EMISSIONS,
        { airEmissions: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      airEmissionsFormConfig
        ? updateTableValues(
            PRINCIPLE6_AIR_EMISSIONS,
            { airEmissions: `${jsonStr}` },
            airEmissionsFormConfig.id
          )
        : createTableValues(PRINCIPLE6_AIR_EMISSIONS, {
            airEmissions: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (airEmissionsFormConfig && airEmissionsFormConfig.data.airEmissions) {
      setParentData(JSON.parse(airEmissionsFormConfig.data.airEmissions));
    }
  }, [airEmissionsFormConfig]);

  const formik = useFormik({
    initialValues: parentData || airEmissionsInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, setFieldValue, handleBlur } = formik;

  const renderFormFields = () => {
    return (
      <>
        {airEmissionsConstantData.map(({ id, label, name, field, type }) => (
          <div key={id}>
            <Typography variant="subtitle" className={list}>
              {label}
            </Typography>
            {field === "textField" && (
              <CustomTextarea
                name={name}
                onChange={handleChange}
                value={values[name]}
                type={type}
                onBlur={handleBlur}
                variant="outlined"
              />
            )}
            {field === "radioButton" && (
              <CustomRadioButton
                name={name}
                CSRdetailsOptions={airEmissionsOptions}
                onChange={handleChange}
                value={values[name]}
              />
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={airEmissionsColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          airEmissionsFormConfig && !airEmissionsFormConfig.data.airEmissions
            ? airEmissionsFormConfig.data
            : parentData.tableData
        }
        tableType={ROW_ADDINGTABLE}
        modalContent={airEmissionsContent}
        renderFormFields={renderFormFields}
        hideHelp={true}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle6"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={airEmissionsColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_ADDINGTABLE}
            modalContent={airEmissionsContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default AirEmissions;
