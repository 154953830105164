import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  essentialIndicatorsP5Columns,
  essentialIndicatorsP5Initialvalues,
  essentialIndicatorsP5Content,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE5_ESSENTIAL_INDICATORS,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const EssentialIndicatorsP5 = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(
    essentialIndicatorsP5Initialvalues
  );
  const formik = useFormik({
    initialValues: parentData || essentialIndicatorsP5Initialvalues,

    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;
  //to get data from api
  const essentialIndicatorsP5FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE5_ESSENTIAL_INDICATORS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE5_ESSENTIAL_INDICATORS,
        { essentialIndicatorsP5: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      essentialIndicatorsP5FormConfig
        ? updateTableValues(
            PRINCIPLE5_ESSENTIAL_INDICATORS,
            { essentialIndicatorsP5: `${jsonStr}` },
            essentialIndicatorsP5FormConfig.id
          )
        : createTableValues(PRINCIPLE5_ESSENTIAL_INDICATORS, {
            essentialIndicatorsP5: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      essentialIndicatorsP5FormConfig &&
      essentialIndicatorsP5FormConfig.data.essentialIndicatorsP5
    ) {
      setParentData(
        JSON.parse(essentialIndicatorsP5FormConfig.data.essentialIndicatorsP5)
      );
    }
  }, [essentialIndicatorsP5FormConfig]);

  return (
    <>
      <TableWrapper
        columns={essentialIndicatorsP5Columns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          essentialIndicatorsP5FormConfig &&
          !essentialIndicatorsP5FormConfig.data.essentialIndicatorsP5
            ? essentialIndicatorsP5FormConfig.data
            : parentData.tableData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        modalContent={essentialIndicatorsP5Content}
        keyProps={"panel3"}
        chileKeyPros={"principle5"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={essentialIndicatorsP5Columns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={essentialIndicatorsP5Content}
          />
        </div>
      </Modal>
    </>
  );
};

export default EssentialIndicatorsP5;
