import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  instancesColumns,
  instancesInitialvalues,
  instancesContent,
  STATIC_TABLE,
  PRINCIPLE9_INSTANCES,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const Instances = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(instancesInitialvalues);

  //to get data from api
  const instancesFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE9_INSTANCES
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE9_INSTANCES,
        { instances: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      instancesFormConfig
        ? updateTableValues(
            PRINCIPLE9_INSTANCES,
            { instances: `${jsonStr}` },
            instancesFormConfig.id
          )
        : createTableValues(PRINCIPLE9_INSTANCES, {
            instances: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (instancesFormConfig && instancesFormConfig.data.instances) {
      setParentData(JSON.parse(instancesFormConfig.data.instances));
    }
  }, [instancesFormConfig]);

  const formik = useFormik({
    initialValues: parentData || instancesInitialvalues,

    enableReinitialize: true,
  });
  const { values } = formik;

  return (
    <>
      <TableWrapper
        columns={instancesColumns}
        storeTableData={storeTableData}
        cellData={
          instancesFormConfig && !instancesFormConfig.data.instances
            ? instancesFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={instancesContent}
        hideHelp={true}
        keyProps={"panel3"}
        chileKeyPros={"principle9"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={instancesColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={instancesContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default Instances;
