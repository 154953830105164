import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  riskConcernColumns,
  riskConcernCellData,
  riskConcernContent,
  ROW_ADDINGTABLE,
  PRINCIPLE2_RISKCONCERN,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";

const RiskConcern = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(riskConcernCellData);

  //to get data from api
  const riskConcernFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE2_RISKCONCERN
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    riskConcernFormConfig
      ? updateTableValues(
          PRINCIPLE2_RISKCONCERN,
          { riskConcern: `${jsonStr}` },
          riskConcernFormConfig.id
        )
      : createTableValues(PRINCIPLE2_RISKCONCERN, {
          riskConcern: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (riskConcernFormConfig) {
      setParentData(JSON.parse(riskConcernFormConfig.data.riskConcern));
    }
  }, [riskConcernFormConfig]);

  return (
    <TableWrapper
      columns={riskConcernColumns}
      storeTableData={storeTableData}
      cellData={parentData}
      tableType={ROW_ADDINGTABLE}
      modalContent={riskConcernContent}
      keyProps={"panel3"}
      chileKeyPros={"principle2"}
    />
  );
};

export default RiskConcern;
