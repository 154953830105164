export const managementSystemOptions = ["Yes", "No"];

export const managementSystemContent = {
  id: 1,
  title: " 10. Health and safety management system:",
};

export const managementSystemInitialvalues = {
  safety: "",
  assessRisks: "",
  reportWorks: "",
  medical: "",
};

export const managementSystemConstants = [
  {
    id: 1,
    name: "safety",
    label:
      "a. Whether an occupational health and safety management system has been implemented by the entity? (Yes/ No). If yes, the coverage such system? ",
    field: "radioButton",
  },
  {
    id: 2,
    name: "assessRisks",
    label:
      "b. What are the processes used to identify work-related hazards and assess risks on a routine and non-routine basis by the entity?",
    field: "textField",
  },
  {
    id: 3,
    name: "reportWorks",
    label:
      "c. Whether you have processes for workers to report the work related hazards and to remove themselves from such risks. (Y/N)",
    field: "radioButton",
  },
  {
    id: 4,
    name: "medical",
    label:
      "d. Do the employees/ worker of the entity have access to non-occupational medical and healthcare services? (Yes/ No)",
    field: "radioButton",
  },
];
