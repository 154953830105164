import DropdownField from "../../../components/reusable/DropDownField";
import { EditableCell } from "../../../components/utils/EditableCell";

export const reviewOfNGRBCSDropdown = [
  { id: 1, name: "Y", acccessor: "name" },
  { id: 2, name: "N", acccessor: "name" },
  { id: 2, name: "A", acccessor: "name" },
  { id: 2, name: "B", acccessor: "name" },
  { id: 2, name: "Q", acccessor: "name" },
  { id: 2, name: "O", acccessor: "name" },
];

const showDropdownField = (props, isView) => {
  const { updateData, row, cell } = props;

  return isView ? (
    <div>{cell.value}</div>
  ) : (
    <DropdownField
      inputValues={reviewOfNGRBCSDropdown || []}
      name={cell.column.id}
      fieldStyle={{ margin: 0, padding: 4 }}
      onChange={(e) => updateData(row.index, cell.column.id, e.target.value)}
      value={cell.value}
    />
  );
};

export const reviewOfNGRBCSColumns = (isView) => [
  {
    Header: "Subject for Review ",
    accessor: "subjectForReview",
  },
  {
    Header:
      "Indicate whether review was undertaken by Director / Committee of the Board/ Any other Committee",
    columns: [
      {
        Header: "P1",
        accessor: "indicateP1",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P2",
        accessor: "indicateP2",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P3",
        accessor: "indicateP3",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P4",
        accessor: "indicateP4",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P5",
        accessor: "indicateP5",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P6",
        accessor: "indicateP6",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P7",
        accessor: "indicateP7",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P8",
        accessor: "indicateP8",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P9",
        accessor: "indicateP9",
        Cell: (props) => showDropdownField(props, isView),
      },
    ],
  },
  {
    Header:
      "Frequency (Annually/ Half yearly/ Quarterly/ Any other – please specify)",
    columns: [
      {
        Header: "P1",
        accessor: "frequencyP1",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P2",
        accessor: "frequencyP2",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P3",
        accessor: "frequencyP3",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P4",
        accessor: "frequencyP4",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P5",
        accessor: "frequencyP5",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P6",
        accessor: "frequencyP6",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P7",
        accessor: "frequencyP7",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P8",
        accessor: "frequencyP8",
        Cell: (props) => showDropdownField(props, isView),
      },
      {
        Header: "P9",
        accessor: "frequencyP9",
        Cell: (props) => showDropdownField(props, isView),
      },
    ],
  },
];

export const reviewOfNGRBCSCellData = [
  {
    id: "1",
    subjectForReview: "Performance against above policies and follow up action",
    indicateP1: "",
    indicateP2: "",
    indicateP3: "",
    indicateP4: "",
    indicateP5: "",
    indicateP6: "",
    indicateP7: "",
    indicateP8: "",
    indicateP9: "",
    frequencyP1: "",
    frequencyP2: "",
    frequencyP3: "",
    frequencyP4: "",
    frequencyP5: "",
    frequencyP6: "",
    frequencyP7: "",
    frequencyP8: "",
    frequencyP9: "",
  },
  {
    id: "2",
    subjectForReview:
      "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliances",
    indicateP1: "",
    indicateP2: "",
    indicateP3: "",
    indicateP4: "",
    indicateP5: "",
    indicateP6: "",
    indicateP7: "",
    indicateP8: "",
    indicateP9: "",
    frequencyP1: "",
    frequencyP2: "",
    frequencyP3: "",
    frequencyP4: "",
    frequencyP5: "",
    frequencyP6: "",
    frequencyP7: "",
    frequencyP8: "",
    frequencyP9: "",
  },
];

export const reviewOfNGRBCSInitialvalues = {
  tableData: reviewOfNGRBCSCellData,
};

export const reviewOfNGRBCSConstantData = [
  {
    id: 1,
    rowId: 1,
    label: "1.Indicate P1:",
    name: "indicateP1",
    accessor: "indicateP1",
  },
  {
    id: 2,
    rowId: 1,
    label: "1.Indicate P2:",
    name: "indicateP2",
    accessor: "indicateP2",
  },
  {
    id: 3,
    rowId: 1,
    label: "1.Indicate P3:",
    name: "indicateP3",
    accessor: "indicateP3",
  },
  {
    id: 4,
    rowId: 1,
    label: "1.Indicate P4:",
    name: "indicateP4",
    accessor: "indicateP4",
  },
  {
    id: 5,
    rowId: 1,
    label: "1.Indicate P5:",
    name: "indicateP5",
    accessor: "indicateP5",
  },
  {
    id: 6,
    rowId: 1,
    label: "1.Indicate P6:",
    name: "indicateP6",
    accessor: "indicateP6",
  },
  {
    id: 7,
    rowId: 1,
    label: "1.Indicate P7:",
    name: "indicateP7",
    accessor: "indicateP7",
  },
  {
    id: 8,
    rowId: 1,
    label: "1.Indicate P8:",
    name: "indicateP8",
    accessor: "indicateP8",
  },
  {
    id: 9,
    rowId: 1,
    label: "1.Indicate P9:",
    name: "indicateP9",
    accessor: "indicateP9",
  },
  {
    id: 10,
    rowId: 1,
    label: "1.Frequency P1:",
    name: "frequencyP1",
    accessor: "frequencyP1",
  },
  {
    id: 11,
    rowId: 1,
    label: "1.Frequency P2:",
    name: "frequencyP2",
    accessor: "frequencyP2",
  },
  {
    id: 12,
    rowId: 1,
    label: "1.Frequency P3:",
    name: "frequencyP3",
    accessor: "frequencyP3",
  },
  {
    id: 13,
    rowId: 1,
    label: "1.Frequency P4:",
    name: "frequencyP4",
    accessor: "frequencyP4",
  },
  {
    id: 14,
    rowId: 1,
    label: "1.Frequency P5:",
    name: "frequencyP5",
    accessor: "frequencyP5",
  },
  {
    id: 15,
    rowId: 1,
    label: "1.Frequency P6:",
    name: "frequencyP6",
    accessor: "frequencyP6",
  },
  {
    id: 16,
    rowId: 1,
    label: "1.Frequency P7:",
    name: "frequencyP7",
    accessor: "frequencyP7",
  },
  {
    id: 17,
    rowId: 1,
    label: "1.Frequency P8:",
    name: "frequencyP8",
    accessor: "frequencyP8",
  },
  {
    id: 18,
    rowId: 1,
    label: "1.Frequency P9:",
    name: "frequencyP9",
    accessor: "frequencyP9",
  },

  {
    id: 19,
    rowId: 2,
    label: "2.Indicate P1:",
    name: "indicateP21",
    accessor: "indicateP1",
  },
  {
    id: 20,
    rowId: 2,
    label: "2.Indicate P2:",
    name: "indicateP22",
    accessor: "indicateP2",
  },
  {
    id: 21,
    rowId: 2,
    label: "2.Indicate P3:",
    name: "indicateP23",
    accessor: "indicateP3",
  },
  {
    id: 22,
    rowId: 2,
    label: "2.Indicate P4:",
    name: "indicateP24",
    accessor: "indicateP4",
  },
  {
    id: 23,
    rowId: 2,
    label: "2.Indicate P5:",
    name: "indicateP25",
    accessor: "indicateP5",
  },
  {
    id: 24,
    rowId: 2,
    label: "2.Indicate P6:",
    name: "indicateP26",
    accessor: "indicateP6",
  },
  {
    id: 25,
    rowId: 2,
    label: "2.Indicate P7:",
    name: "indicateP27",
    accessor: "indicateP7",
  },
  {
    id: 26,
    rowId: 2,
    label: "2.Indicate P8:",
    name: "indicateP28",
    accessor: "indicateP8",
  },
  {
    id: 27,
    rowId: 2,
    label: "2.Indicate P9:",
    name: "indicateP29",
    accessor: "indicateP9",
  },
  {
    id: 28,
    rowId: 2,
    label: "2.Frequency P1:",
    name: "frequency2P1",
    accessor: "frequencyP1",
  },
  {
    id: 29,
    rowId: 2,
    label: "2.Frequency P2:",
    name: "frequency2P2",
    accessor: "frequencyP2",
  },
  {
    id: 30,
    rowId: 2,
    label: "2.Frequency P3:",
    name: "frequency2P3",
    accessor: "frequencyP3",
  },
  {
    id: 31,
    rowId: 2,
    label: "2.Frequency P4:",
    name: "frequency2P4",
    accessor: "frequencyP4",
  },
  {
    id: 32,
    rowId: 2,
    label: "2.Frequency P5:",
    name: "frequency2P5",
    accessor: "frequencyP5",
  },
  {
    id: 33,
    rowId: 2,
    label: "2.Frequency P6:",
    name: "frequency2P6",
    accessor: "frequencyP6",
  },
  {
    id: 34,
    rowId: 2,
    label: "2.Frequency P7:",
    name: "frequency2P7",
    accessor: "frequencyP7",
  },
  {
    id: 35,
    rowId: 2,
    label: "2.Frequency P8:",
    name: "frequency2P8",
    accessor: "frequencyP8",
  },
  {
    id: 36,
    rowId: 2,
    label: "2.Frequency P9:",
    name: "frequency2P9",
    accessor: "frequencyP9",
  },
];
