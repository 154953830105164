import { EditableCell } from "../../../../components/utils/EditableCell";

export const chambersColumns = [
  {
    Header: "S. No.",
    accessor: "sNO",
  },
  {
    Header: "Name of the trade and industry chambers/ associations",
    accessor: "name",
    Cell: EditableCell,
  },
  {
    Header:
      "Reach of trade and industry chambers/ associations (State/National)",
    accessor: "state",
    Cell: EditableCell,
  },
];

const columnName = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 },
  { id: 10 },
];
export const chambersCellData = columnName.map((item) => ({
  id: item.id,
  sNO: item.id,
  name: "",
  state: "",
}));

export const chambersInitialvalues = {
  exports: "",
  customers: "",
  affiliations: "",
  tableData: chambersCellData,
};

export const chambersConstantDatas = [
  {
    id: 1,
    label:
      "1. a. Number of affiliations with trade and industry chambers/ associations.",
    name: "affiliations",
  },
];
