import { EditableCell } from "../../../../components/utils/EditableCell";

export const EssentialIndicatorsColumns = [
  {
    Header: "Segment",
    accessor: "segment",
  },
  {
    Header: "Total number of training and awareness programmes held",
    accessor: "awareness ",
    Cell: EditableCell,
  },
  {
    Header: "Topics / principles covered under the training and its impact",
    accessor: "principles",
    Cell: EditableCell,
  },
  {
    Header:
      "%age of persons in respective category covered by the awareness programmes",
    accessor: "programmes",
    Cell: EditableCell,
    type: "number",
    maxLength: 3,
  },
];

export const EssentialIndicatorsCellData = [
  {
    id: "1",
    segment: "Board of Directors",
    awareness: "",
    principles: "",
    programmes: "",
  },
  {
    id: "2",
    segment: "Key Managerial Personnel",
    awareness: "",
    principles: "",
    programmes: "",
  },
  {
    id: "3",
    segment: "Employees other than BoD and KMPs",
    awareness: "",
    principles: "",
    programmes: "",
  },
  {
    id: "4",
    segment: "Workers",
    awareness: "",
    principles: "",
    programmes: "",
  },
];
