import { EditableCell } from "../../../components/utils/EditableCell";

export const participationOfWomenColumns = [
  {
    Header: " ",
    // accessor: "sNo",
    rowspan: 2,
    columns: [
      {
        Header: " ",
        hideHeader: true,
        accessor: "sNo",
      },
    ],
  },
  {
    Header: "Total (A)",
    // accessor: "totalA",
    rowspan: 2,
    columns: [
      {
        Header: " ",
        hideHeader: true,
        accessor: "totalA",
        type: "number",
        Cell: EditableCell,
      },
    ],
  },
  {
    Header: "No. and percentage of Females",
    columns: [
      {
        Header: "No. (B)",
        accessor: "noB",
        type: "number",
        Cell: EditableCell,
      },
      {
        Header: "% (B / A)",
        accessor: "BA",
        type: "number",
        maxLength: 3,
        Cell: EditableCell,
      },
    ],
  },
];

export const participationOfWomenCellData = [
  {
    id: "1",
    sNo: "Board of Directors",
    totalA: "",
    noB: "",
    BA: "",
  },
  {
    id: "2",
    sNo: "Key Management Personnel",
    totalA: "",
    noB: "",
    BA: "",
  },
];
