import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../Context";
import { CSRdetailsStyles } from "../../../../styles/index";
import { createTableValues, updateTableValues } from "../../../../api/method";
import CustomImageUpload from "../../../../components/reusable/CustomImageUpload";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import {
  CustomTextField,
  CustomRadioButton,
  CustomizedSnackbars,
} from "../../../reusable/index";
import {
  CSRdetailsConstants,
  CSRdetailsInitialvalues,
  CSRdetailsOptions,
  CSRdetailsContent,
  SECTIONA_CSRDETAILS,
} from "../../../../constant/index";
import { TableNavbar } from "../../../reusable/TableNavbar";
import { CustomizedAccordions } from "../../../reusable/Accordion";
import Cookies from "universal-cookie";
import Context from "../../../../context/Context";
import CustomImageDownload from "../../../reusable/CustomImageDownload";
import moment from 'moment';
import axios from "axios";


function CSRdetails() {
  const {
    list,
    root,
    rootAlign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
    fileUplodeContainer,
  } = CSRdetailsStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const token = cookies.get("token");
  const ContextForSaveFile = useContext(Context);
  const { setSaveFile } = ContextForSaveFile;

  const [historyData, setHistoryData] = useState("");
  const [historyLogs, setHistoryLogs] = useState(false);



  const CSRdetailsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_CSRDETAILS
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    CSRdetailsFormConfig
      ? updateTableValues(
          SECTIONA_CSRDETAILS,
          { CSRdetails: `${jsonStr}` },
          CSRdetailsFormConfig.id
        )
      : createTableValues(SECTIONA_CSRDETAILS, {
          CSRdetails: `${jsonStr}`,
        });
  };

  let forDownLoadFile = existingTableData &&existingTableData.filter((data)=> data.section.split("/").join("").slice(8) === "CSRdetails")
  forDownLoadFile = forDownLoadFile && forDownLoadFile.map(data=>data.document_link);


  //  fetch db data
  useEffect(() => {
    if (CSRdetailsFormConfig) {
      setParentData(JSON.parse(CSRdetailsFormConfig.data.CSRdetails));
    }
  }, [CSRdetailsFormConfig]);

  //   snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // formik
  const onSubmit = (values) => {
    storeTableData(values);
    handleClickSnackBar();
  };

  const formik = useFormik({
    initialValues: parentData || CSRdetailsInitialvalues,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;




  const callingHistoryAPI = () => {
    const url = `${cookies.get("baseURL")}/updateHistory`;
    const data = {
      formId: cookies.get("formId"),
      section: window.location.pathname.split("/").slice(1, 3).join("/"),
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(url, data, { headers })
      .then((response) => {
        setHistoryData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions keyProps={"panel1"} />
        </div>
        <div className={rootAlign} >
        <div style={{position: "relative"}}>
        {userTypeName === "Company Admin" ?
            <div className="hoverdroup">
              <BookmarkBorderIcon
                // style={{ position: "relative" }}
                onClick={callingHistoryAPI}
                onMouseEnter={() => {
                  setHistoryLogs(true);
                  callingHistoryAPI();
                }}
                onMouseLeave={() => setHistoryLogs(false)}
                style={{
                  position: "absolute",
                  marginTop: 6,
                  color: "rgb(107 107 107)",
                }}
              />
              <ul
                className={`dropdown_history ${
                  historyLogs ? "active_log" : ""
                }`}
                onMouseEnter={() => {
                  setHistoryLogs(true);
                }}
                onMouseLeave={() => setHistoryLogs(false)}
              >
                  <li className="headerli">Latest Update By: </li> 
                  {historyData &&
                  historyData.map((data, i) => (
                    <li key={i} className="datestemp">{data.userName} <br/>{moment.utc(data.timeStamp).local().format('DD-MM-YYYY hh:mm a')}</li>
                  ))}                  

              </ul>
            </div> : ""}
          <Typography variant="h6">{CSRdetailsContent.title}</Typography>
         </div>
          {CSRdetailsConstants.map(({ id, name, label, type, field }) => (
            <div key={id} style={{ margin: 10 }}>
              <Typography variant="div" className={list}>
                {label}
                {field === "textField" && (
                  <CustomTextField
                    type={type}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                    variant="outlined"
                    logInField
                    style={{
                      width: "410px",
                      background: "white",
                      paddingBottom: "0px ",
                    }}
                  />
                )}
              </Typography>
              {field === "radioButton" && (
                <CustomRadioButton
                  name={name}
                  CSRdetailsOptions={CSRdetailsOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] && errors[name]}
                />
              )}
            </div>
          ))}

          <Box className={fileUplodeContainer}>
            {userTypeName === "Company Admin" ? (
              <CustomImageDownload bufferValue={forDownLoadFile}/>
            ) : (
              <CustomImageUpload />
            )}
          </Box>

          <Box className={btnContainer}>
            <Button variant="outlined" color="primary" onClick={resetForm}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSubmit();
                setSaveFile(true);
              }}
            >
              Save
            </Button>
          </Box>

          <CustomizedSnackbars open={open} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export default CSRdetails;
