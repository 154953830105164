import { EditableCell } from "../../../components/utils/EditableCell";

export const productServicesColumns = [
  {
    Header: "S.No",
    accessor: "sNo",
    type: "number",
    Cell: EditableCell,
  },
  {
    Header: "Product/Service ",
    accessor: "productService",
    Cell: EditableCell,
  },
  {
    Header: "NIC Code",
    accessor: "NIC",
    Cell: EditableCell,
  },
  {
    Header: "%  of total Turnover contributed",
    accessor: "turnover",
    type: "number",
    maxLength: 3,
    Cell: EditableCell,
  },
];

export const productServicesCellData = [
  {
    id: "1",
    sNo: "",
    productService: "",
    NIC: "",
    turnover: "",
  },
  {
    id: "2",
    sNo: "",
    productService: "",
    NIC: "",
    turnover: "",
  },
];
