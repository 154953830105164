export const essentialIndicatorsP2Content = [
  {
    id: "1",
    title:
      "PRINCIPLE 2 : Businesses should provide goods and services in a manner that is sustainable and safe",
    aboutTable: "Essential Indicators",
    subAboutTable:
      "1. Percentage of R&D and capital expenditure (capex) investments in specific technologies to improve the environmental and social impacts of product and processes to total R&D and capex investments made by the entity, respectively.",
  },
];
