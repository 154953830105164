import { EditableCell } from "../../../../components/utils/EditableCell";
export const remunerationSalaryOptions = ["Yes", "No"];
export const remunerationSalaryColumns = [
  {
    Header: " ",
    rowspan: 2,
    columns: [
      {
        Header: " ",
        hideHeader: true,
        accessor: "values",
      },
    ],
  },
  {
    Header: "Male",
    columns: [
      {
        Header: "Number ",
        accessor: "maleNumber",
        Cell: EditableCell,
        type: "number",
      },
      {
        Header: "Median remuneration/ salary / wages of respective category",
        accessor: "maleSalary",
        Cell: EditableCell,
      },
    ],
  },
  {
    Header: "Female",
    columns: [
      {
        Header: "Number ",
        accessor: "femaleNumber",
        Cell: EditableCell,
        type: "number",
      },
      {
        Header: "Median remuneration/ salary / wages of respective category",
        accessor: "femaleSalary",
        Cell: EditableCell,
      },
    ],
  },
];

const columnName = [
  { id: 1, values: "Board of Directors (BoD)" },
  { id: 2, values: "Key Managerial Personnel" },
  { id: 3, values: "Employees other than BoD and KMP" },
  { id: 4, values: "Workers" },
];
export const remunerationSalaryCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  maleNumber: "",
  maleSalary: "",
  femaleNumber: "",
  femaleSalary: "",
}));

export const remunerationSalaryInitialvalues = {
  tableData: remunerationSalaryCellData,
  committee: "",
  mechanisms: "",
};

export const remunerationSalaryConstantData = [
  {
    id: 1,
    label:
      "4. Do you have a focal point (Individual/ Committee) responsible for addressing human rights impacts or issues caused or contributed to by the business? (Yes/No)",
    name: "committee",
    field: "radioButton",
    upload: "uploadImage",
  },
  {
    id: 2,
    label:
      "5. Describe the internal mechanisms in place to redress grievances related to human rights issues. ",
    field: "textField",
    name: "mechanisms",
    upload: "uploadImage",
  },
];
