import { EditableCell } from "../../../../components/utils/EditableCell";

export const caseDetailsColumns = [
  {
    Header: `              Case Details                      `,
    accessor: "caseDetails ",
    Cell: EditableCell,
  },
  {
    Header:
      "Name of the regulatory/ enforcement agencies/ judicial institutions",
    accessor: "institutions",
    Cell: EditableCell,
  },
];

export const caseDetailsCellData = [
  {
    id: "1",
    caseDetails: "",
    institutions: "",
  },
  {
    id: "2",
    caseDetails: "",
    institutions: "",
  },
];
