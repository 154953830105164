import React, { useContext, useEffect, useState, useMemo } from "react";
import { createTableValues, updateTableValues } from "../../../../api/method";
import ElectronicFormContext from "../../../../Context";
import { TableWrapper } from "../../../reusable/index";
import {
  employeesAndWorkersCellData,
  employeesAndWorkersColumns,
  employeesAndWorkersContents,
  SECTIONA_EMPLOYEESANDWORKERS,
  ROW_FULLWIDTH_TABLE,
} from "../../../../constant/index";

function EmployeesAndWorkers() {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(employeesAndWorkersCellData);

  const employeesFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_EMPLOYEESANDWORKERS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    employeesFormConfig
      ? updateTableValues(
          SECTIONA_EMPLOYEESANDWORKERS,
          { employeesAndWorkers: `${jsonStr}` },
          employeesFormConfig.id
        )
      : createTableValues(SECTIONA_EMPLOYEESANDWORKERS, {
          employeesAndWorkers: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (employeesFormConfig) {
      console.log(employeesFormConfig, "employeesAndWorkers");
      setParentData(JSON.parse(employeesFormConfig.data.employeesAndWorkers));
    }
  }, [employeesFormConfig]);
  return (
    <div className="">
    <TableWrapper
      columns={employeesAndWorkersColumns}
      cellData={parentData}
      storeTableData={storeTableData}
      tableType={ROW_FULLWIDTH_TABLE}
      modalContent={employeesAndWorkersContents}
      keyProps={"panel1"}
      fileUplode={false}
    />
    </div>
  );
}
export default EmployeesAndWorkers;
