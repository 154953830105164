import { objectFieldTemplate } from "../template/objectTemplate";

export const userFormInitialSchema = {
  type: "object",
  required: ["name", "emailId", "mobileNo", "profile", "l1", "l2", "password", "confirmPassword",],
  properties: {
    name: {
      type: "string",
      label: "User Name *",
    },
    emailId: {
      type: "string",
      label: "User Email*",
      format: "email",
    },
    mobileNo: {
      type: "number",
      label: "User Mobile*",
    },
    profile: {
      type: "array",
      label: "Profile Image",
      // format: "data-url",
      items: {
        type: "object",
        options: [
          { id: 1, name: "User1" },
          { id: 2, name: "User2" },
        ],
      },
    },
    l1: {
      type: "string",
      label: "Line Manager 1 Email id*",
      format: "email",
    },
    l2: {
      type: "string",
      label: "Line Manager 2 Email id*",
      format: "email",
    },
    address: {
      type: "string",
      label: "Address",
    },
    country: {
      type: "string",
      label: "Country",
    },
    state: {
      type: "string",
      label: "State",
    },
    district: {
      type: "string",
      label: "District",
    },
    city: {
      type: "string",
      label: "City",
    },
    pincode: {
      type: "string",
      label: "Pincode/Zipcode",
    },
    password: {
      type: "string",
      label: "Password*",
    },
    confirmPassword: {
      type: "string",
      label: "Confirm Password*",
    },
  },
};

export const userFormUischema = (props) => {
  return {
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    name: {
      xs: 6,
      "ui:readonly": props,
      // validateOnBlur: true,
    },
    emailId: {
      xs: 6,
      "ui:readonly": props,
    },
    mobileNo: {
      xs: 6,
      "ui:readonly": props,
    },
    profile: {
      xs: 6,
      "ui:readonly": props,
      "ui:widget": "FileWidget",
    },
    l1: {
      xs: 6,
      "ui:readonly": props,
    },
    l2: {
      xs: 6,
      "ui:readonly": props,
    },
    address: {
      xs: 12,
      "ui:readonly": props,
    },
    country: {
      xs: 12,
      "ui:readonly": props,
    },
    state: {
      xs: 12,
      "ui:readonly": props,
    },
    district: {
      xs: 4,
      "ui:readonly": props,
    },
    city: {
      xs: 4,
      "ui:readonly": props,
    },
    pincode: {
      xs: 4,
      "ui:readonly": props,
    },
    password: {
      xs: 6,
      "ui:widget": "password",
      showEyeIcon: true,
    },
    confirmPassword: {
      xs: 6,
      "ui:widget": "password",
      showEyeIcon: true,
    },
    timeLimit: {
      xs: 12,
      "ui:readonly": props,
    },
  };
};

export const userEditFormInitialSchema = {
  type: "object",
  required: ["name", "emailId", "mobileNo", "l1", "l2",],
  properties: {
    name: {
      type: "string",
      label: "User Name*",
    },
    emailId: {
      type: "string",
      label: "User Email*",
      format: "email",
    },
    mobileNo: {
      type: "string",
      label: "User Mobile*",
    },
    profile: {
      type: "array",
      label: "Profile Image",
      require: false,
      items: {
        type: "object",
        options: [
          { id: 1, name: "User1" },
          { id: 2, name: "User2" },
        ],
      },
    },
    l1: {
      type: "string",
      label: "Line Manager 1 Email id*",
      format: "email",
    },
    l2: {
      type: "string",
      label: "Line Manager 2 Email id*",
      format: "email",
    },
    address: {
      type: "string",
      label: "Address",
    },
    country: {
      type: "string",
      label: "Country",
    },
    state: {
      type: "string",
      label: "State",
    },
    district: {
      type: "string",
      label: "District",
    },
    city: {
      type: "string",
      label: "City",
    },
    pincode: {
      type: "string",
      label: "Pincode/Zipcode",
    },
    // timeLimit: {
    //   type: "string",
    //   label: "Set Completion date",
    //   format: "date",
    // },
  },
};
