import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  assessmentsColumns,
  assessmentsCellData,
  assessmentsContent,
  ROW_ADDINGTABLE,
  PRINCIPLE2_ASSESSMENTS,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";

const Assessments = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(assessmentsCellData);

  //to get data from api
  const assessmentsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE2_ASSESSMENTS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    assessmentsFormConfig
      ? updateTableValues(
          PRINCIPLE2_ASSESSMENTS,
          { assessments: `${jsonStr}` },
          assessmentsFormConfig.id
        )
      : createTableValues(PRINCIPLE2_ASSESSMENTS, {
          assessments: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (assessmentsFormConfig) {
      setParentData(JSON.parse(assessmentsFormConfig.data.assessments));
    }
  }, [assessmentsFormConfig]);

  return (
    <TableWrapper
      columns={assessmentsColumns}
      storeTableData={storeTableData}
      cellData={parentData}
      tableType={ROW_ADDINGTABLE}
      modalContent={assessmentsContent}
      keyProps={"panel3"}
      chileKeyPros={"principle2"}
    />
  );
};

export default Assessments;
