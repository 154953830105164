import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  intellectualPropertyColumns,
  intellectualPropertyCellData,
  intellectualPropertyContent,
  ROW_ADDINGTABLE,
  PRINCIPLE8_INTELLECTUAL_PROPERTY,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const IntellectualProperty = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(intellectualPropertyCellData);

  //to get data from api
  const intellectualPropertyFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE8_INTELLECTUAL_PROPERTY
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE8_INTELLECTUAL_PROPERTY,
        { intellectualProperty: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      intellectualPropertyFormConfig
        ? updateTableValues(
            PRINCIPLE8_INTELLECTUAL_PROPERTY,
            { intellectualProperty: `${jsonStr}` },
            intellectualPropertyFormConfig.id
          )
        : createTableValues(PRINCIPLE8_INTELLECTUAL_PROPERTY, {
            intellectualProperty: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      intellectualPropertyFormConfig &&
      intellectualPropertyFormConfig.data.intellectualProperty
    ) {
      setParentData(
        JSON.parse(intellectualPropertyFormConfig.data.intellectualProperty)
      );
    }
  }, [intellectualPropertyFormConfig]);

  return (
    <>
      <TableWrapper
        columns={intellectualPropertyColumns}
        storeTableData={storeTableData}
        cellData={
          intellectualPropertyFormConfig &&
          !intellectualPropertyFormConfig.data.intellectualProperty
            ? intellectualPropertyFormConfig.data
            : parentData
        }
        tableType={ROW_ADDINGTABLE}
        modalContent={intellectualPropertyContent}
        keyProps={"panel3"}
        chileKeyPros={"principle8"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={intellectualPropertyColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_ADDINGTABLE}
            modalContent={intellectualPropertyContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default IntellectualProperty;
