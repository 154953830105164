import React, { useContext, useEffect, useState, useMemo } from "react";
import ElectronicFormContext from "../../../../Context";
import { TableWrapper } from "../../../reusable/index";
import {
  numberOfLocationsColumns,
  numberOfLocationsCellData,
  numberOfLocationsContents,
  STATIC_TABLE,
  SECTIONA_NUMBEROFLOCATIONS,
} from "../../../../constant/index";
import { createTableValues, updateTableValues } from "../../../../api/method";

function NumberOfLocations() {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(numberOfLocationsCellData);

  const locationsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_NUMBEROFLOCATIONS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    locationsFormConfig
      ? updateTableValues(
          SECTIONA_NUMBEROFLOCATIONS,
          { numberOfLocations: `${jsonStr}` },
          locationsFormConfig.id
        )
      : createTableValues(SECTIONA_NUMBEROFLOCATIONS, {
          numberOfLocations: `${jsonStr}`,
        });
  };
  //  fetch previous data
  useEffect(() => {
    if (locationsFormConfig) {
      setParentData(JSON.parse(locationsFormConfig.data.numberOfLocations));
    }
  }, [locationsFormConfig]);
  return (
    <TableWrapper
      columns={numberOfLocationsColumns}
      cellData={parentData}
      storeTableData={storeTableData}
      tableType={STATIC_TABLE}
      modalContent={numberOfLocationsContents}
      keyProps={"panel1"}
    />
  );
}

export default NumberOfLocations;
