export const accessibilityInitialvalues = {
  workPlaces: "",
  webLink: "",
};

export const accessibilityConstants = [
  {
    id: 1,
    name: "workPlaces",
    label:
      " 3. Are the premises / offices of the entity accessible to differently abled employees and workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016? If not, whether any steps are being taken by the entity in this regard.",
      upload: "uploadImage",
    },
  {
    id: 2,
    name: "turnover",
    label:
      "4. Does the entity have an equal opportunity policy as per the Rights of Persons with Disabilities Act, 2016? If so, provide a web-link to the policy. ",
      upload: "uploadImage",
    },
];
