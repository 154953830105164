import { CustomTextField } from "../../../../components/reusable/index";
import { EditableCell } from "../../../../components/utils/EditableCell";

export const membershipColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Category ",
      // accessor: "value",
      id: 1,
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "value",
        },
      ],
    },

    {
      id: 2,
      Header: () => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      columns: [
        {
          Header: "Total employees / workers in respective category (A) ",
          accessor: "currentTotalEmployees",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header:
            "No. of employees / workers in respective category, who are part of association(s) or Union (B)",
          accessor: "currentTotalWorkers",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "% (B / A)",
          accessor: "percentageBA",
          type: "number",
          Cell: EditableCell,
          maxLength: 3,
        },
      ],
    },
    {
      id: 3,
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),

      columns: [
        {
          Header: "Total employees / workers in respective category (C)",
          accessor: "previousTotalEmployee",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header:
            "No. of employees / workers in respective category, who are part of association(s) or Union (D)",
          accessor: "previousTotalWorkers",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "% (D / C)",
          accessor: "percentageDC",
          type: "number",
          maxLength: 3,
          Cell: EditableCell,
        },
      ],
    },
  ];
};

const columnName = [
  { id: 1, value: "Total Permanent Employees" },
  { id: 2, value: "- Male" },
  { id: 3, value: "- Female" },
  { id: 4, value: "Total Permanent Workers" },
  { id: 5, value: "- Male" },
  { id: 6, value: "- Female" },
];
export const membershipCellData = columnName.map((item) => ({
  id: item.id,
  value: item.value,
  currentTotalEmployees: "",
  currentTotalWorkers: "",
  percentageBA: "",
  previousTotalEmployee: "",
  previousTotalWorkers: "",
  percentageDC: "",
}));

export const membershipInitialvalues = {
  tableData: membershipCellData,
  current: "",
  previous: "",
};
