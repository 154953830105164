import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";
import DropdownField from "../../../../components/reusable/DropDownField";

export const consumerComplaintsDropdown = [
  { id: 1, name: "NA", acccessor: "name" },
  { id: 2, name: "SB", acccessor: "name" },
];

export const consumerComplaintsColumns = (setFieldValue, rate, isView) => {
  return [
    {
      Header: " ",
      accessor: "values",
      id: 1,
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "values",
        },
      ],
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      id: 2,
      columns: [
        {
          Header: "Received during the year",
          accessor: "currentReceived",
          Cell: EditableCell,
          type: "number",
          maxLength: 4,
        },

        {
          Header: "Pending resolution at end of year",
          accessor: "currentPending",
          Cell: EditableCell,
          type: "number",
          maxLength: 4,
        },
      ],
    },
    {
      Header: "Remarks",

      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "currentRemarks",
          Cell: (props) => {
            const { updateData, row, cell } = props;
            return isView ? (
              <div>{cell.value}</div>
            ) : (
              <DropdownField
                inputValues={consumerComplaintsDropdown || []}
                name={cell.column.id}
                fieldStyle={{ margin: 0, padding: 4 }}
                onChange={(e) =>
                  updateData(row.index, cell.column.id, e.target.value)
                }
                value={cell.value}
              />
            );
          },
        },
      ],
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      id: 3,
      columns: [
        {
          Header: "Received during the year",
          accessor: "previousReceived",
          Cell: EditableCell,
          type: "number",
          maxLength: 4,
        },

        {
          Header: "Pending resolution at end of year",
          accessor: "previousPending",
          Cell: EditableCell,
          type: "number",
          maxLength: 4,
        },
      ],
    },
    {
      Header: "Remarks",

      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "previousRemarks",
          Cell: (props) => {
            const { updateData, row, cell } = props;
            return isView ? (
              <div>{cell.value}</div>
            ) : (
              <DropdownField
                inputValues={consumerComplaintsDropdown || []}
                name={cell.column.id}
                fieldStyle={{ margin: 0, padding: 4 }}
                onChange={(e) =>
                  updateData(row.index, cell.column.id, e.target.value)
                }
                value={cell.value}
              />
            );
          },
        },
      ],
    },
  ];
};

const columnName = [
  {
    id: 1,
    value: "Data privacy",
  },
  { id: 2, value: "Advertising" },
  { id: 3, value: "Cyber-security" },
  { id: 4, value: "Delivery of essential servicesAdvertising" },
  { id: 5, value: "Restrictive Trade Practices" },
  { id: 6, value: "Unfair Trade Practices" },
  { id: 7, value: "Other " },
];
export const consumerComplaintsCellData = columnName.map((item) => ({
  id: item.id,
  values: item.value,
  currentReceived: "",
  currentPending: "",
  currentRemarks: "",
  previousReceived: "",
  previousPending: "",
  previousRemarks: "",
}));

export const consumerComplaintsInitialvalues = {
  tableData: consumerComplaintsCellData,
  current: "",
  previous: "",
};

export const consumerComplaintsConstantData = [
  {
    id: 1,
    rowId: 1,
    label: "Remarks - 1a:",
    name: "remark1a",
    accessor: "currentRemarks",
  },
  {
    id: 2,
    rowId: 1,
    label: "Remarks - 1b:",
    name: "remark1b",
    accessor: "previousRemarks",
  },
  {
    id: 3,
    rowId: 2,
    label: "Remarks - 2a:",
    name: "remark2a",
    accessor: "currentRemarks",
  },
  {
    id: 4,
    rowId: 2,
    label: "Remarks - 2b:",
    name: "remark2b",
    accessor: "previousRemarks",
  },
  {
    id: 5,
    rowId: 3,
    label: "Remarks - 3a:",
    name: "remark3a",
    accessor: "currentRemarks",
  },
  {
    id: 6,
    rowId: 3,
    label: "Remarks - 3b:",
    name: "remark3b",
    accessor: "previousRemarks",
  },
  {
    id: 7,
    rowId: 4,
    label: "Remarks - 4a:",
    name: "remark4a",
    accessor: "currentRemarks",
  },
  {
    id: 8,
    rowId: 4,
    label: "Remarks - 4b:",
    name: "remark4b",
    accessor: "previousRemarks",
  },
  {
    id: 9,
    rowId: 5,
    label: "Remarks - 5a:",
    name: "remark5a",
    accessor: "currentRemarks",
  },
  {
    id: 10,
    rowId: 5,
    label: "Remarks - 5b:",
    name: "remark5b",
    accessor: "previousRemarks",
  },
  {
    id: 11,
    rowId: 6,
    label: "Remarks - 6a:",
    name: "remark6a",
    accessor: "currentRemarks",
  },
  {
    id: 12,
    rowId: 6,
    label: "Remarks - 6b:",
    name: "remark6b",
    accessor: "previousRemarks",
  },
  {
    id: 13,
    rowId: 7,
    label: "Remarks - 7a:",
    name: "remark7a",
    accessor: "currentRemarks",
  },
  {
    id: 14,
    rowId: 7,
    label: "Remarks - 7b:",
    name: "remark7b",
    accessor: "previousRemarks",
  },
];
