import { EditableCell } from "../../../../components/utils/EditableCell";

export const beneficiariesColumns = [
  {
    Header: "S. No",
    accessor: "sNO",
    type: "number",
    Cell: EditableCell,
  },
  {
    Header: "CSR Project  ",
    accessor: "project",
    Cell: EditableCell,
  },
  {
    Header: "No. of persons benefitted from CSR Projects ",
    accessor: "benefits",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "% of beneficiaries from vulnerable and marginalized groups",
    type: "number",
    accessor: "action",
    Cell: EditableCell,
    maxLength: 3,
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const beneficiariesCellData = columnName.map((item) => ({
  id: item.id,
  sNO: "",
  project: "",
  benefits: "",
  action: "",
}));
