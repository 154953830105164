import { toggleButtonSecA, toggleButtonSecB, principles } from "./sidebar";

export const accordionData = [
  {
    id: "1",
    name: "SECTION A",
    isCheck: false,
    value: "sectionA",
    buttons: toggleButtonSecA,
    key: "panel1",
  },
  {
    id: "2",
    name: "SECTION B",
    isCheck: false,
    value: "sectionB",
    buttons: toggleButtonSecB,
    key: "panel2",
  },
  {
    id: "3",
    name: "SECTION C",
    isCheck: false,
    value: "sectionC",
    buttons: principles,
    key: "panel3",
  },
];
