import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  parentalLeaveColumns,
  parentalLeaveCellData,
  parentalLeaveContent,
  STATIC_TABLE,
  PRINCIPLE3_PARENTAL_LEAVE,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const ParentalLeave = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(parentalLeaveCellData);

  //to get data from api
  const parentalLeaveFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_PARENTAL_LEAVE
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE3_PARENTAL_LEAVE,
        { parentalLeave: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      parentalLeaveFormConfig
        ? updateTableValues(
            PRINCIPLE3_PARENTAL_LEAVE,
            { parentalLeave: `${jsonStr}` },
            parentalLeaveFormConfig.id
          )
        : createTableValues(PRINCIPLE3_PARENTAL_LEAVE, {
            parentalLeave: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (parentalLeaveFormConfig && parentalLeaveFormConfig.data.parentalLeave) {
      setParentData(JSON.parse(parentalLeaveFormConfig.data.parentalLeave));
    }
  }, [parentalLeaveFormConfig]);

  return (
    <>
      <TableWrapper
        columns={parentalLeaveColumns}
        storeTableData={storeTableData}
        cellData={
          parentalLeaveFormConfig && !parentalLeaveFormConfig.data.parentalLeave
            ? parentalLeaveFormConfig.data
            : parentData
        }
        tableType={STATIC_TABLE}
        modalContent={parentalLeaveContent}
        keyProps={"panel3"}
        chileKeyPros={"principle3"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={parentalLeaveColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={parentalLeaveContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default ParentalLeave;
