import React, { useContext, useEffect, useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import { Typography, Box, IconButton, Button } from "@mui/material";
import { CustomizedSnackbars, CustomizedDialogs } from "../index";
// import Moment from "react-moment";
import moment from 'moment';
import {
  RowFullwidthTable,
  RowAddingTable,
  StaticTable,
  RowGroupingTable,
} from "../index";
import {
  ROW_ADDINGTABLE,
  STATIC_TABLE,
  ROW_FULLWIDTH_TABLE,
  ROW_GROUPING_TABLE,
  ROW_DROPDOWN_TABLE,
} from "../../../constant/index";
import { tableWrapperStyles } from "../../../styles";
import { TableNavbar } from "../TableNavbar";
import { CustomizedAccordions } from "../Accordion";
import Cookies from "universal-cookie";
import { RowDropdownTable } from "./RowDropdownTable";
import CustomImageUpload from "../../../components/reusable/CustomImageUpload";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Context from "../../../context/Context";
import CustomImageDownload from "../CustomImageDownload";
import axios from "axios";

export function TableWrapper(props) {
  const {
    columns,
    cellData,
    tableType,
    modalContent,
    dropdownValues,
    storeTableData,
    renderFormFields,
    renderFormFieldTop,
    renderFormFieldBottom,
    hideHelp,
    hideAbout,
    resetForm,
    keyProps,
    chileKeyPros,
    fileUplode,
    cloneActive,
    fileDownload,
  } = props;
  const {
    btnContainer,
    headerContainer,
    helperBtn,
    root,
    accordionContainer,
    accordionContainerAlign,
    align,
    tableNavbarStyle,
    fileUplodeContainer,
  } = tableWrapperStyles();
  const [data, setData] = React.useState(cellData);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [historyData, setHistoryData] = useState("");
  const [historyLogs, setHistoryLogs] = useState(false);
  // const [formClone, setformClone] = React.useState(cellData);

  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const token = cookies.get("token");

  const contextForUploadFile = useContext(Context);
  const { setSaveFile, setModal, setEditData, setUserUpdateId } =
    contextForUploadFile;

  let incomingTableData;

  useEffect(() => {
    setData(cellData);
  }, [cellData, incomingTableData]);

  // snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  //  dialog box
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const saveData = () => {
    storeTableData(data);
    handleClickSnackBar();
  };

  const resetData = () => {
    setModal(false);
    setData(cellData);
    resetForm();
  };


  const callingHistoryAPI = () => {
    const url = `${cookies.get("baseURL")}/updateHistory`;
    const data = {
      formId: cookies.get("formId"),
      section: window.location.pathname.split("/").slice(1, 5).join("/"),
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(url, data, { headers })
      .then((response) => {
        setHistoryData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const renderTable = () => {
    //console.log("Table Type :", tableType)
    if (tableType === STATIC_TABLE) {
      return cellData &&
        userTypeName === "Company Admin" &&
        data &&
        data[0].userId ? (
        data.map((item, index) => {
          return (
            <div key={index}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3
                  style={{
                    border: "3px solid lightgray",
                    padding: "5px 10px",
                    display: "inline-flex",
                    background: "#ffff",
                  }}
                >
                  User-
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      alignSelf: "center",
                    }}
                  >
                    {item.userName}
                    {/* <Moment format="DD-MM-YYYY, h:mm:ss A" className="datesstt">{data.updatedAt}</Moment> */}
                  </span>
                </h3>
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setEditData(
                      item.participationOfWomen
                        ? JSON.parse(item.participationOfWomen)
                        : item.turnOverRate
                        ? JSON.parse(item.turnOverRate).tableData
                        : item.transparencyAndDisclosure
                        ? JSON.parse(item.transparencyAndDisclosure).tableData
                        : item.essentialIndicators
                        ? JSON.parse(item.essentialIndicators)
                        : item.remunerationSalary
                        ? JSON.parse(item.remunerationSalary).tableData
                        : item.numberOfComplaints
                        ? JSON.parse(item.numberOfComplaints).tableData
                        : item.retirementBenefits
                        ? JSON.parse(item.retirementBenefits).tableData
                        : item.parentalLeave
                        ? JSON.parse(item.parentalLeave)
                        : item.mechanism
                        ? JSON.parse(item.mechanism)
                        : item.membershipOfEmployees
                        ? JSON.parse(item.membershipOfEmployees).tableData
                        : item.essentialIndicatorsP6
                        ? JSON.parse(item.essentialIndicatorsP6).tableData
                        : item.greenhouseGas
                        ? JSON.parse(item.greenhouseGas).tableData
                        : item.environmentalAssessments
                        ? JSON.parse(item.environmentalAssessments).tableData
                        : item.energyConsumed
                        ? JSON.parse(item.energyConsumed).tableData
                        : item.emissions
                        ? JSON.parse(item.emissions).tableData
                        : item.disclosuresWater
                        ? JSON.parse(item.disclosuresWater).tableData
                        : item.chambers
                        ? JSON.parse(item.chambers).tableData
                        : item.impactAssessments
                        ? JSON.parse(item.impactAssessments).tableData
                        : item.inputMaterial
                        ? JSON.parse(item.inputMaterial).tableData
                        : item.turnover
                        ? JSON.parse(item.turnover).tableData
                        : item.instances
                        ? JSON.parse(item.instances).tableData
                        : item.consumerComplaints &&
                          JSON.parse(item.consumerComplaints).tableData
                    );
                    setUserUpdateId(item.id);
                  }}
                  style={{ height: 40, alignSelf: "center" }}
                >
                  Edit
                </Button>
              </div>
              <StaticTable
                key={index}
                columns={columns}
                data={
                  item.participationOfWomen
                    ? JSON.parse(item.participationOfWomen)
                    : item.turnOverRate
                    ? JSON.parse(item.turnOverRate).tableData
                    : item.transparencyAndDisclosure
                    ? JSON.parse(item.transparencyAndDisclosure).tableData
                    : item.essentialIndicators
                    ? JSON.parse(item.essentialIndicators)
                    : item.remunerationSalary
                    ? JSON.parse(item.remunerationSalary).tableData
                    : item.numberOfComplaints
                    ? JSON.parse(item.numberOfComplaints).tableData
                    : item.retirementBenefits
                    ? JSON.parse(item.retirementBenefits).tableData
                    : item.parentalLeave
                    ? JSON.parse(item.parentalLeave)
                    : item.mechanism
                    ? JSON.parse(item.mechanism)
                    : item.membershipOfEmployees
                    ? JSON.parse(item.membershipOfEmployees).tableData
                    : item.essentialIndicatorsP6
                    ? JSON.parse(item.essentialIndicatorsP6).tableData
                    : item.greenhouseGas
                    ? JSON.parse(item.greenhouseGas).tableData
                    : item.environmentalAssessments
                    ? JSON.parse(item.environmentalAssessments).tableData
                    : item.energyConsumed
                    ? JSON.parse(item.energyConsumed).tableData
                    : item.emissions
                    ? JSON.parse(item.emissions).tableData
                    : item.disclosuresWater
                    ? JSON.parse(item.disclosuresWater).tableData
                    : item.chambers
                    ? JSON.parse(item.chambers).tableData
                    : item.impactAssessments
                    ? JSON.parse(item.impactAssessments).tableData
                    : item.inputMaterial
                    ? JSON.parse(item.inputMaterial).tableData
                    : item.turnover
                    ? JSON.parse(item.turnover).tableData
                    : item.instances
                    ? JSON.parse(item.instances).tableData
                    : item.consumerComplaints &&
                      JSON.parse(item.consumerComplaints).tableData
                }
                setData={setData}
                modalContent={modalContent}
                cloneActive={cloneActive}
              />

              {fileUplode ? (
                <>
                  <Box className={fileUplodeContainer}>
                    <div style={{ display: "flex" }}>
                      {userTypeName === "Company Admin" ? (
                        <CustomImageDownload bufferValue={item.document_link} />
                      ) : (
                        <CustomImageUpload />
                      )}
                    </div>
                  </Box>
                </>
              ) : null}
              <div style={{ marginTop: "20px" }}></div>
            </div>
          );
        })
      ) : (
        <>
          <StaticTable
            columns={columns}
            data={data}
            setData={setData}
            modalContent={modalContent}
            cloneActive={cloneActive}
          />
          {fileUplode ? (
            <Box className={fileUplodeContainer}>
              <div style={{ display: "flex" }}>
                {userTypeName === "Company Admin" ? (
                  <CustomImageDownload bufferValue={fileDownload} />
                ) : (
                  <CustomImageUpload />
                )}
              </div>
            </Box>
          ) : null}
        </>
      );
    } else if (tableType === ROW_ADDINGTABLE) {
      return cellData &&
        userTypeName === "Company Admin" &&
        data &&
        data[0].userId ? (
        data.map((data, index) => {
          return (
            <div key={index}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3
                  style={{
                    border: "3px solid lightgray",
                    padding: "5px 10px",
                    display: "inline-flex",
                    background: "#ffff",
                  }}
                >
                  User-
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      alignSelf: "center",
                    }}
                  >
                    {data.userName}
                    {/* <Moment format="DD-MM-YYYY, h:mm:ss A" className="datesstt">{data.updatedAt}</Moment> */}
                  </span>
                </h3>
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setEditData(
                      data.airEmissions
                        ? JSON.parse(data.airEmissions).tableData
                        : data.pollution
                        ? JSON.parse(data.pollution)
                        : data.operationsOffices
                        ? JSON.parse(data.operationsOffices)
                        : data.effluentDischarge
                        ? JSON.parse(data.effluentDischarge)
                        : data.corrective
                        ? JSON.parse(data.corrective)
                        : data.publicPolicy
                        ? JSON.parse(data.publicPolicy)
                        : data.rehabilitation
                        ? JSON.parse(data.rehabilitation).tableData
                        : data.beneficiaries
                        ? JSON.parse(data.beneficiaries)
                        : data.correctiveP8
                        ? JSON.parse(data.correctiveP8)
                        : data.intellectualProperty
                        ? JSON.parse(data.intellectualProperty)
                        : data.negativeImpacts
                        ? JSON.parse(data.negativeImpacts)
                        : data.projects && JSON.parse(data.projects)
                    );
                    setUserUpdateId(data.id);
                  }}
                  style={{ height: 40, alignSelf: "center" }}
                >
                  Edit
                </Button>
              </div>
              <RowAddingTable
                key={index}
                columns={columns}
                data={
                  data.airEmissions
                    ? JSON.parse(data.airEmissions).tableData
                    : data.pollution
                    ? JSON.parse(data.pollution)
                    : data.operationsOffices
                    ? JSON.parse(data.operationsOffices)
                    : data.effluentDischarge
                    ? JSON.parse(data.effluentDischarge)
                    : data.corrective
                    ? JSON.parse(data.corrective)
                    : data.publicPolicy
                    ? JSON.parse(data.publicPolicy)
                    : data.rehabilitation
                    ? JSON.parse(data.rehabilitation).tableData
                    : data.beneficiaries
                    ? JSON.parse(data.beneficiaries)
                    : data.correctiveP8
                    ? JSON.parse(data.correctiveP8)
                    : data.intellectualProperty
                    ? JSON.parse(data.intellectualProperty)
                    : data.negativeImpacts
                    ? JSON.parse(data.negativeImpacts)
                    : data.projects && JSON.parse(data.projects)
                }
                initialCellData={cellData}
                setData={setData}
                modalContent={modalContent}
              />
            </div>
          );
        })
      ) : (
        <>
          <RowAddingTable
            columns={columns}
            data={data}
            initialCellData={cellData}
            setData={setData}
            modalContent={modalContent}
          />
        </>
      );
    } else if (tableType === ROW_FULLWIDTH_TABLE) { 
      return cellData &&
        userTypeName === "Company Admin" &&
        data &&
        data[0].userId ? (
        data.map((data, index) => {
          return (
            <div key={index}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3
                  style={{
                    border: "3px solid lightgray",
                    padding: "5px 10px",
                    display: "inline-flex",
                    background: "#ffff",
                  }}
                >
                  User-
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      alignSelf: "center",
                    }}
                  >
                    {data.userName}
                    {/* <Moment format="DD-MM-YYYY, h:mm:ss A" className="datesstt">{data.updatedAt}</Moment> */}
                  </span>
                </h3>
                <Button
                  variant="contained"
                  onClick={() => {
                    setModal(true);
                    setEditData(
                      data.differentAbled
                        ? JSON.parse(data.differentAbled)
                        : data.monetary
                        ? JSON.parse(data.monetary).tableData
                        : data.nonMonetary
                        ? JSON.parse(data.nonMonetary).tableData
                        : data.caseDetails
                        ? JSON.parse(data.caseDetails)
                        : data.essentialIndicatorsP4
                        ? JSON.parse(data.essentialIndicatorsP4).tableData
                        : data.essentialIndicatorsP5
                        ? JSON.parse(data.essentialIndicatorsP5).tableData
                        : data.minimumWages
                        ? JSON.parse(data.minimumWages).tableData
                        : data.assessmentsP5
                        ? JSON.parse(data.assessmentsP5).tableData
                        : data.employeesCovered
                        ? JSON.parse(data.employeesCovered)
                        : data.workersCovered
                        ? JSON.parse(data.workersCovered)
                        : data.training
                        ? JSON.parse(data.training).tableData
                        : data.performance
                        ? JSON.parse(data.performance).tableData
                        : data.complaints
                        ? JSON.parse(data.complaints).tableData
                        : data.assessmentP3
                        ? JSON.parse(data.assessmentP3).tableData
                        : data.waterDischarged
                        ? JSON.parse(data.waterDischarged).tableData
                        : data.wasteManagement
                        ? JSON.parse(data.wasteManagement).tableData
                        : data.waterWithdrawal &&
                          JSON.parse(data.waterWithdrawal).tableData
                    );
                    setUserUpdateId(data.id);
                  }}
                  style={{ height: 40, alignSelf: "center" }}
                >
                  Edit
                </Button>
              </div>
              <RowFullwidthTable
                key={index}
                columns={columns}
                data={
                  data.differentAbled
                    ? JSON.parse(data.differentAbled)
                    : data.monetary
                    ? JSON.parse(data.monetary).tableData
                    : data.nonMonetary
                    ? JSON.parse(data.nonMonetary).tableData
                    : data.caseDetails
                    ? JSON.parse(data.caseDetails)
                    : data.essentialIndicatorsP4
                    ? JSON.parse(data.essentialIndicatorsP4).tableData
                    : data.essentialIndicatorsP5
                    ? JSON.parse(data.essentialIndicatorsP5).tableData
                    : data.minimumWages
                    ? JSON.parse(data.minimumWages).tableData
                    : data.assessmentsP5
                    ? JSON.parse(data.assessmentsP5).tableData
                    : data.employeesCovered
                    ? JSON.parse(data.employeesCovered)
                    : data.workersCovered
                    ? JSON.parse(data.workersCovered)
                    : data.training
                    ? JSON.parse(data.training).tableData
                    : data.performance
                    ? JSON.parse(data.performance).tableData
                    : data.complaints
                    ? JSON.parse(data.complaints).tableData
                    : data.assessmentP3
                    ? JSON.parse(data.assessmentP3).tableData
                    : data.waterDischarged
                    ? JSON.parse(data.waterDischarged).tableData
                    : data.wasteManagement
                    ? JSON.parse(data.wasteManagement).tableData
                    : data.waterWithdrawal &&
                      JSON.parse(data.waterWithdrawal).tableData
                }
                setData={setData}
                modalContent={modalContent}
                cloneActive={cloneActive}
              />
              {fileUplode ? (
                <Box className={fileUplodeContainer}>
                  <div style={{ display: "flex" }}>
                    {userTypeName === "Company Admin" ? (
                      <CustomImageDownload bufferValue={data.document_link} />
                    ) : (
                      <CustomImageUpload />
                    )}
                  </div>
                </Box>
              ) : null}
            </div>
          );
        })
      ) : (
        <>
          <RowFullwidthTable
            columns={columns}
            data={data}
            setData={setData}
            modalContent={modalContent}
            cloneActive={cloneActive}
          />
          {fileUplode ? (
            <Box className={fileUplodeContainer}>
              <div style={{ display: "flex" }}>
                {userTypeName === "Company Admin" ? (
                  <CustomImageDownload bufferValue={fileDownload} />
                ) : (
                  <CustomImageUpload />
                )}
              </div>
            </Box>
          ) : null}
        </>
      );
    } else if (tableType === ROW_GROUPING_TABLE) { 
      return cellData &&
        userTypeName === "Company Admin" &&
        data &&
        data[0].userId ? (
        data.map((data, index) => (
          <div key={index}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3
                style={{
                  border: "3px solid lightgray",
                  padding: "5px 10px",
                  display: "inline-flex",
                  background: "#ffff",
                }}
              >
                User-
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "400",
                    alignSelf: "center",
                  }}
                >
                  {data.userName}
                  {/* <Moment format="DD-MM-YYYY, h:mm:ss A" className="datesstt">{data.updatedAt}</Moment> */}
                </span>
              </h3>
              <Button
                variant="contained"
                onClick={() => {
                  setModal(true);
                  setEditData(
                    data.relatedIncidents &&
                      JSON.parse(data.relatedIncidents).tableData
                  );
                  setUserUpdateId(data.userId);
                }}
                style={{ height: 40, alignSelf: "center" }}
              >
                Edit
              </Button>
            </div>
            <RowGroupingTable
              columns={columns}
              data={
                data.relatedIncidents &&
                JSON.parse(data.relatedIncidents).tableData
              }
              setData={setData}
              modalContent={modalContent}
              cloneActive={cloneActive}
            />
            {fileUplode ? (
              <Box className={fileUplodeContainer}>
                <div style={{ display: "flex" }}>
                  {userTypeName === "Company Admin" ? (
                    <CustomImageDownload />
                  ) : (
                    <CustomImageUpload />
                  )}
                </div>
              </Box>
            ) : null}
          </div>
        ))
      ) : (
        <>
          <RowGroupingTable
            columns={columns}
            data={data}
            setData={setData}
            modalContent={modalContent}
            cloneActive={cloneActive}
          />
          {fileUplode ? (
            <Box className={fileUplodeContainer}>
              <div style={{ display: "flex" }}>
                {userTypeName === "Company Admin" ? (
                  <CustomImageDownload />
                ) : (
                  <CustomImageUpload />
                )}
              </div>
            </Box>
          ) : null}
        </>
      );
    } else if (tableType === ROW_DROPDOWN_TABLE) {
      return (
        <>
          <RowDropdownTable
            columns={columns}
            data={data}
            setData={setData}
            modalContent={modalContent}
            dropdownValues={dropdownValues}
            cloneActive={cloneActive}
          />
          {fileUplode ? (
            <Box className={fileUplodeContainer}>
              <div style={{ display: "flex" }}>
                {userTypeName === "Company Admin" ? (
                  <CustomImageDownload />
                ) : (
                  <CustomImageUpload />
                )}
              </div>
            </Box>
          ) : null}
        </>
      );
    }
  };
  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}

      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions
            keyProps={keyProps}
            chileKeyPros={chileKeyPros}
          />
        </div>
        <div className={align}>
          {renderFormFieldTop && renderFormFields && renderFormFields()}

          <Box className={headerContainer} style={{ position: "relative", paddingRight: "40px" }}>
          {userTypeName === "Company Admin" ?
            <div className="hoverdroup">
              <BookmarkBorderIcon
                // style={{ position: "relative" }}
                onClick={callingHistoryAPI}
                onMouseEnter={() => {
                  setHistoryLogs(true);
                  callingHistoryAPI();
                }}
                onMouseLeave={() => setHistoryLogs(false)}
                style={{
                  position: "absolute",
                  marginTop: 6,
                  color: "rgb(107 107 107)",
                }}
              />
              <ul
                className={`dropdown_history ${
                  historyLogs ? "active_log" : ""
                }`}
                onMouseEnter={() => {
                  setHistoryLogs(true);
                }}
                onMouseLeave={() => setHistoryLogs(false)}
              >
                  <li className="headerli">Latest Update By: </li> 
                  {historyData &&
                  historyData.map((data, i) => (
                    <li key={i} className="datestemp">{data.userName} <br/>{moment.utc(data.timeStamp).local().format('DD-MM-YYYY hh:mm a')}</li>
                  ))}                  

              </ul>
            </div> : ""}

            {modalContent?.map((content, i) => (
              <div key={i}>
                {!hideAbout && (
                  <>
                    <Typography
                      variant={renderFormFieldTop ? "subtitle" : "h6"}
                      style={{ display: "flex", align: "center" }}
                    >
                      {content.aboutTable}
                    </Typography>
                  </>
                )}

                <br />
                <Typography variant={renderFormFieldTop ? "subtitle" : "h6"}>
                  {content.subAboutTable}
                </Typography>
                
              </div>
            ))}
            {!hideHelp && (
              <IconButton onClick={handleClickOpenDialog}>
                <HelpIcon className={helperBtn} color="primary" />
              </IconButton>
            )}
          </Box>

          {renderTable()}

          {renderFormFieldBottom && renderFormFields && renderFormFields(data)}

          {!data[0].userId && (
            <Box className={btnContainer}>
              <Button variant="outlined" color="primary" onClick={resetData}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  saveData();
                  setSaveFile(true);
                }}
              >
                Save
              </Button>
            </Box>
          )}

          <CustomizedSnackbars open={open} handleClose={handleClose} />
          <CustomizedDialogs
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            modalContent={modalContent}
          />
        </div>
      </div>
    </>
  );
}
