import React, { useEffect } from "react";
import {
  userFormInitialSchema,
  userFormUischema,
  userEditFormInitialSchema,
} from "../../../../constant/userForm";
import MuiForm from "@rjsf/material-ui";
import { widgets } from "../../../../widgets/widgets";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { customErrorMsg } from "../../../../template/customErrorMsg";
import {
  useNavigate,
  useOutletContext,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import { ROUTE_PATHS } from "../../../../routes/routePath";
import {
  createUser,
  updateUser,
  getUsersDetailsById,
} from "../../../../api/api";
import { useDispatch } from "react-redux";
import { toastMessageAction } from "../../../../redux/action";
import Cookies from "universal-cookie";

const useStyles = makeStyles({
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: 30,
    paddingTop: 30,
  },
  btn: {
    margin: "0 6px",
  },
  saveBtn: {
    background: "#3AD865 !important",
    color: "white",
    height: "40px",
    width: "100px",
    padding: 10,
  },
  cancelBtn: {
    background: "#0000001A !important",
    color: "white",
    height: "40px",
    width: "100px",
    padding: 10,
  },
});

function UserForm(props) {
  const { handleNext, setModalOpen } = useOutletContext();
  const cookies = new Cookies();
  const [schema, setSchema] = React.useState(userFormInitialSchema);
  const [formData, setFormData] = React.useState({});
  const [liveValidator, setLiveValidator] = React.useState(false);
  const { btnContainer, saveBtn, cancelBtn } = useStyles();
  const navigate = useNavigate();
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const customerId = cookies.get("custId");
  const userTypeId = "8d92f2ca-ba3d-49d1-a3e0-ddc15a77e98d";
  const editId = searchParam?.get("editId");
  const isViewMode = searchParam?.get("isViewMode");
  const dispatch = useDispatch();

  useEffect(() => {
    if (editId) {
      setSchema(userEditFormInitialSchema);
      getUsersDetailsById(editId).then((response) => {
        setFormData(response.data);
        console.log(editId);
      });
    }
  }, [dispatch, editId]);

  return (
    <div>
      <MuiForm
        schema={schema}
        widgets={widgets}
        uiSchema={userFormUischema(isViewMode)}
        showErrorList={false}
        formData={formData}
        formContext={formData}
        noHtml5Validate
        transformErrors={customErrorMsg}
        noValidate={editId && false}
        liveValidate={liveValidator}
        onError={(errors) => {
          if (errors.length !== 0) {
            setModalOpen(true);
          }
        }}
        onChange={(e) =>
          setFormData({
            ...e.formData,
          })
        }
        onSubmit={({ formData }) => {
          if (!editId) {
            let workData = {
              ...formData,
              profile: formData?.profile ? formData?.profile[0] : null,
              userTypeId: userTypeId,
              customerId: customerId,
              statusId: "af6f2eb0-2147-47e0-9da3-5128cd5e4b12",
            };
            delete workData.confirmPassword;
            let form = new FormData();
            Object.keys(workData)
              .filter(
                (fill) => workData[fill] !== null && workData[fill] !== ""
              )
              .map((item) => {
                //to remove spaces in value
                return form.append(
                  item,
                  typeof workData[item] === "string"
                    ? workData[item]
                        .toString()
                        .replace(/\s{2,}/g, " ")
                        .trim()
                    : workData[item]
                );
              });

            createUser(form)
              .then((response) => {
                const { values } = response.data;
                navigate({
                  pathname: ROUTE_PATHS.COMPANY_USER_ACCESS,
                  search: `?${createSearchParams({
                    userId: JSON.stringify(values.userDetails.id),
                  })}`,
                });
                dispatch(
                  toastMessageAction({
                    severity: "success",
                    message: "User created and email notification sent to user",
                    messageStatus: true,
                  })
                );
                handleNext();
              })
              .catch((response) => {
                console.log(response);
                dispatch(
                  toastMessageAction({
                    severity: "error",
                    message: "Email Id already exists",
                    messageStatus: true,
                  })
                );
              });
          } else {
            let workData = { ...formData };
            delete workData.password;
            delete workData.timeLimit;
            let form = new FormData();

            Object.keys(workData)
              .filter(
                (fill) => workData[fill] !== null && workData[fill] !== ""
              )
              .map((item) => {
                //to remove spaces in value
                return form.append(
                  item,
                  typeof workData[item] === "string"
                    ? workData[item]
                        .toString()
                        .replace(/\s{2,}/g, " ")
                        .trim()
                    : workData[item]
                );
              });

            updateUser(editId, form).then((response) => {
              isViewMode
                ? navigate({
                    pathname: ROUTE_PATHS.COMPANY_USER_ACCESS,
                    search: `?${createSearchParams({
                      userId: JSON.stringify(response.data.id),
                      isViewMode: true,
                    })}`,
                  })
                : navigate({
                    pathname: ROUTE_PATHS.COMPANY_USER_ACCESS,
                    search: `?${createSearchParams({
                      userId: JSON.stringify(response.data.id),
                    })}`,
                  });
            })
            .catch((response) => {
              console.log(response);
              dispatch(
                toastMessageAction({
                  severity: "error",
                  message: "Please select date",
                  messageStatus: true,
                })
              );
            });
            handleNext();
          }
        }}
      >
        <div className={btnContainer}>
          <Button
            variant="contained"
            className={cancelBtn}
            onClick={() => navigate(ROUTE_PATHS.COMPANYUSERS)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={saveBtn}
            onClick={() => setLiveValidator(true)}
          >
            {editId ? "Update" : "Save"}
          </Button>
        </div>
      </MuiForm>
    </div>
  );
}
export default UserForm;
