/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
//import User from "../../asset/user.png";
import Uplode from "../../asset/uploadImage.png";
import { Button, FormControl, FormHelperText } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "universal-cookie";
import axios from "axios";

function CustomImageUpload({
  name,
  label,
  error,
  url,
  touched,
  value,
  sytle,
  onChange,
  profileUrl,
  isViewMode,
  uploadFile,
}) {
  const [imgUrl, setImgUrl] = React.useState(url);
  const [fileName, setFileName] = React.useState("File Uplode");
  const myRefname = useRef(null);
  const handleClick = (e) => {
    myRefname.current.click();
  };

  const cookies = new Cookies();
  const token = cookies.get("token");

  const [file, setFile] = React.useState("");
  const [save, setSave] = React.useState(false);

  const onImageChange = (event) => {
    setFile(event.target.files[0]);
    console.log(file);
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImgUrl(reader.result);
        onChange(name, event.target.files[0]);
        setFileName(event.target.files[0].name);
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  useEffect(() => {
    setImgUrl(url);
    const FormData = require("form-data");
    var data = new FormData();
    data.append("file", file);
    data.append("formId", cookies.get("formId"));
    data.append("sectionName", cookies.get("sectionName"));
    data.append("id", cookies.get("id"));
    var config = {
      method: "post",
      url: `${cookies.get("baseURL")}/uploadFile`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    if(save) {
      axios(config)
        .then(function (response) {
          console.log(response);
          setFile("");
          cookies.set("status", undefined, {
            path: "/",
          });
          cookies.set("id", undefined, {
            path: "/",
          });
          setFile("");
          setFileName("File Uplode");
        })
        .catch(function (error) {
          console.log(error.response.data);
        });
        setSave(false)
    }
  }, [save]);

  return (
    <>
      <FormControl
        fullWidth
        style={{ marginLeft: 3, width: 330, cursor: "pointer" }}
      >
        <input
          ref={myRefname}
          className="hiddenFileNo"
          name={name}
          type={"file"}
          error={touched && error}
          onChange={onImageChange}
          helperText={touched && error}
          label={label}
          disabled={isViewMode}
        />
        <div
          onClick={(e) => handleClick(e)}
          className="fileInputHolder"
          sytle={sytle}
        >
          {uploadFile ? (
            <FileUploadIcon style={{ marginRight: 10 }} />
          ) : (
            <img
              src={imgUrl || profileUrl || Uplode}
              style={{ maxWidth: "100%", width: 40, height: 40 }}
              alt=""
            ></img>
          )}
          <p className="fileUplodeStyle">
            {!file ? "Choose File" : file.name + " -[Choose File]"}
          </p>
         </div>
          <Button
              variant="contained"
             onClick={()=> setSave(true)}
             style={{ marginTop:10}}
             >
              Save
            </Button>
        <FormHelperText style={{ color: "#d32f2f" }}>{error}</FormHelperText>
      </FormControl>
    </>
  );
}

export default CustomImageUpload;
