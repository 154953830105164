import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../../Context";
import { CSRdetailsStyles } from "../../../../../styles";
import Cookies from "universal-cookie";
import {
  CustomizedAccordions,
  CustomizedSnackbars,
  CustomRadioButton,
  CustomTextField,
  TableNavbar,
} from "../../../../reusable";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  cyberSecurityConstants,
  cyberSecurityInitialvalues,
  cyberSecurityOptions,
} from "../../../../../constant/tableData/sectionC/principle9/cyberSecurity";
import { PRINCIPLE9_CYBERSECURITY } from "../../../../../constant";

function CyberSecurity() {
  const {
    list,
    root,
    rootAlign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
  } = CSRdetailsStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const cyberSecurityFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE9_CYBERSECURITY
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    cyberSecurityFormConfig
      ? updateTableValues(
          PRINCIPLE9_CYBERSECURITY,
          { cyberSecurity: `${jsonStr}` },
          cyberSecurityFormConfig.id
        )
      : createTableValues(PRINCIPLE9_CYBERSECURITY, {
          cyberSecurity: `${jsonStr}`,
        });
  };

  //  fetch db data
  useEffect(() => {
    if (cyberSecurityFormConfig && cyberSecurityFormConfig.data.cyberSecurity) {
      setParentData(JSON.parse(cyberSecurityFormConfig.data.cyberSecurity));
    }
  }, [cyberSecurityFormConfig]);

  //   snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // formik
  const onSubmit = (values) => {
    storeTableData(values);
    handleClickSnackBar();
  };

  const formik = useFormik({
    initialValues: parentData || cyberSecurityInitialvalues,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;
  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions
            keyProps={"panel3"}
            chileKeyPros={"principle9"}
          />
        </div>
        <div className={rootAlign}>
          {/* <Typography variant="h6">{CSRdetailsContent.title}</Typography> */}

          {cyberSecurityConstants.map(({ id, name, label, type, field }) => (
            <div key={id} style={{ margin: 10 }}>
              <Typography>
                {label}
                {field === "textField" && (
                  <CustomTextField
                    type={type}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                    variant="outlined"
                    logInField
                    style={{
                      width: "410px",
                      background: "white",
                      paddingBottom: "0px ",
                    }}
                  />
                )}
              </Typography>
              {field === "radioButton" && (
                <CustomRadioButton
                  name={name}
                  CSRdetailsOptions={cyberSecurityOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] && errors[name]}
                />
              )}
            </div>
          ))}

          <Box className={btnContainer}>
            <Button variant="outlined" color="primary" onClick={resetForm}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>

          <CustomizedSnackbars open={open} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export default CyberSecurity;
