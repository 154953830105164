import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const waterDischargedOptions = ["Yes", "No"];

export const waterDischargedColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Parameter",
      accessor: "values",
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      type: "number",
      Cell: EditableCell,
      maxLength: 4,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      type: "number",
      Cell: EditableCell,
      maxLength: 4,
    },
  ];
};

const columnName = [
  {
    id: 1,
    values:
      "Water discharge by destination and level of treatment (in kilolitres)",
    fullwidth: true,
  },
  { id: 2, values: "(i) To Surface water" },
  { id: 3, values: "- No treatment" },
  { id: 4, values: "- With treatment – please specify level of treatment" },
  { id: 5, values: "(ii) To Groundwater" },
  { id: 6, values: "- No treatment" },
  { id: 7, values: "- With treatment – please specify level of treatment" },
  { id: 8, values: "(iii) To Seawater" },
  {
    id: 9,
    values: "- No treatment",
  },
  { id: 10, values: "- With treatment – please specify level of treatment" },
  {
    id: 11,
    values: "(iv) Sent to third-parties ",
  },
  { id: 12, values: "- No treatment" },
  { id: 13, values: "- With treatment – please specify level of treatment" },
  { id: 14, values: "(v) Others" },
  { id: 15, values: "- No treatment" },
  {
    id: 16,
    values:
      "- With treatment – please specify level of treatment- With treatment – please specify level of treatment",
  },
  {
    id: 17,
    values: "Total water discharged (in kilolitres) ",
  },
];
export const waterDischargedCellData = columnName.map((item) =>
  item.fullwidth
    ? {
        id: item.id,
        values: item.values,
        fullwidth: item.fullwidth,
      }
    : {
        id: item.id,
        values: item.values,
        currentYear: "",
        previousYear: "",
      }
);

export const waterDischargedInitialvalues = {
  tableData: waterDischargedCellData,
  independent: "",
  chemicals: "",
  current: "",
  previous: "",
};

export const waterDischargedConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency",
    field: "radioButton",
  },
];
