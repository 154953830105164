import React, { useContext, useEffect, useMemo, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../Context";
import { CustomizedSnackbars, TableNavbar } from "../../../reusable/index";
import {
  SECTIONA_LISTEDENTITY,
  listedEntityContent,
} from "../../../../constant/index";
import { createTableValues, updateTableValues } from "../../../../api/method";
import { listedEntityStyles } from "../../../../styles/index";
import { CustomizedAccordions } from "../../../reusable/Accordion";
import MuiForm from "@rjsf/material-ui";
import {
  listedEntityInitialSchema,
  listedEntityUischema,
} from "../../../../constant/index";
import { widgets } from "../../../../widgets/widgets";
import Cookies from "universal-cookie";
import { customErrorMsg } from "../../../../template/customErrorMsg";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import moment from "moment";
import axios from "axios";

function ListedEntity(props) {
  // const { download } = props;
  const {
    title,
    root,
    // downloadRoot,
    rootAlign,
    // dowloadRootALign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
  } = listedEntityStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [open, setOpen] = React.useState(false);
  const [schema, setSchema] = React.useState(listedEntityInitialSchema);
  const [formData, setFormData] = React.useState({});
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const token = cookies.get("token");

  const [historyData, setHistoryData] = useState("");
  const [historyLogs, setHistoryLogs] = useState(false);

  const listedEntityFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_LISTEDENTITY
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    listedEntityFormConfig
      ? updateTableValues(
          SECTIONA_LISTEDENTITY,
          { listedEntity: `${jsonStr}` },
          listedEntityFormConfig.id
        )
      : createTableValues(SECTIONA_LISTEDENTITY, {
          listedEntity: `${jsonStr}`,
        });
  };

  // fetch db data
  useEffect(() => {
    if (listedEntityFormConfig) {
      setFormData(JSON.parse(listedEntityFormConfig.data.listedEntity));
    }
  }, [listedEntityFormConfig]);

  // snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const callingHistoryAPI = () => {
    const url = `${cookies.get("baseURL")}/updateHistory`;
    const data = {
      formId: cookies.get("formId"),
      section: window.location.pathname.split("/").slice(1, 3).join("/"),
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(url, data, { headers })
      .then((response) => {
        setHistoryData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


//  function convertUTCToLocalDateIgnoringTimezone(utcDate: Date) {
//     return new Date(
//       utcDate.getUTCFullYear(),
//       utcDate.getUTCMonth(),
//       utcDate.getUTCDate(),
//       utcDate.getUTCHours(),
//       utcDate.getUTCMinutes(),
//       utcDate.getUTCSeconds(),
//       utcDate.getUTCMilliseconds(),
//     );
//   }


  return (
    <>
      {/* {!download && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}

      <div className={!download ? root : downloadRoot}>
        {!download && (
          <div className={accordionContainer}>
            <CustomizedAccordions />
          </div>
        )} */}

      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions keyProps={"panel1"} />
        </div>

        {/* <div className={!download ? rootAlign : dowloadRootALign}> */}
        <div className={rootAlign}>
          <Typography variant="h6" style={{position: "relative"}}>
            {listedEntityContent.header} 
            {userTypeName === "Company Admin" ?
            <div className="hoverdroup">
              <BookmarkBorderIcon
                // style={{ position: "relative" }}
                onClick={callingHistoryAPI}
                onMouseEnter={() => {
                  setHistoryLogs(true);
                  callingHistoryAPI();
                }}
                onMouseLeave={() => setHistoryLogs(false)}
                style={{
                  position: "absolute",
                  marginTop: 6,
                  color: "rgb(107 107 107)",
                }}
              />
              <ul
                className={`dropdown_history ${
                  historyLogs ? "active_log" : ""
                }`}
                onMouseEnter={() => {
                  setHistoryLogs(true);
                }}
                onMouseLeave={() => setHistoryLogs(false)}
              >
                  <li className="headerli">Latest Update By: </li> 
                  {historyData &&
                  historyData.map((data, i) => (
                    <li key={i} className="datestemp">{data.userName} <br/>
                    {moment.utc(data.timeStamp).local().format('DD-MM-YYYY hh:mm a')}
                    </li>
                  ))}                  

              </ul>
            </div> : ""}
          </Typography>
          <Typography className={title} variant="h6">
            I. <span className={title}>{listedEntityContent.title}</span>
          </Typography>

          <MuiForm
            schema={schema}
            widgets={widgets}
            uiSchema={listedEntityUischema}
            showErrorList={false}
            formData={formData}
            noHtml5Validate
            transformErrors={customErrorMsg}
            noValidate
            // validate={validate}

            // liveValidate={true}
            onChange={(e) =>
              setFormData({
                ...e.formData,
              })
            }
            onSubmit={({ formData }) => console.log(formData)}
          >
            <Box className={btnContainer}>
              <Button variant="outlined" color="primary">
                Cancel
              </Button>
              <Button
                variant="contained"
                type={"submit"}
                onClick={() => {
                  storeTableData(formData);
                  handleClickSnackBar();
                }}
              >
                Save
              </Button>
            </Box>

            <CustomizedSnackbars open={open} handleClose={handleClose} />
          </MuiForm>
        </div>
      </div>
    </>
  );
}

export default ListedEntity;
