export const essentialIndicatorsP4Content = [
  {
    id: "1",
    title:
      "PRINCIPLE 4: Businesses should respect the interests of and be responsive to all its stakeholders",
    // aboutTable:
    // "1. Describe the processes for identifying key stakeholder groups of the entity.",
    aboutTable:
      "2. List stakeholder groups identified as key for your entity and the frequency of engagement with each stakeholder group. ",
  },
];
