import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const airEmissionsOptions = ["Yes", "No"];

export const airEmissionsColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Parameter",
      accessor: "values",
      Cell: EditableCell,
    },
    {
      Header: "Please specify unit",
      accessor: "unit",
      Cell: EditableCell,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      type: "number",
      Cell: EditableCell,
      maxLength: 4,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
  ];
};

const columnName = [
  { id: 1, values: "NOx" },
  { id: 2, values: "SOx" },
  { id: 3, values: "Particulate matter (PM)" },
  { id: 4, values: "Persistent organic pollutants (POP)" },
  { id: 5, values: "Volatile organic compounds (VOC)" },
  { id: 6, values: "Hazardous air pollutants (HAP)" },
  { id: 7, values: "Others – please specify" },
];
export const airEmissionsCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  unit: "",
  currentYear: "",
  previousYear: "",
}));

export const airEmissionsInitialvalues = {
  tableData: airEmissionsCellData,
  independent: "",
  agency: "",
  current: "",
  previous: "",
};

export const airEmissionsConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N)",
    field: "radioButton",
  },
  {
    id: 2,
    name: "agency",
    label: "If yes, name of the external agency.",
    field: "textField",
  },
];
