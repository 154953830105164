import { EditableCell } from "../../../components/utils/EditableCell";

export const AssessmentEvaluationDropdown = [
  { id: 1, name: "Yes", acccessor: "name" },
  { id: 2, name: "No", acccessor: "name" },
];

export const AssessmentEvaluationColumns = [
  {
    Header: "",
    accessor: "values",
  },
  {
    Header: "P1",
    accessor: "p1",
    Cell: EditableCell,
  },
  {
    Header: "P2",
    accessor: "p2",
    Cell: EditableCell,
  },
  {
    Header: "P3",
    accessor: "p3",
    Cell: EditableCell,
  },
  {
    Header: "P4",
    accessor: "p4",
    Cell: EditableCell,
  },
  {
    Header: "P5",
    accessor: "p5",
    Cell: EditableCell,
  },
  {
    Header: "P6",
    accessor: "p6",
    Cell: EditableCell,
  },
  {
    Header: "P7",
    accessor: "p7",
    Cell: EditableCell,
  },
  {
    Header: "P8",
    accessor: "p8",
    Cell: EditableCell,
  },
  {
    Header: "P9",
    accessor: "p9",
    Cell: EditableCell,
  },
];

export const AssessmentEvaluationCellData = [
  {
    id: "1",
    values:
      "11. Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
    dropdownRow: true,
  },
];

export const AssessmentEvaluationInitialvalues = {
  tableData: AssessmentEvaluationCellData,
};

export const AssessmentEvaluationConstantData = [
  {
    id: 1,
    rowId: 1,
    label: "External Agency - P1",
    name: "p1",
    accessor: "p1",
  },
  {
    id: 2,
    rowId: 1,
    label: "External Agency - P2",
    name: "p2",
    accessor: "p2",
  },
  {
    id: 3,
    rowId: 1,
    label: "External Agency - P3",
    name: "p3",
    accessor: "p3",
  },
  {
    id: 4,
    rowId: 1,
    label: "External Agency - P4",
    name: "p4",
    accessor: "p4",
  },
  {
    id: 5,
    rowId: 1,
    label: "External Agency - P5",
    name: "p5",
    accessor: "p5",
  },
  {
    id: 6,
    rowId: 1,
    label: "External Agency - P6",
    name: "p6",
    accessor: "p6",
  },
  {
    id: 7,
    rowId: 1,
    label: "External Agency - P7",
    name: "p7",
    accessor: "p7",
  },
  {
    id: 8,
    rowId: 1,
    label: "External Agency - P8",
    name: "p8",
    accessor: "p8",
  },
  {
    id: 9,
    rowId: 1,
    label: "External Agency - P9",
    name: "p9",
    accessor: "p9",
  },
];
