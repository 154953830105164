import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Modal, Typography } from "@mui/material";
import { CustomTextarea, CustomRadioButton } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  essentialIndicatorsP6Columns,
  essentialIndicatorsP6Initialvalues,
  essentialIndicatorsP6ConstantData,
  essentialIndicatorsP6Content,
  essentialIndicatorsP6Options,
  STATIC_TABLE,
  PRINCIPLE6_ESSENTIAL_INDICATORS,
  essentialIndicatorsP5Content,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
  contentStyle: {
    marginTop: "20px",
  },
}));

const EssentialIndicatorsP6 = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(
    essentialIndicatorsP6Initialvalues
  );
  const { list } = useStyles();

  //to get data from api
  const essentialIndicatorsP6FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE6_ESSENTIAL_INDICATORS
    );
  }, [existingTableData]);

  const formik = useFormik({
    initialValues: parentData || essentialIndicatorsP6Initialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, setFieldValue, handleBlur } = formik;

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE6_ESSENTIAL_INDICATORS,
        { essentialIndicatorsP6: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      essentialIndicatorsP6FormConfig
        ? updateTableValues(
            PRINCIPLE6_ESSENTIAL_INDICATORS,
            { essentialIndicatorsP6: `${jsonStr}` },
            essentialIndicatorsP6FormConfig.id
          )
        : createTableValues(PRINCIPLE6_ESSENTIAL_INDICATORS, {
            essentialIndicatorsP6: `${jsonStr}`,
          });
    }
  };
  //  fetch db data

  useEffect(() => {
    if (
      essentialIndicatorsP6FormConfig &&
      essentialIndicatorsP6FormConfig.data.essentialIndicatorsP6
    ) {
      setParentData(
        JSON.parse(essentialIndicatorsP6FormConfig.data.essentialIndicatorsP6)
      );
    }
  }, [essentialIndicatorsP6FormConfig]);

  const renderFormFields = () => {
    return (
      <>
        {essentialIndicatorsP6ConstantData.map(
          ({ id, label, name, field, type }) => (
            <div key={id}>
              <Typography variant="subtitle" className={list}>
                {label}
              </Typography>
              {field === "textField" && (
                <CustomTextarea
                  name={name}
                  onChange={handleChange}
                  value={values[name]}
                  type={type}
                  onBlur={handleBlur}
                  variant="outlined"
                />
              )}
              {field === "radioButton" && (
                <>
                  <CustomRadioButton
                    name={name}
                    CSRdetailsOptions={essentialIndicatorsP6Options}
                    onChange={handleChange}
                    value={values[name]}
                  />
                </>
              )}
            </div>
          )
        )}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={essentialIndicatorsP6Columns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          essentialIndicatorsP6FormConfig &&
          !essentialIndicatorsP6FormConfig.data.essentialIndicatorsP6
            ? essentialIndicatorsP6FormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={essentialIndicatorsP6Content}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        hideHelp={true}
        keyProps={"panel3"}
        chileKeyPros={"principle6"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={essentialIndicatorsP6Columns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={essentialIndicatorsP6Content}
          />
        </div>
      </Modal>
    </>
  );
};

export default EssentialIndicatorsP6;
