import React from "react";
import { Button } from "@mui/material";

export const ButtonField = (props) => {
  const {
    label,
    className,
    onClick,
    type,
    color,
    startIcon,
    variant,
    isViewMode,
  } = props;
  return (
    <>
      <Button
        variant={variant}
        className={className}
        onClick={onClick}
        type={type}
        color={color}
        startIcon={startIcon}
        disabled={isViewMode}
      >
        {label}
      </Button>
    </>
  );
};
