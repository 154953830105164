export const environmentalAssessmentsContent = [
  {
    id: "1",
    title:
      "PRINCIPLE 6: Businesses should respect and make efforts to protect and restore the environment",
    aboutTable:
      "11. Details of environmental impact assessments of projects undertaken by the entity based on applicable laws, in the current financial year:",
    subAboutTable: "Response: (SB - See Below), NA(Not Applicable)",
  },
];
