import { EditableCell } from "../../../../components/utils/EditableCell";

export const mechanismColumns = [
  {
    Header: "",
    accessor: "values",
  },

  {
    Header: "Yes/No (If Yes, then give details of the mechanism in brief)",
    accessor: "mechanismvalues",
    Cell: EditableCell,
  },
];

const columnName = [
  { id: 1, values: "Permanent Workers" },
  { id: 2, values: "Other than Permanent Workers" },
  { id: 3, values: "Permanent Employees" },
  { id: 3, values: "Other than Permanent Employees" },
];
export const mechanismCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  mechanismvalues: "",
}));
