import { EditableCell } from "../../../../components/utils/EditableCell";

export const employeesCoveredColumns = [
  {
    Header: "Category ",
    // accessor: "values",
    id: 1,
    rowspan: 2,
    columns: [
      {
        Header: " ",
        hideHeader: true,
        accessor: "values",
      },
    ],
  },
  {
    id: 3,
    Header: "Total (A)",
    rowspan: 2,
    columns: [
      {
        Header: " ",
        hideHeader: true,
        accessor: "totalA",
        Cell: EditableCell,
        type: "number",
      },
    ],
  },
  {
    id: 2,
    Header: "% of employees covered by",
    columns: [
      {
        Header: "Health insurance",
        columns: [
          {
            Header: "Number (B) ",
            accessor: "numberB",
            Cell: EditableCell,
            type: "number",
          },
          {
            Header: "% (B / A)",
            accessor: "percentageBA",
            Cell: EditableCell,
            type: "number",
            maxLength: 3,
          },
        ],
      },
      {
        Header: "Accident insurance",
        columns: [
          {
            Header: "Number (C) ",
            accessor: "numberC",
            Cell: EditableCell,
            type: "number",
          },
          {
            Header: "% (C / A)",
            accessor: "percentageCA",
            Cell: EditableCell,
            type: "number",
            maxLength: 3,
          },
        ],
      },
      {
        Header: "Maternity benefits",
        columns: [
          {
            Header: "Number (D) ",
            accessor: "numberD",
            Cell: EditableCell,
            type: "number",
          },
          {
            Header: "% (D / A)",
            accessor: "percentageDA",
            Cell: EditableCell,
            type: "number",
            maxLength: 3,
          },
        ],
      },
      {
        Header: "Paternity Benefits",
        columns: [
          {
            Header: "Number (E) ",
            accessor: "numberE",
            Cell: EditableCell,
            type: "number",
          },
          {
            Header: "% (E / A)",
            accessor: "percentageEA",
            Cell: EditableCell,
            type: "number",
            maxLength: 3,
          },
        ],
      },
      {
        Header: "Day Care facilities",
        columns: [
          {
            Header: "Number (F) ",
            accessor: "numberF",
            Cell: EditableCell,
            type: "number",
          },
          {
            Header: "% (F / A)",
            accessor: "percentageFA",
            Cell: EditableCell,
            type: "number",
            maxLength: 3,
          },
        ],
      },
    ],
  },
];

const columnName = [
  { id: 1, values: "Permanent employees", fullwidth: true },
  { id: 2, values: "Male" },
  { id: 3, values: "Female" },
  { id: 4, values: "Total" },
  { id: 5, values: "Other than Permanent employees", fullwidth: true },
  { id: 6, values: "Male" },
  { id: 7, values: "Female" },
  { id: 8, values: "Total" },
];
export const employeesCoveredCellData = columnName.map((item) =>
  item.fullwidth
    ? {
        id: item.id,
        values: item.values,
        fullwidth: item.fullwidth,
      }
    : {
        id: item.id,
        values: item.values,
        totalA: "",
        numberB: "",
        percentageBA: "",
        numberC: "",
        percentageCA: "",
        numberD: "",
        percentageDA: "",
        numberE: "",
        percentageEA: "",
        numberF: "",
        percentageFA: "",
      }
);
