import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { useFormik } from "formik";
import CustomImageUpload from "../../reusable/CustomImageUpload";
import Grid from "@material-ui/core/Grid";
import { CustomTextField } from "../../reusable";
import { ButtonField } from "../../reusable/ButtonField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { formGroup, myUserUpdateInitial } from "../../../constant/myUsers";
import { myUserStyle } from "../../../styles/dashboard";
import { updateUser } from "../../../api/api";
import { companyAdminEditProfileValidation } from "../../../constant/validation";
import { getUsersDetailsById } from "../../../api/api";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { toastMessageAction } from "../../../redux/action";

const EditProfile = () => {
  const {
    root,
    header,
    saveButton,
    headerContainer,
    container,
    arrowBackIcon,
    superAdminRoot,
    superAdminTitle,
    adminArrowBackIcon,
    cancelButton,
    btnContainer,
  } = myUserStyle();
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const userTypeName = cookies.get("userTypeName");
  const dispatch = useDispatch();
  //to handle onSubmit
  const onSubmit = (data) => {
    let workData = { ...data };
    let formData = new FormData();

    Object.keys(workData)
      .filter((fill) => workData[fill] !== null && workData[fill] !== "")
      .map((item) => {
        return formData.append(
          item,
          typeof workData[item] === "string"
            ? workData[item]
                .toString()
                .replace(/\s{2,}/g, " ")
                .trim()
            : workData[item]
        );
      });
    updateUser(userId, formData).then(
      () => {
        dispatch(
          toastMessageAction({
            severity: "success",
            message: "Update Successfully  !",
            messageStatus: true,
          })
        );

        navigate(`${ROUTE_PATHS.PROFILE}`);
      },
      ({ response }) => {
        console.log(response.data.error);
      }
    );
  };

  const formik = useFormik({
    initialValues: myUserUpdateInitial,
    validationSchema: companyAdminEditProfileValidation,
    onSubmit,
  });

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    setValues,
  } = formik;
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      getUsersDetailsById(userId)
        .then((response) => {
          setValues(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [setValues, userId]);

  const handleBackArrow = () => {
    userTypeName === "Company User"
      ? navigate(ROUTE_PATHS.USER_PROFILE)
      : navigate(ROUTE_PATHS.PROFILE);
  };
  return (
    <>
      <div
        className={
          userTypeName === "Company Admin" ? container : superAdminRoot
        }
      >
        <Box
          className={
            userTypeName === "Company Admin" ? headerContainer : superAdminTitle
          }
        >
          <ArrowBackIcon
            className={
              userTypeName === "Company Admin"
                ? arrowBackIcon
                : adminArrowBackIcon
            }
            onClick={handleBackArrow}
          />
          <Typography variant="h6" className={header}>
            {"Edit User"}
          </Typography>
        </Box>
      </div>
      <div className={root}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={3} p={4}>
            <Grid item xs={6}>
              <CustomTextField
                label={formGroup.name.label}
                variant={formGroup.name.variant}
                name={formGroup.name.name}
                value={values.name}
                onChange={handleChange}
                error={touched.name && errors.name}
                onBlur={handleBlur}
                logInField
                style={{ width: "330px", marginBottom: 20 }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name={formGroup.emailId.name}
                label={formGroup.emailId.label}
                variant={formGroup.emailId.variant}
                type={formGroup.emailId.type}
                onChange={handleChange}
                value={values.emailId}
                error={touched.emailId && errors.emailId}
                onBlur={handleBlur}
                logInField
                style={{ width: "330px", marginBottom: 20 }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name={formGroup.mobileNo.name}
                label={formGroup.mobileNo.label}
                variant={formGroup.mobileNo.variant}
                type={formGroup.mobileNo.type}
                onChange={handleChange}
                value={values.mobileNo}
                error={touched.mobileNo && errors.mobileNo}
                onBlur={handleBlur}
                logInField
                style={{ width: "330px", marginBottom: 20 }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomImageUpload
                label={formGroup.profile.label}
                name={formGroup.profile.name}
                accept={formGroup.profile.accept}
                onChange={setFieldValue}
                value={values.profile}
                profileUrl={values?.profileUrl || ""}
                error={touched.profile && errors.profile}
              />
            </Grid>
          </Grid>
          <div className={btnContainer}>
            <ButtonField
              variant="contained"
              label="CANCEL"
              type="button"
              className={cancelButton}
              onClick={() => navigate(`${ROUTE_PATHS.PROFILE}`)}
            />

            <ButtonField
              variant="contained"
              label="Update"
              className={saveButton}
              type="submit"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditProfile;
