import * as React from "react";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export function CustomRadioButton(props) {
  const {
    CSRdetailsOptions,
    name,
    onChange,
    onBlur,
    error,
    value,
    label,
    style,
  } = props;

  return (
    <FormControl>
      <span style={{ marginLeft: "10px", color: "#5C6BC0" }}>{label}</span>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        value={value || ""}
        style={style}
        row
      >
        {CSRdetailsOptions.map((option, i) => (
          <FormControlLabel
            value={option.id || option}
            control={<Radio />}
            label={option.name || option}
            key={i}
            name={name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
