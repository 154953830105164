import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const affectedEmployeesOptions = ["Yes", "No"];

export const affectedEmployeesColumns = (setFieldValue, rate) => {
  return [
    {
      Header: " ",
      accessor: "values",
    },
    {
      Header: "Total no. of affected employees/ workers",
      columns: [
        {
          Header: (props) => (
            <span>
              FY
              <CustomTextField
                name="currentWorkers"
                type="number"
                headerField
                value={rate?.currentWorkers || ""}
                setFieldValue={setFieldValue}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
              />
              (Current Financial Year)
            </span>
          ),

          accessor: "currentAffectedEmployees",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: (props) => (
            <span>
              FY
              <CustomTextField
                name="previousWorkers"
                type="number"
                headerField
                value={rate?.previousWorkers || ""}
                setFieldValue={setFieldValue}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
              />
              (Previous Financial Year)
            </span>
          ),

          accessor: "previousAffectedEmployees",
          type: "number",
          Cell: EditableCell,
        },
      ],
    },
    {
      Header:
        "No. of employees/workers that are rehabilitated and placed in suitable employment or whose family members have been placed in suitable employment",
      columns: [
        {
          Header: (props) => (
            <span>
              FY
              <CustomTextField
                name="currentEmployment"
                type="number"
                headerField
                value={rate?.currentEmployment || ""}
                setFieldValue={setFieldValue}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
              />
              (Current Financial Year)
            </span>
          ),
          accessor: "currentSuitableEmployment",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: (props) => (
            <span>
              FY
              <CustomTextField
                name="previousEmployment"
                type="number"
                headerField
                value={rate?.previousEmployment || ""}
                setFieldValue={setFieldValue}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
              />
              (Previous Financial Year)
            </span>
          ),
          accessor: "previousSuitableEmployment",
          type: "number",
          Cell: EditableCell,
        },
      ],
    },
  ];
};

const columnName = [
  { id: 1, values: "Employees" },
  { id: 2, values: "Workers" },
];
export const affectedEmployeesCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  currentAffectedEmployees: "",
  previousAffectedEmployees: "",
  currentSuitableEmployment: "",
  previousSuitableEmployment: "",
}));

export const affectedEmployeesInitialvalues = {
  tableData: affectedEmployeesCellData,
  safety: "",
  currentWorkers: "",
  previousWorkers: "",
  currentEmployment: "",
  previousEmployment: "",
};

export const affectedEmployeesConstantData = [
  {
    id: 1,
    label:
      "4. Does the entity provide transition assistance programs to facilitate continued employability and the management of career endings resulting from retirement or termination of employment? (Yes/ No)",
    name: "safety",
  },
];
