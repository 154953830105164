export const briberyCorruptionContent = [
  {
    id: "1",
    title: "SECTION C: PRINCIPLE WISE PERFORMANCE DISCLOSURE",
    subTitle:
      "Number of Directors/KMPs/employees/workers against whom disciplinary action was taken by any law enforcement agency for the charges of bribery/ corruption",
    aboutTable:
      "4. Does the entity have an anti-corruption or anti-bribery policy? If yes, provide details in brief and if available, provide a web-link to the policy. ",
    subAboutTable:
      "5. Number of Directors/KMPs/employees/workers against whom disciplinary action was taken by any law enforcement agency for the charges of bribery/ corruption:",
  },
];
