export const apiRoutes = {
  LOG_IN_API: "userLogin",
  FORGET_PASSWORD: "forgotPassword  ",
  RESET_PASSWORD: "resetPassword",
  CHANGE_PASSWORD: "changePassword",

  //create user
  CREATE_USER: "createUser",
  UPDATE_USER: "updateUser",
  REJECT_USER: "rejectCompletionStatus",
  GET_USERS_DETAILS: "getAllUserDetailsWithCount",
  UPDATE_USER_STATUS: "updateUserStatus",
  GET_USERS_DETAILS_BY_ID: "getUserDetailsById",
  USER_ACCESS: "userAccess",
  USER_DOWNLOAD_DETAILS: "userDownloadDetails",

  //customers
  GET_ALL_CUSTOMERS_DETAILS: "getAllCustomerDetailsWithCount",
  GET_CUSTOMERS_DETAILS_BY_ID: "getCustomerDetailsById",
  UPDATE_CUSTOMERS_DETAILS: "updateCustomerDetails",
  CREATE_CUSTOMERS_DETAILS: "createCustomerDetails",
  CUSTOMER_DOWNLOAD_DETAILS: "customerDownload",

  //invoices
  GET_ALL_INVOICES_DETAILS: "getAllInvoiceDetailsWithCount",
  INVOICES_DOWNLOAD: "invoiceDownload",
  CREATE_INVOICE_DETAILS: "createInvoiceDetails",
  UPDATE_INVOICE_DETAILS: "updateInvoiceDetails",
  GET_INVOICE_DETAILS_BY_ID: "getInvoiceDetailsById",

  //seeds
  GET_PAYMENT_STATUS: "getPaymentStatus",
  GET_ALL_STATUS: "getStatus",
  GET_CUSTOMERS_ID: "getCustomers",

  //form

  GET_FORM_DATA_BY_USER: "getFormDataByCustomerId",

  //get profile pic

  GET_INVOICE_DOWNLOAD: "getInvoice",

  //get invoice setup

  GET_INVOICE_SETUP: "getInvoiceDetails",
  //dashboard
  GET_GRAPH_DETAILS: "getGraphDetails",
  GET_INVOICE_GRAPH_DETAILS: "getInvoiceGraphDetails",
  GET_USER_GRAPH_DETAILS: "getUserGraphDetails",

  //send remainder
  SEND_REMAINDER: "sendReminder",
};
