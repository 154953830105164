export const grievancesContent = {
  id: 1,
  title: "Leadership Indicators",
};

export const grievancesInitialvalues = {
  complaints: "",
  scope: "",
  premise: "",
};

export const grievancesConstants = [
  {
    id: 1,
    name: "complaints",
    label:
      "1. Details of a business process being modified / introduced as a result of addressing human rights grievances/complaints. ",
    field: "textField",
  },
  {
    id: 2,
    name: "scope",
    label:
      "2. Details of the scope and coverage of any Human rights due-diligence conducted.",
    field: "textField",
  },
  {
    id: 3,
    name: "premise",
    label:
      "3. Is the premise/office of the entity accessible to differently abled visitors, as per the requirements of the Rights of Persons with Disabilities Act, 2016?",
    field: "textField",
  },
];
