import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Typography } from "@mui/material";
import { CustomTextField } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  detailsOnAssessmentP5Columns,
  detailsOnAssessmentP5Initialvalues,
  detailsOnAssessmentP5ConstantData,
  detailsOnAssessmentP5Content,
  STATIC_TABLE,
  PRINCIPLE5_DETAILS_ON_ASSESSMENT,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
  contentStyle: {
    marginTop: "20px",
  },
}));

const DetailsOnAssessmentP5 = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(
    detailsOnAssessmentP5Initialvalues
  );
  const { list, contentStyle } = useStyles();

  //to get data from api
  const detailsOnAssessmentP5FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE5_DETAILS_ON_ASSESSMENT
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    detailsOnAssessmentP5FormConfig
      ? updateTableValues(
          PRINCIPLE5_DETAILS_ON_ASSESSMENT,
          { detailsOnAssessmentP5: `${jsonStr}` },
          detailsOnAssessmentP5FormConfig.id
        )
      : createTableValues(PRINCIPLE5_DETAILS_ON_ASSESSMENT, {
          detailsOnAssessmentP5: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (detailsOnAssessmentP5FormConfig) {
      setParentData(
        JSON.parse(detailsOnAssessmentP5FormConfig.data.detailsOnAssessmentP5)
      );
    }
  }, [detailsOnAssessmentP5FormConfig]);

  const formik = useFormik({
    initialValues: parentData || detailsOnAssessmentP5Initialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, handleBlur } = formik;

  const renderFormFields = () => {
    return (
      <>
        {detailsOnAssessmentP5ConstantData.map(({ id, label, name }) => (
          <div key={id} className={contentStyle}>
            <Typography variant="h6" className={list}>
              {label}
            </Typography>

            <CustomTextField
              name={name}
              onChange={handleChange}
              value={values[name]}
              onBlur={handleBlur}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <TableWrapper
      columns={detailsOnAssessmentP5Columns}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={STATIC_TABLE}
      modalContent={detailsOnAssessmentP5Content}
      renderFormFields={renderFormFields}
      renderFormFieldBottom={true}
      keyProps={"panel3"}
      chileKeyPros={"principle5"}
    />
  );
};

export default DetailsOnAssessmentP5;
