import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  monetaryColumns,
  monetaryInitialvalues,
  monetaryContent,
  monetaryConstantData,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE1_MONETARY,
} from "../../../../../constant/index";
import { TableWrapper, CustomTextarea } from "../../../../reusable/index";
import { Modal, Typography } from "@mui/material";
import { useFormik } from "formik";
import Cookies from "universal-cookie";
import Context from "../../../../../context/Context";

const Monetary = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(monetaryInitialvalues);

  const formik = useFormik({
    initialValues: parentData || monetaryInitialvalues,
    enableReinitialize: true,
  });

  const { values, handleChange, errors, touched, handleBlur } = formik;

  //to get data from api
  const monetaryFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE1_MONETARY
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE1_MONETARY,
        { monetary: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      monetaryFormConfig
        ? updateTableValues(
            PRINCIPLE1_MONETARY,
            { monetary: `${jsonStr}` },
            monetaryFormConfig.id
          )
        : createTableValues(PRINCIPLE1_MONETARY, {
            monetary: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (monetaryFormConfig && monetaryFormConfig.data.monetary) {
      setParentData(JSON.parse(monetaryFormConfig.data.monetary));
    }
  }, [monetaryFormConfig]);

  let forTextArea =
    monetaryFormConfig &&
    !monetaryFormConfig.data.monetary &&
    monetaryFormConfig.data.map(
      (data) => data.monetary && JSON.parse(data.monetary)
    );

  const renderFormFields = (tableData) => {
    return (
      <>
        {monetaryConstantData.map(({ id, rowId, label, name, accessor }) => {
          let filterData = tableData?.filter((item) => {
            return parseInt(item.id) === rowId;
          });
          return forTextArea
            ? filterData && (
                <div key={id} style={{ margin: 8 }}>
                  <Typography
                    variant="subtitle"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {label}
                    {forTextArea.map((data) => (
                      <div>
                        <CustomTextarea
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={data[name]}
                          error={touched[name] && errors[name]}
                          helperText={touched[name] && errors[name]}
                          variant="outlined"
                          logInField
                          style={{
                            width: "75%",
                            background: "white",
                            paddingBottom: "0px ",
                          }}
                        />
                      </div>
                    ))}
                  </Typography>
                </div>
              )
            : filterData && (
                <div key={id} style={{ margin: 8 }}>
                  <Typography
                    variant="subtitle"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {label}

                    <CustomTextarea
                      name={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[name]}
                      error={touched[name] && errors[name]}
                      helperText={touched[name] && errors[name]}
                      variant="outlined"
                      logInField
                      style={{
                        width: "75%",
                        background: "white",
                        paddingBottom: "0px ",
                      }}
                    />
                  </Typography>
                </div>
              );
        })}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={monetaryColumns}
        storeTableData={storeTableData}
        cellData={
          monetaryFormConfig && monetaryFormConfig.data.length > 1
            ? monetaryFormConfig.data
            : parentData.tableData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        modalContent={monetaryContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle1"}
        cloneActive={true}
        fileUplode={false}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={monetaryColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={monetaryContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default Monetary;
