export const initialStateReducer = {
  toastMessageState: {
    severity: "",
    message: "",
    messageStatus: false,
  },
  leadershipFlag: false,
};

export const toastMessageStateReducer = (
  state = initialStateReducer,
  action
) => {
  const { type, payload } = action;
  const copyState = JSON.parse(JSON.stringify(state));

  switch (type) {
    case "TOASTMESSAGEACTION": {
      return { ...copyState, toastMessageState: payload };
    }
    case "LEADERSHIP": {
      return { ...copyState, leadershipFlag: payload };
    }
    default:
      return state;
  }
};
