import React, { useState } from "react";
import { useTable, usePagination } from "react-table";
import { Box, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { staticTableStyles } from "../../../styles";

export function StaticTable(props) {
  const { columns, data, setData, cloneActive } = props;
  const { boxContainer, tableCellFocus, stickyHeader } = staticTableStyles();
  //const [noOfTImes, setNoOfTimes] = useState([])
  const updateData = (rowIndex, columnId, value) => {
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const headerCell = (headerGroup) => {
    return headerGroup.headers.map((column) =>
      column.hideHeader ? null : (
        <TableCell {...column.getHeaderProps()} rowSpan={column.rowspan || 1}>
          {column.render("Header")}
        </TableCell>
      )
    );
  };

  const bodyCell = (row) => {
    return row.cells.map((cell) => {
      return (
        <TableCell
          {...cell.getCellProps()}
          className={cell.column.type === "number" ? tableCellFocus : ""}
        >
          {cell.render("Cell")}
        </TableCell>
      );
    });
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        updateData,
      },
      usePagination
    );
    // const cloneHandle = () => {
    //   setNoOfTimes(preValue => [...preValue, {}])
    // }
  return (
    <>
      <Box className={boxContainer}>
        <Paper sx={{ width: "100%" }} elevation={8}>
          <Table {...getTableProps()} style={{ borderCollapse: "separate" }}>
            <TableHead className={stickyHeader}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerCell(headerGroup)}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>{bodyCell(row)}</TableRow>
                );
              })}
            </TableBody>
          </Table>
          {/* {
              noOfTImes.map((_, i) => {
                return (
                <>
                <Table {...getTableProps()} style={{ borderCollapse: "separate",marginTop: "30px" }}>
                  <TableHead className={stickyHeader}>
                    {headerGroups.map((headerGroup) => (
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerCell(headerGroup)}
                      </TableRow>
                    ))}
                  </TableHead>

                  <TableBody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <TableRow {...row.getRowProps()}>{bodyCell(row)}</TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                </>
                )
              })
            } */}
        </Paper>
      </Box>
      {/* {cloneActive ? <div className="cloneTbl"><span className="cloneIcon" onClick={cloneHandle}>+</span></div> : null} */}
    </>
  );
}
