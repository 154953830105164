import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../../Context";
import { CSRdetailsStyles } from "../../../../../styles";
import Cookies from "universal-cookie";
import {
  CustomizedAccordions,
  CustomizedSnackbars,
  CustomRadioButton,
  CustomTextField,
  TableNavbar,
} from "../../../../reusable";
import { PRINCIPLE2_PRODUCT_RECALMATIONBOX } from "../../../../../constant";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  productRecalmationConstants,
  productRecalmationInitialvalues,
  productRecalmationOptions,
} from "../../../../../constant/tableData/sectionC/principle2/productReclamation";
import CustomImageUpload from "../../../../reusable/CustomImageUpload";
function ProductRecalmation() {
  const {
    list,
    root,
    rootAlign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
  } = CSRdetailsStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const productRecalmationFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE2_PRODUCT_RECALMATIONBOX
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    productRecalmationFormConfig
      ? updateTableValues(
          PRINCIPLE2_PRODUCT_RECALMATIONBOX,
          { productRecalmationBox: `${jsonStr}` },
          productRecalmationFormConfig.id
        )
      : createTableValues(PRINCIPLE2_PRODUCT_RECALMATIONBOX, {
          productRecalmationBox: `${jsonStr}`,
        });
  };

  //  fetch db data
  useEffect(() => {
    if (productRecalmationFormConfig) {
      setParentData(
        JSON.parse(productRecalmationFormConfig.data.productRecalmationBox)
      );
    }
  }, [productRecalmationFormConfig]);

  //   snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // formik
  const onSubmit = (values) => {
    storeTableData(values);
    handleClickSnackBar();
  };

  const formik = useFormik({
    initialValues: parentData || productRecalmationInitialvalues,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;
  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions
            keyProps={"panel3"}
            chileKeyPros={"principle2"}
          />
        </div>
        <div className={rootAlign}>
          {/* <Typography variant="h6">{CSRdetailsContent.title}</Typography> */}

          {productRecalmationConstants.map(
            ({ id, name, label, type, field, upload }) => (
              <div key={id} style={{ margin: 10 }}>
                
                <Typography>
                  {label}
                  {field === "textField" && (
                    <CustomTextField
                      type={type}
                      name={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[name]}
                      error={touched[name] && errors[name]}
                      helperText={touched[name] && errors[name]}
                      variant="outlined"
                      logInField
                      style={{
                        width: "90%",
                        background: "white",
                        paddingBottom: "0px ",
                      }}
                    />
                  )}
                </Typography>
                {field === "radioButton" && (
                  <CustomRadioButton
                    name={name}
                    CSRdetailsOptions={productRecalmationOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                  />
                )}
                <div className="fileUplodeTab">
                  {upload === "uploadImage" && (
                    <CustomImageUpload />
                  )}
            </div>
              </div>
            )
          )}

          <Box className={btnContainer}>
            <Button variant="outlined" color="primary" onClick={resetForm}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>

          <CustomizedSnackbars open={open} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export default ProductRecalmation;
