import { EditableCell } from "../../../../components/utils/EditableCell";

export const pollutionColumns = [
  {
    Header: "S. No.",
    accessor: "sNo",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header:
      "Specify the law / regulation / guidelines which was not complied with",
    accessor: "regulation",
    Cell: EditableCell,
  },
  {
    Header: "Provide details of the noncompliance",
    accessor: "noncompliance",
    Cell: EditableCell,
  },
  {
    Header:
      "Any fines / penalties / action taken by regulatory agencies such as pollution control boards or by courts",
    accessor: "penalties",
    Cell: EditableCell,
  },
  {
    Header: "Corrective action taken, if any",
    accessor: "action",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const pollutionCellData = columnName.map((item) => ({
  id: item.id,
  sNo: "",
  regulation: "",
  noncompliance: "",
  penalties: "",
  action: "",
}));
