import { CustomTextField } from "../../../../components/reusable/index";
import { EditableCell } from "../../../../components/utils/EditableCell";

export const productsPackagingColumns = (setFieldValue, rate) => {
  return [
    {
      id: 1,
      Header: " ",
      // accessor: "values",
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "values",
        },
      ],
    },

    {
      id: 2,
      Header: () => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      columns: [
        {
          Header: "Re-Used",
          accessor: "currentReused",
          Cell: EditableCell,
        },
        {
          Header: "Recycled",
          accessor: "currentRecycled",
          Cell: EditableCell,
        },
        {
          Header: "Safely Disposed",
          accessor: "currentDisposed",
          Cell: EditableCell,
        },
      ],
    },
    {
      id: 3,
      Header: (props) => (
        <span>
          FY{" "}
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),

      columns: [
        {
          Header: "Re-Used",
          accessor: "previousReused",

          Cell: EditableCell,
        },
        {
          Header: "Recycled",
          accessor: "previousRecycled",
          Cell: EditableCell,
        },
        {
          Header: "Safely Disposed",
          accessor: "previousDisposed",
          Cell: EditableCell,
        },
      ],
    },
  ];
};

export const productsPackagingCellData = [
  {
    id: "1",
    values: "Plastics (including packaging)",
    currentReused: "",
    currentRecycled: "",
    currentDisposed: "",
    previousReused: "",
    previousRecycled: "",
    previousDisposed: "",
  },
  {
    id: "2",
    values: "E-waste",
    currentReused: "",
    currentRecycled: "",
    currentDisposed: "",
    previousReused: "",
    previousRecycled: "",
    previousDisposed: "",
  },
  {
    id: "3",
    values: "Hazardous waste",
    currentReused: "",
    currentRecycled: "",
    currentDisposed: "",
    previousReused: "",
    previousRecycled: "",
    previousDisposed: "",
  },
  {
    id: "4",
    values: "Other waste",
    currentReused: "",
    currentRecycled: "",
    currentDisposed: "",
    previousReused: "",
    previousRecycled: "",
    previousDisposed: "",
  },
];

export const productsPackagingInitialvalues = {
  tableData: productsPackagingCellData,
  current: "",
  previous: "",
};
