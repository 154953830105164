import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  conflictOfInterestColumns,
  conflictOfInterestInitialvalues,
  conflictOfInterestContent,
  conflictOfInterestConstantData,
  STATIC_TABLE,
  PRINCIPLE1_CONFLICT_OF_INTEREST,
} from "../../../../../constant/index";
import { TableWrapper, CustomTextarea } from "../../../../reusable/index";
import { useFormik } from "formik";
import { Typography } from "@mui/material";

const ConflictOfInterest = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(conflictOfInterestInitialvalues);

  const formik = useFormik({
    initialValues: parentData || conflictOfInterestInitialvalues,
    enableReinitialize: true,
  });

  const { values, handleChange, errors, touched, handleBlur, setFieldValue } =
    formik;

  //to get data from api
  const conflictOfInterestFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE1_CONFLICT_OF_INTEREST
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    conflictOfInterestFormConfig
      ? updateTableValues(
          PRINCIPLE1_CONFLICT_OF_INTEREST,
          { conflictOfInterest: `${jsonStr}` },
          conflictOfInterestFormConfig.id
        )
      : createTableValues(PRINCIPLE1_CONFLICT_OF_INTEREST, {
          conflictOfInterest: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (conflictOfInterestFormConfig) {
      setParentData(
        JSON.parse(conflictOfInterestFormConfig.data.conflictOfInterest)
      );
    }
  }, [conflictOfInterestFormConfig]);

  const renderFormFields = (tableData) => {
    return (
      <>
        {conflictOfInterestConstantData.map(
          ({ id, rowId, label, name, accessor, type }) => {
            let filterData = tableData?.filter((item) => {
              return parseInt(item.id) === rowId;
            });
            return type === "textField" ? (
              <div key={id} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle"
                  style={{ display: "flex", flexDirection: "column " }}
                >
                  {label}

                  <CustomTextarea
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                    variant="outlined"
                    style={{
                      width: "75%",
                      background: "white",
                      paddingBottom: "0px ",
                    }}
                  />
                </Typography>
              </div>
            ) : (
              filterData && filterData[0][accessor] === "SB" && (
                <div key={id} style={{ margin: 8 }}>
                  <Typography variant="subtitle" style={{ display: "flex" }}>
                    {label}

                    <CustomTextarea
                      name={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[name]}
                      error={touched[name] && errors[name]}
                      helperText={touched[name] && errors[name]}
                      variant="outlined"
                      style={{
                        width: "75%",
                        background: "white",
                        paddingBottom: "0px ",
                      }}
                    />
                  </Typography>
                </div>
              )
            );
          }
        )}
      </>
    );
  };

  return (
    <TableWrapper
      columns={conflictOfInterestColumns(setFieldValue, values)}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={STATIC_TABLE}
      modalContent={conflictOfInterestContent}
      renderFormFields={renderFormFields}
      renderFormFieldBottom={true}
      hideHelp={true}
      keyProps={"panel3"}
      chileKeyPros={"principle1"}
    />
  );
};

export default ConflictOfInterest;
