export const disclosureQuestionsContents = [
  {
    id: "1",
    title: " SECTION B: Disclosure Questions",
    subTitle: "NGRBC Principles and Core Elements",
    subAboutTable:
      "Response: Yes (Y), No (N), NA (Not applicable) and SB (See below) ",

    aboutTable:
      "This section is aimed at helping businesses demonstrate the structures, policies and processes put in place towards adopting the NGRBC Principles and Core Elements ",
  },
];
