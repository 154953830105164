import { EditableCell } from "../../../../components/utils/EditableCell";

export const correctiveP8Columns = [
  {
    Header: "Name of authority",
    accessor: "name",
    Cell: EditableCell,
  },
  {
    Header: "Brief of the Case ",
    accessor: "brief",
    Cell: EditableCell,
  },
  {
    Header: "Corrective action taken ",
    accessor: "action",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const correctiveP8CellData = columnName.map((item) => ({
  id: item.id,
  name: "",
  brief: "",
  action: "",
}));
