import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  impactAssessmentsColumns,
  impactAssessmentsInitialvalues,
  impactAssessmentsContent,
  impactAssessmentsConstantData,
  STATIC_TABLE,
  PRINCIPLE8_IMPACT_ASSESSMENTS,
} from "../../../../../constant/index";
import { TableWrapper, CustomTextarea } from "../../../../reusable/index";
import { Modal, Typography } from "@mui/material";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const ImpactAssessments = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(impactAssessmentsInitialvalues);

  const formik = useFormik({
    initialValues: parentData || impactAssessmentsInitialvalues,
    enableReinitialize: true,
  });

  const { values, handleChange, errors, touched, handleBlur } = formik;

  //to get data from api
  const impactAssessmentsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE8_IMPACT_ASSESSMENTS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE8_IMPACT_ASSESSMENTS,
        { impactAssessments: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      impactAssessmentsFormConfig
        ? updateTableValues(
            PRINCIPLE8_IMPACT_ASSESSMENTS,
            { impactAssessments: `${jsonStr}` },
            impactAssessmentsFormConfig.id
          )
        : createTableValues(PRINCIPLE8_IMPACT_ASSESSMENTS, {
            impactAssessments: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      impactAssessmentsFormConfig &&
      impactAssessmentsFormConfig.data.impactAssessments
    ) {
      setParentData(
        JSON.parse(impactAssessmentsFormConfig.data.impactAssessments)
      );
    }
  }, [impactAssessmentsFormConfig]);

  const renderFormFields = (tableData) => {
    return (
      <>
        {impactAssessmentsConstantData.map(
          ({ id, rowId, label, name, accessor, type }) => {
            let filterData = tableData?.filter((item) => {
              return parseInt(item.id) === rowId;
            });
            return (
              filterData &&
              filterData[0][accessor] === "SB" && (
                <div key={id} style={{ margin: 8 }}>
                  <Typography variant="subtitle" style={{ display: "flex" }}>
                    {label}

                    <CustomTextarea
                      name={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[name]}
                      error={touched[name] && errors[name]}
                      helperText={touched[name] && errors[name]}
                      variant="outlined"
                      style={{
                        width: "75%",
                        background: "white",
                        paddingBottom: "0px ",
                      }}
                    />
                  </Typography>
                </div>
              )
            );
          }
        )}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={impactAssessmentsColumns()}
        storeTableData={storeTableData}
        cellData={
          impactAssessmentsFormConfig &&
          !impactAssessmentsFormConfig.data.impactAssessments
            ? impactAssessmentsFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={impactAssessmentsContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        hideHelp={true}
        keyProps={"panel3"}
        chileKeyPros={"principle8"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={impactAssessmentsColumns()}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={impactAssessmentsContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default ImpactAssessments;
