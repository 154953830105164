import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Modal, Typography } from "@mui/material";
import { CustomTextarea } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  assessmentP3Columns,
  assessmentP3Initialvalues,
  assessmentP3ConstantData,
  assessmentP3Content,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE3_ASSESSMENT,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import CustomImageUpload from "../../../../reusable/CustomImageUpload";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
}));

const AssessmentP3 = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(assessmentP3Initialvalues);
  const { list } = useStyles();
  const [noOfTImes, setNoOfTimes] = useState([]);
  //to get data from api
  const assessmentP3FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_ASSESSMENT
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE3_ASSESSMENT,
        { assessmentP3: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      assessmentP3FormConfig
        ? updateTableValues(
            PRINCIPLE3_ASSESSMENT,
            { assessmentP3: `${jsonStr}` },
            assessmentP3FormConfig.id
          )
        : createTableValues(PRINCIPLE3_ASSESSMENT, {
            assessmentP3: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (assessmentP3FormConfig && assessmentP3FormConfig.data.assessmentP3) {
      setParentData(JSON.parse(assessmentP3FormConfig.data.assessmentP3));
    }
  }, [assessmentP3FormConfig]);

  const formik = useFormik({
    initialValues: parentData || assessmentP3Initialvalues,
    enableReinitialize: true,
  });
  const { values, handleChange, handleBlur } = formik;
  const cloneHandle = () => {
    setNoOfTimes((preValue) => [...preValue, {}]);
  };
  const renderFormFields = () => {
    return (
      <div className="" style={{ marginTop: "20px" }}>
        {assessmentP3ConstantData.map(
          ({ id, label, name }) => (
            console.log("map DAta : ", assessmentP3ConstantData),
            (
              <div key={id}>
                <Typography variant="subtitle" className={list}>
                  {label}
                </Typography>

                <CustomTextarea
                  name={name}
                  onChange={handleChange}
                  value={values[name]}
                  onBlur={handleBlur}
                  variant="outlined"
                />
              </div>
            )
          )
        )}

        {noOfTImes.map((_, i) => {
          return (
            <div className="" style={{ marginTop: "20px" }}>
              {assessmentP3ConstantData.map(({ id, label, name }) => (
                <div key={id}>
                  <Typography variant="subtitle" className={list}>
                    {label}
                  </Typography>

                  <CustomTextarea
                    name={name}
                    onChange={handleChange}
                    value={values[name]}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                </div>
              ))}
            </div>
          );
        })}
        <div className="cloneTbl">
          <span className="cloneIcon" onClick={cloneHandle}>
            +
          </span>
        </div>
        <div className="fileUplodeTab">
          <CustomImageUpload />
        </div>
      </div>
    );
  };

  return (
    <>
      <TableWrapper
        columns={assessmentP3Columns}
        storeTableData={storeTableData}
        cellData={
          assessmentP3FormConfig && !assessmentP3FormConfig.data.assessmentP3
            ? assessmentP3FormConfig.data
            : parentData.tableData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        modalContent={assessmentP3Content}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle3"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={assessmentP3Columns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={assessmentP3Content}
          />
        </div>
      </Modal>
    </>
  );
};

export default AssessmentP3;
