import { EditableCell } from "../../../../components/utils/EditableCell";

export const assessmentP3Columns = [
  {
    Header: " ",
    accessor: "values",
    Cell: EditableCell,
  },
  {
    Header:
      "% of your plants and offices that were assessed (by entity or statutory authorities or third parties)",
    accessor: "plants",
    maxLength: 3,
    type: "number",
    Cell: EditableCell,
  },
];

const columnName = [
  { id: 1, values: "Health and safety practices" },
  { id: 2, values: "Working Conditions" },
];
export const assessmentP3CellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  plants: "",
}));

export const assessmentP3Initialvalues = {
  tableData: assessmentP3CellData,
  safety: "",
};

export const assessmentP3ConstantData = [
  {
    id: 1,
    label:
      "15. Provide details of any corrective action taken or underway to address safety-related incidents (if any) and on significant risks / concerns arising from assessments of health & safety practices and working conditions.",
    name: "safety",
    upload: "uploadImage",
  },
];
