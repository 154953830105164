import { EditableCell } from "../../../../components/utils/EditableCell";

export const assessmentsColumns = [
  {
    Header: "NIC Code",
    accessor: "nicCode",
    Cell: EditableCell,
  },
  {
    Header: "Name of Product /Service",
    accessor: "productName",
    Cell: EditableCell,
  },
  {
    Header: "% of total Turnover contributed",
    accessor: "turnOver ",
    Cell: EditableCell,
    type: "number",
    maxLength: 3,
  },
  {
    Header:
      "Boundary for which the Life Cycle Perspective / Assessment was conducted ",
    accessor: "boundary",
    Cell: EditableCell,
  },
  {
    Header: "Whether conducted by independent external agency (Yes/No)",
    accessor: "externalAgency ",
    Cell: EditableCell,
  },
  {
    Header:
      "Results communicated in public domain (Yes/No) If yes, provide the web-link. ",
    accessor: "domain",
    Cell: EditableCell,
  },
];

export const assessmentsCellData = [
  {
    id: "1",
    nicCode: "",
    productName: "",
    turnOver: "",
    boundary: "",
    externalAgency: "",
    domain: "",
  },
];
