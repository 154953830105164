import { EditableCell } from "../../../components/utils/EditableCell";

export const marketsServedEntityColumns = [
  {
    Header: "Locations",
    accessor: "locations",
  },
  {
    Header: "Number ",
    accessor: "number",
    type: "number",
    Cell: EditableCell,
  },
];

export const marketsServedEntityCellData = [
  {
    id: "1",
    locations: "National (No. of States)",
    number: "",
  },
  {
    id: "2",
    locations: "International (No. of Countries)",
    number: "",
  },
];

export const marketsInitialvalues = {
  exports: "",
  customers: "",
  tableData: marketsServedEntityCellData,
};

export const marketsServedConstantDatas = [
  {
    id: 1,
    label:
      "b. What is the contribution of exports as a percentage of the total turnover of the entity?",
    name: "exports",
  },
  {
    id: 2,
    label: "c. A brief on types of customers",
    name: "customers",
  },
];
