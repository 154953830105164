import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const disclosuresWaterOptions = ["Yes", "No"];

export const disclosuresWaterColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Parameter",
      accessor: "values",
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
  ];
};

const columnName = [
  { id: 1, values: "Water withdrawal by source (in kilolitres)" },
  { id: 2, values: "(i) Surface water" },
  { id: 3, values: "(ii) Groundwater" },
  { id: 4, values: "(iii) Third party water" },
  { id: 5, values: "Seawater / desalinated water" },
  { id: 6, values: "(v) Others " },
  {
    id: 7,
    values:
      "Total volume of water withdrawal (in kilolitres) (i + ii + iii + iv + v)",
  },
  { id: 8, values: "Total volume of water consumption (in kilolitres)" },
  {
    id: 9,
    values: "Water intensity per rupee of turnover (Water consumed / turnover)",
  },
  {
    id: 10,
    values:
      "Water intensity (optional) – the relevant metric may be selected by the entity",
  },
];
export const disclosuresWaterCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  currentYear: "",
  previousYear: "",
}));

export const disclosuresWaterInitialvalues = {
  tableData: disclosuresWaterCellData,
  independent: "",
  agency: "",
  zeroLiquid: "",
  previous: "",
  current: "",
};

export const disclosuresWaterConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N)",
    field: "radioButton",
  },
  {
    id: 2,
    name: "agency",
    label: "If yes, name of the external agency.",
    field: "textField",
  },
  {
    id: 3,
    name: "zeroLiquid",
    label:
      "4. Has the entity implemented a mechanism for Zero Liquid Discharge? If yes, provide details of its coverage and implementation.",
    field: "textField",
  },
];
