export const impactAssessmentsContent = [
  {
    id: "1",
    title:
      "PRINCIPLE 8 Businesses should promote inclusive growth and equitable development",
    subAboutTable: "Response: (SB - See Below), NA(Not Applicable)",
    aboutTable:
      "1. Details of Social Impact Assessments (SIA) of projects undertaken by the entity based on applicable laws, in the current financial year.",
  },
];
