import * as Tables from "../components/pages/tables/sectionB/Index";

export const sectionB = [
  {
    path: "/sectionB/disclosureQuestions",
    component: <Tables.DisclosureQuestions />,
  },
  {
    path: "/sectionB/governanceLeadership",
    component: <Tables.GovernanceLeadership />,
  },
  {
    path: "/sectionB/reviewOfNGRBCS",
    component: <Tables.ReviewOfNGRBCS />,
  },
  {
    path: "/sectionB/assessmentEvaluation",
    component: <Tables.AssessmentEvaluation />,
  },
  {
    path: "/sectionB/questions",
    component: <Tables.Questions />,
  },
];
