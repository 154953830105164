import React from "react";
import { CustomizedAccordions } from "../../reusable/Accordion";

const DefaultPage = () => {
  return (
    <div
      style={{
        width: "350px",
        height: "calc(100vh - 58px)",
        zIndex: " 100",
        position: "fixed",
        overflowY: "scroll",
        borderRight: "2px solid #021256",
        backgroundColor: "white",
      }}
    >
      <CustomizedAccordions />
    </div>
  );
};

export default DefaultPage;
