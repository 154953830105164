import { EditableCell } from "../../../../components/utils/EditableCell";

export const essentialIndicatorsP4Columns = [
  {
    Header: "Stakeholder Group",
    accessor: "stakeholder",
    Cell: EditableCell,
  },
  {
    Header: "Whether identified as Vulnerable & Marginalized Group (Yes/No)",
    accessor: "vulnerable",
    Cell: EditableCell,
  },
  {
    Header:
      "Channels of communication (Email, SMS, Newspaper, Pamphlets, Advertisement, Community Meetings, Notice Board, Website), Other",
    accessor: "channels",
    Cell: EditableCell,
  },
  {
    Header:
      "Frequency of engagement (Annually/ Half yearly/ Quarterly / others – please specify) ",
    accessor: "frequency",
    Cell: EditableCell,
  },
  {
    Header:
      "Purpose and scope of engagement including key topics and concerns raised during such engagement",
    accessor: "scope",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }, { id: 2 }];
export const essentialIndicatorsP4CellData = columnName.map((item) => ({
  id: item.id,
  stakeholder: "",
  vulnerable: "",
  channels: "",
  frequency: "",
  scope: "",
}));

export const essentialIndicatorsP4Initialvalues = {
  stakeholder: "",
  tableData: essentialIndicatorsP4CellData,
};

export const essentialIndicatorsP4ConstantData = [
  {
    id: 1,
    label:
      "1. Describe the processes for identifying key stakeholder groups of the entity.",
    name: "stakeholder",
  },
];
