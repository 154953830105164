import React from "react";
import { useTable, usePagination } from "react-table";
import { Box, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { rowFullwidthStyles } from "../../../styles";
import DropdownField from "../DropDownField";

export function RowDropdownTable(props) {
  const { columns, data, setData, dropdownValues } = props;
  const { boxContainer, cellFullwidth, tableCellFocus, stickyHeader } =
    rowFullwidthStyles();

  const updateData = (rowIndex, columnId, value) => {
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const headerCell = (headerGroup) => {
    return headerGroup.headers.map((column) =>
      column.hideHeader ? null : (
        <TableCell {...column.getHeaderProps()} rowSpan={column.rowspan || 1}>
          {column.render("Header")}
        </TableCell>
      )
    );
  };

  const bodyCell = (row, index) => {
    return row.cells.map((cell, i) => {
      let findData = data?.filter((item) => {
        return cell.row.original.id === item.id;
      });
      return cell.row.original?.dropdownRow ? (
        <TableCell
          {...cell.getCellProps()}
          className={cell.column.type === "number" ? tableCellFocus : ""}
        >
          {i === 0 ? (
            cell.render("Cell")
          ) : (
            <DropdownField
              inputValues={dropdownValues || []}
              name={cell.column.id}
              fieldStyle={{ margin: 0, padding: 4 }}
              onChange={(e) =>
                updateData(row.index, cell.column.id, e.target.value)
              }
              value={findData ? findData[0][cell.column.id] : ""}
            />
          )}
        </TableCell>
      ) : (
        <TableCell
          {...cell.getCellProps()}
          className={cell.column.type === "number" ? tableCellFocus : ""}
        >
          {cell.render("Cell")}
        </TableCell>
      );
    });
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        updateData,
      },
      usePagination
    );
  return (
    <>
      <Box className={boxContainer}>
        <Paper sx={{ width: "100%", overflowY: "scroll" }} elevation={8}>
          <Table {...getTableProps()} style={{ borderCollapse: "separate" }}>
            <TableHead className={stickyHeader}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerCell(headerGroup)}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);

                return row.original.fullwidth ? (
                  <TableRow key={i}>
                    <TableCell colSpan={12} className={cellFullwidth}>
                      {row.original.values}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow {...row.getRowProps()} key={i}>
                    {bodyCell(row)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </>
  );
}
