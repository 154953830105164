export const waterWithdrawalContent = [
  {
    id: "1",
    title:
      "3. Water withdrawal, consumption and discharge in areas of water stress (in kilolitres):",
    aboutTable: "2. Provide the following details related to water discharged:",
    subAboutTable:
      "For each facility / plant located in areas of water stress, provide the following information:  (i) Name of the area (ii) Nature of operations (iii) Water withdrawal, consumption and discharge in the following format:",
  },
];
