import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  mechanismColumns,
  mechanismCellData,
  mechanismContent,
  STATIC_TABLE,
  PRINCIPLE3_MECHANISM,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import Cookies from "universal-cookie";
import Context from "../../../../../context/Context";
import { Modal } from "@mui/material";

const Mechanism = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(mechanismCellData);

  //to get data from api
  const mechanismFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_MECHANISM
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE3_MECHANISM,
        { mechanism: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      mechanismFormConfig
        ? updateTableValues(
            PRINCIPLE3_MECHANISM,
            { mechanism: `${jsonStr}` },
            mechanismFormConfig.id
          )
        : createTableValues(PRINCIPLE3_MECHANISM, {
            mechanism: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (mechanismFormConfig && mechanismFormConfig.data.mechanism) {
      setParentData(JSON.parse(mechanismFormConfig.data.mechanism));
    }
  }, [mechanismFormConfig]);

  return (
    <>
      <TableWrapper
        columns={mechanismColumns}
        storeTableData={storeTableData}
        cellData={
          mechanismFormConfig && !mechanismFormConfig.data.mechanism
            ? mechanismFormConfig.data
            : parentData
        }
        tableType={STATIC_TABLE}
        modalContent={mechanismContent}
        keyProps={"panel3"}
        chileKeyPros={"principle3"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={mechanismColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={mechanismContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default Mechanism;
