import { makeStyles } from "@mui/styles";

export const rowAddingStyles = makeStyles((theme) => ({
  addBtnContainer: {
    display: "flex ",
    justifyContent: "flex-end ",
    padding: "20px",
  },
  addBtn: {
    padding: 6,
    backgroundColor: "#1976d2 ",
    color: "white",
    borderRadius: "50%",
  },
  tableCellFocus: {
    "&:focus-within": {
      border: " 1.1px solid rgb(114 113 113) ",
    },
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
  },
}));
