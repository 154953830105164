const labels = {
  oldPassword: "Old Password*",
  newPassword: "New Password *",
  confirmNewPassword: "Confirm New Password *",
};

export const formGroup = {
  oldPassword: {
    label: labels.oldPassword,
    variant: "outlined",
    type: "password",
    name: "oldPassword",
  },

  newPassword: {
    name: "newPassword",
    label: labels.newPassword,
    type: "password",
    variant: "outlined",
  },
  confirmNewPassword: {
    label: labels.confirmNewPassword,
    name: "confirmNewPassword",
    type: "password",
    variant: "outlined",
  },
};

export const changePasswordInitialState = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};
