import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  recycledReusedColumns,
  recycledReusedInitialvalues,
  recycledReusedContent,
  ROW_ADDINGTABLE,
  PRINCIPLE2_RECYCLED_REUSED,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";

const RecycledReused = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(recycledReusedInitialvalues);
  const formik = useFormik({
    initialValues: parentData || recycledReusedInitialvalues,
    enableReinitialize: true,
  });

  const { values, setFieldValue } = formik;
  //to get data from api
  const recycledReusedFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE2_RECYCLED_REUSED
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    recycledReusedFormConfig
      ? updateTableValues(
          PRINCIPLE2_RECYCLED_REUSED,
          { recycledReused: `${jsonStr}` },
          recycledReusedFormConfig.id
        )
      : createTableValues(PRINCIPLE2_RECYCLED_REUSED, {
          recycledReused: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (recycledReusedFormConfig) {
      setParentData(JSON.parse(recycledReusedFormConfig.data.recycledReused));
    }
  }, [recycledReusedFormConfig]);

  return (
    <TableWrapper
      columns={recycledReusedColumns(values, setFieldValue)}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={ROW_ADDINGTABLE}
      modalContent={recycledReusedContent}
      keyProps={"panel3"}
      chileKeyPros={"principle2"}
    />
  );
};

export default RecycledReused;
