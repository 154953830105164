import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  turnoverColumns,
  turnoverConstantData,
  turnoverContent,
  turnoverInitialvalues,
  STATIC_TABLE,
  PRINCIPLE9_TURNOVER,
} from "../../../../../constant/index";
import { TableWrapper, CustomTextarea } from "../../../../reusable/index";
import { useFormik } from "formik";
import { Modal, Typography } from "@mui/material";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const Turnover = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(turnoverInitialvalues);

  // formik
  const formik = useFormik({
    initialValues: parentData || turnoverInitialvalues,
    enableReinitialize: true,
  });
  const { values, handleChange, errors, touched, handleBlur, resetForm } =
    formik;

  //to get data from api
  const turnoverFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE9_TURNOVER
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE9_TURNOVER,
        { turnover: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      turnoverFormConfig
        ? updateTableValues(
            PRINCIPLE9_TURNOVER,
            { turnover: `${jsonStr}` },
            turnoverFormConfig.id
          )
        : createTableValues(PRINCIPLE9_TURNOVER, {
            turnover: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (turnoverFormConfig && turnoverFormConfig.data.turnover) {
      setParentData(JSON.parse(turnoverFormConfig.data.turnover));
    }
  }, [turnoverFormConfig]);

  const renderFormFields = () => {
    return (
      <>
        {turnoverConstantData.map(({ id, label, name }) => (
          <div key={id}>
            <Typography variant="subtitle">
              {label}

              <CustomTextarea
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name]}
                error={touched[name] && errors[name]}
                helperText={touched[name] && errors[name]}
                variant="outlined"
                logInField
                style={{
                  background: "white",
                  paddingBottom: "0px ",
                }}
              />
            </Typography>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={turnoverColumns}
        storeTableData={storeTableData}
        cellData={
          turnoverFormConfig && !turnoverFormConfig.data.turnover
            ? turnoverFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={turnoverContent}
        renderFormFields={renderFormFields}
        resetForm={resetForm}
        renderFormFieldTop={true}
        hideHelp={true}
        hideAbout={true}
        keyProps={"panel3"}
        chileKeyPros={"principle9"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={turnoverColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={turnoverContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default Turnover;
