import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  briberyCorruptionColumns,
  briberyCorruptionInitialvalues,
  briberyCorruptionContent,
  briberyCorruptionConstantDatas,
  STATIC_TABLE,
  PRINCIPLE1_BRIBERY_CORRECTION,
} from "../../../../../constant/index";
import { TableWrapper, CustomTextarea } from "../../../../reusable/index";
import { useFormik } from "formik";
import { Typography } from "@mui/material";

const BriberyCorruption = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(briberyCorruptionInitialvalues);

  const formik = useFormik({
    initialValues: parentData || briberyCorruptionInitialvalues,
    enableReinitialize: true,
  });

  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    resetForm,
    setFieldValue,
  } = formik;
  //to get data from api
  const briberyCorruptionFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE1_BRIBERY_CORRECTION
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });
    console.log(jsonStr);

    briberyCorruptionFormConfig
      ? updateTableValues(
          PRINCIPLE1_BRIBERY_CORRECTION,
          { briberyCorruption: `${jsonStr}` },
          briberyCorruptionFormConfig.id
        )
      : createTableValues(PRINCIPLE1_BRIBERY_CORRECTION, {
          briberyCorruption: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (briberyCorruptionFormConfig) {
      setParentData(
        JSON.parse(briberyCorruptionFormConfig.data.briberyCorruption)
      );
    }
  }, [briberyCorruptionFormConfig]);

  const renderFormFields = () => {
    return (
      <>
        {briberyCorruptionConstantDatas.map(({ id, label, name }) => (
          <div key={id}>
            <Typography
              variant="subtitle"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {label}

              <CustomTextarea
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name]}
                error={touched[name] && errors[name]}
                helperText={touched[name] && errors[name]}
                variant="outlined"
                logInField
                style={{
                  background: "white",
                  paddingBottom: "0px ",
                }}
              />
            </Typography>
          </div>
        ))}
      </>
    );
  };

  return (
    <TableWrapper
      columns={briberyCorruptionColumns(setFieldValue, values)}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={STATIC_TABLE}
      modalContent={briberyCorruptionContent}
      renderFormFields={renderFormFields}
      renderFormFieldTop={true}
      hideHelp={true}
      hideAbout={true}
      keyProps={"panel3"}
      chileKeyPros={"principle1"}
      cloneActive={false}
      fileUplode={true}
    />
  );
};

export default BriberyCorruption;
