export const lifeInsuranceOptions = ["Yes", "No"];

export const lifeInsuranceContent = {
  id: 1,
  title: " Leadership Indicators",
};

export const lifeInsuranceInitialvalues = {
  insurance: "",
  statutory: "",
};

export const lifeInsuranceConstants = [
  {
    id: 1,
    name: "insurance",
    label:
      "1. Does the entity extend any life insurance or any compensatory package in the event of death of (A) Employees (Y/N) (B) Workers (Y/N).",
    field: "radioButton",
  },
  {
    id: 2,
    name: "statutory",
    label:
      "2. Provide the measures undertaken by the entity to ensure that statutory dues have been deducted and deposited by the value chain partners",
    field: "textField",
  },
];
