import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Modal, Typography } from "@mui/material";
import { CustomTextarea } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  rehabilitationColumns,
  rehabilitationInitialvalues,
  rehabilitationConstantData,
  rehabilitationContent,
  ROW_ADDINGTABLE,
  PRINCIPLE8_REHABILITATION,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
  contentStyle: {
    marginTop: "20px",
  },
}));

const Rehabilitation = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(rehabilitationInitialvalues);
  const { list } = useStyles();

  //to get data from api
  const rehabilitationFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE8_REHABILITATION
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE8_REHABILITATION,
        { rehabilitation: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      rehabilitationFormConfig
        ? updateTableValues(
            PRINCIPLE8_REHABILITATION,
            { rehabilitation: `${jsonStr}` },
            rehabilitationFormConfig.id
          )
        : createTableValues(PRINCIPLE8_REHABILITATION, {
            rehabilitation: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      rehabilitationFormConfig &&
      rehabilitationFormConfig.data.rehabilitation
    ) {
      setParentData(JSON.parse(rehabilitationFormConfig.data.rehabilitation));
    }
  }, [rehabilitationFormConfig]);

  const formik = useFormik({
    initialValues: parentData || rehabilitationInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, handleBlur } = formik;

  const renderFormFields = () => {
    return (
      <>
        {rehabilitationConstantData.map(({ id, label, name, field, type }) => (
          <div key={id}>
            <Typography variant="subtitle" className={list}>
              {label}
            </Typography>
            {field === "textField" && (
              <CustomTextarea
                name={name}
                onChange={handleChange}
                value={values[name]}
                type={type}
                onBlur={handleBlur}
                variant="outlined"
              />
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={rehabilitationColumns}
        storeTableData={storeTableData}
        cellData={
          rehabilitationFormConfig &&
          !rehabilitationFormConfig.data.rehabilitation
            ? rehabilitationFormConfig.data
            : parentData.tableData
        }
        tableType={ROW_ADDINGTABLE}
        modalContent={rehabilitationContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        hideHelp={true}
        keyProps={"panel3"}
        chileKeyPros={"principle8"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={rehabilitationColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_ADDINGTABLE}
            modalContent={rehabilitationContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default Rehabilitation;
