import { makeStyles } from "@mui/styles";
export const navBarStyle = makeStyles({
  root: {
    height: "64px",
    background: "#EEEEEE 0% 0% no-repeat padding-box !important",
  },
  rootAlign: {
    height: "64px",
    // background: "#021256 0% 0% no-repeat padding-box !important",
  },
  container: {
    display: "flex ",
    // height: 64,
    // background: "#E46D35 0% 0% no-repeat padding-box !important",
    boxShadow: "none !important",
  },
  logoImg: {
    height: 64,
  },
  profileContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    width: "calc(100% - 200px)",
    font: "normal normal 600 19px/19px Rubik !important",
    color: "#FFFFFF ",
    height: 66,
  },
  superAdminProfileContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    width: "calc(100% - 200px)",
    font: "normal normal 600 16px/16px Rubik !important",
    color: "#FFFFFF ",
    height: 66,
  },
  navBarContentStyle: {
    font: "normal normal 600 16px/19px Montserrat !important",
    height: "61px !important",
    color: "#021256 !important",
  },
  navBarContentAdminStyle: {
    font: "normal normal 600 16px/19px Montserrat !important",
    height: "61px !important",
    color: "#FFFFFF !important",
  },
  userProfile: {
    display: "flex",
    flexDirection: "column",
  },
  profile: {
    display: "flex",
    alignItems: "center",
  },
  adminName: {
    font: "normal normal 16px/16px Rubik",
    letterSpacing: "0px",
    color: "#021256",
    opacity: 1,
    textTransform: "capitalize",
  },
  adminRole: {
    font: "normal normal 12px/16px Rubik",
    letterSpacing: "0px",
    color: "#021256",
    marginTop: 3,
    opacity: 1,
    textTransform: "capitalize",
  },
  menuAppBar: {
    position: "absolute !important",
    top: "48px !important",
    right: "20px",
  },
  superAdminRoot: {
    background: "#2A5BA8 0% 0% no-repeat padding-box !important",
    opacity: 1,
  },
  adminContainer: {
    display: "flex ",
    height: 64,
    background: "#2A5BA8 0% 0% no-repeat padding-box !important",
    boxShadow: "none !important",
  },
});

export const loginPageStyle = makeStyles((theme) => ({
  container: {
    height: 64,
    background: "#021256 0% 0% no-repeat padding-box",
    // borderRadius: "0px 40px 0px 0px",
  },
  root: {
    height: 85,
    background: "#E46D35 0% 0% no-repeat padding-box !important",
  },
  rootAlign: {
    height: 64,
    background: "#021256 0% 0% no-repeat padding-box !important",
  },
  maskGroup2Image: {
    right: 0,
    bottom: 0,
  },
  hidePassword: {
    "& div > input": {
      "-webkit-text-security": "disc",
    },
  },
  loginContainerAlign: {
    width: "86%",
    margin: "auto",
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  loginContainerImg: {
    width: "542px",
    height: "355px",
    boxShadow: "18px 18px #E46D35",
  },
  formContainer: {
    // position: "absolute",
    // top: "31%",
    // left: "35%",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 0 0 0 !important",
    backgroundColor: "#eeeeee !important",
    minWidth: "450px !important",
    padding: "20px !important",
    [theme.breakpoints.down("lg")]: {
      minWidth: "0px !important",
      height: "320px",
      marginLeft: "50px !important",
    },
  },
  heading: {
    color: "#2C3E50",
    font: "normal normal bold 20px/24px Montserrat",
    margin: "15px 15px 25px 15px",
  },
  userName: {
    width: "390px !important",
    border: "1px solid #0000008A",
    borderRadius: "4px",
  },
  forgetPassword: {
    textTransform: "capitalize !important",
    font: "normal normal 600 16px/24px Montserrat !important",
    color: "#021256 !important",
  },
  password: {
    display: "flex !important",
    justifyContent: "center !important",
    width: "100% !important",
    marginBottom: "16px",
    textDecoration: "underline",
  },
  loginButton: {
    width: "200px",
    font: "normal normal bold 17px/27px Nunito Sans !important",
    background: "#E46D35 0% 0% no-repeat padding-box !important",
    borderRadius: "40px !important",
    [theme.breakpoints.down("lg")]: {
      font: "bold 16px / 22px Montserrat !important",
      width: "180px ",
    },
  },
  subHeading: {
    color: "#2C3E50 ",
    font: "normal normal normal 18px/22px Montserrat",
    marginLeft: 15,
    marginBottom: 30,
  },
  backToLogin: {
    textTransform: "capitalize !important",
    font: "normal normal 600 16px/24px Montserrat !important",
    color: "#021256 !important",
    margin: "20px !important",
    textDecoration: "underline",
  },
  resetPassword: {
    display: "flex",
    position: "absolute",
    top: "33%",
    left: "28%",
    boxShadow: "0 0 0 0 !important",
    backgroundColor: "#eeeeee !important",
  },
  backToPage: {
    textTransform: "capitalize !important",
    font: "normal normal 600 16px/24px Montserrat !important",
    color: "#021256 !important",
    textDecoration: "underline !important",
  },
  formTitle: {
    [theme.breakpoints.down("lg")]: {
      font: "bold 22px / 39px Montserrat !important",
    },
  },
}));

export const tableStyle = makeStyles({
  container: {
    width: "90% !important",
    margin: "auto",
    marginTop: 50,
  },
});

export const filterStyle = makeStyles({
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: " 20px",
    background: "#021256 0% 0% no-repeat padding-box",
    color: "white",
  },
  invoiceTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
  },
  contentStyle: {
    width: "450px",
    padding: "10px !important",
  },
  statusStyle: {
    width: "250px !important",
  },
  paymentStatus: {
    width: "340px !important",
  },
  topbarIcons: {
    color: "white",
    backgroundColor: "#E46D35",
    padding: 6,
    marginTop: 8,
    borderRadius: 4,
  },
  superAdminTopbarIcons: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    padding: "8px 16px",
    fontSize: 14,
    // marginTop: 8,
    borderRadius: 4,
    color: "black",
  },
  newButton: {
    color: "white",
    background: "#59B961 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 4px #00000033",
    borderRadius: "4px",
  },

  cancelButton: {
    cursor: "pointer",
    background: "#C0C1CE 0% 0% no-repeat padding-box",
    border: "1px solid #A1ABA4",
    borderRadius: 4,
    padding: "5px !important",
    marginLeft: "15px !important",
    color: "white !important",
    font: "normal normal bold 16px/19px Lato !important",
  },
  saveButton: {
    background: "#3AD865 0% 0% no-repeat padding-box !important",
    border: "1px solid #3AD865",
    borderRadius: 4,
    marginLeft: "15px !important",
    font: "normal normal bold 16px/19px Lato !important",
  },
  titleContentStyle: {
    width: "200px !important",
    margin: "10px !important",
  },
  content: {
    width: "240px !important",
    margin: "10px !important",
  },
  statusActiveStyle: {
    width: "250px !important",
  },
});

export const myUserStyle = makeStyles((theme) => ({
  container: {
    height: 85,
    background: "#E46D35 0% 0% no-repeat padding-box !important",
  },
  root: {
    position: "absolute !important",
    top: "180px",
    left: "400px",
    zIndex: 10,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #7391B5",
    borderRadius: "8px",
    opacity: 1,
    width: "750px",
    padding: "30px 20px",
  },
  formConatainer: {
    position: "absolute !important",
    top: "425px",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    zIndex: 10,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #7391B5",
    borderRadius: "8px",
    opacity: 1,
    width: "750px",
    padding: "30px 20px",
    height: "14vw",
  },
  header: {
    fontWeight: "900 !important",
    marginLeft: "5px !important",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "20px !important",
    height: 64,
    background: "#021256 0% 0% no-repeat padding-box",
    // borderRadius: "0px 40px 0px 0px",
    color: "white",
  },
  uploadImg: {
    border: "1px dashed #8284ab  !important",
    width: "200px !important",
    height: "100% !important",
    padding: "14px !important",
    color: "#8284ab !important",
  },
  superAdminRoot: {
    backgroundColor: "#F0EDED!important",
  },
  userAdminTitle: {
    display: "flex",
    alignItems: "center",
  },

  superAdminTitle: {
    display: "flex",
    alignItems: "center",
    margin: "20px 30px !important",
    background: "#F0EDED!important",
  },
  adminArrowBackIcon: {
    margin: 20,
    cursor: "pointer",
  },
  cancelBtn: {
    backgroundColor: "#c0c1ce !important",
    marginRight: "20px !important",
    color: "white !important",
  },
  arrowBackIcon: {
    color: "white",
    margin: 20,
    cursor: "pointer",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
  },
  cancelButton: {
    background: "#C0C1CE 0% 0% no-repeat padding-box !important",
    color: "white",
  },
  saveButton: {
    background: "#3AD865 0% 0% no-repeat padding-box !important",
    color: "white",
    marginRight: "30px !important",
  },
  auditLog: {
    marginTop: 40,
    fontWeight: "500",
  },
  userDetails: {
    fontWeight: "normal",
  },
  auditPara: {
    margin: "10px 0",
  },
  userProfileContainer: {
    width: "calc(100vw - 370px)",
    position: "absolute",
    left: "360px",
    padding: 0,
    boxSizing: "border-box",
  },
  userRoot: {
    display: "flex",
  },
  accordionContainer: {
    width: "350px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #021256",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
}));

export const profileDetailSytle = {
  listContainer: {
    position: "absolute !important",
    top: "63px",
    width: "100%",
  },
  superAdminRoot: {
    backgroundColor: "#F0EDED!important",
  },
  superAdminTitle: {
    fontSize: "18px",
    textTransform: "uppercase",
    background: "#F0EDED",
    opacity: 1,
    padding: "20px 50px",
  },
  root: {
    height: 85,
    background: "#E46D35 !important",
  },
  title: {
    font: "normal normal medium 24px/16px Rubik !important",
    color: "white",
    textTransform: "uppercase",
    background: "#021256 0% 0% no-repeat padding-box",
    // borderRadius: "0px 40px 0px 0px",
    opacity: 1,
    padding: "0px 50px",
    height: 64,
    display: "flex",
    alignItems: "center",
  },
  emailTitle: {
    fontSize: "14px !important",
    letterSpacing: 0,
    color: "#0000008A !important",
    opacity: 1,
  },
  profileContainer: {
    display: "flex !important",
    justifyContent: "space-between !important",
    width: "712px",
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    boxShadow: "0px 1px 3px #00000029 !important",
    border: "1px solid #707070 !important",
    borderRadius: "4px !important",
    opacity: 1,
    margin: "30px 50px ",
    padding: "20px",
  },
  profile: {
    display: "flex",
    alignItems: "center ",
  },
  editProfile: {
    marginTop: "10px",
  },
  accountCircleIcon: {
    width: "150px !important",
    height: "150px !important",
    color: "#bdbdbd",
    borderRadius: "50%",
    marginRight: "20px ",
  },
  lockIcon: {
    width: "22px !important",
    height: "22px !important",
    background: "#F0EDED 0% 0% no-repeat padding-box",
    borderRadius: "2px",
    opacity: 1,
    color: "#0000008A !important",
    marginRight: "15px !important",
    padding: "15px !important",
    cursor: "pointer",
  },
  type: {
    background: "#F0EDED 0% 0% no-repeat padding-box",
    textAlign: "center",
    fontSize: 14,
    padding: 10,
    marginTop: 20,
  },
  companyProfile: {
    display: "flex !important",
    justifyContent: "space-between !important",
    width: "500px",
    alignItems: "center",
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    boxShadow: "0px 1px 3px #00000029 !important",
    border: "1px solid #707070 !important",
    borderRadius: "4px !important",
    opacity: 1,
    margin: "60px",
    padding: "30px 40px",
  },
  userProfileContainer: {
    width: "calc(100vw - 370px)",
    position: "absolute",
    left: "360px",
    padding: 0,
    boxSizing: "border-box",
  },
  userRoot: {
    display: "flex",
  },
  accordionContainer: {
    width: "350px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #021256",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
};

export const updateStatusStyle = makeStyles({
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    height: 56,
    color: "white",
    background: "#021256 0% 0% no-repeat padding-box",
  },
  topbarIcons: {
    padding: "10px 40px !important",
    cursor: "pointer",
  },
  form: {
    margin: 20,
    fontSize: 20,
  },
  effectiveDate: {
    margin: "20px 0 !important",
  },
  cancelButton: {
    border: "1px solid #A1ABA4",
    borderRadius: "4px",
    padding: 7,
  },
  updateButton: {
    background: "#3AD865 0% 0% no-repeat padding-box !important",
    boxShadow: "0px 3px 5px #00000033 !important",
    border: "1px solid #3AD865 !important",
    borderRadius: "4px",
    marginLeft: "20px !important",
  },
});
