import React, { useCallback, useState } from "react";
import { Bar } from "react-chartjs-2";
import { chartData, chartOptions } from "../../constant";

function CustomizedBarChart(props) {
  const { startDate, endDate, labelData, accessor } = props;

  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);

  React.useEffect(() => {
    setXAxis(
      labelData?.map((item) => {
        return item.month;
      })
    );
    setYAxis(
      labelData?.map((item) => {
        return item[accessor];
      })
    );
  }, [labelData, accessor]);

  return (
    <Bar
      data={chartData(xAxis, yAxis)}
      height={65}
      options={chartOptions(
        yAxis && Math.min(...yAxis),
        yAxis && Math.max(...yAxis)
      )}
    />
  );
}

export default CustomizedBarChart;
