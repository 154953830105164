import { EditableCell } from "../../../../components/utils/EditableCell";

export const detailsOnAssessmentColumns = [
  {
    Header: " ",
    accessor: "values",
  },
  {
    Header:
      "% of value chain partners (by value of business done with such partners) that were assessed",
    accessor: "plants",
    maxLength: 3,
    Cell: EditableCell,
  },
];

const columnName = [
  { id: 1, values: "Health and safety practices" },
  { id: 2, values: "Working Conditions" },
];
export const detailsOnAssessmentCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  plants: "",
}));

export const detailsOnAssessmentInitialvalues = {
  tableData: detailsOnAssessmentCellData,
  safety: "",
};

export const detailsOnAssessmentConstantData = [
  {
    id: 1,
    label:
      "6. Provide details of any corrective actions taken or underway to address significant risks / concerns arising from assessments of health and safety practices and working conditions of value chain partners",
    name: "safety",
  },
];
