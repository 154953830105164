import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const energyConsumedOptions = ["Yes", "No"];

export const energyConsumedColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Parameter",
      accessor: "values",
    },

    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
  ];
};

const columnName = [
  { id: 1, values: "From renewable sources", fullwidth: true },
  { id: 2, values: "Total electricity consumption (A)" },
  { id: 3, values: "Total fuel consumption (B)" },
  { id: 4, values: "Energy consumption through other sources (C)" },
  { id: 5, values: "Total energy consumed from renewable sources (A+B+C)" },
  { id: 6, values: "From non-renewable sources", fullwidth: true },
  { id: 7, values: "Total electricity consumption (D)" },
  { id: 8, values: "Total fuel consumption (E)" },
  { id: 9, values: "Energy consumption through other sources (F)" },
  {
    id: 10,
    values: "Total energy consumed from non-renewable sources (D+E+F)",
  },
];
export const energyConsumedCellData = columnName.map((item) =>
  item.fullwidth
    ? {
        id: item.id,
        values: item.values,
        fullwidth: item.fullwidth,
      }
    : {
        id: item.id,
        values: item.values,
        currentYear: "",
        previousYear: "",
      }
);

export const energyConsumedInitialvalues = {
  tableData: energyConsumedCellData,
  independent: "",
  current: "",
  previous: "",
};

export const energyConsumedConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency.",
    field: "radioButton",
  },
];
