import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  correctiveP8Columns,
  correctiveP8CellData,
  correctiveP8Content,
  ROW_ADDINGTABLE,
  PRINCIPLE8_CORRECTIVE,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const CorrectiveP8 = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(correctiveP8CellData);

  //to get data from api
  const correctiveP8FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE8_CORRECTIVE
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE8_CORRECTIVE,
        { correctiveP8: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      correctiveP8FormConfig
        ? updateTableValues(
            PRINCIPLE8_CORRECTIVE,
            { correctiveP8: `${jsonStr}` },
            correctiveP8FormConfig.id
          )
        : createTableValues(PRINCIPLE8_CORRECTIVE, {
            correctiveP8: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (correctiveP8FormConfig && correctiveP8FormConfig.data.correctiveP8) {
      setParentData(JSON.parse(correctiveP8FormConfig.data.correctiveP8));
    }
  }, [correctiveP8FormConfig]);

  return (
    <>
      <TableWrapper
        columns={correctiveP8Columns}
        storeTableData={storeTableData}
        cellData={
          correctiveP8FormConfig && !correctiveP8FormConfig.data.correctiveP8
            ? correctiveP8FormConfig.data
            : parentData
        }
        tableType={ROW_ADDINGTABLE}
        modalContent={correctiveP8Content}
        keyProps={"panel3"}
        chileKeyPros={"principle8"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={correctiveP8Columns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_ADDINGTABLE}
            modalContent={correctiveP8Content}
          />
        </div>
      </Modal>
    </>
  );
};

export default CorrectiveP8;
