export const productRecalmationOptions = ["Yes", "No"];

export const productRecalmationInitialvalues = {
  products: "",
  entityActivity: "",
  EPR: "",
};

export const productRecalmationConstants = [
  {
    id: 1,
    label:
      "3. Describe the processes in place to safely reclaim your products for reusing, recycling and disposing at the end of life, for (a) Plastics (including packaging) (b) E-waste (c) Hazardous waste and (d) other waste",
    field: "textField",
    name: "products",
    upload: "uploadImage",
  },
  {
    id: 2,
    label:
      "4. Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities (Yes / No).",
    name: "entityActivity",
    field: "radioButton",
  },
  {
    id: 3,
    label:
      " If yes, whether the waste collection plan is in line with the Extended Producer Responsibility (EPR) plan submitted to Pollution Control Boards? If not, provide steps taken to address the same",
    name: "EPR",
    field: "textField",
    upload: "uploadImage",
  },
];
