import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../Context";
import { createTableValues, updateTableValues } from "../../../../api/method";
import {
  DisclosureQuestionsColumns,
  disclosureQuestionsContents,
  DisclosureQuestionsDropdown,
  DisclosureQuestionsConstantData,
  DisclosureQuestionsInitialvalues,
  SECTIONB_DISCLOSURE_QUESTIONS,
  ROW_DROPDOWN_TABLE,
} from "../../../../constant/index";
import { TableWrapper, CustomTextField } from "../../../reusable/index";
import { useFormik } from "formik";
import { Typography } from "@mui/material";

const DisclosureQuestions = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(
    DisclosureQuestionsInitialvalues
  );

  const formik = useFormik({
    initialValues: parentData || DisclosureQuestionsInitialvalues,
    enableReinitialize: true,
  });

  const { values, handleChange, errors, touched, handleBlur } = formik;

  //to get data from api
  const disclosureFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONB_DISCLOSURE_QUESTIONS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    disclosureFormConfig
      ? updateTableValues(
          SECTIONB_DISCLOSURE_QUESTIONS,
          { disclosureQuestions: `${jsonStr}` },
          disclosureFormConfig.id
        )
      : createTableValues(SECTIONB_DISCLOSURE_QUESTIONS, {
          disclosureQuestions: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (disclosureFormConfig) {
      setParentData(JSON.parse(disclosureFormConfig.data.disclosureQuestions));
    }
  }, [disclosureFormConfig]);

  const renderFormFields = (tableData) => {
    return (
      <>
        {DisclosureQuestionsConstantData.map(
          ({ id, rowId, label, name, accessor }) => {
            let filterData = tableData?.filter((item) => {
              return parseInt(item.id) === rowId;
            });
            return (
              filterData &&
              filterData[0][accessor] === "SB" && (
                <div key={id} style={{ margin: 8 }}>
                  <Typography variant="subtitle">
                    {label}

                    <CustomTextField
                      name={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[name]}
                      error={touched[name] && errors[name]}
                      helperText={touched[name] && errors[name]}
                      variant="outlined"
                      logInField
                      style={{
                        width: "80%",
                        background: "white",
                        paddingBottom: "0px ",
                      }}
                    />
                  </Typography>
                </div>
              )
            );
          }
        )}
      </>
    );
  };

  return (
    <TableWrapper
      columns={DisclosureQuestionsColumns}
      dropdownValues={DisclosureQuestionsDropdown}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={ROW_DROPDOWN_TABLE}
      modalContent={disclosureQuestionsContents}
      renderFormFields={renderFormFields}
      renderFormFieldBottom={true}
      keyProps={"panel2"}
    />
  );
};

export default DisclosureQuestions;
