export const employeesCoveredContent = [
  {
    id: "1",
    title:
      "PRINCIPLE 3 Businesses should respect and promote the well-being of all employees, including those in their value chains",
    aboutTable: "Essential Indicators",
    subAboutTable:
      "1. a. Details of measures for the well-being of employees: ",
  },
];
