import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";
import DropdownField from "../../../../components/reusable/DropDownField";

export const complaintsDropdown = [
  { id: 1, name: "NA", acccessor: "name" },
  { id: 2, name: "SB", acccessor: "name" },
];

export const complaintsColumns = (setFieldValue, rate, isView) => {
  return [
    {
      id: 1,
      Header: " ",
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "values",
        },
      ],
    },
    {
      id: 2,
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      columns: [
        {
          Header: "Filed during the year",
          accessor: "currentYear",
          Cell: EditableCell,
          type: "number",
        },

        {
          Header: "Pending resolution at the end of year ",
          accessor: "currentEndOfYear",
          Cell: EditableCell,
          type: "number",
        },
        {
          Header: "Remarks",
          accessor: "currentRemarks",
          Cell: (props) => {
            const { updateData, row, cell } = props;
            return isView ? (
              <div>{cell.value}</div>
            ) : (
              <DropdownField
                inputValues={complaintsDropdown || []}
                name={cell.column.id}
                fieldStyle={{ margin: 0, padding: 4 }}
                onChange={(e) =>
                  updateData(row.index, cell.column.id, e.target.value)
                }
                value={cell.value}
              />
            );
          },
        },
      ],
    },
    {
      id: 3,
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),

      columns: [
        {
          Header: "Filed during the year",
          accessor: "previousYear",
          Cell: EditableCell,
          type: "number",
        },

        {
          Header: "Pending resolution at the end of year ",
          accessor: "previousEndOfYear",
          Cell: EditableCell,
          type: "number",
        },
        {
          Header: "Remarks",
          accessor: "previousRemarks",
          Cell: (props) => {
            const { updateData, row, cell } = props;
            return isView ? (
              <div>{cell.value}</div>
            ) : (
              <DropdownField
                inputValues={complaintsDropdown || []}
                name={cell.column.id}
                fieldStyle={{ margin: 0, padding: 4 }}
                onChange={(e) =>
                  updateData(row.index, cell.column.id, e.target.value)
                }
                value={cell.value}
              />
            );
          },
        },
      ],
    },
  ];
};

const columnName = [
  { id: 1, values: "Working Conditions" },
  { id: 2, values: "Health & Safety" },
];
export const complaintsCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  currentYear: "",
  currentEndOfYear: "",
  currentRemarks: "",
  previousYear: "",
  previousEndOfYear: "",
  previousRemarks: "",
}));

export const complaintsInitialvalues = {
  tableData: complaintsCellData,
  current: "",
  previous: "",
};

export const complaintsConstantData = [
  {
    id: 1,
    rowId: 1,
    label: "Remarks - 1a:",
    name: "remark1a",
    accessor: "currentRemarks",
  },
  {
    id: 2,
    rowId: 1,
    label: "Remarks - 1b:",
    name: "remark1b",
    accessor: "previousRemarks",
  },
  {
    id: 3,
    rowId: 2,
    label: "Remarks - 2a:",
    name: "remark2a",
    accessor: "currentRemarks",
  },
  {
    id: 4,
    rowId: 2,
    label: "Remarks - 2b:",
    name: "remark2b",
    accessor: "previousRemarks",
  },
];
