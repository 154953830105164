import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../../Context";
import { CSRdetailsStyles } from "../../../../../styles/index";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  CustomTextarea,
  CustomRadioButton,
  CustomizedSnackbars,
  TableNavbar,
} from "../../../../reusable/index";
import {
  managementSystemConstants,
  managementSystemInitialvalues,
  managementSystemOptions,
  managementSystemContent,
  PRINCIPLE3_MANAGEMENT_SYSTEM,
} from "../../../../../constant/index";
import Cookies from "universal-cookie";
import { CustomizedAccordions } from "../../../../reusable/Accordion";
import CustomImageUpload from "../../../../reusable/CustomImageUpload";
function ManagementSystem() {
  const {
    list,
    root,
    rootAlign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
  } = CSRdetailsStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const [noOfTImes, setNoOfTimes] = useState([])
  const managementSystemFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_MANAGEMENT_SYSTEM
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    managementSystemFormConfig
      ? updateTableValues(
          PRINCIPLE3_MANAGEMENT_SYSTEM,
          { managementSystem: `${jsonStr}` },
          managementSystemFormConfig.id
        )
      : createTableValues(PRINCIPLE3_MANAGEMENT_SYSTEM, {
          managementSystem: `${jsonStr}`,
        });
  };

  //  fetch db data
  useEffect(() => {
    if (managementSystemFormConfig) {
      // setParentData(
      //   JSON.parse(managementSystemFormConfig.data.managementSystem)
      // );
    }
  }, [managementSystemFormConfig]);

  //   snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // formik
  const onSubmit = (values) => {
    storeTableData(values);
    handleClickSnackBar();
  };

  const formik = useFormik({
    initialValues: parentData || managementSystemInitialvalues,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  const cloneHandle = () => {
    setNoOfTimes(preValue => [...preValue, {}])
  }

  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions
            keyProps={"panel3"}
            chileKeyPros={"principle3"}
          />
        </div>
        <div className={rootAlign}>
          <Typography variant="h6">{managementSystemContent.title}</Typography>
          <br />
          {managementSystemConstants.map(({ id, name, label, type, field }) => (
            <div key={id}>
              <Typography variant="div" className={list}>
                {label}
              </Typography>
              {field === "textField" && (
                <CustomTextarea
                  type={type}
                  name={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] && errors[name]}
                  variant="outlined"
                  style={{ margin: "8px 0", fontSize: "16px" }}
                />
              )}
              {field === "radioButton" && (
                <CustomRadioButton
                  name={name}
                  CSRdetailsOptions={managementSystemOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] && errors[name]}
                />
              )}
            </div>
          ))}
             {
              noOfTImes.map((_, i) => {
                return (
                <div style={{ borderTop: "2px solid #000000", marginTop: "40px", paddingTop: "40px" }}>
                {managementSystemConstants.map(({ id, name, label, type, field }) => (
                <div key={id}>
              <Typography variant="div" className={list}>
                {label}
              </Typography>
              {field === "textField" && (
                <CustomTextarea
                  type={type}
                  name={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] && errors[name]}
                  variant="outlined"
                  style={{ margin: "8px 0", fontSize: "16px" }}
                />
              )}
              {field === "radioButton" && (
                <CustomRadioButton
                  name={name}
                  CSRdetailsOptions={managementSystemOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] && errors[name]}
                />
              )}
            </div>
            ))}
                </div>
                )
              })
            }
           <div className="cloneTbl"><span className="cloneIcon" onClick={cloneHandle}>+</span></div>
          <div className="fileUplodeTab">
              <CustomImageUpload />
          </div>
          <Box className={btnContainer}>
            <Button variant="outlined" color="primary" onClick={resetForm}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>

          <CustomizedSnackbars open={open} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export default ManagementSystem;
