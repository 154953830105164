export const monetaryContent = [
  {
    id: "1",
    title: "SECTION C: PRINCIPLE WISE PERFORMANCE DISCLOSURE",
    subTitle: "Monetary",
    aboutTable:
      "2. Details of fines / penalties /punishment/ award/ compounding fees/ settlement amount paid in proceedings (by the entity or by directors / KMPs) with regulators/ law enforcement agencies/ judicial institutions, in the financial year, in the following format (Note: the entity shall make disclosures on the basis of materiality as specified in Regulation 30 of SEBI (Listing Obligations and Disclosure Obligations) Regulations, 2015 and as disclosed on the entity’s website):",
    subAboutTable: "SB (See Below)",
  },
];
