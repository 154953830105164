import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    minWidth: 250,
    backgroundColor: "white",
    padding: 20,
    marginRight: "20px",
    borderRadius: 4,
    boxShadow: "0px 0px 10px #0000001A",
  },
}));

export default function CustomStepper(props) {
  const { steps, activeStep } = props;
  const { stepperContainer } = useStyles();

  return (
    <Box className={stepperContainer}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
