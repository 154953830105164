const TOTAL_USERS = "Total Users",
  ACTIVE_USERS = "Active Users",
  INACTIVE_USERS = "Inactive Users",
  NEW_USERS = "New Users";

export const userAnalytics = (graphDetails) => [
  {
    id: 1,
    label: "Total Users",
    value: graphDetails && graphDetails[TOTAL_USERS][0].count,
  },
  {
    id: 2,
    label: "Active Users",
    value: graphDetails && graphDetails[ACTIVE_USERS][0].count,
    status: "af6f2eb0-2147-47e0-9da3-5128cd5e4b12",
  },
  {
    id: 3,
    label: "Inactive Users",
    value: graphDetails && graphDetails[INACTIVE_USERS][0].count,
    status: "2b328687-4562-47ad-be73-40ebfd557ea1",
  },
  {
    id: 4,
    label: "New Users",
    value: graphDetails && graphDetails[NEW_USERS][0].count,
  },
];

const TOTAL_CUSTOMERS = "total Customers",
  ACTIVE_CUSTOMERS = "active Customers",
  INACTOVE_CUSTOMERS = "Inactive Customers",
  NEW_CUSTOMERS = "New Customers";

export const customerAnalyticsData = (graphDetails) => {
  return [
    {
      id: 1,
      label: "Total Customers",
      value:
        graphDetails && graphDetails[TOTAL_CUSTOMERS]
          ? graphDetails[TOTAL_CUSTOMERS][0]?.count
          : 0,
      name: "total Customers",
    },
    {
      id: 2,
      label: "Active Customers",
      value:
        graphDetails && graphDetails[ACTIVE_CUSTOMERS]
          ? graphDetails[ACTIVE_CUSTOMERS][0]?.count
          : 0,
      name: "active Customers",
      status: "af6f2eb0-2147-47e0-9da3-5128cd5e4b12",
    },
    {
      id: 3,
      label: "Inactive Customers",
      value:
        graphDetails && graphDetails[INACTOVE_CUSTOMERS]
          ? graphDetails[INACTOVE_CUSTOMERS][0]?.count
          : 0,
      name: "Inactive Customers",
      status: "2b328687-4562-47ad-be73-40ebfd557ea1",
    },
    {
      id: 4,
      label: "New Customers",
      value:
        graphDetails && graphDetails[NEW_CUSTOMERS]
          ? graphDetails[NEW_CUSTOMERS][0]?.count
          : 0,
      name: "New Customers",
    },
  ];
};

const TOTAL_INVOICES = "Total Invoices",
  INVOICES_PAID = "Invoice Paid",
  INVOICES_NOT_PAID = "Invoice Not Paid";

export const invoiceAnalyticsData = (graphDetails) => {
  return [
    {
      id: 1,
      label: "Total Invoices",
      value:
        graphDetails && graphDetails[TOTAL_INVOICES]
          ? graphDetails[TOTAL_INVOICES][0].count
          : 0,
    },
    {
      id: 2,
      label: "Invoice Paid",
      value:
        graphDetails && graphDetails[INVOICES_PAID]
          ? graphDetails[INVOICES_PAID][0].count
          : 0,
      status: "c2e48603-8c4f-4055-b373-3d21fa3beafb",
    },
    {
      id: 3,
      label: "Invoice Not Paid",
      value:
        graphDetails && graphDetails[INVOICES_NOT_PAID]
          ? graphDetails[INVOICES_NOT_PAID][0].count
          : 0,
      status: "5e641a9b-8ef9-4928-a457-0a672f3abf28",
    },
  ];
};
