export const correctiveActionP9Initialvalues = {
  actions: "",
};

export const correctiveActionP9Constants = [
  {
    id: 1,
    label:
      "6. Provide details of any corrective actions taken or underway on issues relating to advertising, and delivery of essential services; cyber security and data privacy of customers; re-occurrence of instances of product recalls; penalty / action taken by regulatory authorities on safety of products / services.",
    field: "textField",
    name: "actions",
  },
];
