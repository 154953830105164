/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/index";
import { getTableValues, getTableValuesByUserId } from "./api/method";
import ElectronicFormContext from "./Context";
import { sectionA, sectionB, sectionC } from "./routes/index";
import MyProfile from "./components/pages/profile/MyProfile";
import EditProfile from "./components/pages/profile/EditProfile";
import ChangePassword from "./components/pages/profile/ChangePassword";
import { useLocation } from "react-router-dom";
import { routes, loginRoutes } from "./routes/routes";
import NavBar from "./components/reusable/NavBar";
import CircularProgress from "@mui/material/CircularProgress";
import CompanyUserForm from "./components/pages/companyUsersAdmin/companyUsers/CompanyUserForm";
import UserForm from "./components/pages/companyUsersAdmin/companyUsers/UserForm";
import UserAccess from "./components/pages/companyUsersAdmin/companyUsers/UserAccess";
import SuperAdminNavBar from "./components/reusable/SuperAdminNavBar";
import Cookies from "universal-cookie";
import UserNavbar from "./components/reusable/UserNavbar";
import PrivateRouteClient from "./PrivateRouteClient";
import { connect } from "react-redux";
import ToastMessage from "./components/reusable/ToastMessage";
import { useDispatch } from "react-redux";
import { leadershipIndicatorAction, toastMessageAction } from "./redux/action";
import ContextState from "./context/ContextState";
import DefaultPage from "./components/pages/defaultPageForUser/DefaultPage";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 62,
  },
}));

function Root(props) {
  const { toastMessageState } = props.toastMessageStateReducer;
  const dispatch = useDispatch();
  const { root } = useStyles();
  const [storedTableData, setStoredTableData] = React.useState(null);
  const location = useLocation();
  const pathName = location.pathname;
  const cookies = new Cookies();
  const custId = cookies.get("custId");
  const userTypeName = cookies.get("userTypeName");

  const fetchData = async () => {
    if (custId !== "null" && custId !== "undefined") {
      console.log("🚀 ~ file: Root.js ~ line 51 ~ fetchData ~ cookies.get");
      if (cookies.get("userTypeName") === "Company User") {
        if (cookies.get("formId") !== "undefined") {
          const { data } = await getTableValuesByUserId(custId);
          console.log("🚀 ~ file: Root.js ~ line 51 ~ fetchData ~ data", data);
          cookies.set("formId", data.formId, {
            path: "/",
          });
          dispatch(
            toastMessageAction({
              messageStatus: false,
            })
          );
          return setStoredTableData(data.data);
        } else {
          let { data } = await getTableValues(custId);
          console.log("🚀 ~ file: Root.js ~ line 63 ~ fetchData ~ data", data);
          let formId = data.formAccess[0].formId;
          cookies.set("formId", formId, {
            path: "/",
          });
          dispatch(
            toastMessageAction({
              messageStatus: false,
            })
          );
          return setStoredTableData(data.data);
        }
      } else if (cookies.get("userTypeName") === "Company Admin") {
        let { data } = await getTableValues(custId);
        console.log("🚀 ~ file: Root.js ~ line 63 ~ fetchData ~ data", data);
        cookies.set("formId", data.id, {
          path: "/",
        });
        // let incomingData = data.formData.map((data)=>(data.data))
        dispatch(leadershipIndicatorAction(data.leadershipIndicator));
        dispatch(
          toastMessageAction({
            messageStatus: false,
          })
        );
        return setStoredTableData(data.formData);
      } else {
        return;
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [pathName]);

  return (
    <ElectronicFormContext.Provider value={storedTableData}>
      <ContextState>
        <Suspense fallback={<CircularProgress className="loaderStyle" />}>
          <ThemeProvider theme={theme}>
            {pathName === "/" ||
            pathName === "/forgetPassword" ||
            pathName === "/forget-password" ||
            pathName === "/resetPassword" ? (
              <NavBar disableProfile />
            ) : (
              (userTypeName === "Super Admin" && <SuperAdminNavBar />) ||
              (userTypeName === "Company Admin" && <NavBar />) ||
              (userTypeName === "Company User" && <UserNavbar />)
            )}

            <div className={root}>
              <ToastMessage toastMessageState={toastMessageState} />
              <Routes>
                {loginRoutes.map(({ component, path, exact }) => {
                  const Component = component;

                  return (
                    <Route
                      key={path}
                      exact={exact}
                      path={path}
                      element={<Component />}
                    />
                  );
                })}
                {routes.map(({ component, path, exact }) => {
                  const Component = component;
                  return (
                    <Route
                      key={path}
                      exact={exact}
                      path={path}
                      element={<PrivateRouteClient Component={Component} />}
                    />
                  );
                })}
                <Route
                  exact={true}
                  path={"/companyUsersForm"}
                  element={<CompanyUserForm />}
                >
                  <Route path={"userForm"} element={<UserForm />} />
                  <Route path={"userAccess"} element={<UserAccess />} />
                </Route>

                <Route path={"/"} element={""} />

                <Route path={"/profile"} element={<MyProfile />} />
                <Route path={"/editProfile"} element={<EditProfile />} />
                <Route path={"/changePassword"} element={<ChangePassword />} />
                <Route path={"/company-user"} element={<DefaultPage />} />

                {sectionA.map((secA, index) => (
                  <Route
                    path={secA.path}
                    element={secA.component}
                    key={index}
                  />
                ))}
                {sectionB.map((secB, index) => (
                  <Route
                    path={secB.path}
                    element={secB.component}
                    key={index}
                  />
                ))}
                {sectionC.map((secC, index) => (
                  <Route
                    path={secC.path}
                    element={secC.component}
                    key={index}
                  />
                ))}
              </Routes>
            </div>
          </ThemeProvider>
        </Suspense>
      </ContextState>
    </ElectronicFormContext.Provider>
  );
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Root);
