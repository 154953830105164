export const jointVenturesContents = [
  {
    id: "1",
    title: " SECTION A: GENERAL DISCLOSURES",
    subTitle:
      "V. Holding, Subsidiary and Associate Companies (including joint ventures)",
    aboutTable:
      "21.  Names of holding / subsidiary / associate companies / joint ventures",
  },
];
