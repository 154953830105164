import { EditableCell } from "../../../components/utils/EditableCell";
import CustomImageUpload from "../../../components/reusable/CustomImageUpload";
export const differentlyAbledColumns = [
  {
    Header: "S.No",
    // accessor: "sNo",
    rowspan: 2,
    columns: [
      {
        Header: " ",
        hideHeader: true,
        accessor: "sNo",
      },
    ],
  },
  {
    Header: "Particulars",
    // accessor: "particulars",
    rowspan: 2,
    columns: [
      {
        Header: " ",
        hideHeader: true,
        accessor: "particulars",
      },
    ],
  },
  {
    Header: "Total (A)",
    // accessor: "totalA",
    rowspan: 2,
    columns: [
      {
        Header: " ",
        hideHeader: true,
        accessor: "totalA",
        type: "number",
        Cell: EditableCell,
      },
    ],
  },
  {
    Header: "Male",
    columns: [
      {
        Header: "No. (B)",
        accessor: "noB",
        type: "number",
        Cell: EditableCell,
      },
      {
        Header: "% (B/A)",
        accessor: "BA",
        type: "number",
        maxLength: 3,
        Cell: EditableCell,
      },
    ],
  },
  {
    Header: "Female",
    columns: [
      {
        Header: "No. (C)",
        accessor: "noC",
        type: "number",
        // maxLength: 4,
        Cell: EditableCell,
      },
      {
        Header: "% (C/A)",
        accessor: "CA",
        type: "number",
        maxLength: 3,
        Cell: EditableCell,
      },
    ],
  },
];

export const differentlyAbledCellData = [
  {
    id: "1",
    values: "DIFFERENTLY ABLED EMPLOYEES",
    fullwidth: true,
  },
  {
    id: "2",
    sNo: "1",
    particulars: "Permanent (D)",
    totalA: "",
    noB: "",
    BA: "",
    noC: "",
    CA: "",
  },
  {
    id: "3",
    sNo: "2",
    particulars: "Other than Permanent (E)",
    totalA: "",
    noB: "",
    BA: "",
    noC: "",
    CA: "",
  },
  {
    id: "4",
    sNo: "3",
    particulars: "Total differently abled employees (D + E)",
    totalA: "",
    noB: "",
    BA: "",
    noC: "",
    CA: "",
  },
  {
    id: "5",
    values: "DIFFERENTLY ABLED WORKERS",
    fullwidth: true,
  },
  {
    id: "6",
    sNo: "4",
    particulars: "Permanent (F)",
    totalA: "",
    noB: "",
    BA: "",
    noC: "",
    CA: "",
  },
  {
    id: "7",
    sNo: "5",
    particulars: "Other than Permanent (G)",
    totalA: "",
    noB: "",
    BA: "",
    noC: "",
    CA: "",
  },
  {
    id: "8",
    sNo: "6",
    particulars: "Total differently abled workers (F + G) hello",
    totalA: "",
    noB: "",
    BA: "",
    noC: "",
    CA: "",
  },
];
export const differentlyAbledCellDataUplode = () => {
<CustomImageUpload />
}
