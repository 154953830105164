import { EditableCell } from "../../../../components/utils/EditableCell";

export const negativeImpactsColumns = [
  {
    Header: "Details of negative social impact identified ",
    accessor: "negative",
    Cell: EditableCell,
  },
  {
    Header: "Corrective action taken ",
    accessor: "action",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const negativeImpactsCellData = columnName.map((item) => ({
  id: item.id,
  negative: "",
  action: "",
}));
