import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { accordionData } from "../../../../constant";
import { useNavigate, useOutletContext, useLocation } from "react-router-dom";
import { userAccessApi, getFormDataByUser } from "../../../../api/api";
import { ROUTE_PATHS } from "../../../../routes/routePath";
import { useDispatch } from "react-redux";
import { toastMessageAction } from "../../../../redux/action";
import ElectronicFormContext from "../../../../Context";
import Cookies from "universal-cookie";

const useStyles = makeStyles({
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: 30,
    paddingTop: 30,
  },
  btn: {
    margin: "0 6px",
  },
  saveBtn: {
    background: "#3AD865 !important",
    color: "white",
    height: "40px",
    width: "100px",
    padding: 10,
  },
  cancelBtn: {
    background: "#0000001A !important",
    color: "white",
    height: "40px",
    width: "100px",
    padding: 10,
  },
});

export default function UserAccess() {
  const { btnContainer, saveBtn, cancelBtn } = useStyles();
  const { handleBack } = useOutletContext();
  const navigate = useNavigate();
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const userId = JSON.parse(searchParam?.get("userId"));
  const cookies = new Cookies();
  const customerId = cookies.get("custId");
  const isViewMode = JSON.parse(searchParam?.get("isViewMode"));
  const [allBox, setAllBox] = React.useState(false);
  const [secChecked, setSecChecked] = React.useState({});
  const [principleChecked, setPrincipleChecked] = React.useState({});
  const [tableChecked, setTableChecked] = React.useState({});
  const [getAccess, setGetAccess] = useState();
  const dispatch = useDispatch();
  const [leadershipIndicatorApi, setLeadershipIndicatorApi] =
    React.useState(false);
  const existingTableData = useContext(ElectronicFormContext);
// filter for update feature 
  let currentUrl = window.location.href
  console.log(currentUrl)
  
  useEffect(() => {
    if (customerId) {
      getFormDataByUser(customerId)
        .then((response) => {
          setLeadershipIndicatorApi(response?.data[0]?.leadershipIndicator);
          // const accessData = response.data[0]?.formAccess.filter((item) => {
          //   return item.userId === userId;
          // });
          
          const accessData = response.data.formAccess.filter(data=> data.userId === userId)
          console.log(accessData)
          setGetAccess(accessData[0]?.formAccess);
        })
        .catch((error) => console.log(error));
    }
  }, [customerId, userId]);

  const allBoxHandleChange = (event) => {
    setAllBox(event.target.checked);
    event.target.checked &&
      setSecChecked({
        sectionA: true,
        sectionB: true,
        sectionC: true,
      });
  };

  const secHandleChange = (event) => {
    setSecChecked({
      ...secChecked,
      [event.target.name]: event.target.checked,
    });
  };

  const principleHandleChange = (event) => {
    setPrincipleChecked({
      ...principleChecked,
      [event.target.name]: event.target.checked,
    });
    !event.target.checked &&
      setSecChecked({
        ...secChecked,
        sectionC: false,
      });
  };

  const tableHandleChange = (e, name) => {
    setTableChecked({
      ...tableChecked,
      [name]: {
        ...tableChecked[name],
        [e.target.name]: e.target.checked,
      },
    });

    (!e.target.checked && name === "sectionA") ||
    (!e.target.checked && name === "sectionB")
      ? setSecChecked({
          ...secChecked,
          [name]: false,
        })
      : changePrincipleValues(name);
  };

  // uncheck principle and sectionC
  const changePrincipleValues = (name) => {
    setPrincipleChecked({
      ...principleChecked,
      [name]: false,
    });
    setSecChecked({
      ...secChecked,
      sectionC: false,
    });
  };

  React.useEffect(() => {
    let a = Object.keys(secChecked).every(
      (secName) => secChecked[secName] === true
    );
    !a && setAllBox(false);
  }, [secChecked]);

  React.useEffect(() => {
    secChecked &&
      accordionData.map(({ buttons, value }) => {
        return secChecked[value] && value !== "sectionC"
          ? buttons.map((item) => {
              const btnValue = item.value;
              return setTableChecked((prevData) => ({
                ...prevData,
                [value]: {
                  ...prevData[value],
                  [btnValue]: true,
                },
              }));
            })
          : secChecked[value] &&
              buttons.map((item) => {
                const principleValue = item.value;
                return setPrincipleChecked((prevData) => ({
                  ...prevData,
                  [principleValue]: true,
                }));
              });
      });
  }, [secChecked]);

  React.useEffect(() => {
    principleChecked &&
      accordionData.map(({ buttons, value }) => {
        return (
          value === "sectionC" &&
          buttons.map((item) => {
            const principle = item.value;
            const principleButton = item.buttons;
            return (
              principleChecked[principle] &&
              principleButton.map((item) => {
                const principleValue = item.value;
                return setTableChecked((prevData) => ({
                  ...prevData,
                  [principle]: {
                    ...prevData[principle],
                    [principleValue]: true,
                  },
                }));
              })
            );
          })
        );
      });
  }, [principleChecked]);

  const children = (buttons, principleName) => {
    let getprincipleValue = tableChecked[principleName];
    return buttons.map(({ name, id, value, leadershipIndicators }) =>
      leadershipIndicators ? (
        leadershipIndicatorApi && (
          <Box sx={{ display: "flex", flexDirection: "column", ml: 10 }}>
            <FormControlLabel
              key={id}
              label={name}
              control={
                <Checkbox
                  name={value}
                  disabled={isViewMode}
                  onChange={(e) => tableHandleChange(e, principleName)}
                  checked={
                    (getprincipleValue && getprincipleValue[value]) || false
                  }
                  sx={{ "&.Mui-checked": { color: "#E46D35" } }}
                />
              }
            />
          </Box>
        )
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", ml: 10 }}>
          <FormControlLabel
            key={id}
            label={name}
            control={
              <Checkbox
                name={value}
                disabled={isViewMode}
                onChange={(e) => tableHandleChange(e, principleName)}
                checked={
                  (getprincipleValue && getprincipleValue[value]) || false
                }
                sx={{ "&.Mui-checked": { color: "#E46D35" } }}
              />
            }
          />
        </Box>
      )
    );
  };

  const handleSubmit = () => {
    const data = {
      userId: userId,
      hasRestriction: true,
      formAccess: {
        principleChecked,
        secChecked,
        tableChecked,
      },
    };

    userAccessApi(data).then((response) => {
      navigate(ROUTE_PATHS.COMPANYUSERS);
    });
  };

  useEffect(() => {
    if (getAccess !== undefined) {
      setSecChecked(getAccess?.secChecked || {});
      setPrincipleChecked(getAccess?.principleChecked || {});
      setTableChecked(getAccess?.tableChecked || {});
    }
  }, [getAccess]);

  return (
    <div>
      <FormControlLabel
        style={{ width: "100%" }}
        control={
          <Checkbox
            onChange={allBoxHandleChange}
            checked={allBox}
            disabled={isViewMode}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              "&.Mui-checked": { color: "#E46D35" },
            }}
          />
        }
        label="ALL"
      />
      {accordionData.map((sec) => (
        <>
          <FormControlLabel
            label={sec.name}
            key={sec.id}
            style={{ marginTop: 15 }}
            control={
              <Checkbox
                name={sec.value}
                onChange={secHandleChange}
                disabled={isViewMode}
                checked={secChecked[sec.value] || false}
                sx={{ "&.Mui-checked": { color: "#E46D35" } }}
              />
            }
          />
          {sec?.buttons?.map((item) => {
            let getTable = tableChecked[sec.value];
            return (
              <>
                <Box sx={{ display: "flex", flexDirection: "column", ml: 5 }}>
                  <FormControlLabel
                    label={item.name}
                    key={item.id}
                    control={
                      <Checkbox
                        name={item.value}
                        disabled={isViewMode}
                        onChange={
                          sec.name === "SECTION C"
                            ? (e) => principleHandleChange(e, sec.value)
                            : (e) => tableHandleChange(e, sec.value)
                        }
                        checked={
                          (principleChecked && principleChecked[item.value]) ||
                          (getTable && getTable[item.value]) ||
                          false
                        }
                        sx={{ "&.Mui-checked": { color: "#E46D35" } }}
                      />
                    }
                  />
                </Box>
                {sec.name === "SECTION C" && children(item.buttons, item.value)}
              </>
            );
          })}
        </>
      ))}
      <div className={btnContainer}>
        <Button
          variant="contained"
          className={cancelBtn}
          onClick={() => {
            handleBack();
            navigate("/companyUsersForm/userForm");
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          className={saveBtn}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
