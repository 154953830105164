import { EditableCell } from "../../../../components/utils/EditableCell";

export const riskConcernColumns = [
  {
    Header: " Name of Product / Service",
    accessor: "productName",
    Cell: EditableCell,
  },
  {
    Header: "Description of the risk / concern",
    accessor: "riskConcern",
    Cell: EditableCell,
  },
  {
    Header: "Action Taken",
    accessor: "actionTaken ",
    Cell: EditableCell,
  },
];

export const riskConcernCellData = [
  {
    id: "1",
    productName: "",
    riskConcern: "",
    actionTaken: "",
  },
  {
    id: "2",
    productName: "",
    riskConcern: "",
    actionTaken: "",
  },
  {
    id: "3",
    productName: "",
    riskConcern: "",
    actionTaken: "",
  },
];
