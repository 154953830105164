import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../../Context";
import { CSRdetailsStyles } from "../../../../../styles/index";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  CustomTextField,
  CustomizedSnackbars,
  TableNavbar,
} from "../../../../reusable/index";
import {
  managementPlanConstants,
  managementPlanInitialvalues,
  managementPlanContent,
  PRINCIPLE6_MANAGEMENT_PLAN,
} from "../../../../../constant/index";
import { CustomizedAccordions } from "../../../../reusable/Accordion";
import Cookies from "universal-cookie";
import Context from "../../../../../context/Context";

function ManagementPlan() {
  const {
    list,
    root,
    rootAlign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
  } = CSRdetailsStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const managementPlanFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE6_MANAGEMENT_PLAN
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE6_MANAGEMENT_PLAN,
        { managementPlan: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      managementPlanFormConfig
        ? updateTableValues(
            PRINCIPLE6_MANAGEMENT_PLAN,
            { managementPlan: `${jsonStr}` },
            managementPlanFormConfig.id
          )
        : createTableValues(PRINCIPLE6_MANAGEMENT_PLAN, {
            managementPlan: `${jsonStr}`,
          });
    }
  };

  //  fetch db data
  useEffect(() => {
    if (
      managementPlanFormConfig &&
      managementPlanFormConfig.data.managementPlan
    ) {
      setParentData(JSON.parse(managementPlanFormConfig.data.managementPlan));
    }
  }, [managementPlanFormConfig]);

  //   snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // formik
  const onSubmit = (values) => {
    storeTableData(values);
    handleClickSnackBar();
  };

  const formik = useFormik({
    initialValues: parentData || managementPlanInitialvalues,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions
            keyProps={"panel3"}
            chileKeyPros={"principle6"}
          />
        </div>
        <div className={rootAlign}>
          <Typography variant="h6">{managementPlanContent.title}</Typography>
          <br />
          {managementPlanConstants.map(({ id, name, label, type, field }) => (
            <div key={id}>
              <Typography variant="subtitle" className={list}>
                {label}
              </Typography>
              {field === "textField" && (
                <CustomTextField
                  type={type}
                  name={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] && errors[name]}
                />
              )}
            </div>
          ))}

          <Box className={btnContainer}>
            <Button variant="outlined" color="primary" onClick={resetForm}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>

          <CustomizedSnackbars open={open} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export default ManagementPlan;
