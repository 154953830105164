import React, { useContext, useEffect, useState, useMemo } from "react";
import ElectronicFormContext from "../../../../Context";
import { TableWrapper } from "../../../reusable/index";
import {
  participationOfWomenColumns,
  participationOfWomenCellData,
  participationOfWomenContents,
  SECTIONA_PARTICIPATIONOFWOMEN,
  STATIC_TABLE,
} from "../../../../constant/index";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../api/method";
import Context from "../../../../context/Context";
import Modal from "@mui/material/Modal";
import Cookies from "universal-cookie";

function ParticipationOfWomen() {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(participationOfWomenCellData);

  const participationOfWomenFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_PARTICIPATIONOFWOMEN
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        SECTIONA_PARTICIPATIONOFWOMEN,
        { participationOfWomen: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      participationOfWomenFormConfig
        ? updateTableValues(
            SECTIONA_PARTICIPATIONOFWOMEN,
            { participationOfWomen: `${jsonStr}` },
            participationOfWomenFormConfig.id
          )
        : createTableValues(SECTIONA_PARTICIPATIONOFWOMEN, {
            participationOfWomen: `${jsonStr}`,
          });
    }
  };

  let participationOfWomenDataWithUserId =
    existingTableData &&
    existingTableData.filter(
      (data) =>
        data.section.split("/").join("").slice(8) === "participationOfWomen"
    );
  participationOfWomenDataWithUserId =
    participationOfWomenDataWithUserId &&
    participationOfWomenDataWithUserId.map((data) => data.data).flat();

  let forDownLoadFile =
    existingTableData &&
    existingTableData.filter(
      (data) =>
        data.section.split("/").join("").slice(8) === "participationOfWomen"
    );
  forDownLoadFile =
    forDownLoadFile && forDownLoadFile.map((data) => data.document_link);

  //  fetch db data
  useEffect(() => {
    if (
      participationOfWomenFormConfig &&
      participationOfWomenDataWithUserId.length <= 1
    ) {
      setParentData(
        JSON.parse(participationOfWomenFormConfig.data.participationOfWomen)
      );
    }
  }, [participationOfWomenFormConfig]);

  return (
    <>
      {participationOfWomenDataWithUserId &&
      participationOfWomenDataWithUserId.length > 1 ? (
        <TableWrapper
          columns={participationOfWomenColumns}
          cellData={participationOfWomenDataWithUserId}
          storeTableData={storeTableData}
          tableType={STATIC_TABLE}
          modalContent={participationOfWomenContents}
          keyProps={"panel1"}
          cloneActive={true}
          fileUplode={true}
        />
      ) : (
        <TableWrapper
          columns={participationOfWomenColumns}
          cellData={parentData}
          storeTableData={storeTableData}
          tableType={STATIC_TABLE}
          modalContent={participationOfWomenContents}
          keyProps={"panel1"}
          cloneActive={true}
          fileUplode={true}
          fileDownload={forDownLoadFile}
        />
      )}
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={participationOfWomenColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={participationOfWomenContents}
          />
        </div>
      </Modal>
    </>
  );
}

export default ParticipationOfWomen;
