import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  inputMaterialColumns,
  inputMaterialInitialvalues,
  inputMaterialContent,
  STATIC_TABLE,
  PRINCIPLE8_INPUT_MATERIAL,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const InputMaterial = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(inputMaterialInitialvalues);
  const formik = useFormik({
    initialValues: parentData || inputMaterialInitialvalues,
    enableReinitialize: true,
  });
  const { values, setFieldValue } = formik;

  //to get data from api
  const inputMaterialFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE8_INPUT_MATERIAL
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE8_INPUT_MATERIAL,
        { inputMaterial: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      inputMaterialFormConfig
        ? updateTableValues(
            PRINCIPLE8_INPUT_MATERIAL,
            { inputMaterial: `${jsonStr}` },
            inputMaterialFormConfig.id
          )
        : createTableValues(PRINCIPLE8_INPUT_MATERIAL, {
            inputMaterial: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (inputMaterialFormConfig && inputMaterialFormConfig.data.inputMaterial) {
      setParentData(JSON.parse(inputMaterialFormConfig.data.inputMaterial));
    }
  }, [inputMaterialFormConfig]);

  return (
    <>
      <TableWrapper
        columns={inputMaterialColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          inputMaterialFormConfig && !inputMaterialFormConfig.data.inputMaterial
            ? inputMaterialFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={inputMaterialContent}
        hideHelp={true}
        keyProps={"panel3"}
        chileKeyPros={"principle8"}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={inputMaterialColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={inputMaterialContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default InputMaterial;
