import { CustomTextField } from "../../../../components/reusable/index";
import { EditableCell } from "../../../../components/utils/EditableCell";

export const briberyCorruptionColumns = (setFieldValue, rate) => {
  return [
    {
      Header: " ",
      accessor: "values",
    },

    {
      id: 1,
      Header: () => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          {/* (Turnover rate in current FY) */}
        </span>
      ),
      accessor: "1",
      Cell: EditableCell,
      type: "number",
      maxLength: "4",
    },
    {
      id: 2,
      Header: (props) => (
        <span>
          FY{" "}
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
            style={{ marginLeft: "20px !important" }}
            logInField
          />
          {/* (Turnover rate in previous FY) */}
        </span>
      ),
      accessor: "2",
      Cell: EditableCell,
      type: "number",
      maxLength: "4",
    },
  ];
};

export const briberyCorruptionCellData = [
  {
    id: "1",
    values: "Directors",
    currentRate: "",
    previousRate: "",
  },
  {
    id: "2",
    values: "KMPs",
    currentRate: "",
    previousRate: "",
  },
  {
    id: "3",
    values: "Employees ",
    currentRate: "",
    previousRate: "",
  },
  {
    id: "4",
    values: "Workers",
    currentRate: "",
    previousRate: "",
  },
];

export const briberyCorruptionInitialvalues = {
  tableData: briberyCorruptionCellData,
  current: "",
  previous: "",
  antiCorruption: "",
};

export const briberyCorruptionConstantDatas = [
  {
    id: 1,
    label:
      "4. Does the entity have an anti-corruption or anti-bribery policy? If yes, provide details in brief and if available, provide a web-link to the policy.",
    name: "antiCorruption",
  },
];
