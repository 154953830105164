import { EditableCell } from "../../../../components/utils/EditableCell";

export const intellectualPropertyColumns = [
  {
    Header: "S. No.",
    accessor: "sNo",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "Intellectual Property based on traditional knowledge",
    accessor: "traditional",
    Cell: EditableCell,
  },
  {
    Header: "Owned/ Acquired (Yes/No)",
    accessor: "owned",
    Cell: EditableCell,
  },
  {
    Header: "Benefit shared (Yes / No)",
    accessor: "benefit",
    Cell: EditableCell,
  },
  {
    Header: "Basis of calculating benefit share",
    accessor: "calculate",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const intellectualPropertyCellData = columnName.map((item) => ({
  id: item.id,
  sNo: "",
  traditional: "",
  owned: "",
  benefit: "",
  calculate: "",
}));
