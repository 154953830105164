import React, { useContext, useEffect, useState, useMemo } from "react";
import ElectronicFormContext from "../../../../Context";
import { TableWrapper } from "../../../reusable/index";
import {
  overviewEntityColumns,
  overviewEntityCellData,
  overviewEntityContents,
  SECTIONA_OVERVIEWENTITY,
  ROW_ADDINGTABLE,
} from "../../../../constant/index";
import { createTableValues, updateTableValues } from "../../../../api/method";

function OverviewEntity() {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(overviewEntityCellData);

  const overviewEntityFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_OVERVIEWENTITY
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    overviewEntityFormConfig
      ? updateTableValues(
          SECTIONA_OVERVIEWENTITY,
          { overviewEntity: `${jsonStr}` },
          overviewEntityFormConfig.id
        )
      : createTableValues(SECTIONA_OVERVIEWENTITY, {
          overviewEntity: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (overviewEntityFormConfig) {
      setParentData(JSON.parse(overviewEntityFormConfig.data.overviewEntity));
    }
  }, [overviewEntityFormConfig]);
  return (
    <TableWrapper
      columns={overviewEntityColumns}
      cellData={parentData}
      storeTableData={storeTableData}
      tableType={ROW_ADDINGTABLE}
      modalContent={overviewEntityContents}
      hideHelp={true}
      keyProps={"panel1"}
    />
  );
}

export default OverviewEntity;
