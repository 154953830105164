import { EditableCell } from "../../../components/utils/EditableCell";

export const DisclosureQuestionsDropdown = [
  { id: 1, name: "NA", acccessor: "name" },
  { id: 2, name: "SB", acccessor: "name" },
];

export const DisclosureQuestionsColumns = [
  {
    Header: "Disclosure Questions",
    accessor: "disclosureQuestions",
  },
  {
    Header: "P1 ",
    accessor: "p1",
    Cell: EditableCell,
  },
  {
    Header: "P2 ",
    accessor: "p2",
    Cell: EditableCell,
  },
  {
    Header: "P3 ",
    accessor: "p3",
    Cell: EditableCell,
  },
  {
    Header: "P4 ",
    accessor: "p4",
    Cell: EditableCell,
  },
  {
    Header: "P5 ",
    accessor: "p5",
    Cell: EditableCell,
  },
  {
    Header: "P6 ",
    accessor: "p6",
    Cell: EditableCell,
  },
  {
    Header: "P7 ",
    accessor: "p7",
    Cell: EditableCell,
  },
  {
    Header: `P8  `,
    accessor: "p8",
    Cell: EditableCell,
  },
  {
    Header: "P9 ",
    accessor: "p9",
    Cell: EditableCell,
  },
];

export const DisclosureQuestionsCellData = [
  {
    id: "1",
    values: "Policy and management processes",
    fullwidth: true,
  },
  {
    id: "2",
    disclosureQuestions:
      "1.  a. Whether your entity’s policy/policies cover each principle and its core elements of the NGRBCs. (Yes/No)",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
  },
  {
    id: "3",
    disclosureQuestions:
      "b. Has the policy been approved by the Board? (Yes/No)",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
  },
  {
    id: "4",
    disclosureQuestions: "c. Web Link of the Policies, if available",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
    dropdownRow: true,
  },
  {
    id: "5",
    disclosureQuestions:
      "2.  Whether the entity has translated the policy into procedures. (Yes / No)",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
  },
  {
    id: "6",
    disclosureQuestions:
      "3. Do the enlisted policies extend to your value chain partners? (Yes/No)",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
  },
  {
    id: "7",
    disclosureQuestions:
      "4. Name of the national and international codes/certifications/labels/ standards (e.g. Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustea) standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and mapped to each principle.",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
    dropdownRow: true,
  },
  {
    id: "8",
    disclosureQuestions:
      "5. Specific commitments, goals and targets set by the entity with defined timelines, if any.",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
    dropdownRow: true,
  },
  {
    id: "9",
    disclosureQuestions:
      "6. Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met.",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
    dropdownRow: true,
  },
];

export const DisclosureQuestionsInitialvalues = {
  tableData: DisclosureQuestionsCellData,
};

export const DisclosureQuestionsConstantData = [
  {
    id: 1,
    rowId: 4,
    label: "No.1C - P1",
    name: "cp1",
    accessor: "p1",
  },
  {
    id: 2,
    rowId: 4,
    label: "No.1C - P2",
    name: "cp2",
    accessor: "p2",
  },
  {
    id: 3,
    rowId: 4,

    label: "No.1C - P3",
    name: "cp3",
    accessor: "p3",
  },
  {
    id: 4,
    rowId: 4,
    label: "No.1C - P4",
    name: "cp4",
    accessor: "p4",
  },
  {
    id: 5,
    rowId: 4,
    label: "No.1C - P5",
    name: "cp5",
    accessor: "p5",
  },
  {
    id: 6,
    rowId: 4,
    label: "No.1C - P6",
    name: "cp6",
    accessor: "p6",
  },
  {
    id: 7,
    rowId: 4,
    label: "No.1C - P7",
    name: "cp7",
    accessor: "p7",
  },
  {
    id: 8,
    rowId: 4,
    label: "No.1C - P8",
    name: "cp8",
    accessor: "p8",
  },
  {
    id: 9,
    rowId: 4,
    label: "No.1C - P9",
    name: "cp9",
    accessor: "p9",
  },
  {
    id: 10,
    rowId: 7,
    label: "No.4 - P1",
    name: "4p1",
    accessor: "p1",
  },
  {
    id: 11,
    rowId: 7,
    label: "No.4 - P2",
    name: "4p2",
    accessor: "p2",
  },
  {
    id: 12,
    rowId: 7,
    label: "No.4 - P3",
    name: "4p3",
    accessor: "p3",
  },
  {
    id: 13,
    rowId: 7,
    label: "No.4 - P4",
    name: "4p4",
    accessor: "p4",
  },
  {
    id: 14,
    rowId: 7,
    label: "No.4 - P5",
    name: "4p5",
    accessor: "p5",
  },
  {
    id: 15,
    rowId: 7,
    label: "No.4 - P6",
    name: "4p6",
    accessor: "p6",
  },
  {
    id: 16,
    rowId: 7,
    label: "No.4 - P7",
    name: "4p7",
    accessor: "p7",
  },
  {
    id: 17,
    rowId: 7,
    label: "No.4 - P8",
    name: "4p8",
    accessor: "p8",
  },
  {
    id: 18,
    rowId: 7,
    label: "No.4 - P9",
    name: "4p9",
    accessor: "p9",
  },

  {
    id: 19,
    rowId: 8,
    label: "No.5 - P1",
    name: "5p1",
    accessor: "p1",
  },
  {
    id: 20,
    rowId: 8,
    label: "No.5 - P2",
    name: "5p2",
    accessor: "p2",
  },
  {
    id: 21,
    rowId: 8,
    label: "No.5 - P3",
    name: "5p3",
    accessor: "p3",
  },
  {
    id: 22,
    rowId: 8,
    label: "No.5 - P4",
    name: "5p4",
    accessor: "p4",
  },
  {
    id: 23,
    rowId: 8,
    label: "No.5 - P5",
    name: "5p5",
    accessor: "p5",
  },
  {
    id: 24,
    rowId: 8,
    label: "No.5 - P6",
    name: "5p6",
    accessor: "p6",
  },
  {
    id: 25,
    rowId: 8,
    label: "No.5 - P7",
    name: "5p7",
    accessor: "p7",
  },
  {
    id: 26,
    rowId: 8,
    label: "No.5 - P8",
    name: "5p8",
    accessor: "p8",
  },
  {
    id: 27,
    rowId: 8,
    label: "No.5 - P9",
    name: "5p9",
    accessor: "p9",
  },
  {
    id: 28,
    rowId: 9,
    label: "No.6 - P1",
    name: "6p1",
    accessor: "p1",
  },
  {
    id: 29,
    rowId: 9,
    label: "No.6 - P2",
    name: "6p2",
    accessor: "p2",
  },
  {
    id: 30,
    rowId: 9,
    label: "No.6 - P3",
    name: "6p3",
    accessor: "p3",
  },
  {
    id: 31,
    rowId: 9,
    label: "No.6 - P4",
    name: "6p4",
    accessor: "p4",
  },
  {
    id: 32,
    rowId: 9,
    label: "No.6 - P5",
    name: "6p5",
    accessor: "p5",
  },
  {
    id: 33,
    rowId: 9,
    label: "No.6 - P6",
    name: "6p6",
    accessor: "p6",
  },
  {
    id: 34,
    rowId: 9,
    label: "No.6 - P7",
    name: "6p7",
    accessor: "p7",
  },
  {
    id: 35,
    rowId: 9,
    label: "No.6 - P8",
    name: "6p8",
    accessor: "p8",
  },
  {
    id: 36,
    rowId: 9,
    label: "No.6 - P9",
    name: "6p9",
    accessor: "p9",
  },
];
