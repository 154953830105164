import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../../Context";
import { CSRdetailsStyles } from "../../../../../styles/index";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  CustomTextarea,
  CustomizedSnackbars,
} from "../../../../reusable/index";
import {
  accessibilityConstants,
  accessibilityInitialvalues,
  accessibilityContent,
  PRINCIPLE3_ACCESSIBILITY,
} from "../../../../../constant/index";
import { TableNavbar } from "../../../../reusable/TableNavbar";
import { CustomizedAccordions } from "../../../../reusable/Accordion";
import Cookies from "universal-cookie";
import CustomImageUpload from "../../../../reusable/CustomImageUpload";
function Accessibility() {
  const {
    list,
    root,
    rootAlign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
  } = CSRdetailsStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const accessibilityFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_ACCESSIBILITY
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    accessibilityFormConfig
      ? updateTableValues(
          PRINCIPLE3_ACCESSIBILITY,
          { accessibility: `${jsonStr}` },
          accessibilityFormConfig.id
        )
      : createTableValues(PRINCIPLE3_ACCESSIBILITY, {
          accessibility: `${jsonStr}`,
        });
  };

  //  fetch db data
  useEffect(() => {
    if (accessibilityFormConfig) {
      //setParentData(JSON.parse(accessibilityFormConfig.data.accessibility));
    }
  }, [accessibilityFormConfig]);

  //   snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // formik
  const onSubmit = (values) => {
    storeTableData(values);
    handleClickSnackBar();
  };

  const formik = useFormik({
    initialValues: parentData || accessibilityInitialvalues,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions
            keyProps={"panel3"}
            chileKeyPros={"principle3"}
          />
        </div>
        <div className={rootAlign}>
          {/* <Typography variant="h6">{accessibilityContent.title}</Typography> */}

          {accessibilityConstants.map(({ id, name, label, type, field, upload }) => (
            <div key={id}>
              <Typography variant="subtitle" className={list}>
                {label}
              </Typography>
              <CustomTextarea
                type={type}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name]}
                error={touched[name] && errors[name]}
                helperText={touched[name] && errors[name]}
                variant="outlined"
              />
               <div className="fileUplodeTab">
             {upload === "uploadImage" && (
               <CustomImageUpload />
             )}
            </div>
            </div>
          ))}

          <Box className={btnContainer}>
            <Button variant="outlined" color="primary" onClick={resetForm}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>

          <CustomizedSnackbars open={open} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export default Accessibility;
