export const ROUTE_PATHS = {
  LOGIN: "/",
  FORGETPASSWORD: "/forgetPassword",
  RESETPASSWORD: "/resetPassword",
  UPDATEPASSWORD: "/forget-password",

  DASHBOARD: "/dashboard",
  COMPANYUSERS: "/companyUsers",
  BRSRENTRY: "/brsrEntry",
  COMPANYUSERSFORM: "/companyUsersForm",
  INVOICES: "/invoices",
  PRINTPDF: "/printPdf",
  USER_FORM: "/companyUsersForm/userForm",
  COMPANY_USER_ACCESS: "/companyUsersForm/userAccess",

  PROFILE: "/profile",
  CHAHGE_PASSWORD: "/changePassword",
  EDIT_PROFILE: "/editProfile",

  //company user profile
  USER_PROFILE: "/userProfile",
  USER_CHAHGE_PASSWORD: "/userChangePassword",
  USER_EDIT_PROFILE: "/userEditProfile",

  //my users
  MY_USERS_LIST: "/myUsersList",
  MY_USERS_FORM: "/myUsers/MyUserForm",

  //customers

  CUSTOMERS_FORM: "/customersForm",
  CUSTOMERS_LIST: "/customersList",

  //invoice setup

  INVOICES_SETUP_FORM: "/invoiceSetupForm",
  INVOICES_SETUP_LIST: "/invoiceSetupList",

  //company profile

  COMPANY_PROFILE: "/companyProfile",
  COMPANY_EDIT_PROFILE: "/companyEditProfile",

  //super admin

  SUPER_ADMIN_DASHBOARD: "/adminDashboard",
  INVOICE_DASHBOARD: "/invoiceDashboard",

  //user entry
  USER_ENTRY: "/userEntry",
};
