import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const essentialIndicatorsP5Columns = (setFieldValue, rate) => {
  return [
    {
      id: 1,
      Header: "Category ",
      // accessor: "values",
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "values",
        },
      ],
    },
    {
      id: 2,
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      columns: [
        {
          Header: "Total (A)",
          accessor: "totalA",
          Cell: EditableCell,
          type: "number",
        },

        {
          Header: "No. of employees / workers covered (B)",
          accessor: "numberB",
          Cell: EditableCell,
          type: "number",
        },
        {
          Header: "% (B / A)",
          accessor: "percentageBA",
          Cell: EditableCell,
          type: "number",
          maxLength: 3,
        },
      ],
    },
    {
      id: 3,
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),

      columns: [
        {
          Header: "Total (C)",
          accessor: "totalC",
          Cell: EditableCell,
          type: "number",
        },

        {
          Header: "No. of employees / workers covered (D)",
          accessor: "numberD",
          Cell: EditableCell,
          type: "number",
        },
        {
          Header: "% (D / C)",
          accessor: "percentageDC",
          Cell: EditableCell,
          maxLength: 3,
          type: "number",
        },
      ],
    },
  ];
};

const columnName = [
  { id: 1, values: "Employees", fullwidth: true },
  { id: 2, values: "Permanent" },
  { id: 3, values: "Other than permanent" },
  { id: 4, values: "Total Employees " },
  { id: 5, values: "Workers", fullwidth: true },
  { id: 6, values: "Permanent" },
  { id: 7, values: "Other than permanent" },
  { id: 8, values: "Total Workers" },
];
export const essentialIndicatorsP5CellData = columnName.map((item) =>
  item.fullwidth
    ? {
        id: item.id,
        values: item.values,
        fullwidth: item.fullwidth,
      }
    : {
        id: item.id,
        values: item.values,
        totalA: "",
        numberB: "",
        percentageBA: "",
        totalC: "",
        numberD: "",
        percentageDC: "",
      }
);

export const essentialIndicatorsP5Initialvalues = {
  tableData: essentialIndicatorsP5CellData,
  current: "",
  previous: "",
};
