import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import { Modal, Typography } from "@mui/material";
import { CustomRadioButton, CustomTextarea } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { useFormik } from "formik";
import {
  remunerationSalaryColumns,
  remunerationSalaryInitialvalues,
  remunerationSalaryContent,
  STATIC_TABLE,
  remunerationSalaryConstantData,
  PRINCIPLE5_REMUNERATIONS_SALARY,
  remunerationSalaryOptions,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import CustomImageUpload from "../../../../reusable/CustomImageUpload";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
}));

const RemunerationSalary = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(remunerationSalaryInitialvalues);
  const { list } = useStyles();

  //to get data from api
  const remunerationSalaryFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE5_REMUNERATIONS_SALARY
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE5_REMUNERATIONS_SALARY,
        { remunerationSalary: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      remunerationSalaryFormConfig
        ? updateTableValues(
            PRINCIPLE5_REMUNERATIONS_SALARY,
            { remunerationSalary: `${jsonStr}` },
            remunerationSalaryFormConfig.id
          )
        : createTableValues(PRINCIPLE5_REMUNERATIONS_SALARY, {
            remunerationSalary: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      remunerationSalaryFormConfig &&
      remunerationSalaryFormConfig.data.remunerationSalary
    ) {
      setParentData(
        JSON.parse(remunerationSalaryFormConfig.data.remunerationSalary)
      );
    }
  }, [remunerationSalaryFormConfig]);

  const formik = useFormik({
    initialValues: parentData || remunerationSalaryInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, handleBlur } = formik;

  const renderFormFields = () => {
    return (
      <>
        {remunerationSalaryConstantData.map(
          ({ id, label, name, field, type, upload }) => (
            <div key={id}>
              <Typography variant="subtitle" className={list}>
                {label}
              </Typography>
              {field === "textField" && (
                <CustomTextarea
                  name={name}
                  onChange={handleChange}
                  value={values[name]}
                  type={type}
                  onBlur={handleBlur}
                  variant="outlined"
                />
              )}
              {field === "radioButton" && (
                <CustomRadioButton
                  name={name}
                  CSRdetailsOptions={remunerationSalaryOptions}
                  onChange={handleChange}
                  value={values[name]}
                />
              )}
              <div className="fileUplodeTab">
                {upload === "uploadImage" && <CustomImageUpload />}
              </div>
            </div>
          )
        )}
      </>
    );
  };
  console.log(remunerationSalaryFormConfig);

  return (
    <>
      <TableWrapper
        columns={remunerationSalaryColumns}
        storeTableData={storeTableData}
        cellData={
          remunerationSalaryFormConfig &&
          !remunerationSalaryFormConfig.data.remunerationSalary
            ? remunerationSalaryFormConfig.data
            : parentData.tableData
        }
        tableType={STATIC_TABLE}
        modalContent={remunerationSalaryContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle5"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={remunerationSalaryColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={remunerationSalaryContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default RemunerationSalary;
