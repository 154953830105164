import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../../Context";
import { CSRdetailsStyles } from "../../../../../styles/index";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  CustomTextField,
  CustomRadioButton,
  CustomizedSnackbars,
  TableNavbar,
} from "../../../../reusable/index";
import {
  marginalizedConstants,
  marginalizedInitialvalues,
  marginalizedContent,
  marginalizedOptions,
  PRINCIPLE8_MARGINALIZED,
} from "../../../../../constant/index";
import { CustomizedAccordions } from "../../../../reusable/Accordion";
import Cookies from "universal-cookie";

function Marginalized() {
  const {
    list,
    root,
    rootAlign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
  } = CSRdetailsStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const marginalizedFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE8_MARGINALIZED
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    marginalizedFormConfig
      ? updateTableValues(
          PRINCIPLE8_MARGINALIZED,
          { marginalized: `${jsonStr}` },
          marginalizedFormConfig.id
        )
      : createTableValues(PRINCIPLE8_MARGINALIZED, {
          marginalized: `${jsonStr}`,
        });
  };

  //  fetch db data
  useEffect(() => {
    if (marginalizedFormConfig && marginalizedFormConfig.data.marginalized) {
      setParentData(JSON.parse(marginalizedFormConfig.data.marginalized));
    }
  }, [marginalizedFormConfig]);

  //   snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // formik
  const onSubmit = (values) => {
    storeTableData(values);
    handleClickSnackBar();
  };

  const formik = useFormik({
    initialValues: parentData || marginalizedInitialvalues,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions
            keyProps={"panel3"}
            chileKeyPros={"principle8"}
          />
        </div>
        <div className={rootAlign}>
          <Typography variant="h6">{marginalizedContent.title}</Typography>
          <br />
          {marginalizedConstants.map(({ id, name, label, type, field }) => (
            <div key={id}>
              <Typography variant="subtitle" className={list}>
                {label}
              </Typography>
              {field === "textField" && (
                <CustomTextField
                  type={type}
                  name={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] && errors[name]}
                />
              )}
              {field === "radioButton" && (
                <CustomRadioButton
                  name={name}
                  CSRdetailsOptions={marginalizedOptions}
                  onChange={handleChange}
                  value={values[name]}
                />
              )}
            </div>
          ))}

          <Box className={btnContainer}>
            <Button variant="outlined" color="primary" onClick={resetForm}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>

          <CustomizedSnackbars open={open} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export default Marginalized;
