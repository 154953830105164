import { makeStyles } from "@mui/styles";

export const listedEntityStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    position: "absolute",
    top: "125px",
  },
  downloadRoot: {
    display: "flex",
    // position: "absolute",
    // top: "75px",
  },
  rootAlign: {
    padding: "20px 30px",
    width: "calc(100vw - 430px)",
    position: "relative",
    left: "350px",
    [theme.breakpoints.down("lg")]: {
      left: "300px",
      width: "calc(100vw - 380px)",
    },
  },
  dowloadRootALign: {
    padding: "20px 30px",
    boxSizing: "border-box",
  },

  title: {
    textDecoration: "underline",
    fontSize: "16px !important",
    marginBottom: "20px !important",
  },
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 50px 0 50px",
  },
  tableNavbarStyle: {
    position: "fixed",
    width: "100%",
    zIndex: 100,
  },
  accordionContainer: {
    position: "fixed",
    zIndex: 100,
    height: "calc(100vh - 128px)",
    width: "350px",
    overflowY: "scroll",
    borderRight: "2px solid #021256",
    backgroundColor: "white",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: "300px",
    },
  },
  accordionContainerAlign: {
    position: "fixed",
    zIndex: 100,
    height: "calc(100vh - 58px)",
    width: "350px",
    overflowY: "scroll",
    borderRight: "2px solid #021256",
    backgroundColor: "white",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
  },
}));
