import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../../Context";
import { CSRdetailsStyles } from "../../../../../styles/index";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  CustomTextField,
  CustomRadioButton,
  CustomizedSnackbars,
  TableNavbar,
} from "../../../../reusable/index";
import {
  leadershipIndicatorsP4Constants,
  leadershipIndicatorsP4Initialvalues,
  leadershipIndicatorsP4Options,
  leadershipIndicatorsP4Content,
  PRINCIPLE4_LEADERSHIP_INDICATORS,
} from "../../../../../constant/index";
import { CustomizedAccordions } from "../../../../reusable/Accordion";
import Cookies from "universal-cookie";

function LeadershipIndicatorsP4() {
  const {
    list,
    root,
    rootAlign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
  } = CSRdetailsStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const leadershipIndicatorsP4FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE4_LEADERSHIP_INDICATORS
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    leadershipIndicatorsP4FormConfig
      ? updateTableValues(
          PRINCIPLE4_LEADERSHIP_INDICATORS,
          { leadershipIndicatorsP4: `${jsonStr}` },
          leadershipIndicatorsP4FormConfig.id
        )
      : createTableValues(PRINCIPLE4_LEADERSHIP_INDICATORS, {
          leadershipIndicatorsP4: `${jsonStr}`,
        });
  };

  //  fetch db data
  useEffect(() => {
    if (leadershipIndicatorsP4FormConfig) {
      setParentData(
        JSON.parse(leadershipIndicatorsP4FormConfig.data.leadershipIndicatorsP4)
      );
    }
  }, [leadershipIndicatorsP4FormConfig]);

  //   snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // formik
  const onSubmit = (values) => {
    storeTableData(values);
    handleClickSnackBar();
  };

  const formik = useFormik({
    initialValues: parentData || leadershipIndicatorsP4Initialvalues,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions
            keyProps={"panel3"}
            chileKeyPros={"principle4"}
          />
        </div>
        <div className={rootAlign}>
          <Typography variant="h6">
            {leadershipIndicatorsP4Content.title}
          </Typography>
          <br />
          {leadershipIndicatorsP4Constants.map(
            ({ id, name, label, type, field }) => (
              <div key={id}>
                <Typography variant="subtitle" className={list}>
                  {label}
                </Typography>
                {field === "textField" && (
                  <CustomTextField
                    type={type}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                  />
                )}
                {field === "radioButton" && (
                  <CustomRadioButton
                    name={name}
                    CSRdetailsOptions={leadershipIndicatorsP4Options}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={touched[name] && errors[name]}
                    helperText={touched[name] && errors[name]}
                  />
                )}
              </div>
            )
          )}

          <Box className={btnContainer}>
            <Button variant="outlined" color="primary" onClick={resetForm}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>

          <CustomizedSnackbars open={open} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export default LeadershipIndicatorsP4;
