export const essentialIndicatorsContent = [
  {
    id: "1",
    title: "SECTION C: PRINCIPLE WISE PERFORMANCE DISCLOSURE",
    subTitle:
      "PRINCIPLE 1 Businesses should conduct and govern themselves with integrity, and in a manner that is Ethical, Transparent and Accountable.",
    aboutTable:
      "This section is aimed at helping entities demonstrate their performance in integrating the Principles and Core Elements with key processes and decisions. The information sought is categorized as “Essential” and “Leadership”. While the essential indicators are expected to be disclosed by every entity that is mandated to file this report, the leadership indicators may be voluntarily disclosed by entities which aspire to progress to a higher level in their quest to be socially, environmentally and ethically responsible.",
    subAboutTable:
      "1.  Percentage coverage by training and awareness programmes on any of the Principles during the financial year:",
  },
];
