export const managementPlanContent = {
  id: 1,
  title: "Leadership Indicators",
};

export const managementPlanInitialvalues = {
  complaints: "",
  scope: "",
  premise: "",
};

export const managementPlanConstants = [
  {
    id: 1,
    name: "complaints",
    label:
      "7. Does the entity have a business continuity and disaster management plan? Give details in 100 words/ web link.",
    field: "textField",
  },
  {
    id: 2,
    name: "scope",
    label:
      "8. Disclose any significant adverse impact to the environment, arising from the value chain of the entity. What mitigation or adaptation measures have been taken by the entity in this regard.",
    field: "textField",
  },
  {
    id: 3,
    name: "premise",
    label:
      "9. Percentage of value chain partners (by value of business done with such partners) that were assessed for environmental impacts.",
    field: "textField",
  },
];
