import * as Tables from "../components/pages/tables/sectionA/Index";

export const sectionA = [
  {
    path: "/sectionA/listedEntity",
    component: <Tables.ListedEntity />,
  },
  {
    path: "/sectionA/businessActivities",
    component: <Tables.BusinessActivities />,
  },
  {
    path: "/sectionA/productServicesByEntity",
    component: <Tables.ProductServicesByEntity />,
  },
  {
    path: "/sectionA/numberOfLocations",
    component: <Tables.NumberOfLocations />,
  },
  {
    path: "/sectionA/marketsServedEntity",
    component: <Tables.MarkesServedEntity />,
  },
  {
    path: "/sectionA/employeesAndWorkers",
    component: <Tables.EmployeesAndWorkers />,
  },
  {
    path: "/sectionA/differentAbled",
    component: <Tables.DifferentAbled />,
  },
  {
    path: "/sectionA/participationOfWomen",
    component: <Tables.ParticipationOfWomen />,
  },
  {
    path: "/sectionA/turnOverRate",
    component: <Tables.TurnOverRate />,
  },
  {
    path: "/sectionA/jointVentures",
    component: <Tables.JointVentures />,
  },
  {
    path: "/sectionA/CSRdetails",
    component: <Tables.CSRdetails />,
  },
  {
    path: "/sectionA/transparencyAndDisclosures",
    component: <Tables.TransparencyAndDisclosures />,
  },
  {
    path: "/sectionA/overviewEntity",
    component: <Tables.OverviewEntity />,
  },
];




