import * as React from "react";
import { makeStyles } from "@mui/styles";

const MIN_TEXTAREA_HEIGHT = 50;
const PDF_TEXTAREA = 20;
const regex = /^[0-9\b]+$/;

const useStyles = makeStyles((theme) => ({
  textarea: {
    minHeight: `${MIN_TEXTAREA_HEIGHT}px`,
    // minWidth: "7vw",
    width: "100%",
    wordWrap: "anywhere",
    outline: "none",
    resize: "none",
    border: 0,
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    fontWeight: 500,
    fontSize: "1rem",
    letterSpacing: "0.01071em",
    color: "rgba(0, 0, 0, 0.87)",
  },
  pdfTextarea: {
    minHeight: `${PDF_TEXTAREA}px`,
    width: "100%",
    wordWrap: "anywhere",
    outline: "none",
    resize: "none",
    border: 0,
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    fontWeight: 500,
    fontSize: "17px",
    letterSpacing: "0.01071em",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "white",
  },
}));

export const EditableCell = (props) => {
  const {
    value: initialValue,
    row: { index },
    column: { id },
    isView,
    updateData,
  } = props;

  const { type, maxLength } = props.column;
  const { textarea, pdfTextarea } = useStyles();

  const [value, setValue] = React.useState(initialValue);
  const textareaRef = React.useRef(null);
  const textareaRefPdf = React.useRef(null);

  const onChange = (e) => {
    if (type === "number") {
      if (e.target.value === "" || regex.test(e.target.value)) {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }
  };

  const onBlur = () => {
    updateData(index, id, value);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useLayoutEffect(() => {
    if (!isView) {
      textareaRef.current.style.height = "inherit";
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    }

    // textareaRefPdf.current.style.height = "inherit";
    // textareaRefPdf.current.style.height = `${Math.max(
    //   textareaRefPdf.current.scrollHeight,
    //   PDF_TEXTAREA
    // )}px`;
  }, [value, isView]);

  if (isView) {
    return (
      // <textarea className={pdfTextarea} disabled rows={1} ref={textareaRefPdf}>
      //   {value}
      // </textarea>
      <div
        style={{
          minHeight: 20,
          minWidth: 25,
          overflowWrap: "break-word",
          wordBreak: "break-word",
        }}
      >
        {value}
      </div>
    );
  } else {
    return (
      <textarea
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={textareaRef}
        className={textarea}
        maxLength={maxLength}
        style={type === "number" ? { textAlign: "center" } : {}}
      />
    );
  }
};
