import { CustomTextField } from "../../../../components/reusable/index";
import { EditableCell } from "../../../../components/utils/EditableCell";

export const retirementBenefitsColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Benefits",
      // accessor: "benefits",
      id: 1,
      rowspan: 2,
      columns: [
        {
          Header: " ",
          hideHeader: true,
          accessor: "benefits",
        },
      ],
    },

    {
      id: 2,
      Header: () => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      columns: [
        {
          Header: "No. of employees covered as a % of total employees ",
          accessor: "currentTotalEmployee",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "No. of workers covered as a % of total workers",
          accessor: "currentTotalWorkers",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Deducted and deposited with the authority (Y/N/N.A.)",
          accessor: "currentAuthority",
          Cell: EditableCell,
        },
      ],
    },
    {
      id: 3,
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      columns: [
        {
          Header: "No. of employees covered as a % of total employees",
          accessor: "previousTotalEmployee",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "No. of workers covered as a % of total workers",
          accessor: "previousTotalWorkers",
          type: "number",
          Cell: EditableCell,
        },
        {
          Header: "Deducted and deposited with the authority (Y/N/N.A.)",
          accessor: "previousAuthority",
          Cell: EditableCell,
        },
      ],
    },
  ];
};

const columnName = [
  { id: 1, values: "PF" },
  { id: 2, values: "Gratuity" },
  { id: 3, values: "ESI" },
  { id: 4, values: "Others – please specify" },
];
export const retirementBenefitsCellData = columnName.map((item) => ({
  id: item.id,
  benefits: item.values,
  currentTotalEmployee: "",
  currentTotalWorkers: "",
  currentAuthority: "",
  previousTotalEmployee: "",
  previousTotalWorkers: "",
  previousAuthority: "",
}));

export const retirementBenefitsInitialvalues = {
  tableData: retirementBenefitsCellData,
  current: "",
  previous: "",
};
