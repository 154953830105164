import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  complaintsColumns,
  complaintsInitialvalues,
  complaintsContent,
  complaintsConstantData,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE3_COMPLAINTS,
} from "../../../../../constant/index";
import { TableWrapper, CustomTextarea } from "../../../../reusable/index";
import { useFormik } from "formik";
import { Modal, Typography } from "@mui/material";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const Complaints = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(complaintsInitialvalues);

  const formik = useFormik({
    initialValues: parentData || complaintsInitialvalues,
    enableReinitialize: true,
  });
  const { values, handleChange, errors, touched, handleBlur, setFieldValue } =
    formik;

  //to get data from api
  const complaintsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_COMPLAINTS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE3_COMPLAINTS,
        { complaints: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      complaintsFormConfig
        ? updateTableValues(
            PRINCIPLE3_COMPLAINTS,
            { complaints: `${jsonStr}` },
            complaintsFormConfig.id
          )
        : createTableValues(PRINCIPLE3_COMPLAINTS, {
            complaints: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (complaintsFormConfig && complaintsFormConfig.data.complaints) {
      //setParentData(JSON.parse(complaintsFormConfig.data.complaints));
    }
  }, [complaintsFormConfig]);

  const renderFormFields = (tableData) => {
    return (
      <>
        {complaintsConstantData.map(
          ({ id, rowId, label, name, accessor, type }) => {
            let filterData = tableData?.filter((item) => {
              return parseInt(item.id) === rowId;
            });
            // return (
            //   filterData &&
            //   filterData[0][accessor] === "SB" && (
            //     <div key={id} style={{ margin: 8 }}>
            //       <Typography variant="subtitle" style={{ display: "flex" }}>
            //         {label}

            //         <CustomTextarea
            //           name={name}
            //           onChange={handleChange}
            //           onBlur={handleBlur}
            //           value={values[name]}
            //           error={touched[name] && errors[name]}
            //           helperText={touched[name] && errors[name]}
            //           variant="outlined"
            //           style={{
            //             width: "75%",
            //             background: "white",
            //             paddingBottom: "0px ",
            //           }}
            //         />
            //       </Typography>
            //     </div>
            //   )
            // );
          }
        )}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={complaintsColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          complaintsFormConfig && !complaintsFormConfig.data.complaints
            ? complaintsFormConfig.data
            : parentData.tableData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        modalContent={complaintsContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle3"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={complaintsColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={complaintsContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default Complaints;
