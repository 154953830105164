import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const waterWithdrawalOptions = ["Yes", "No"];

export const waterWithdrawalColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Parameter",
      accessor: "values",
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
  ];
};

const columnName = [
  {
    id: 1,
    values: "Water withdrawal by source (in kilolitres)",
    fullwidth: true,
  },
  { id: 2, values: "(i) Surface water" },
  { id: 3, values: "(ii) Groundwater" },
  { id: 4, values: "(iii) Third party water" },
  { id: 5, values: "(iv) Seawater / desalinated water" },
  { id: 6, values: "(v) Others " },
  { id: 7, values: "Total volume of water withdrawal (in kilolitres)" },
  { id: 8, values: "Total volume of water consumption (in kilolitres) " },
  {
    id: 9,
    values: "Water intensity per rupee of turnover (Water consumed / turnover)",
  },
  {
    id: 10,
    values:
      "Water intensity (optional) – the relevant metric may be selected by the entity",
  },
  {
    id: 11,
    values:
      "Water discharge by destination and level of treatment (in kilolitres)",
    fullwidth: true,
  },
  { id: 12, values: "(i) Into Surface water" },
  { id: 13, values: "- No treatment" },
  { id: 14, values: "- With treatment – please specify level of treatment" },
  { id: 15, values: "(ii) Into Groundwater" },
  {
    id: 16,
    values: "- No treatment",
  },
  {
    id: 17,
    values: "- With treatment – please specify level of treatment",
  },
  {
    id: 18,
    values: "(iii) Into Seawater",
  },
  {
    id: 19,
    values: "- No treatment",
  },
  {
    id: 20,
    values: "- With treatment – please specify level of treatment",
  },
  {
    id: 21,
    values: "(iv) Sent to third-parties ",
  },
  {
    id: 22,
    values: "- No treatment",
  },
  {
    id: 23,
    values: "- With treatment – please specify level of treatment",
  },
  {
    id: 24,
    values: "(v) Others",
  },
  {
    id: 25,
    values: "- No treatment",
  },
  {
    id: 26,
    values: "- With treatment – please specify level of treatment",
  },
  {
    id: 27,
    values: "Total water discharged (in kilolitres) ",
  },
];
export const waterWithdrawalCellData = columnName.map((item) =>
  item.fullwidth
    ? {
        id: item.id,
        values: item.values,
        fullwidth: item.fullwidth,
      }
    : {
        id: item.id,
        values: item.values,
        currentYear: "",
        previousYear: "",
      }
);

export const waterWithdrawalInitialvalues = {
  tableData: waterWithdrawalCellData,
  independent: "",
  current: "",
  previous: "",
};

export const waterWithdrawalConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency.",
    field: "radioButton",
  },
];
