import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const essentialIndicatorsP6Options = ["Yes", "No"];

export const essentialIndicatorsP6Columns = (setFieldValue, rate) => {
  return [
    {
      Header: "Parameter",
      accessor: "values",
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate.current}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      type: "number",
      Cell: EditableCell,
      maxLength: 4,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate.previous}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      type: "number",
      Cell: EditableCell,
      maxLength: 4,
    },
  ];
};

const columnName = [
  { id: 1, values: "Total electricity consumption (A)" },
  { id: 2, values: "Total fuel consumption (B)" },
  { id: 3, values: "Energy consumption through other sources (C)" },
  { id: 4, values: "Total energy consumption (A+B+C)" },
  {
    id: 5,
    values:
      "Energy intensity per rupee of turnover (Total energy consumption/ turnover in rupees)",
  },
  {
    id: 6,
    values:
      "Energy intensity (optional) – the relevant metric may be selected by the entity",
  },
];
export const essentialIndicatorsP6CellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  currentYear: "",
  previousYear: "",
}));

export const essentialIndicatorsP6Initialvalues = {
  tableData: essentialIndicatorsP6CellData,
  independent: "",
  name: "",
  scheme: "",
  PAT: "",
  current: "",
  previous: "",
};

export const essentialIndicatorsP6ConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) ",
    field: "radioButton",
  },
  {
    id: 2,
    name: "name",
    label: "If yes, name of the external agency.",
    field: "textField",
  },
  {
    id: 3,
    name: "scheme",
    label:
      "2. Does the entity have any sites / facilities identified as designated consumers (DCs) under the Performance, Achieve and Trade (PAT) Scheme of the Government of India? (Y/N)",
    field: "radioButton",
  },
  {
    id: 4,
    name: "PAT",
    label:
      "If yes, disclose whether targets set under the PAT scheme have been achieved. In case targets have not been achieved, provide the remedial action taken, if any",
    field: "textField",
  },
];
