export const questionsContents = [
  {
    id: "1",
    title: " SECTION B: Questions",
    subTitle: "Questions",
    subAboutTable:
      "Response:  Yes (Y), No (N), NA (Not applicable) and SB (See below) ",

    aboutTable:
      "12. If answer to question (1) above is “No” i.e. not all Principles are covered by a policy, reasons to be stated ",
  },
];
