import DropdownField from "../../../../components/reusable/DropDownField";
import { EditableCell } from "../../../../components/utils/EditableCell";

export const environmentalAssessmentsDropdown = [
  { id: 1, name: "NA", acccessor: "name" },
  { id: 2, name: "SB", acccessor: "name" },
];

export const environmentalAssessmentsColumns = (isView) => [
  {
    Header: "Name and brief details of project",
    accessor: "name",
    Cell: (props) => {
      const { updateData, row, cell } = props;
      return isView ? (
        <div>{cell.value}</div>
      ) : (
        <DropdownField
          inputValues={environmentalAssessmentsDropdown || []}
          name={cell.column.id}
          fieldStyle={{ margin: 0, padding: 4 }}
          onChange={(e) =>
            updateData(row.index, cell.column.id, e.target.value)
          }
          value={cell.value}
        />
      );
    },
  },
  {
    Header: "EIA Notification No.",
    accessor: "number",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "Date ",
    accessor: "date",
    Cell: EditableCell,
  },
  {
    Header: "Whether conducted by independent external agency (Yes / No)",
    accessor: "agency",
    Cell: EditableCell,
  },
  {
    Header: "Results communicated in public domain (Yes / No)",
    accessor: "domain",
    Cell: EditableCell,
  },
  {
    Header: "Relevant Web link",
    accessor: "webLink",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const environmentalAssessmentsCellData = columnName.map((item) => ({
  id: item.id,
  name: "",
  number: "",
  date: "",
  agency: "",
  domain: "",
  webLink: "",
}));

export const environmentalAssessmentsInitialvalues = {
  tableData: environmentalAssessmentsCellData,
};

export const environmentalAssessmentsConstantData = [
  {
    id: 1,
    rowId: 1,
    label: " Brief 1:",
    name: "Brief1",
    accessor: "name",
  },
  {
    id: 2,
    rowId: 2,
    label: " Brief 2:",
    name: "Brief2",
    accessor: "name",
  },
];
