import { EditableCell } from "../../../../components/utils/EditableCell";

export const rehabilitationColumns = [
  {
    Header: "S. No.",
    accessor: "sNo",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "Name of Project for which R&R is ongoing ",
    accessor: "name",
    Cell: EditableCell,
  },
  {
    Header: "State",
    accessor: "state",
    Cell: EditableCell,
  },
  {
    Header: "District",
    accessor: "district",
    Cell: EditableCell,
  },
  {
    Header: "No. of Project Affected Families (PAFs)",
    accessor: "project",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "% of PAFs covered by R&R ",
    accessor: "pafs",
    Cell: EditableCell,
    type: "number",
    maxLength: 3,
  },
  {
    Header: "Amounts paid to PAFs in the FY (In INR)",
    accessor: "amounts",
    Cell: EditableCell,
    type: "number",
  },
];

const columnName = [{ id: 1 }, { id: 2 }];
export const rehabilitationCellData = columnName.map((item) => ({
  id: item.id,
  sNo: "",
  name: "",
  state: "",
  district: "",
  project: "",
  pafs: "",
  amounts: "",
}));

export const rehabilitationInitialvalues = {
  tableData: rehabilitationCellData,
  mechanisms: "",
};

export const rehabilitationConstantData = [
  {
    id: 1,
    name: "mechanisms",
    label:
      "3. Describe the mechanisms to receive and redress grievances of the community. ",
    field: "textField",
  },
];
