export const assessmentsContent = [
  {
    id: "1",
    title:
      "PRINCIPLE 2: Businesses should provide goods and services in a manner that is sustainable and safe",
    aboutTable: "Leadership Indicators",
    subAboutTable:
      "1. Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for manufacturing industry) or for its services (for service industry)? If yes, provide details in the following format?",
  },
];
