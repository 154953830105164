import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Modal, Typography } from "@mui/material";
import { CustomTextarea } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  relatedIncidentsColumns,
  relatedIncidentsInitialvalues,
  relatedIncidentsConstantData,
  relatedIncidentsContent,
  ROW_GROUPING_TABLE,
  PRINCIPLE3_RELATED_INCIDENTS,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
  contentStyle: {
    marginTop: "20px",
  },
}));

const RelatedIncidents = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(relatedIncidentsInitialvalues);
  const { list } = useStyles();

  //to get data from api
  const relatedIncidentsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_RELATED_INCIDENTS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE3_RELATED_INCIDENTS,
        { relatedIncidents: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      relatedIncidentsFormConfig
        ? updateTableValues(
            PRINCIPLE3_RELATED_INCIDENTS,
            { relatedIncidents: `${jsonStr}` },
            relatedIncidentsFormConfig.id
          )
        : createTableValues(PRINCIPLE3_RELATED_INCIDENTS, {
            relatedIncidents: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      relatedIncidentsFormConfig &&
      relatedIncidentsFormConfig.data.relatedIncidents
    ) {
      setParentData(
        JSON.parse(relatedIncidentsFormConfig.data.relatedIncidents)
      );
    }
  }, [relatedIncidentsFormConfig]);

  const formik = useFormik({
    initialValues: parentData || relatedIncidentsInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, setFieldValue, handleBlur } = formik;

  const renderFormFields = () => {
    return (
      <>
        {relatedIncidentsConstantData.map(
          ({ id, label, name, field, type }) => (
            <div key={id}>
              <Typography variant="subtitle" className={list}>
                {label}
              </Typography>
              {field === "textField" && (
                <CustomTextarea
                  name={name}
                  onChange={handleChange}
                  value={values[name]}
                  type={type}
                  onBlur={handleBlur}
                  variant="outlined"
                />
              )}
            </div>
          )
        )}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={relatedIncidentsColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          relatedIncidentsFormConfig &&
          !relatedIncidentsFormConfig.data.relatedIncidents
            ? relatedIncidentsFormConfig.data
            : parentData.tableData
        }
        tableType={ROW_GROUPING_TABLE}
        modalContent={relatedIncidentsContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle3"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={relatedIncidentsColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_GROUPING_TABLE}
            modalContent={relatedIncidentsContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default RelatedIncidents;
