import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  workersCoveredColumns,
  workersCoveredCellData,
  workersCoveredContent,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE3_WORKERSCOVERED,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const WorkersCovered = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(workersCoveredCellData);

  //to get data from api
  const workersCoveredFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_WORKERSCOVERED
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE3_WORKERSCOVERED,
        { workersCovered: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      workersCoveredFormConfig
        ? updateTableValues(
            PRINCIPLE3_WORKERSCOVERED,
            { workersCovered: `${jsonStr}` },
            workersCoveredFormConfig.id
          )
        : createTableValues(PRINCIPLE3_WORKERSCOVERED, {
            workersCovered: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      workersCoveredFormConfig &&
      workersCoveredFormConfig.data.workersCovered
    ) {
      setParentData(JSON.parse(workersCoveredFormConfig.data.workersCovered));
    }
  }, [workersCoveredFormConfig]);

  return (
    <>
      <TableWrapper
        columns={workersCoveredColumns}
        storeTableData={storeTableData}
        cellData={
          workersCoveredFormConfig &&
          !workersCoveredFormConfig.data.workersCovered
            ? workersCoveredFormConfig.data
            : parentData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        modalContent={workersCoveredContent}
        keyProps={"panel3"}
        chileKeyPros={"principle3"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={workersCoveredColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={workersCoveredContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default WorkersCovered;
