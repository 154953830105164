import { makeStyles } from "@mui/styles";

export const accordionStyles = makeStyles((theme) => ({

  toggleBtn: {
    borderRadius: "5px !important",
    border: "1px solid #1976d2 !important",
    flexGrow: "1"
  },
  accordionContainer: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  acitveToggleBtn: {
    backgroundColor: "#3ca2f3 !important",
    color: "white !important",
    borderRadius: "5px !important",
    border: "1px solid #1976d2 !important",
    flexGrow: "1"
  },
  buttonCheckWrap: {
    display: "flex"
  }

}));
