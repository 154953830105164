import React, { useContext, useEffect, useState, useMemo } from "react";
import ElectronicFormContext from "../../../../Context";
import { TableWrapper } from "../../../reusable/index";
import {
  turnOverRateColumns,
  turnOverRateInitialvalues,
  turnOverRateContents,
  SECTIONA_TURNOVERRATE,
  STATIC_TABLE,
} from "../../../../constant/index";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../api/method";
import { useFormik } from "formik";
import Cookies from "universal-cookie";
import Context from "../../../../context/Context";
import { Modal } from "@mui/material";

function TurnOverRate() {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const [parentData, setParentData] = useState(turnOverRateInitialvalues);
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const formik = useFormik({
    initialValues: parentData || turnOverRateInitialvalues,
    enableReinitialize: true,
  });

  const { values, setFieldValue } = formik;

  const turnoverFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_TURNOVERRATE
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        SECTIONA_TURNOVERRATE,
        { turnOverRate: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      turnoverFormConfig
        ? updateTableValues(
            SECTIONA_TURNOVERRATE,
            { turnOverRate: `${jsonStr}` },
            turnoverFormConfig.id
          )
        : createTableValues(SECTIONA_TURNOVERRATE, {
            turnOverRate: `${jsonStr}`,
          });
    }
  };

  let turnOverRateDataWithUserId =
    existingTableData &&
    existingTableData.filter(
      (data) => data.section.split("/").join("").slice(8) === "turnOverRate"
    );
  turnOverRateDataWithUserId =
    turnOverRateDataWithUserId &&
    turnOverRateDataWithUserId.map((data) => data.data).flat();

  let forDownLoadFile =
    existingTableData &&
    existingTableData.filter(
      (data) => data.section.split("/").join("").slice(8) === "turnOverRate"
    );
  forDownLoadFile =
    forDownLoadFile && forDownLoadFile.map((data) => data.document_link);

  let newDataForHeader =
    turnOverRateDataWithUserId &&
    turnOverRateDataWithUserId.map((data) => JSON.parse(data.turnOverRate));
  console.log(newDataForHeader);
  //   fetch db data
  useEffect(() => {
    if (turnoverFormConfig && turnOverRateDataWithUserId.length <= 1) {
      setParentData(JSON.parse(turnoverFormConfig.data.turnOverRate));
    }
  }, [turnoverFormConfig]);

  return (
    <>
      {turnOverRateDataWithUserId && turnOverRateDataWithUserId.length > 1 ? (
        <TableWrapper
          columns={turnOverRateColumns(setFieldValue, values)}
          cellData={turnOverRateDataWithUserId}
          storeTableData={storeTableData}
          tableType={STATIC_TABLE}
          modalContent={turnOverRateContents}
          keyProps={"panel1"}
          cloneActive={true}
          fileUplode={true}
        />
      ) : (
        <TableWrapper
          columns={turnOverRateColumns(setFieldValue, values)}
          cellData={parentData.tableData}
          storeTableData={storeTableData}
          tableType={STATIC_TABLE}
          modalContent={turnOverRateContents}
          keyProps={"panel1"}
          cloneActive={true}
          fileUplode={true}
          fileDownload={forDownLoadFile}
        />
      )}
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={turnOverRateColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={turnOverRateContents}
          />
        </div>
      </Modal>
    </>
  );
}

export default TurnOverRate;
