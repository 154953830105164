import React, { useContext, useEffect, useState, useMemo } from "react";
import ElectronicFormContext from "../../../../Context";
import { createTableValues, updateTableValues } from "../../../../api/method";
import { TableWrapper } from "../../../reusable/index";

import {
  SECTIONA_JOINTVENTURES,
  ROW_ADDINGTABLE,
  jointVenturesColumns,
  jointVenturesCellData,
  jointVenturesContents
} from "../../../../constant/index";
import Cookies from "universal-cookie";

function JointVentures() {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(jointVenturesCellData);
 // const [latestData, setLatestData] = useState([]);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  console.log(parentData);
  const jointVenturesFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_JOINTVENTURES
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify(tableData);
    console.log(jsonStr);
    jointVenturesFormConfig
      ? updateTableValues(
          SECTIONA_JOINTVENTURES,
          { jointVentures: `${jsonStr}` },
          jointVenturesFormConfig.id
        )
      : createTableValues(SECTIONA_JOINTVENTURES, {
          jointVentures: `${jsonStr}`,
        });
  };   
  //  fetch db data
  useEffect(() => {
    if (jointVenturesFormConfig) {
      console.log(jointVenturesFormConfig.data.jointVentures, "join event");
      // let joinventure = jointVenturesFormConfig.data.jointVentures;
         setParentData(JSON.parse(jointVenturesFormConfig.data.jointVentures));
      // console.log(JSON.parse(jointVenturesFormConfig.data.jointVentures));
    }  
  }, [jointVenturesFormConfig]);
  return (
    <TableWrapper
      columns={jointVenturesColumns}
      cellData={parentData}
      storeTableData={storeTableData}
      tableType={ROW_ADDINGTABLE}
      modalContent={jointVenturesContents}
      keyProps={"panel1"}
    />
  );
}

export default JointVentures;
