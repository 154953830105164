import { EditableCell } from "../../../../components/utils/EditableCell";

export const instancesOptions = ["Yes", "No"];

export const instancesColumns = [
  {
    Header: "",
    accessor: "values",
  },
  {
    Header: "Number",
    accessor: "number",
    type: "number",
    Cell: EditableCell,
  },
  {
    Header: "Reasons for recall",
    accessor: "reasons",
    Cell: EditableCell,
  },
];

const columnName = [
  { id: 1, values: "Voluntary recalls" },
  { id: 2, values: "Forced recalls" },
];
export const instancesCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  currentYear: "",
  previousYear: "",
}));

export const instancesInitialvalues = {
  tableData: instancesCellData,
  independent: "",
  scheme: "",
};

export const instancesConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "5. Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If available, provide a web-link of the policy.",
    field: "radioButton",
  },
  {
    id: 2,
    name: "scheme",
    label:
      "6. Provide details of any corrective actions taken or underway on issues relating to advertising, and delivery of essential services; cyber security and data privacy of customers; re-occurrence of instances of product recalls; penalty / action taken by regulatory authorities on safety of products / services.",
    field: "textField",
  },
];
