import * as React from "react";
import { Drawer } from "@mui/material";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { CustomizedAccordions } from "./index";
import { navbarContent } from "../../constant/index";

export function TableNavbar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {navbarContent.projectTitle}
          </Typography>
        </Toolbar>
      </AppBar>

      <div>
        {["left", "right", "top", "bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer("left", false)}
            >
              <CustomizedAccordions toggleDrawer={toggleDrawer} />
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </Box>
  );
}
