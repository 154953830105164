import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ElectronicFormContext from "../../../../Context";
import {
  marketsServedEntityColumns,
  marketsInitialvalues,
  marketsServedConstantDatas,
  STATIC_TABLE,
  SECTIONA_MARKETSSERVEDENTITY,
  marketsServedEntityContents,
} from "../../../../constant/index";
import { CustomTextField, TableWrapper } from "../../../reusable/index";
import { createTableValues, updateTableValues } from "../../../../api/method";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

function MarkesServedEntity() {
  const { list } = useStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(marketsInitialvalues);

  // formik
  const formik = useFormik({
    initialValues: parentData || marketsInitialvalues,
    enableReinitialize: true,
  });
  const { values, handleChange, errors, touched, handleBlur, resetForm } =
    formik;

  const marketsServedFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIONA_MARKETSSERVEDENTITY
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    marketsServedFormConfig
      ? updateTableValues(
          SECTIONA_MARKETSSERVEDENTITY,
          { marketsServedEntity: `${jsonStr}` },
          marketsServedFormConfig.id
        )
      : createTableValues(SECTIONA_MARKETSSERVEDENTITY, {
          marketsServedEntity: `${jsonStr}`,
        });
  };
  //  fetch previous data
  useEffect(() => {
    if (marketsServedFormConfig) {
      setParentData(
        JSON.parse(marketsServedFormConfig.data.marketsServedEntity)
      );
    }
  }, [marketsServedFormConfig]);

  const renderFormFields = () => {
    return (
      <>
        {marketsServedConstantDatas.map(({ id, label, name }) => (
          <div key={id}>
            <Typography variant="subtitle" className={list}>
              {label}

              <CustomTextField
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name]}
                error={touched[name] && errors[name]}
                helperText={touched[name] && errors[name]}
                variant="outlined"
                logInField
                style={{
                  width: "350px",
                  background: "white",
                  paddingBottom: "0px ",
                }}
              />
            </Typography>
          </div>
        ))}
      </>
    );
  };

  return (
    <TableWrapper
      columns={marketsServedEntityColumns}
      cellData={parentData.tableData}
      storeTableData={storeTableData}
      tableType={STATIC_TABLE}
      renderFormFields={renderFormFields}
      renderFormFieldBottom={true}
      resetForm={resetForm}
      modalContent={marketsServedEntityContents}
      keyProps={"panel1"}
    />
  );
}

export default MarkesServedEntity;
