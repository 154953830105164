import { EditableCell } from "../../../../components/utils/EditableCell";

export const nonMonetaryColumns = [
  {
    Header: "",
    accessor: "values",
  },
  {
    Header: "NGRBC Principle",
    accessor: "NGRBCPrinciple",
    Cell: EditableCell,
  },
  {
    Header:
      "Name of the regulatory/ enforcement agencies/ judicial institutions",
    accessor: "institutions",
    Cell: EditableCell,
  },
  // {
  //   Header: "Amount (In INR)",
  //   accessor: "amount",
  //   Cell: EditableCell,
  //   type: "number",
  // },
  {
    Header: "Brief of the Case ",
    accessor: "briefCase",
    Cell: EditableCell,
  },
  {
    Header: "Has an appeal been preferred? (Yes/No)",
    accessor: "appeal",
    Cell: EditableCell,
  },
];

export const nonMonetaryCellData = [
  {
    id: "1",
    values: "Imprisonment",
    NGRBCPrinciple: "",
    institutions: "",
    // amount: "",
    briefCase: "",
    appeal: "",
  },
  {
    id: "2",
    values: "Punishment",
    NGRBCPrinciple: "",
    institutions: "",
    // amount: "",
    briefCase: "",
    appeal: "",
  },
];

export const nonMonetaryInitialvalues = {
  tableData: nonMonetaryCellData,
  Imprisonment: "",
  Punishment: "",
};

export const nonMonetaryConstantData = [
  {
    id: 1,
    label: "Imprisonment Case Brief:",
    name: "fine",
    accessor: "fine",
  },
  {
    id: 2,
    label: "Punishment Case Brief:",
    name: "settlement",
    accessor: "settlement",
  },
];
