import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const relatedIncidentsColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Safety Incident/Number",
      accessor: "values",
      enableRowSpan: true,
    },
    {
      Header: "Category ",
      accessor: "category",
    },

    {
      Header: () => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      Cell: EditableCell,
      type: "number",
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      Cell: EditableCell,
      type: "number",
    },
  ];
};

export const relatedIncidentsCellData = [
  {
    id: "1",
    values:
      "Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)",
    category: "Employees",
    currentYear: "",
    previousYear: "",
    span: true,
  },
  {
    id: "2",
    category: "Workers",
    currentYear: "",
    previousYear: "",
  },
  {
    id: "3",
    values: "Total recordable work-related injuries",
    category: "Employees",
    currentYear: "",
    previousYear: "",
  },
  {
    id: "4",
    category: "Workers",
    currentYear: "",
    previousYear: "",
  },
  {
    id: "5",
    values: "No. of fatalities ",
    category: "Employees",
    currentYear: "",
    previousYear: "",
  },
  {
    id: "6",
    category: "Workers",
    currentYear: "",
    previousYear: "",
  },
  {
    id: "7",
    values:
      "High consequence work-related injury or ill-health (excluding fatalities)",
    category: "Employees",
    currentYear: "",
    previousYear: "",
  },
  {
    id: "8",
    category: "Workers",
    currentYear: "",
    previousYear: "",
  },
];

export const relatedIncidentsInitialvalues = {
  tableData: relatedIncidentsCellData,
  applicable: "",
  turnover: "",
  netWorth: "",
  current: "",
  previous: "",
};

export const relatedIncidentsConstantData = [
  {
    id: 1,
    name: "safety",
    label:
      "12. Describe the measures taken by the entity to ensure a safe and healthy work place. ",
    field: "textField",
  },
];
