import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Typography } from "@mui/material";
import { CustomRadioButton } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  affectedEmployeesColumns,
  affectedEmployeesInitialvalues,
  affectedEmployeesConstantData,
  affectedEmployeesContent,
  STATIC_TABLE,
  affectedEmployeesOptions,
  PRINCIPLE3_AFFECTED_EMPLOYEES,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
}));

const AffectedEmployees = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(affectedEmployeesInitialvalues);
  const { list } = useStyles();

  //to get data from api
  const affectedEmployeesFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_AFFECTED_EMPLOYEES
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    affectedEmployeesFormConfig
      ? updateTableValues(
          PRINCIPLE3_AFFECTED_EMPLOYEES,
          { affectedEmployees: `${jsonStr}` },
          affectedEmployeesFormConfig.id
        )
      : createTableValues(PRINCIPLE3_AFFECTED_EMPLOYEES, {
          affectedEmployees: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (affectedEmployeesFormConfig) {
      setParentData(
        JSON.parse(affectedEmployeesFormConfig.data.affectedEmployees)
      );
    }
  }, [affectedEmployeesFormConfig]);

  const formik = useFormik({
    initialValues: parentData || affectedEmployeesInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, setFieldValue } = formik;

  const renderFormFields = () => {
    return (
      <>
        {affectedEmployeesConstantData.map(({ id, label, name }) => (
          <div key={id}>
            <Typography variant="h6" className={list}>
              {label}
            </Typography>

            <CustomRadioButton
              name={name}
              CSRdetailsOptions={affectedEmployeesOptions}
              onChange={handleChange}
              value={values[name]}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <TableWrapper
      columns={affectedEmployeesColumns(setFieldValue, values)}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={STATIC_TABLE}
      modalContent={affectedEmployeesContent}
      renderFormFields={renderFormFields}
      renderFormFieldBottom={true}
      keyProps={"panel3"}
      chileKeyPros={"principle3"}
    />
  );
};

export default AffectedEmployees;
