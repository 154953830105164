import { apiRoutes } from "./apiPath";
import { appApi } from "./config";

export const loginApi = (value) => {
  return appApi.post(`${apiRoutes.LOG_IN_API}`, value);
};

export const forgetPasswordApi = (value) => {
  return appApi.put(`${apiRoutes.FORGET_PASSWORD}`, value);
};

export const resetPasswordApi = (data) => {
  return appApi.put(`${apiRoutes.RESET_PASSWORD}`, data);
};

export const createUser = (data) => {
  return appApi.post(`${apiRoutes.CREATE_USER}`, data);
};

export const updateUser = (id, data) => {
  console.log(id, data);
  return appApi.put(`${apiRoutes.UPDATE_USER}/${id}`, data);
};

export const rejectUser = (id, data) => {
  console.log(id, data);
  return appApi.put(`${apiRoutes.REJECT_USER}`, data);
};

export const getUsersDetails = (data, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_USERS_DETAILS}/${pageSize}/${offset}`,
    data
  );
};

export const getUsersDetailsSearch = (data, searchdata, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_USERS_DETAILS}/${pageSize}/${offset}?search=${searchdata}`,
    data
  );
};

export const getAllStatus = () => {
  return appApi.get(`${apiRoutes.GET_ALL_STATUS}`);
};

export const updateUserStatus = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_USER_STATUS}/${id}`, data);
};

export const getUsersDetailsById = (id) => {
  return appApi.get(`${apiRoutes.GET_USERS_DETAILS_BY_ID}/${id}`);
};

export const getAllCustomerDetails = (data, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_CUSTOMERS_DETAILS}/${pageSize}/${offset}`,
    data
  );
};

export const getAllCustomerDetailsSearch = (searchData, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_CUSTOMERS_DETAILS}/${pageSize}/${offset}?search=${searchData}`
  );
};
export const getAllInvoiceDetails = (data, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_INVOICES_DETAILS}/${pageSize}/${offset}`,
    data
  );
};

export const getAllInvoiceDetailsSearch = (
  data,
  searchdata,
  pageSize,
  offset
) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_INVOICES_DETAILS}/${pageSize}/${offset}?search=${searchdata}`,
    data
  );
};

export const getPaymentStatus = () => {
  return appApi.get(`${apiRoutes.GET_PAYMENT_STATUS}`);
};

export const userAccessApi = (data) => {
  return appApi.post(`${apiRoutes.USER_ACCESS}`, data);
};

export const getCustomerDetailsById = (id) => {
  return appApi.get(`${apiRoutes.GET_CUSTOMERS_DETAILS_BY_ID}/${id}`);
};

export const updateCustomerDetails = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_CUSTOMERS_DETAILS}/${id}`, data);
};

export const changePassword = (data) => {
  return appApi.put(`${apiRoutes.CHANGE_PASSWORD}`, data);
};

export const invoiceDownload = (id) => {
  return appApi.get(`${apiRoutes.INVOICES_DOWNLOAD}/${id}`);
};

export const customerDownloadDetails = () => {
  return appApi.get(`${apiRoutes.CUSTOMER_DOWNLOAD_DETAILS}`);
};

export const getFormDataByUser = (userId) => {
  return appApi.get(`${apiRoutes.GET_FORM_DATA_BY_USER}/${userId}`);
};

export const userDownloadDetails = (userTypeId) => {
  return appApi.get(`${apiRoutes.USER_DOWNLOAD_DETAILS}/${userTypeId}`);
};

export const createCustomerDetails = (data) => {
  return appApi.post(`${apiRoutes.CREATE_CUSTOMERS_DETAILS}`, data);
};

export const createInvoiceDetails = (data) => {
  return appApi.post(`${apiRoutes.CREATE_INVOICE_DETAILS}`, data);
};

export const updateInvoiceDetails = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_INVOICE_DETAILS}/${id}`, data);
};
export const getCustomers = () => {
  return appApi.get(`${apiRoutes.GET_CUSTOMERS_ID}`);
};

export const updatePaymentStatus = (id) => {
  return appApi.post(`${apiRoutes.UPDATE_INVOICE_DETAILS}/${id}`);
};

export const getInvoiceDetailsById = (id) => {
  return appApi.get(`${apiRoutes.GET_INVOICE_DETAILS_BY_ID}/${id}`);
};

export const getInvoiceDownload = (id) => {
  return appApi.get(`${apiRoutes.GET_INVOICE_DOWNLOAD}/${id}`);
};

export const invoiceSetupDownload = () => {
  return appApi.get(`${apiRoutes.INVOICES_DOWNLOAD}`);
};

export const getAllCustomerDashboard = (pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_CUSTOMERS_DETAILS}/${pageSize}/${offset}`
  );
};

export const getGraphDetails = (data) => {
  return appApi.post(`${apiRoutes.GET_GRAPH_DETAILS}`, data);
};

export const getInvoiceGraphDetails = (data) => {
  return appApi.post(`${apiRoutes.GET_INVOICE_GRAPH_DETAILS}`, data);
};

export const getUserGraphDetails = (data) => {
  return appApi.post(`${apiRoutes.GET_USER_GRAPH_DETAILS}`, data);
};

export const sendReminder = (userTypeId, invoiceId) => {
  return appApi.put(`${apiRoutes.SEND_REMAINDER}/${userTypeId}/${invoiceId}`);
};
