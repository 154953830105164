import React, { useState } from "react";
import Context from "./Context";

function ContextState(props) {
  const [saveFile, setSaveFile] = useState(false);
  const [BRSRReportRef, setBRSRReportRef] = useState(false);
  const [businessActivitiesTotalData, setBusinessActivitiesTotalData] =
    useState(null);
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState("");
  const [userUpdateId, setUserUpdateId] = useState("");
  const [openCelenderOpen, setOpenCelenderOpen] = useState(false);

  return (
    <Context.Provider
      value={{
        saveFile,
        setSaveFile,
        BRSRReportRef,
        setBRSRReportRef,
        businessActivitiesTotalData,
        setBusinessActivitiesTotalData,
        modal,
        setModal,
        editData,
        setEditData,
        userUpdateId,
        setUserUpdateId,
        openCelenderOpen,
        setOpenCelenderOpen,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export default ContextState;
