import { EditableCell } from "../../../../components/utils/EditableCell";

export const publicPolicyColumns = [
  {
    Header: "S. No.",
    accessor: "sNO",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "Public policy advocated",
    accessor: "advocated",
    Cell: EditableCell,
  },
  {
    Header: "Method resorted for such advocacy",
    accessor: "resorted",
    Cell: EditableCell,
  },
  {
    Header: "Whether information available in public domain? (Yes/No) ",
    accessor: "domain",
    Cell: EditableCell,
  },
  {
    Header:
      "Frequency of Review by Board (Annually/ Half yearly/ Quarterly / Others – please specify) ",
    accessor: "board",
    Cell: EditableCell,
  },
  {
    Header: "Web Link, if available",
    accessor: "webLink",
    Cell: EditableCell,
  },
];

const columnName = [{ id: 1 }, { id: 2 }, { id: 3 }];
export const publicPolicyCellData = columnName.map((item) => ({
  id: item.id,
  sNO: "",
  advocated: "",
  resorted: "",
  domain: "",
  board: "",
  webLink: "",
}));
