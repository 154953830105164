import { EditableCell } from "../../../../components/utils/EditableCell";

export const monetaryColumns = [
  {
    Header: "",
    accessor: "values",
  },
  {
    Header: "NGRBC Principle",
    accessor: "NGRBCPrinciple",
    Cell: EditableCell,
  },
  {
    Header:
      "Name of the regulatory/ enforcement agencies/ judicial institutions",
    accessor: "institutions",
    Cell: EditableCell,
  },
  {
    Header: "Amount (In INR)",
    accessor: "amount",
    Cell: EditableCell,
    type: "number",
  },
  {
    Header: "Brief of the Case ",
    accessor: "briefCase",
    // Cell: EditableCell,
  },
  {
    Header: "Has an appeal been preferred? (Yes/No)",
    accessor: "appeal",
    Cell: EditableCell,
  },
];

export const monetaryCellData = [
  {
    id: "1",
    values: "Penalty/ Fine",
    NGRBCPrinciple: "",
    institutions: "",
    amount: "",
    briefCase: "SB",
    appeal: "",
  },
  {
    id: "2",
    values: "Settlement",
    NGRBCPrinciple: "",
    institutions: "",
    amount: "",
    briefCase: "SB",
    appeal: "",
  },
  {
    id: "3",
    values: "Compounding fee",
    NGRBCPrinciple: "",
    institutions: "",
    amount: "",
    briefCase: "SB",
    appeal: "",
  },
];

export const monetaryInitialvalues = {
  tableData: monetaryCellData,
  fine: "",
  settlement: "",
  fee: "",
};

export const monetaryConstantData = [
  {
    id: 1,
    label: "Penalty/ Fine Case Brief: ",
    name: "fine",
    accessor: "fine",
  },
  {
    id: 2,
    label: "Settlement Case Brief:",
    name: "settlement",
    accessor: "settlement",
  },
  {
    id: 3,
    label: "Compounding fee Case Brief:",
    name: "fee",
    accessor: "fee",
  },
];
