import * as Tables from "../components/pages/tables/sectionC/Index";

export const sectionC = [
  //principle 1
  {
    path: "/sectionC/principle1/essentialIndicators",
    component: <Tables.EssentialIndicators />,
  },
  {
    path: "/sectionC/principle1/monetary",
    component: <Tables.Monetary />,
  },
  {
    path: "/sectionC/principle1/nonMonetary",
    component: <Tables.NonMonetary />,
  },
  {
    path: "/sectionC/principle1/caseDetails",
    component: <Tables.CaseDetails />,
  },
  {
    path: "/sectionC/principle1/briberyCorruption",
    component: <Tables.BriberyCorruption />,
  },
  {
    path: "/sectionC/principle1/conflictOfInterest",
    component: <Tables.ConflictOfInterest />,
  },
  {
    path: "/sectionC/principle1/leadershipIndicators",
    component: <Tables.LeadershipIndicators />,
  },

  //principle 2
  {
    path: "/sectionC/principle2/essentialIndicators",
    component: <Tables.EssentialIndicatorsP2 />,
  },
  {
    path: "/sectionC/principle2/productReclamationBox",
    component: <Tables.ProductRecalmation />,
  },
  {
    path: "/sectionC/principle2/assessments",
    component: <Tables.Assessments />,
  },
  {
    path: "/sectionC/principle2/riskConcern",
    component: <Tables.RiskConcern />,
  },
  {
    path: "/sectionC/principle2/recycledReused",
    component: <Tables.RecycledReused />,
  },
  {
    path: "/sectionC/principle2/productsPackaging",
    component: <Tables.ProductsPackaging />,
  },
  {
    path: "/sectionC/principle2/productsSold",
    component: <Tables.ProductsSold />,
  },

  //principle - 3

  {
    path: "/sectionC/principle3/employeesCovered",
    component: <Tables.EmployeesCovered />,
  },
  {
    path: "/sectionC/principle3/workersCovered",
    component: <Tables.WorkersCovered />,
  },
  {
    path: "/sectionC/principle3/retirementBenefits",
    component: <Tables.RetirementBenefits />,
  },
  {
    path: "/sectionC/principle3/accessibility",
    component: <Tables.Accessibility />,
  },
  {
    path: "/sectionC/principle3/parentalLeave",
    component: <Tables.ParentalLeave />,
  },
  {
    path: "/sectionC/principle3/mechanism",
    component: <Tables.Mechanism />,
  },
  {
    path: "/sectionC/principle3/membershipOfEmployees",
    component: <Tables.Membership />,
  },
  {
    path: "/sectionC/principle3/training",
    component: <Tables.Training />,
  },

  {
    path: "/sectionC/principle3/performance",
    component: <Tables.Performance />,
  },
  {
    path: "/sectionC/principle3/managementSystem",
    component: <Tables.ManagementSystem />,
  },
  {
    path: "/sectionC/principle3/relatedIncidents",
    component: <Tables.RelatedIncidents />,
  },
  {
    path: "/sectionC/principle3/complaints",
    component: <Tables.Complaints />,
  },
  {
    path: "/sectionC/principle3/assessmentP3",
    component: <Tables.AssessmentP3 />,
  },
  {
    path: "/sectionC/principle3/lifeInsurance",
    component: <Tables.LifeInsurance />,
  },
  {
    path: "/sectionC/principle3/affectedEmployees",
    component: <Tables.AffectedEmployees />,
  },
  {
    path: "/sectionC/principle3/detailsOnAssessment",
    component: <Tables.DetailsOnAssessment />,
  },

  //principle - 4

  {
    path: "/sectionC/principle4/essentialIndicatorsP4",
    component: <Tables.EssentialIndicatorsP4 />,
  },
  {
    path: "/sectionC/principle4/leadershipIndicatorsP4",
    component: <Tables.LeadershipIndicatorsP4 />,
  },

  //principle - 5

  {
    path: "/sectionC/principle5/essentialIndicatorsP5",
    component: <Tables.EssentialIndicatorsP5 />,
  },
  {
    path: "/sectionC/principle5/minimumWages",
    component: <Tables.MinimumWages />,
  },
  {
    path: "/sectionC/principle5/remunerationSalary",
    component: <Tables.RemunerationSalary />,
  },
  {
    path: "/sectionC/principle5/numberOfComplaints",
    component: <Tables.NumberOfComplaints />,
  },
  {
    path: "/sectionC/principle5/assessmentsP5",
    component: <Tables.AssessmentsP5 />,
  },
  {
    path: "/sectionC/principle5/grievances",
    component: <Tables.Grievances />,
  },
  {
    path: "/sectionC/principle5/detailsOnAssessmentP5",
    component: <Tables.DetailsOnAssessmentP5 />,
  },

  //principle - 6

  {
    path: "/sectionC/principle6/essentialIndicatorsP6",
    component: <Tables.EssentialIndicatorsP6 />,
  },
  {
    path: "/sectionC/principle6/disclosuresWater",
    component: <Tables.DisclosuresWater />,
  },
  {
    path: "/sectionC/principle6/airEmissions",
    component: <Tables.AirEmissions />,
  },
  {
    path: "/sectionC/principle6/greenhouseGas",
    component: <Tables.GreenhouseGas />,
  },
  {
    path: "/sectionC/principle6/wasteManagement",
    component: <Tables.WasteManagement />,
  },
  {
    path: "/sectionC/principle6/operationsOffices",
    component: <Tables.OperationsOffices />,
  },
  {
    path: "/sectionC/principle6/environmentalAssessments",
    component: <Tables.EnvironmentalAssessments />,
  },
  {
    path: "/sectionC/principle6/pollution",
    component: <Tables.Pollution />,
  },
  {
    path: "/sectionC/principle6/energyConsumed",
    component: <Tables.EnergyConsumed />,
  },
  {
    path: "/sectionC/principle6/waterDischarged",
    component: <Tables.WaterDischarged />,
  },
  {
    path: "/sectionC/principle6/waterWithdrawal",
    component: <Tables.WaterWithdrawal />,
  },
  {
    path: "/sectionC/principle6/emissions",
    component: <Tables.Emissions />,
  },
  {
    path: "/sectionC/principle6/effluentDischarge",
    component: <Tables.EffluentDischarge />,
  },
  {
    path: "/sectionC/principle6/managementPlan",
    component: <Tables.ManagementPlan />,
  },

  //principle - 7

  {
    path: "/sectionC/principle7/chambers",
    component: <Tables.Chambers />,
  },
  {
    path: "/sectionC/principle7/corrective",
    component: <Tables.Corrective />,
  },
  {
    path: "/sectionC/principle7/publicPolicy",
    component: <Tables.PublicPolicy />,
  },

  //principle - 8

  {
    path: "/sectionC/principle8/SIA",
    component: <Tables.ImpactAssessments />,
  },
  {
    path: "/sectionC/principle8/R&R",
    component: <Tables.Rehabilitation />,
  },
  {
    path: "/sectionC/principle8/inputMaterial",
    component: <Tables.InputMaterial />,
  },
  {
    path: "/sectionC/principle8/negativeImpacts",
    component: <Tables.NegativeImpacts />,
  },
  {
    path: "/sectionC/principle8/CSR",
    component: <Tables.Projects />,
  },
  {
    path: "/sectionC/principle8/marginalized",
    component: <Tables.Marginalized />,
  },
  {
    path: "/sectionC/principle8/intellectualProperty",
    component: <Tables.IntellectualProperty />,
  },
  {
    path: "/sectionC/principle8/corrective",
    component: <Tables.CorrectiveP8 />,
  },
  {
    path: "/sectionC/principle8/beneficiariesCSR",
    component: <Tables.Beneficiaries />,
  },

  //principle - 9

  {
    path: "/sectionC/principle9/turnover",
    component: <Tables.Turnover />,
  },
  {
    path: "/sectionC/principle9/consumerComplaints",
    component: <Tables.ConsumerComplaints />,
  },
  {
    path: "/sectionC/principle9/instances",
    component: <Tables.Instances />,
  },
  {
    path: "/sectionC/principle9/cyberSecurity",
    component: <Tables.CyberSecurity />,
  },
  {
    path: "/sectionC/principle9/correctiveActionP9",
    component: <Tables.CorrectiveActionP9 />,
  },
  {
    path: "/sectionC/principle9/channels",
    component: <Tables.Channels />,
  },
];
