import React from "react";
import styled from "@emotion/styled";
import { Tabs } from "@mui/material";

export const StyledTabs = styled(({ name, className, ...other }) => {
  return (
    <Tabs
      {...other}
      classes={{
        root: className,
        flexContainer: "flexContainer",
        indicator: "indicator",
      }}
      variant="fullWidth"
      TabIndicatorProps={{
        children: (
          <span
            style={
              name === "superAdmin"
                ? {
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: "3px 3px 0px 0px",
                    opacity: 1,
                    height: "4px",
                  }
                : {
                    background: "#E46D35 0% 0% no-repeat padding-box",
                    borderRadius: "3px 3px 0px 0px",
                    opacity: 1,
                    height: "4px",
                  }
            }
          />
        ),
      }}
      centered
    />
  );
})({
  "& .indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "4px",

    "& > span": {
      maxWidth: 60,
      width: "100%",
    },
  },
  "& .flexContainer": {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // width:'fit-content'
  },
});
