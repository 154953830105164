import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CustomRadioButton } from "../../../../reusable/index";
import { useFormik } from "formik";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  leadershipIndicatorsColumns,
  leadershipIndicatorsInitialvalues,
  leadershipIndicatorsContent,
  ROW_ADDINGTABLE,
  leadershipIndicatorsConstantDatas,
  PRINCIPLE1_LEADERSHIP_INDICATORS,
  CSRdetailsOptions,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
}));

const LeadershipIndicators = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(
    leadershipIndicatorsInitialvalues
  );
  const { list } = useStyles();

  //to get data from api
  const leadershipIndicatorsFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE1_LEADERSHIP_INDICATORS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    leadershipIndicatorsFormConfig
      ? updateTableValues(
          PRINCIPLE1_LEADERSHIP_INDICATORS,
          { leadershipIndicators: `${jsonStr}` },
          leadershipIndicatorsFormConfig.id
        )
      : createTableValues(PRINCIPLE1_LEADERSHIP_INDICATORS, {
          leadershipIndicators: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (leadershipIndicatorsFormConfig) {
      console.log(leadershipIndicatorsFormConfig);
      //setParentData( JSON.parse(leadershipIndicatorsFormConfig.data.leadershipIndicators));
    }
  }, [leadershipIndicatorsFormConfig]);

  const formik = useFormik({
    initialValues: parentData || leadershipIndicatorsInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange } = formik;

  const renderFormFields = () => {
    return (
      <>
        {leadershipIndicatorsConstantDatas.map(({ id, label, name }) => (
          <div key={id}>
            <Typography variant="h6" className={list}>
              {label}
            </Typography>

            <CustomRadioButton
              name={name}
              CSRdetailsOptions={CSRdetailsOptions}
              onChange={handleChange}
              value={values[name]}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <TableWrapper
      columns={leadershipIndicatorsColumns}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={ROW_ADDINGTABLE}
      modalContent={leadershipIndicatorsContent}
      renderFormFields={renderFormFields}
      renderFormFieldBottom={true}
      keyProps={"panel3"}
      chileKeyPros={"principle1"}
    />
  );
};

export default LeadershipIndicators;
