import { EditableCell } from "../../../components/utils/EditableCell";

export const QuestionsDropdown = [
  { id: 1, name: "NA", acccessor: "name" },
  { id: 2, name: "SB", acccessor: "name" },
];

export const QuestionsColumns = [
  {
    Header: "Questions",
    accessor: "questions",
  },
  {
    Header: "P1 ",
    accessor: "p1",
    Cell: EditableCell,
  },
  {
    Header: "P2 ",
    accessor: "p2",
    Cell: EditableCell,
  },
  {
    Header: "P3 ",
    accessor: "p3",
    Cell: EditableCell,
  },
  {
    Header: "P4 ",
    accessor: "p4",
    Cell: EditableCell,
  },
  {
    Header: "P5 ",
    accessor: "p5",
    Cell: EditableCell,
  },
  {
    Header: "P6 ",
    accessor: "p6",
    Cell: EditableCell,
  },
  {
    Header: "P7 ",
    accessor: "p7",
    Cell: EditableCell,
  },
  {
    Header: "P8 ",
    accessor: "p8",
    Cell: EditableCell,
  },
  {
    Header: "P9 ",
    accessor: "p9",
    Cell: EditableCell,
  },
];

export const QuestionsCellData = [
  {
    id: "1",
    questions:
      "The entity does not consider the Principles material to its business (Yes/No) ",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
  },
  {
    id: "2",
    questions:
      "The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No) ",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
  },
  {
    id: "3",
    questions:
      "The entity does not have the financial or/human and technical resources available for the task (Yes/No) ",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
  },
  {
    id: "4",
    questions: "It is planned to be done in the next financial year (Yes/No) ",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
  },
  {
    id: "5",
    questions: "Any other reason (please specify) ",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
    dropdownRow: true,
  },
];

export const QuestionsInitialvalues = {
  tableData: QuestionsCellData,
};

export const QuestionsConstantData = [
  {
    id: 1,
    label: "Reason - P1",
    name: "p1",
    accessor: "p1",
    rowId: 5,
  },
  {
    id: 2,
    label: "Reason - P2",
    name: "p2",
    accessor: "p2",
    rowId: 5,
  },
  {
    id: 3,
    label: "Reason - P3",
    name: "p3",
    accessor: "p3",
    rowId: 5,
  },
  {
    id: 4,
    label: "Reason - P4",
    name: "p4",
    accessor: "p4",
    rowId: 5,
  },
  {
    id: 5,
    label: "Reason - P5",
    name: "p5",
    accessor: "p5",
    rowId: 5,
  },
  {
    id: 6,
    label: "Reason - P6",
    name: "p6",
    accessor: "p6",
    rowId: 5,
  },
  {
    id: 7,
    label: "Reason - P7",
    name: "p7",
    accessor: "p7",
    rowId: 5,
  },
  {
    id: 8,
    label: "Reason - P8",
    name: "p8",
    accessor: "p8",
    rowId: 5,
  },
  {
    id: 9,
    label: "Reason - P9",
    name: "p9",
    accessor: "p9",
    rowId: 5,
  },
];
