import { makeStyles } from "@mui/styles";

export const CSRdetailsStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    top: "125px",
  },
  rootAlign: {
    padding: "20px 30px",
    width: "calc(100vw - 430px)",
    position: "relative",
    left: "350px",
  },

  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "50px 50px 0 50px",
  },
  tableNavbarStyle: {
    position: "fixed",
    width: "100%",
    zIndex: 100,
  },
  accordionContainer: {
    position: "fixed",
    zIndex: 100,
    height: "calc(100vh - 128px)",
    borderRight: "2px solid #021256",
    width: "350px",
    overflowY: "scroll",
    backgroundColor: "white",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  accordionContainerAlign: {
    position: "fixed",
    zIndex: 100,
    height: "calc(100vh - 58px)",
    borderRight: "2px solid #021256",
    width: "350px",
    overflowY: "scroll",
    backgroundColor: "white",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
}));
