import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const emissionsOptions = ["Yes", "No"];

export const emissionsColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Parameter",
      accessor: "values",
    },
    {
      Header: "Please specify unit",
      accessor: "unit",
      Cell: EditableCell,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
  ];
};

const columnName = [
  {
    id: 1,
    values:
      "Total Scope 3 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
    unit: "Metric tonnes of CO2 equivalent",
  },
  { id: 2, values: "Total Scope 3 emissions per rupee of turnover" },
  {
    id: 3,
    values:
      "Total Scope 3 emission intensity (optional) – the relevant metric may be selected by the entity",
  },
];
export const emissionsCellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  unit: item.unit ? item.unit : "",
  currentYear: "",
  previousYear: "",
}));

export const emissionsInitialvalues = {
  tableData: emissionsCellData,
  independent: "",
  biodiversity: "",
  previous: "",
  current: "",
};

export const emissionsConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency.",
    field: "radioButton",
  },
  {
    id: 2,
    name: "biodiversity",
    label:
      "5. With respect to the ecologically sensitive areas reported at Question 10 of Essential Indicators above, provide details of significant direct & indirect impact of the entity on biodiversity in such areas along-with prevention and remediation activities. ",
    field: "textField",
  },
];
