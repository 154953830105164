import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  nonMonetaryColumns,
  nonMonetaryContent,
  nonMonetaryConstantData,
  nonMonetaryInitialvalues,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE1_NONMONETARY,
} from "../../../../../constant/index";
import { TableWrapper, CustomTextarea } from "../../../../reusable/index";
import { useFormik } from "formik";
import { Modal, Typography } from "@mui/material";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const NonMonetary = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(nonMonetaryInitialvalues);

  const formik = useFormik({
    initialValues: parentData || nonMonetaryInitialvalues,
    enableReinitialize: true,
  });

  const { values, handleChange, errors, touched, handleBlur } = formik;

  //to get data from api
  const nonMonetaryFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE1_NONMONETARY
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE1_NONMONETARY,
        { nonMonetary: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      nonMonetaryFormConfig
        ? updateTableValues(
            PRINCIPLE1_NONMONETARY,
            { nonMonetary: `${jsonStr}` },
            nonMonetaryFormConfig.id
          )
        : createTableValues(PRINCIPLE1_NONMONETARY, {
            nonMonetary: `${jsonStr}`,
          });
    }
  };

  //  fetch db data
  useEffect(() => {
    if (nonMonetaryFormConfig && nonMonetaryFormConfig.data.nonMonetary) {
      setParentData(JSON.parse(nonMonetaryFormConfig.data.nonMonetary));
    }
  }, [nonMonetaryFormConfig]);

  let forTextArea =
    nonMonetaryFormConfig &&
    !nonMonetaryFormConfig.data.nonMonetary &&
    nonMonetaryFormConfig.data.map(
      (data) => data.nonMonetary && JSON.parse(data.nonMonetary)
    );
  console.log(forTextArea);

  const renderFormFields = (tableData) => {
    return (
      <>
        {nonMonetaryConstantData.map(({ id, rowId, label, name, accessor }) => {
          let filterData = tableData?.filter((item) => {
            return parseInt(item.id) === rowId;
          });
          return forTextArea
            ? forTextArea.map(
                (data) =>
                  filterData && (
                    <div key={id} style={{ margin: 8 }}>
                      <Typography
                        variant="subtitle"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {label}

                        <CustomTextarea
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={data[name]}
                          error={touched[name] && errors[name]}
                          helperText={touched[name] && errors[name]}
                          variant="outlined"
                          logInField
                          style={{
                            width: "75%",
                            background: "white",
                            paddingBottom: "0px ",
                          }}
                        />
                      </Typography>
                    </div>
                  )
              )
            : filterData && (
                <div key={id} style={{ margin: 8 }}>
                  <Typography
                    variant="subtitle"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {label}

                    <CustomTextarea
                      name={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[name]}
                      error={touched[name] && errors[name]}
                      helperText={touched[name] && errors[name]}
                      variant="outlined"
                      logInField
                      style={{
                        width: "75%",
                        background: "white",
                        paddingBottom: "0px ",
                      }}
                    />
                  </Typography>
                </div>
              );
        })}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={nonMonetaryColumns}
        storeTableData={storeTableData}
        cellData={
          nonMonetaryFormConfig && nonMonetaryFormConfig.data.length > 1
            ? nonMonetaryFormConfig.data
            : parentData.tableData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        modalContent={nonMonetaryContent}
        renderFormFields={renderFormFields}
        renderFormFieldBottom={true}
        keyProps={"panel3"}
        chileKeyPros={"principle1"}
        hideHelp={true}
        cloneActive={false}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={nonMonetaryColumns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={nonMonetaryContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default NonMonetary;
