import React, { useContext, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { Typography, Box, Button } from "@mui/material";
import ElectronicFormContext from "../../../../../Context";
import { CSRdetailsStyles } from "../../../../../styles";
import Cookies from "universal-cookie";
import {
  CustomizedAccordions,
  CustomizedSnackbars,
  CustomTextarea,
  TableNavbar,
} from "../../../../reusable";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  correctiveActionP9Constants,
  correctiveActionP9Initialvalues,
} from "../../../../../constant/tableData/sectionC/principle9/correctiveActionP9";
import { PRINCIPLE9_CORRECTIVE_ACTION } from "../../../../../constant";

function CorrectiveActionP9() {
  const {
    root,
    rootAlign,
    btnContainer,
    tableNavbarStyle,
    accordionContainer,
    accordionContainerAlign,
  } = CSRdetailsStyles();

  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const correctiveActionP9FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE9_CORRECTIVE_ACTION
    );
  }, [existingTableData]);

  const storeTableData = (formData) => {
    let jsonStr = JSON.stringify(formData);

    correctiveActionP9FormConfig
      ? updateTableValues(
          PRINCIPLE9_CORRECTIVE_ACTION,
          { correctiveActionP9: `${jsonStr}` },
          correctiveActionP9FormConfig.id
        )
      : createTableValues(PRINCIPLE9_CORRECTIVE_ACTION, {
          correctiveActionP9: `${jsonStr}`,
        });
  };

  //  fetch db data
  useEffect(() => {
    if (correctiveActionP9FormConfig && correctiveActionP9FormConfig.data.correctiveActionP9) {
      setParentData(
        JSON.parse(correctiveActionP9FormConfig.data.correctiveActionP9)
      );
    }
  }, [correctiveActionP9FormConfig]);

  //   snackbar
  const handleClickSnackBar = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // formik
  const onSubmit = (values) => {
    storeTableData(values);
    handleClickSnackBar();
  };

  const formik = useFormik({
    initialValues: parentData || correctiveActionP9Initialvalues,
    onSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;
  return (
    <>
      {userTypeName !== "Company User" && (
        <div className={tableNavbarStyle}>
          <TableNavbar />
        </div>
      )}
      <div className={userTypeName !== "Company User" && root}>
        <div
          className={
            userTypeName !== "Company User"
              ? accordionContainer
              : accordionContainerAlign
          }
        >
          <CustomizedAccordions
            keyProps={"panel3"}
            chileKeyPros={"principle9"}
          />
        </div>
        <div className={rootAlign}>
          {/* <Typography variant="h6">{CSRdetailsContent.title}</Typography> */}

          {correctiveActionP9Constants.map(
            ({ id, name, label, type, field }) => (
              <div key={id} style={{ margin: 10 }}>
                <Typography
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {label}
                  {field === "textField" && (
                    <CustomTextarea
                      type={type}
                      name={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[name]}
                      error={touched[name] && errors[name]}
                      helperText={touched[name] && errors[name]}
                      variant="outlined"
                      logInField
                      style={{
                        width: "85%",
                        background: "white",
                        paddingBottom: "0px ",
                      }}
                    />
                  )}
                </Typography>
              </div>
            )
          )}

          <Box className={btnContainer}>
            <Button variant="outlined" color="primary" onClick={resetForm}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>

          <CustomizedSnackbars open={open} handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export default CorrectiveActionP9;
