import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  trainingColumns,
  trainingInitialvalues,
  trainingContent,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE3_TRAINING,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";
import { Modal } from "@mui/material";

const Training = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(trainingInitialvalues);

  const formik = useFormik({
    initialValues: parentData || trainingInitialvalues,
    enableReinitialize: true,
  });

  const { values, setFieldValue } = formik;
  //to get data from api
  const trainingFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_TRAINING
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE3_TRAINING,
        { training: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      trainingFormConfig
        ? updateTableValues(
            PRINCIPLE3_TRAINING,
            { training: `${jsonStr}` },
            trainingFormConfig.id
          )
        : createTableValues(PRINCIPLE3_TRAINING, {
            training: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (trainingFormConfig && trainingFormConfig.data.training) {
      setParentData(JSON.parse(trainingFormConfig.data.training));
    }
  }, [trainingFormConfig]);

  return (
    <>
      <TableWrapper
        columns={trainingColumns(setFieldValue, values)}
        storeTableData={storeTableData}
        cellData={
          trainingFormConfig && !trainingFormConfig.data.training
            ? trainingFormConfig.data
            : parentData.tableData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        modalContent={trainingContent}
        keyProps={"panel3"}
        chileKeyPros={"principle3"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={trainingColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={trainingContent}
          />
        </div>
      </Modal>
    </>
  );
};

export default Training;
