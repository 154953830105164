import { EditableCell } from "../../../../components/utils/EditableCell";

export const leadershipIndicatorsColumns = [
  {
    Header: "Total number of awareness programmes held",
    accessor: "awareness ",
    Cell: EditableCell,
  },
  {
    Header: "Topics / principles covered under the training",
    accessor: "principles",
    Cell: EditableCell,
  },
  {
    Header:
      "% age of value chain partners covered (by value of business done with such partners) under the awareness programmes",
    accessor: "partners",
    Cell: EditableCell,
    type: "number",
    maxLength: 3,
  },
];

export const leadershipIndicatorsCellData = [
  {
    id: "1",
    awareness: "",
    principles: "",
    partners: "",
  },
];

export const leadershipIndicatorsInitialvalues = {
  tableData: leadershipIndicatorsCellData,
  members: "",
};

export const leadershipIndicatorsConstantDatas = [
  {
    id: 1,
    label:
      "2. Does the entity have processes in place to avoid/ manage conflict of interests involving members of the Board? (Yes/No) If Yes, provide details of the same.",
    name: "members",
  },
];
