import * as yup from "yup";
import {
  MOBILE_NUMBER_PATTERN,
  SPECIAL_CHARACTER_PATTERN,
} from "../utils/regexPattern";

//login page validation
export const logInPageValidation = () => {
  const messages = {
    emailId: ["Email Id is required", "Enter valid email id"],
    password: ["Password is required"],
  };

  return yup.object().shape({
    emailId: yup
      .string()
      .trim()
      .required(messages.emailId[0])
      .email(messages.emailId[1]),
    password: yup.string().trim().required(messages.password[0]),
  });
};

//forget password validation
export const forgetPasswordValidation = () => {
  const messages = {
    emailId: ["Email Id is required"],
  };
  return yup.object().shape({
    emailId: yup.string().trim().required(messages.emailId[0]),
  });
};

//update Password validation
export const updatePasswordValidation = () => {
  const messages = {
    password: ["Password is required"],
    confirmNewPassword: ["Confirm New Password is required"],
  };

  return yup.object().shape({
    password: yup.string().trim().required(messages.password[0]),
    confirmNewPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required(messages.confirmNewPassword[0]),
  });
};

//my user validation

export const myUserValidation = () => {
  const messages = {
    name: ["Name is Required"],
    emailId: ["Email is Required", "Enter the valid email"],
    mobileNo: ["Mobile number must be in 10 digit"],
    password: ["Password is required"],
    confirmPassword: ["Confirm  Password is required"],
  };

  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(messages.name[0])
      .matches(SPECIAL_CHARACTER_PATTERN, "Enter the valid name"),
    emailId: yup
      .string()
      .email(messages.emailId[1])
      .trim()
      .required(messages.emailId[0]),

    mobileNo: yup
      .string()
      .required(messages.mobileNo[0])
      .matches(MOBILE_NUMBER_PATTERN, "Phone number is not valid"),

    password: yup.string().trim().required(messages.password[0]),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
};

export const changePasswordToSchema = () => {
  const messages = {
    oldPassword: ["Old Password is required"],
    newPassword: ["New Password is required"],
    confirmNewPassword: ["Confirm New Password is required"],
  };

  return yup.object().shape({
    oldPassword: yup.string().trim().required(messages.oldPassword[0]),
    newPassword: yup.string().trim().required(messages.newPassword[0]),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required(messages.confirmNewPassword[0]),
  });
};

export const companyAdminEditProfileValidation = () => {
  const messages = {
    name: ["Name is Required"],
    emailId: ["Email is Required", "Enter the valid email"],
    mobileNo: ["Mobile number must be in 10 digit"],
  };

  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(messages.name[0])
      .matches(SPECIAL_CHARACTER_PATTERN, "Enter the valid name"),
    emailId: yup
      .string()
      .email(messages.emailId[1])
      .trim()
      .required(messages.emailId[0]),

    mobileNo: yup
      .string()
      .required(messages.mobileNo[0])
      .matches(MOBILE_NUMBER_PATTERN, "Phone number is not valid"),
  });
};

export const updateStatusValidation = () => {
  const messages = {
    statusId: ["Status is required"],
    effectiveDate: ["Effective Date is required"],
    reason: ["Reason field is required"],
  };

  return yup.object().shape({
    statusId: yup.string().trim().required(messages.statusId[0]),
    effectiveDate: yup.string().trim().required(messages.effectiveDate[0]),
    reason: yup.string().trim().required(messages.reason[0]),
  });
};


export const updateStatusValidationStatus = () => {
  const messages = {
    statusId: ["Status is required"],
    reason: ["Reason field is required"],
  };

  return yup.object().shape({
    statusId: yup.string().trim().required(messages.statusId[0]),
    reason: yup.string().trim().required(messages.reason[0]),
  });
};

export const invoiceSetupValidation = () => {
  const messages = {
    invoiceNo: ["Invoice No is required"],
    amount: ["Amount Date is required"],
    invoice: ["Upload Invoice field is required"],
  };

  return yup.object().shape({
    invoiceNo: yup.string().trim().required(messages.invoiceNo[0]),
    amount: yup.string().trim().required(messages.amount[0]),
    invoice: yup.string().trim().required(messages.invoice[0]),
  });
};
