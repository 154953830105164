import { EditableCell } from "../../../../components/utils/EditableCell";

export const assessmentsP5Columns = [
  {
    Header: " ",
    accessor: "values",
    Cell: EditableCell,
  },
  {
    Header:
      "% of your plants and offices that were assessed (by entity or statutory authorities orthird parties)",
    accessor: "plants",
    type: "number",
    maxLength: 3,
    Cell: EditableCell,
  },
];

const columnName = [
  { id: 1, values: "Child labour " },
  { id: 2, values: "Forced/involuntary labour " },
  { id: 3, values: "Sexual harassment" },
  { id: 4, values: "Discrimination at workplace" },
  { id: 5, values: "Wages" },
  { id: 6, values: "Others – please specify" },
];
export const assessmentsP5CellData = columnName.map((item) => ({
  id: item.id,
  values: item.values,
  plants: "",
}));

export const assessmentsP5Initialvalues = {
  tableData: assessmentsP5CellData,
  safety: "",
};

export const assessmentsP5ConstantData = [
  {
    id: 1,
    label:
      "10. Provide details of any corrective actions taken or underway to address significant risks / concerns arising from the assessments at Question 9 above. ",
    name: "safety",
    upload: "uploadImage",
  },
];
