import { EditableCell } from "../../../components/utils/EditableCell";

export const jointVenturesColumns = [
  {
    Header: "S.No",
    accessor: "sNo",
    type: "number",
    Cell: EditableCell,
  },
  {
    Header:
      "Name of the holding / subsidiary / associate companies / joint ventures (A)",
    accessor: "name",
    Cell: EditableCell,
  },
  {
    Header: "Indicate whether holding/ Subsidiary/ Associate/ Joint Venture",
    accessor: "indicate",
    Cell: EditableCell,
  },
  {
    Header: "% of shares held by listed entity",
    accessor: "shares",
    type: "number",
    maxLength: 3,
    Cell: EditableCell,
  },
  {
    Header:
      "Does the entity indicated at column A, participate in the  Business Responsibility initiatives of the listed entity? (Yes/No) ",
    accessor: "participate",
    Cell: EditableCell,
  },
];

export const jointVenturesCellData = [
  {
    "id": 1,
    "sNo": "",
    "name": "",
    "indicate": "",
    "shares": "",
    "participate": ""
  },
  { 
    "id": 2,
    "sNo": "",
    "name": "",
    "indicate": "",
    "shares": "",
    "participate": ""
  }
];
