import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import { Typography } from "@mui/material";
import { CustomRadioButton, CustomTextField } from "../../../../reusable/index";
import CustomImageUpload from "../../../../reusable/CustomImageUpload";
import { makeStyles } from "@mui/styles";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { useFormik } from "formik";
import {
  EssentialIndicatorsP2Columns,
  EssentialIndicatorsP2Initialvalues,
  essentialIndicatorsP2Content,
  STATIC_TABLE,
  essentialIndicatorsP2ConstantData,
  PRINCIPLE2_ESSENTIAL_INDICATORS,
  CSRdetailsOptions,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
}));

const EssentialIndicatorsP2 = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(
    EssentialIndicatorsP2Initialvalues
  );
  const { list } = useStyles();

  //to get data from api
  const essentialIndicatorsP2FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE2_ESSENTIAL_INDICATORS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    essentialIndicatorsP2FormConfig
      ? updateTableValues(
          PRINCIPLE2_ESSENTIAL_INDICATORS,
          { essentialIndicatorsP2: `${jsonStr}` },
          essentialIndicatorsP2FormConfig.id
        )
      : createTableValues(PRINCIPLE2_ESSENTIAL_INDICATORS, {
          essentialIndicatorsP2: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (essentialIndicatorsP2FormConfig) {
      // setParentData(
      //   JSON.parse(essentialIndicatorsP2FormConfig.data.essentialIndicatorsP2)
      // );
    }
  }, [essentialIndicatorsP2FormConfig]);

  const formik = useFormik({
    initialValues: parentData || EssentialIndicatorsP2Initialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, handleBlur } = formik;

  const renderFormFields = () => {
    return (
      <>
        {essentialIndicatorsP2ConstantData.map(
          ({ id, label, name, field, type, upload }) => (
            <div key={id}>
              <Typography variant="subtitle" className={list}>
                {label}
              </Typography>
              {field === "textField" && (
                <CustomTextField
                  name={name}
                  onChange={handleChange}
                  value={values[name]}
                  type={type}
                  onBlur={handleBlur}
                  variant="outlined"
                />
              )}
              {field === "radioButton" && (
                <CustomRadioButton
                  name={name}
                  CSRdetailsOptions={CSRdetailsOptions}
                  onChange={handleChange}
                  value={values[name]}
                />
              )}
              <div className="fileUplodeTab">
             {upload === "uploadImage" && (
               <CustomImageUpload />
             )}
            </div>
            </div>
          )
        )}
      </>
    );
  };

  return (
    <TableWrapper
      columns={EssentialIndicatorsP2Columns}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={STATIC_TABLE}
      modalContent={essentialIndicatorsP2Content}
      renderFormFields={renderFormFields}
      renderFormFieldBottom={true}
      hideHelp={true}
      keyProps={"panel3"}
      chileKeyPros={"principle2"}
      cloneActive={false}
      fileUplode={true}
    />
  );
};

export default EssentialIndicatorsP2;
