import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateTableValues,
} from "../../../../../api/method";
import { Typography } from "@mui/material";
import { CustomTextField } from "../../../../reusable/index";
import { makeStyles } from "@mui/styles";
import {
  detailsOnAssessmentColumns,
  detailsOnAssessmentInitialvalues,
  detailsOnAssessmentConstantData,
  detailsOnAssessmentContent,
  STATIC_TABLE,
  PRINCIPLE3_DETAILS_ON_ASSESSMENT,
} from "../../../../../constant/index";
import { TableWrapper } from "../../../../reusable/index";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "block",
  },
  contentStyle: {
    marginTop: "20px",
  },
}));

const DetailsOnAssessment = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const [parentData, setParentData] = useState(
    detailsOnAssessmentInitialvalues
  );
  const { list, contentStyle } = useStyles();

  //to get data from api
  const detailsOnAssessmentFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE3_DETAILS_ON_ASSESSMENT
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    detailsOnAssessmentFormConfig
      ? updateTableValues(
          PRINCIPLE3_DETAILS_ON_ASSESSMENT,
          { detailsOnAssessment: `${jsonStr}` },
          detailsOnAssessmentFormConfig.id
        )
      : createTableValues(PRINCIPLE3_DETAILS_ON_ASSESSMENT, {
          detailsOnAssessment: `${jsonStr}`,
        });
  };
  //  fetch db data
  useEffect(() => {
    if (detailsOnAssessmentFormConfig) {
      // setParentData(
      //   JSON.parse(detailsOnAssessmentFormConfig.data.detailsOnAssessment)
      // );
    }
  }, [detailsOnAssessmentFormConfig]);

  const formik = useFormik({
    initialValues: parentData || detailsOnAssessmentInitialvalues,

    enableReinitialize: true,
  });
  const { values, handleChange, handleBlur } = formik;

  const renderFormFields = () => {
    return (
      <>
        {detailsOnAssessmentConstantData.map(({ id, label, name }) => (
          <div key={id} className={contentStyle}>
            <Typography variant="h6" className={list}>
              {label}
            </Typography>

            <CustomTextField
              name={name}
              onChange={handleChange}
              value={values[name]}
              onBlur={handleBlur}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <TableWrapper
      columns={detailsOnAssessmentColumns}
      storeTableData={storeTableData}
      cellData={parentData.tableData}
      tableType={STATIC_TABLE}
      modalContent={detailsOnAssessmentContent}
      renderFormFields={renderFormFields}
      renderFormFieldBottom={true}
      keyProps={"panel3"}
      chileKeyPros={"principle3"}
    />
  );
};

export default DetailsOnAssessment;
