import React, { useContext, useEffect, useState, useMemo } from "react";
import ElectronicFormContext from "../../../../Context";
import { TableWrapper } from "../../../reusable/index";
import {
  transparencyAndDisclosureColumns,
  transparencyAndDisclosureInitialvalues,
  transparencyAndDisclosuresContents,
  SECTIOA_TRANSPARENCYANDDISCLOSURE,
  STATIC_TABLE,
} from "../../../../constant/index";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../api/method";
import { useFormik } from "formik";
import Cookies from "universal-cookie";
import Context from "../../../../context/Context";
import { Modal } from "@mui/material";

function TransparencyAndDisclosures() {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  const [parentData, setParentData] = useState(
    transparencyAndDisclosureInitialvalues
  );
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const formik = useFormik({
    initialValues: parentData || transparencyAndDisclosureInitialvalues,
    enableReinitialize: true,
  });

  const { values, setFieldValue } = formik;
  const transparencyFormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === SECTIOA_TRANSPARENCYANDDISCLOSURE
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        SECTIOA_TRANSPARENCYANDDISCLOSURE,
        { transparencyAndDisclosures: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      transparencyFormConfig
        ? updateTableValues(
            SECTIOA_TRANSPARENCYANDDISCLOSURE,
            { transparencyAndDisclosures: `${jsonStr}` },
            transparencyFormConfig.id
          )
        : createTableValues(SECTIOA_TRANSPARENCYANDDISCLOSURE, {
          transparencyAndDisclosures: `${jsonStr}`,
          });
    }
  };
  let transparencyAndDisclosureDataWithUserId =
    existingTableData &&
    existingTableData.filter(
      (data) =>
        data.section.split("/").join("").slice(8) ===
        "transparencyAndDisclosures"
    );
  transparencyAndDisclosureDataWithUserId =
    transparencyAndDisclosureDataWithUserId &&
    transparencyAndDisclosureDataWithUserId.map((data) => data.data).flat();

  let forDownLoadFile =
    existingTableData &&
    existingTableData.filter(
      (data) =>
        data.section.split("/").join("").slice(8) ===
        "transparencyAndDisclosures"
    );
  forDownLoadFile =
    forDownLoadFile && forDownLoadFile.map((data) => data.document_link);

  //    fetch db data
  useEffect(() => {
    if (
      transparencyFormConfig &&
      transparencyAndDisclosureDataWithUserId.length <= 1
    ) {
      setParentData(
        JSON.parse(transparencyFormConfig.data.transparencyAndDisclosures)
      );
    }
  }, [transparencyFormConfig]);
  return (
    <>
      {transparencyAndDisclosureDataWithUserId &&
      transparencyAndDisclosureDataWithUserId.length > 1 ? (
        <TableWrapper
          columns={transparencyAndDisclosureColumns(setFieldValue, values)}
          cellData={transparencyAndDisclosureDataWithUserId}
          storeTableData={storeTableData}
          tableType={STATIC_TABLE}
          modalContent={transparencyAndDisclosuresContents}
          keyProps={"panel1"}
          cloneActive={true}
          fileUplode={true}
        />
      ) : (
        <TableWrapper
          columns={transparencyAndDisclosureColumns(setFieldValue, values)}
          cellData={parentData.tableData}
          storeTableData={storeTableData}
          tableType={STATIC_TABLE}
          modalContent={transparencyAndDisclosuresContents}
          keyProps={"panel1"}
          cloneActive={true}
          fileUplode={true}
          fileDownload={forDownLoadFile}
        />
      )}
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={transparencyAndDisclosureColumns(setFieldValue, values)}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={STATIC_TABLE}
            modalContent={transparencyAndDisclosuresContents}
          />
        </div>
      </Modal>
    </>
  );
}

export default TransparencyAndDisclosures;
