export const turnoverContent = [
  {
    id: "1",
    title:
      "PRINCIPLE 9: Businesses should engage with and provide value to their consumers in a responsible manner",
    aboutTable:
      "1. Describe the mechanisms in place to receive and respond to consumer complaints and feedback.  ",
    subAboutTable:
      "2. Turnover of products and/ services as a percentage of turnover from all products/service that carry information about:",
  },
];
