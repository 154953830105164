import { EditableCell } from "../../../../components/utils/EditableCell";
import { CustomTextField } from "../../../../components/reusable/index";

export const wasteManagementOptions = ["Yes", "No"];

export const wasteManagementColumns = (setFieldValue, rate) => {
  return [
    {
      Header: "Parameter",
      accessor: "values",
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="current"
            type="number"
            headerField
            value={rate?.current || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Current Financial Year)
        </span>
      ),
      accessor: "currentYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
    {
      Header: (props) => (
        <span>
          FY
          <CustomTextField
            name="previous"
            type="number"
            headerField
            value={rate?.previous || ""}
            setFieldValue={setFieldValue}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
          />
          (Previous Financial Year)
        </span>
      ),
      accessor: "previousYear",
      type: "number",
      maxLength: 4,
      Cell: EditableCell,
    },
  ];
};

const columnName = [
  {
    id: 1,
    values: "Total Waste generated (in metric tonnes)",
    fullwidth: true,
  },
  { id: 2, values: "Plastic waste (A)" },
  { id: 3, values: "E-waste (B)" },
  { id: 4, values: "Bio-medical waste (C)" },
  { id: 5, values: "Construction and demolition waste (D)", fullwidth: true },
  { id: 6, values: "Battery waste (E)" },
  { id: 7, values: "Radioactive waste (F)" },
  { id: 8, values: "Other Hazardous waste. Please specify, if any. (G)" },
  {
    id: 9,
    values:
      "Other Non-hazardous waste generated (H). Please specify, if any. (Break-up by composition i.e. by materials relevant to the sector)",
  },
  { id: 10, values: "Total (A+B + C + D + E + F + G + H) " },
  {
    id: 11,
    values:
      "For each category of waste generated, total waste recovered through recycling, re-using or other recovery operations (in metric tonnes)",
    fullwidth: true,
  },
  { id: 12, values: "Category of waste" },
  { id: 13, values: "(i) Recycled" },
  { id: 14, values: "(ii) Re-used" },
  { id: 15, values: "(iii) Other recovery operations" },
  { id: 16, values: "Total " },
  {
    id: 17,
    values:
      "For each category of waste generated, total waste disposed by nature of disposal method (in metric tonnes)",
    fullwidth: true,
  },
  { id: 18, values: "Category of waste" },
  { id: 19, values: "(i) Incineration" },
  { id: 20, values: "(ii) Landfilling" },
  { id: 21, values: "(iii) Other disposal operations" },
  { id: 22, values: "Total" },
];
export const wasteManagementCellData = columnName.map((item) =>
  item.fullwidth
    ? {
        id: item.id,
        values: item.values,
        fullwidth: item.fullwidth,
      }
    : {
        id: item.id,
        values: item.values,
        currentYear: "",
        previousYear: "",
      }
);

export const wasteManagementInitialvalues = {
  tableData: wasteManagementCellData,
  independent: "",
  agency: "",
  chemicals: "",
  current: "",
  previous: "",
};

export const wasteManagementConstantData = [
  {
    id: 1,
    name: "independent",
    label:
      "Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N)",
    field: "radioButton",
  },
  {
    id: 2,
    name: "agency",
    label: "If yes, name of the external agency.",
    field: "textField",
  },
  {
    id: 3,
    name: "chemicals",
    label:
      "9. Briefly describe the waste management practices adopted in your establishments. Describe the strategy adopted by your company to reduce usage of hazardous and toxic chemicals in your products and processes and the practices adopted to manage such wastes.",
    field: "textField",
  },
];
