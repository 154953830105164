import React, { useContext, useState, useMemo, useEffect } from "react";
import ElectronicFormContext from "../../../../../Context";
import {
  createTableValues,
  updateAdminTableValues,
  updateTableValues,
} from "../../../../../api/method";
import {
  essentialIndicatorsP4Columns,
  essentialIndicatorsP4CellData,
  essentialIndicatorsP4Content,
  essentialIndicatorsP4Initialvalues,
  essentialIndicatorsP4ConstantData,
  ROW_FULLWIDTH_TABLE,
  PRINCIPLE4_ESSENTIAL_INDICATORS,
} from "../../../../../constant/index";
import { TableWrapper, CustomTextField } from "../../../../reusable/index";
import { Modal, Typography } from "@mui/material";
import { useFormik } from "formik";
import Context from "../../../../../context/Context";
import Cookies from "universal-cookie";

const EssentialIndicatorsP4 = () => {
  const existingTableData = useContext(ElectronicFormContext);
  const contextData = useContext(Context);
  const { modal, setModal, editData, userUpdateId } = contextData;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");
  const style = {
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #eeeeee",
    boxShadow: 24,
    p: 4,
  };

  const [parentData, setParentData] = useState(
    essentialIndicatorsP4Initialvalues
  );

  // formik
  const formik = useFormik({
    initialValues: parentData || essentialIndicatorsP4Initialvalues,
    enableReinitialize: true,
  });
  const { values, handleChange, errors, touched, handleBlur, resetForm } =
    formik;

  //to get data from api
  const essentialIndicatorsP4FormConfig = useMemo(() => {
    return existingTableData?.find(
      (item) => item.section === PRINCIPLE4_ESSENTIAL_INDICATORS
    );
  }, [existingTableData]);

  const storeTableData = (tableData) => {
    let jsonStr = JSON.stringify({ ...values, tableData });

    if (userTypeName === "Company Admin" && modal) {
      updateAdminTableValues(
        PRINCIPLE4_ESSENTIAL_INDICATORS,
        { essentialIndicatorsP4: `${jsonStr}` },
        userUpdateId
      );
      setTimeout(() => {
        window.location.reload();
        setModal(false);
      }, 100);
    } else {
      essentialIndicatorsP4FormConfig
        ? updateTableValues(
            PRINCIPLE4_ESSENTIAL_INDICATORS,
            { essentialIndicatorsP4: `${jsonStr}` },
            essentialIndicatorsP4FormConfig.id
          )
        : createTableValues(PRINCIPLE4_ESSENTIAL_INDICATORS, {
            essentialIndicatorsP4: `${jsonStr}`,
          });
    }
  };
  //  fetch db data
  useEffect(() => {
    if (
      essentialIndicatorsP4FormConfig &&
      essentialIndicatorsP4FormConfig.data.essentialIndicatorsP4
    ) {
      setParentData(
        JSON.parse(essentialIndicatorsP4FormConfig.data.essentialIndicatorsP4)
      );
    }
  }, [essentialIndicatorsP4FormConfig]);

  let forTextArea =
    essentialIndicatorsP4FormConfig &&
    !essentialIndicatorsP4FormConfig.data.essentialIndicatorsP4 &&
    essentialIndicatorsP4FormConfig.data.map(
      (data) =>
        data.essentialIndicatorsP4 && JSON.parse(data.essentialIndicatorsP4)
    );

  const renderFormFields = () => {
    return forTextArea ? (
      <>
        {essentialIndicatorsP4ConstantData.map(({ id, label, name }) =>
          forTextArea.map((data) => (
            <div key={id}>
              <Typography variant="subtitle">
                {label}

                <CustomTextField
                  name={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={data[name]}
                  error={touched[name] && errors[name]}
                  helperText={touched[name] && errors[name]}
                  variant="outlined"
                  logInField
                  style={{
                    width: "350px",
                    background: "white",
                    paddingBottom: "0px ",
                    marginBottom: 20,
                  }}
                />
              </Typography>
            </div>
          ))
        )}
      </>
    ) : (
      <>
        {essentialIndicatorsP4ConstantData.map(({ id, label, name }) => (
          <div key={id}>
            <Typography variant="subtitle">
              {label}

              <CustomTextField
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name]}
                error={touched[name] && errors[name]}
                helperText={touched[name] && errors[name]}
                variant="outlined"
                logInField
                style={{
                  width: "350px",
                  background: "white",
                  paddingBottom: "0px ",
                  marginBottom: 20,
                }}
              />
            </Typography>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <TableWrapper
        columns={essentialIndicatorsP4Columns}
        storeTableData={storeTableData}
        cellData={
          essentialIndicatorsP4FormConfig &&
          !essentialIndicatorsP4FormConfig.data.essentialIndicatorsP4
            ? essentialIndicatorsP4FormConfig.data
            : parentData.tableData
        }
        tableType={ROW_FULLWIDTH_TABLE}
        renderFormFields={renderFormFields}
        renderFormFieldTop={true}
        modalContent={essentialIndicatorsP4Content}
        hideHelp={true}
        keyProps={"panel3"}
        chileKeyPros={"principle4"}
        cloneActive={true}
        fileUplode={true}
      />
      <Modal open={modal}>
        <div className="hideother" style={style}>
          <TableWrapper
            columns={essentialIndicatorsP4Columns}
            cellData={editData}
            storeTableData={storeTableData}
            tableType={ROW_FULLWIDTH_TABLE}
            modalContent={essentialIndicatorsP4Content}
          />
        </div>
      </Modal>
    </>
  );
};

export default EssentialIndicatorsP4;
