export const toggleButtonSecA = [
  {
    id: 1,
    isCheck: false,
    name: "Listed Entity Details",
    value: "listedEntity",
    path: "/sectionA/listedEntity",
    tooltipContent: "Details of the listed entity",
  },
  {
    id: 2,
    isCheck: false,
    name: "Business Activities",
    value: "businessActivities",
    path: "/sectionA/businessActivities",
    tooltipContent:
      "Details of business activities (accounting for 90% of the turnover):",
  },
  {
    id: 3,
    isCheck: false,
    name: "Product/Services by entity",
    value: "productServicesByEntity",
    path: "/sectionA/productServicesByEntity",
    tooltipContent:
      "Products/Services sold by the entity (accounting for 90% of the entity’s Turnover):",
  },
  {
    id: 4,
    isCheck: false,
    name: "Operations",
    value: "numberOfLocations",
    path: "/sectionA/numberOfLocations",
    tooltipContent:
      "Number of locations where plants and/or operations/offices of the entity are situated:",
  },
  {
    id: 5,
    isCheck: false,
    name: "Markets served by entity",
    value: "marketsServedEntity",
    path: "/sectionA/marketsServedEntity",
    tooltipContent: "Markets served by the entity:",
  },
  {
    id: 6,
    isCheck: false,
    name: "Employees and Workers",
    value: "employeesAndWorkers",
    path: "/sectionA/employeesAndWorkers",
    tooltipContent: " Employees and workers (including differently abled):",
  },
  {
    id: 7,
    isCheck: false,
    name: "Differently abled Employees and Workers",
    value: "differentlyAbledEmployeesAndWorkers",
    path: "/sectionA/differentAbled",
    tooltipContent: "Differently abled Employees and workers:",
  },
  {
    id: 8,
    isCheck: false,
    name: "Participation of Women",
    value: "participationOfWomen",
    path: "/sectionA/participationOfWomen",
    tooltipContent: "Participation/Inclusion/Representation of women",
  },
  {
    id: 9,
    isCheck: false,
    name: "Employee-Worker Turnover",
    value: "turnoverRate",
    path: "/sectionA/turnOverRate",
    tooltipContent: "Turnover rate for permanent employees and workers",
  },
  {
    id: 10,
    isCheck: false,
    name: "Holding, Subsidiary, Associate companies/ Joint ventures",
    value: "jointVentures",
    path: "/sectionA/jointVentures",
    tooltipContent:
      "Names of holding / subsidiary / associate companies / joint ventures",
  },
  {
    id: 11,
    isCheck: false,
    name: "CSR Details",
    value: "csrDetails",
    path: "/sectionA/CSRdetails",
    tooltipContent: "CSR Details",
  },
  {
    id: 12,
    isCheck: false,
    name: "Transparency and Disclosures Compliances",
    value: "transparencyAndDisclosures",
    path: "/sectionA/transparencyAndDisclosures",
    tooltipContent:
      "Complaints/Grievances on any of the principles (Principles 1 to 9) under the National Guidelines on Responsible Business Conduct:",
  },
  {
    id: 13,
    isCheck: false,
    name: "Overview; Material Business Conduct Issues",
    value: "overviewEntity",
    path: "/sectionA/overviewEntity",
    tooltipContent:
      "Overview of the entity’s material responsible business conduct issues",
  },
];

export const toggleButtonSecB = [
  {
    id: 1,
    isCheck: false,
    name: "Polices and management processes",
    tooltipContent:
      "This section is aimed at helping businesses demonstrate the structures, policies and processes put in place towards adopting the NGRBC Principles and Core Elements.",
    value: "disclosureQuestions",
    path: "/sectionB/disclosureQuestions",
  },
  {
    id: 2,
    isCheck: false,
    name: "Governance, leadership and oversight",
    tooltipContent:
      "Business responsibility report, highlighting ESG related challenges, targets and achievements.",
    value: "governanceLeadership",
    path: "/sectionB/governanceLeadership",
  },

  {
    id: 3,
    isCheck: false,
    name: "Company Review of Principles",
    tooltipContent: "Details of Review of NGRBCs by the Company:",
    value: "reviewOfNGRBCs",
    path: "/sectionB/reviewOfNGRBCS",
  },
  {
    id: 4,
    isCheck: false,
    name: "Independent Assessment of Priciples",
    tooltipContent:
      "Has the entity carried out independent  assessment/ evaluation of the working of  its policies by an external agency?",
    value: "assessmentEvaluation",
    path: "/sectionB/assessmentEvaluation",
  },
  {
    id: 5,
    isCheck: false,
    name: "Addressing the Principles",
    tooltipContent:
      "If answer to question (1) above is “No” i.e. not all Principles are covered by a policy, reasons to be stated:",
    value: "questions",
    path: "/sectionB/questions",
  },
];

export const principles = [
  {
    id: "1",
    isCheck: false,
    name: "PRINCIPLE 1",
    value: "principle1",
    key: "principle1",
    buttons: [
      {
        id: 1,
        isCheck: false,
        name: "Principles training and awareness",
        value: "essentialIndicators",
        path: "/sectionC/principle1/essentialIndicators",
        tooltipContent:
          "Percentage coverage by training and awareness programmes on any of the Principles during the financial year:",
      },
      {
        id: 2,
        isCheck: false,
        name: "Monetary penalities",
        value: "monetary",
        path: "/sectionC/principle1/monetary",
        tooltipContent:
          "Details of fines / penalties /punishment/ award/ compounding fees/ settlement amount",
      },
      {
        id: 3,
        isCheck: false,
        name: "Non-Monetary penalities",
        value: "nonMonetary",
        path: "/sectionC/principle1/nonMonetary",
        tooltipContent:
          "Details of fines / penalties /punishment/ award/ compounding fees/ settlement amount",
      },
      {
        id: 4,
        isCheck: false,
        name: "Case Details",
        value: "caseDetails",
        path: "/sectionC/principle1/caseDetails",
        tooltipContent:
          "Of the instances disclosed in Question 2 above, details of the Appeal/ Revision preferred in cases where monetary or non-monetary action has been appealed.",
      },
      {
        id: 5,
        isCheck: false,
        name: "Bribery / Corruption",
        value: "briberyCorruption",
        path: "/sectionC/principle1/briberyCorruption",
        tooltipContent:
          "Number of Directors/KMPs/employees/workers against whom disciplinary action was taken by any law enforcement agency for the charges of bribery/ corruption:",
      },
      {
        id: 6,
        isCheck: false,
        name: "Conflict of Interest",
        value: "conflictOfInterest",
        path: "/sectionC/principle1/conflictOfInterest",
        tooltipContent:
          "Details of complaints with regard to conflict of interest:",
      },
      {
        id: 7,
        isCheck: false,
        name: "Leadership Indicators",
        value: "leadershipIndicators",
        leadershipIndicators: true,
        path: "/sectionC/principle1/leadershipIndicators",
        tooltipContent:
          "Awareness programmes conducted for value chain partners on any of the Principles during the financial year:",
      },
    ],
  },
  {
    id: "2",
    isCheck: false,
    name: "PRINCIPLE 2",
    key: "principle2",
    value: "principle2",
    buttons: [
      {
        id: 1,
        isCheck: false,
        name: "Sustainability R&D, Capex and sourcing",
        value: "essentialIndicatorsP2",
        path: "/sectionC/principle2/essentialIndicators",
        tooltipContent:
          ". Percentage of R&D and capital expenditure (capex) investments in specific technologies to improve the environmental and social impacts of product and processes to total R&D and capex investments made by the entity, respectively.",
      },
      {
        id: 2,
        isCheck: false,
        name: "Product Reclamation And ERP box,",
        value: "productReclamationBox",
        path: "/sectionC/principle2/productReclamationBox",
        tooltipContent:
          "Place to safely reclaim your products for reusing, recycling and disposing at the end of life",
      },
      {
        id: 3,
        isCheck: false,
        name: "Life Cycle Perspective / Assessments (LCA)",
        value: "assessments",
        leadershipIndicators: true,
        path: "/sectionC/principle2/assessments",
        tooltipContent:
          "Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its  products (for manufacturing industry) or for its services (for service industry)? If yes, provide details in the following format?",
      },
      {
        id: 4,
        isCheck: false,
        name: "Description of the risk / concern",
        value: "riskConcern",
        leadershipIndicators: true,
        path: "/sectionC/principle2/riskConcern",
        tooltipContent:
          "If there are any significant social or environmental concerns and/or risks arising from  production or disposal of your products / services, as identified in the Life Cycle Perspective / Assessments (LCA) or through any other means, briefly describe the same along-with action taken to mitigate the same.",
      },
      {
        id: 5,
        isCheck: false,
        name: "Percentage of recycled or reused",
        value: "recycledReused",
        leadershipIndicators: true,
        path: "/sectionC/principle2/recycledReused",
        tooltipContent:
          "Percentage of recycled or reused input material to total material (by value) used in production (for manufacturing industry) or providing services (for service industry).",
      },
      {
        id: 6,
        isCheck: false,
        name: "Products and packaging reclaimed",
        value: "productsPackaging",
        leadershipIndicators: true,
        path: "/sectionC/principle2/productsPackaging",
        tooltipContent:
          "Of the products and packaging reclaimed at end of life of products, amount (in metric tonnes) reused, recycled, and safely disposed, as per the following format:",
      },
      {
        id: 7,
        isCheck: false,
        name: "Percentage of products sold",
        value: "productsSold",
        leadershipIndicators: true,
        path: "/sectionC/principle2/productsSold",
        tooltipContent:
          "Reclaimed products and their packaging materials (as percentage of products sold) for each product category",
      },
    ],
  },
  {
    id: "3",
    isCheck: false,
    name: "PRINCIPLE 3",
    key: "principle3",
    value: "principle3",

    buttons: [
      {
        id: 1,
        isCheck: false,
        name: "Benefits % of employees covered",
        value: "employeesCovered",
        path: "/sectionC/principle3/employeesCovered",
        tooltipContent: "Details of measures for the well-being of employees:",
      },
      {
        id: 2,
        isCheck: false,
        name: "Benefits % of workers covered",
        value: "workersCovered",
        path: "/sectionC/principle3/workersCovered",
        tooltipContent: "Details of measures for the well-being of workers:",
      },
      {
        id: 3,
        isCheck: false,
        name: "Details of retirement benefits",
        value: "retirementBenefits",
        path: "/sectionC/principle3/retirementBenefits",
        tooltipContent:
          "Details of retirement benefits, for Current FY and Previous Financial Year.",
      },
      {
        id: 4,
        isCheck: false,
        name: "Disabled Employees",
        value: "accessibility",
        path: "/sectionC/principle3/accessibility",
        tooltipContent:
          "The entity accessible to differently abled employees and workers, as per the requirements of the Rights of Persons with Disabilities",
      },
      {
        id: 5,
        isCheck: false,
        name: "Retention rates of parental leave",
        value: "parentalLeave",
        path: "/sectionC/principle3/parentalLeave",
        tooltipContent:
          "Return to work and Retention rates of permanent employees and workers that took parental leave.",
      },
      {
        id: 6,
        isCheck: false,
        name: "Mechanism",
        value: "mechanism",
        path: "/sectionC/principle3/mechanism",
        tooltipContent:
          "Is there a mechanism available to receive and redress grievances for the following categories of employees and worker? If yes, give details of the mechanism in brief.",
      },
      {
        id: 7,
        isCheck: false,
        name: "Membership of employees",
        value: "membershipOfEmployees",
        path: "/sectionC/principle3/membershipOfEmployees",
        tooltipContent:
          "Membership of employees and worker in association(s) or Unions recognised by the listed entity:",
      },
      {
        id: 8,
        isCheck: false,
        name: "Employee / Worker training",
        value: "training",
        path: "/sectionC/principle3/training",
        tooltipContent: "Details of training given to employees and workers:",
      },
      {
        id: 9,
        isCheck: false,
        name: "Employee / Worker performance",
        value: "performance",
        path: "/sectionC/principle3/performance",
        tooltipContent:
          "Details of performance and career development reviews of employees and worker:",
      },
      {
        id: 10,
        isCheck: false,
        name: "Safety management system",
        value: "managementSystem",
        path: "/sectionC/principle3/managementSystem",
        tooltipContent:
          "An occupational health and safety management system has been implemented",
      },
      {
        id: 11,
        isCheck: false,
        name: "Safety related incidents",
        value: "relatedIncidents",
        path: "/sectionC/principle3/relatedIncidents",
        tooltipContent:
          "Details of safety related incidents, in the following format:",
      },
      {
        id: 12,
        isCheck: false,
        name: "Number of Complaints",
        value: "complaints",
        path: "/sectionC/principle3/complaints",
        tooltipContent:
          "Number of Complaints on the following made by employees and workers:",
      },
      {
        id: 13,
        isCheck: false,
        name: "Assessments for the year",
        value: "assessmentsP3",
        path: "/sectionC/principle3/assessmentP3",
        tooltipContent: "Assessments for the year:",
      },
      {
        id: 14,
        isCheck: false,
        name: "Life Insurance",
        value: "lifeInsurance",
        leadershipIndicators: true,
        path: "/sectionC/principle3/lifeInsurance",
        tooltipContent:
          "The entity extend any life insurance or any compensatory package in the event of death",
      },
      {
        id: 15,
        isCheck: false,
        name: "Affected employees and workers",
        value: "affectedEmployees",
        leadershipIndicators: true,
        path: "/sectionC/principle3/affectedEmployees",
        tooltipContent:
          "Provide the number of employees / workers having suffered high consequence work- related injury / ill-health / fatalities",
      },
      {
        id: 16,
        isCheck: false,
        name: "Details on assessment",
        value: "detailsOnAssessment",
        leadershipIndicators: true,
        path: "/sectionC/principle3/detailsOnAssessment",
        tooltipContent: "Turnover rate for permanent employees and workers",
      },
    ],
  },
  {
    id: "4",
    isCheck: false,
    name: "PRINCIPLE 4",
    key: "principle4",
    value: "principle4",

    buttons: [
      {
        id: 1,
        isCheck: false,
        name: "Stakeholder identification and engagement",
        value: "essentialIndicatorsP4",
        path: "/sectionC/principle4/essentialIndicatorsP4",
        tooltipContent:
          "List stakeholder groups identified as key for your entity and the frequency of  engagement with each stakeholder group.",
      },
      {
        id: 2,
        isCheck: false,
        name: "Leadership Indicators",
        value: "leadershipIndicatorsP4",
        leadershipIndicators: true,
        path: "/sectionC/principle4/leadershipIndicatorsP4",
        tooltipContent:
          "Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social topics or if consultation is delegated",
      },
    ],
  },
  {
    id: "5",
    isCheck: false,
    name: "PRINCIPLE 5",
    key: "principle5",
    value: "principle5",

    buttons: [
      {
        id: 1,
        isCheck: false,
        name: "Human rights related training",
        value: "essentialIndicatorsP5",
        path: "/sectionC/principle5/essentialIndicatorsP5",
        tooltipContent:
          "Employees and workers who have been provided training on human rights issues and policy(ies) of the entity, in the following format:",
      },
      {
        id: 2,
        isCheck: false,
        name: "Minimum wages information",
        value: "minimumWages",
        path: "/sectionC/principle5/minimumWages",
        tooltipContent:
          "Details of minimum wages paid to employees and workers, in the following format:",
      },
      {
        id: 3,
        isCheck: false,
        name: "Other remuneration /salary /wages",
        value: "remunerationSalary",
        path: "/sectionC/principle5/remunerationSalary",
        tooltipContent:
          "Details of remuneration/salary/wages, in the following format:",
      },
      {
        id: 4,
        isCheck: false,
        name: "Addressing human rights issues",
        value: "numberOfComplaints",
        path: "/sectionC/principle5/numberOfComplaints",
        tooltipContent:
          "Number of Complaints on the following made by employees and workers:",
      },
      {
        id: 5,
        isCheck: false,
        name: "Assessments for the year",
        value: "assessmentsP5",
        path: "/sectionC/principle5/assessmentsP5",
        tooltipContent: "Assessments for the year:",
      },

      {
        id: 6,
        isCheck: false,
        name: "Grievances/complaints",
        value: "grievances",
        leadershipIndicators: true,
        path: "/sectionC/principle5/grievances",
        tooltipContent:
          "Introduced as a result of addressing human rights grievances/complaints",
      },
      {
        id: 7,
        isCheck: false,
        name: "Details on assessment",
        value: "detailsOnAssessmentP5",
        leadershipIndicators: true,
        path: "/sectionC/principle5/detailsOnAssessmentP5",
        tooltipContent: "Details on assessment of value chain partners:",
      },
    ],
  },
  {
    id: "6",
    isCheck: false,
    name: "PRINCIPLE 6",
    key: "principle6",
    value: "principle6",

    buttons: [
      {
        id: 1,
        isCheck: false,
        name: "Energy consumption and intensity",
        value: "essentialIndicatorsP6",
        path: "/sectionC/principle6/essentialIndicatorsP6",
        tooltipContent:
          "Details of total energy consumption (in Joules or multiples) and energy intensity, in the following format:",
      },
      {
        id: 2,
        isCheck: false,
        name: "water",
        value: "disclosuresWater",
        path: "/sectionC/principle6/disclosuresWater",
        tooltipContent:
          "Provide details of the following disclosures related to water, in the following format:",
      },
      {
        id: 3,
        isCheck: false,
        name: "Air emissions (Other than GHG)",
        value: "airEmissions",
        path: "/sectionC/principle6/airEmissions",
        tooltipContent:
          "Please provide details of air emissions (other than GHG emissions) by the entity, in the following format:",
      },
      {
        id: 4,
        isCheck: false,
        name: "GHG emissions details",
        value: "greenhouseGas",
        path: "/sectionC/principle6/greenhouseGas",
        tooltipContent:
          "Provide details of greenhouse gas emissions (Scope 1 and Scope 2 emissions) & its intensity, in the following format:",
      },
      {
        id: 5,
        isCheck: false,
        name: "Waste management",
        value: "wasteManagement",
        path: "/sectionC/principle6/wasteManagement",
        tooltipContent:
          "Provide details related to waste management by the entity, in the following format:",
      },
      {
        id: 6,
        isCheck: false,
        name: "Ecologically sensitive areas",
        value: "operationsOffices",
        path: "/sectionC/principle6/operationsOffices",
        tooltipContent:
          "If the entity has operations/offices in/around ecologically sensitive areas (such as national parks, wildlife sanctuaries, biosphere reserves, wetlands, biodiversity hotspots, forests, coastal regulation zones etc.)",
      },
      {
        id: 7,
        isCheck: false,
        name: "Environmental impact assessments",
        value: "environmentalAssessments",
        path: "/sectionC/principle6/environmentalAssessments",
        tooltipContent:
          "Details of environmental impact assessments of projects undertaken by the entity based on applicable laws, in the current financial year:",
      },
      {
        id: 8,
        isCheck: false,
        name: "Environmental compliance",
        value: "pollution",
        path: "/sectionC/principle6/pollution",
        tooltipContent:
          "Is the entity compliant with the applicable environmental law/ regulations/ guidelines in India; such as the Water (Prevention and Control of Pollution) Act, Air (Prevention and Control of Pollution)",
      },
      {
        id: 9,
        isCheck: false,
        name: "Energy consumed",
        value: "energyConsumed",
        leadershipIndicators: true,
        path: "/sectionC/principle6/energyConsumed",
        tooltipContent:
          "Provide break-up of the total energy consumed (in Joules or multiples) from renewable and non-renewable sources",
      },
      {
        id: 10,
        isCheck: false,
        name: "Water discharged",
        value: "waterDischarged",
        leadershipIndicators: true,
        path: "/sectionC/principle6/waterDischarged",
        tooltipContent:
          "Provide the following details related to water discharged:",
      },
      {
        id: 11,
        isCheck: false,
        name: "Water withdrawal, consumption",
        value: "waterWithdrawal",
        leadershipIndicators: true,
        path: "/sectionC/principle6/waterWithdrawal",
        tooltipContent:
          "Water withdrawal, consumption and discharge in areas of water stress (in kilolitres):",
      },
      {
        id: 12,
        isCheck: false,
        name: "Emissions & its intensity",
        value: "emissions",
        leadershipIndicators: true,
        path: "/sectionC/principle6/emissions",
        tooltipContent:
          "Please provide details of total Scope 3 emissions & its intensity",
      },
      {
        id: 13,
        isCheck: false,
        name: "Emissions / effluent discharge",
        value: "effluentDischarge",
        leadershipIndicators: true,
        path: "/sectionC/principle6/effluentDischarge",
        tooltipContent:
          "If the entity has undertaken any specific initiatives or used innovative technology or solutions to improve resource efficiency, or reduce impact due to emissions / effluent discharge / waste generated",
      },
      {
        id: 14,
        isCheck: false,
        name: "Disaster management plan",
        value: "managementPlan",
        leadershipIndicators: true,
        path: "/sectionC/principle6/managementPlan",
        tooltipContent:
          "the entity have a business continuity and disaster management plan? Give details in 100 words/ web link.",
      },
    ],
  },
  {
    id: "7",
    isCheck: false,
    name: "PRINCIPLE 7",
    key: "principle7",
    value: "principle7",

    buttons: [
      {
        id: 1,
        isCheck: false,
        name: "Chambers/ associations",
        value: "chambers",
        path: "/sectionC/principle7/chambers",
        tooltipContent:
          "List the top 10 trade and industry chambers/ associations (determined based on the  total members of such body) the entity is a member of/ affiliated to.",
      },
      {
        id: 2,
        isCheck: false,
        name: "Anti-competive Correction action ",
        value: "corrective",
        path: "/sectionC/principle7/corrective",
        tooltipContent:
          "Provide details of corrective action taken or underway on any issues related to anti- competitive conduct by the entity, based on adverse orders from regulatory authorities.",
      },
      {
        id: 3,
        isCheck: false,
        name: "Public policy",
        value: "publicPolicy",
        leadershipIndicators: true,
        path: "/sectionC/principle7/publicPolicy",
        tooltipContent:
          "Details of public policy positions advocated by the entity:",
      },
    ],
  },
  {
    id: "8",
    isCheck: false,
    name: "PRINCIPLE 8",
    key: "principle8",
    value: "principle8",

    buttons: [
      {
        id: 1,
        isCheck: false,
        name: "Social Impact Assessments (SIA)",
        value: "impactAssessments",
        path: "/sectionC/principle8/SIA",
        tooltipContent:
          "Details of Social Impact Assessments (SIA) of projects undertaken by the entity based on applicable laws, in the current financial year.",
      },
      {
        id: 2,
        isCheck: false,
        name: "Rehabilitation and Resettlement (R&R)",
        value: "rehabilitation",
        path: "/sectionC/principle8/R&R",
        tooltipContent:
          "Provide information on project(s) for which ongoing Rehabilitation and Resettlement (R&R) is being undertaken by your entity, in the following format:",
      },
      {
        id: 3,
        isCheck: false,
        name: "Local and small suppliers",
        value: "inputMaterial",
        path: "/sectionC/principle8/inputMaterial",
        tooltipContent:
          "Percentage of input material (inputs to total inputs by value) sourced from suppliers:",
      },
      {
        id: 4,
        isCheck: false,
        name: "Negative social impacts",
        value: "negativeImpacts",
        leadershipIndicators: true,
        path: "/sectionC/principle8/negativeImpacts",
        tooltipContent:
          "Provide details of actions taken to mitigate any negative social impacts identified in the Social Impact Assessments (Reference: Question 1 of Essential Indicators above):",
      },
      {
        id: 5,
        isCheck: false,
        name: "CSR projects",
        value: "projects",
        leadershipIndicators: true,
        path: "/sectionC/principle8/CSR",
        tooltipContent:
          "Provide the following information on CSR projects undertaken by your entity in designated aspirational districts as identified by government bodies",
      },
      {
        id: 6,
        isCheck: false,
        name: "Marginalized /vulnerable groups",
        value: "marginalized",
        leadershipIndicators: true,
        path: "/sectionC/principle8/marginalized",
        tooltipContent:
          "A preferential procurement policy where you give preference to purchase from suppliers comprising marginalized /vulnerable groups?",
      },
      {
        id: 7,
        isCheck: false,
        name: "Intellectual Property",
        value: "intellectualProperty",
        leadershipIndicators: true,
        path: "/sectionC/principle8/intellectualProperty",
        tooltipContent:
          "Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity (in the current financial year), based on traditional knowledge:",
      },
      {
        id: 8,
        isCheck: false,
        name: "Corrective action taken",
        value: "correctiveP8",
        leadershipIndicators: true,
        path: "/sectionC/principle8/corrective",
        tooltipContent:
          "Details of corrective actions taken or underway, based on any adverse order in intellectual property related disputes wherein usage of traditional knowledge is involved.",
      },
      {
        id: 9,
        isCheck: false,
        name: "Beneficiaries of CSR Projects",
        value: "beneficiaries",
        leadershipIndicators: true,
        path: "/sectionC/principle8/beneficiariesCSR",
        tooltipContent: "Details of beneficiaries of CSR Projects:",
      },
    ],
  },
  {
    id: "9",
    isCheck: false,
    name: "PRINCIPLE 9",
    key: "principle9",
    value: "principle9",

    buttons: [
      {
        id: 1,
        isCheck: false,
        name: "Customer response / information needed",
        value: "turnover",
        path: "/sectionC/principle9/turnover",
        tooltipContent:
          "Turnover of products and/ services as a percentage of turnover from all products/service that carry information about:",
      },
      {
        id: 2,
        isCheck: false,
        name: "Consumer complaints",
        value: "consumerComplaints",
        path: "/sectionC/principle9/consumerComplaints",
        tooltipContent:
          "Number of consumer complaints in respect of the following:",
      },
      {
        id: 3,
        isCheck: false,
        name: "Product recall safety issues",
        value: "instances",
        path: "/sectionC/principle9/instances",
        tooltipContent:
          "Details of instances of product recalls on account of safety issues:",
      },
      {
        id: 4,
        isCheck: false,
        name: "Cyber Security And Data Privacy",
        value: "cyberSecurity",
        path: "/sectionC/principle9/cyberSecurity",
        tooltipContent:
          " Framework/ policy on cyber security and risks related to data privacy",
      },
      {
        id: 5,
        isCheck: false,
        name: "Product/Services Corrective Action",
        value: "correctiveActionP9",
        path: "/sectionC/principle9/correctiveActionP9",
        tooltipContent:
          "Details of instances of product recalls on account of safety issues:",
      },
      {
        id: 6,
        isCheck: false,
        name: "Channels / platforms",
        value: "channels",
        leadershipIndicators: true,
        path: "/sectionC/principle9/channels",
        tooltipContent:
          "Channels / platforms where information on products and services of the entity can be accessed (provide web link, if available)",
      },
    ],
  },
];
