export const chartData = (xAxis, yAxis) => {
  return {
    labels: xAxis ? xAxis : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    datasets: [
      {
        id: 1,
        label: "Users",
        data: yAxis ? yAxis : [],
        backgroundColor: ["#081f57"],
        borderWidth: 1,
        barThickness: 16,
      },
    ],
  };
};

export const chartOptions = (min, max) => {
  return {
    scales: {
      xAxes: {
        title: {
          display: true,
          text: "Months",
          color: "#111010",
        },
      },
      yAxes: {
        title: {
          display: true,
          text: "Users",
          color: "#111010",
        },
        min: 0,
        max: parseInt(max) + parseInt(min) || 0,
      },
    },
  };
};
