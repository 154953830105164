export const caseDetailsContent = [
  {
    id: "1",
    title: "SECTION C: PRINCIPLE WISE PERFORMANCE DISCLOSURE",
    subTitle:
      "3. Of the instances disclosed in Question 2 above, details of the Appeal/ Revision preferred in cases where monetary or non-monetary action has been appealed.",
    aboutTable:
      "3. Of the instances disclosed in Question 2 above, details of the Appeal/ Revision preferred in cases where monetary or non-monetary action has been appealed.",
  },
];
